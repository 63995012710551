import { Button } from '@mui/material'
import React from 'react'
import { MapContainer, Polyline, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
const CustomOpenStreetMap = (props) => {
    const { onNavigate, text = "", userLocation, showButton = true, showDisabled = false, showPolyline = false, data = [] } = props;

    const blurredMapStyle = {
        height: '100vh',
        pointerEvents: 'none',
        opacity: '0.3'
    };
    const mapStyle = {
        height: '100vh',
    };

    const centeredContainerStyle = {
        position: 'absolute',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '65vw'
    };
    const buttonStyles = {
        background: 'transparent',
        borderRadius: '20px',
        border: '2px solid #000000',
        color: '#000000',
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#000000',
        },
        padding: '10px 20px',
    };
    return (
        <>
            {showButton ? <div style={centeredContainerStyle}>
                <Button variant='outlined' style={buttonStyles} onClick={onNavigate}>{text}</Button>
            </div> : <></>}
            <MapContainer
                center={showPolyline ? data[0] : [userLocation.lat, userLocation.lng]}
                style={showDisabled ? blurredMapStyle : mapStyle}
                zoom={18}
                zoomControl={showPolyline}
            >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {showPolyline ? <Polyline pathOptions={{ color: 'green' }} positions={data} /> : <></>}
            </MapContainer>
        </ >
    )
}

export default CustomOpenStreetMap