import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Tooltip, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import ControlChip from "./ControlChip";
import { HotKeys, GlobalHotKeys } from "react-hotkeys";
import Error from "./Error";
import InteractiveViewer from "./InteractiveViewer";
import { useTooltipContext } from "../../contexts/TooltipContext";

const SimpleDialogBox = ({ fullScreen = false, showCancel = true, zoomable = false, errorMessage = '', showError = false, width = 500, minWidth = 400, onClose, onPositive = ((dataMap) => { }), showPositive = false, open = false, title = "", desc = null, bodyWidget = <></>, cancelText = "Cancel", positiveText = "Positive", positiveButtonWidget = null, titleWidget = null, shouldTitleAppear = true, showCancelShortcutOnLeft = false, showShortcut = false, disableEscape = false }) => {
    const dialogRef = useRef(null)

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const handlePositive = (e) => {
        if (showPositive) {
            onPositive(e);
        }
    };

    const preventDefaultHandlers = (handlers) => {
        const newHandlers = {};
        for (const [action, handler] of Object.entries(handlers)) {
            newHandlers[action] = (event) => {
                if (event) {
                    event.preventDefault();
                }
                handler();
            };
        }
        return newHandlers;
    };

    const keyMap = {
        CLOSE: { name: "Close", sequences: ["esc"] },
    };

    const handlers = preventDefaultHandlers({
        CLOSE: handleClose,
    });

    // //////console.log(dialogRef?.current)
    const bodyWidgetToDisplay = <>
        {desc ? <DialogContentText sx={{ marginBottom: '10px', fontSize: '14px' }}>
            {desc}
        </DialogContentText> : <></>}
        <div style={{ backgroundColor: '' }}>
            {bodyWidget}
        </div>
    </>
    const { handleCloseTooltipToggle } = useTooltipContext();

    useEffect(() => {
        handleCloseTooltipToggle();
    }, [])
    return (
        <>
            <GlobalHotKeys keyMap={keyMap} handlers={handlers}></GlobalHotKeys>
            <Dialog fullScreen={fullScreen} ref={dialogRef} onClose={handleClose} open={open} disableEscapeKeyDown={disableEscape}
                PaperProps={{
                    style: { borderRadius: fullScreen ? '0px' : '15px', background: 'rgba(255,255,255,0.95)', outline: '0px solid white', backdropFilter: 'blur(10px)' }
                }}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: '100%', maxWidth: `${fullScreen ? '100%' : `${width}px`}`,
                        },
                    },
                }}
            >
                {shouldTitleAppear ? <>
                    <DialogTitle sx={{ fontWeight: 'bold', fontSize: '17px', backgroundColor: '' }}>
                        {titleWidget !== null ? { titleWidget } : <>{title}</>}
                        {zoomable ? <Typography sx={{ fontSize: '11px', fontWeight: 'normal', color: 'black' }}>Zoom and pan mode (beta)</Typography> : <></>}
                    </DialogTitle>
                    <Divider light />
                </> : <></>
                }
                {/* The padding added below is default padding of dialog content. Just kept here to keep it constant. ~Aamir Vakeel */}
                <DialogContent sx={{ backgroundColor: '', padding: '10px 24px' }}>
                    {zoomable ? <InteractiveViewer>
                        {bodyWidgetToDisplay}
                    </InteractiveViewer> : <>{bodyWidgetToDisplay}</>}
                </DialogContent>
                <Divider light />
                <DialogActions sx={{ padding: '16px 24px', backgroundColor: '' }}>
                    <Grid container spacing={0}>
                        {showError ? <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                            <Error errorMessage={errorMessage} />
                        </Grid> : <></>}
                        <Grid item xs={showError ? 4 : 12} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            {showCancel ? <Button variant="contained" onClick={handleClose} sx={{
                                // marginInline: '2px',
                                color: 'black',
                                textTransform: 'initial',
                                backgroundColor: 'rgba(0,0,0,0.01)', ':hover': {
                                    backgroundColor: 'rgba(0,0,0,0.04)'
                                },
                            }}>

                                {showShortcut ? showCancelShortcutOnLeft ?
                                    <>
                                        <ControlChip showControlCommand={false} text="Or press esc" size={8} />
                                        <span style={{ marginLeft: '8px' }}>{cancelText}</span>

                                    </> :
                                    <>
                                        <span style={{ marginRight: '8px' }}>{cancelText}</span>
                                        <ControlChip showControlCommand={false} text="Or press esc" size={8} />
                                    </>
                                    : <>
                                        <Tooltip title="Or press escape to close" arrow placement="left"><span>{cancelText}</span></Tooltip>
                                    </>
                                }


                            </Button> : <></>}
                            {showPositive && positiveButtonWidget ? <span style={{ margin: 5 }}></span> : <></>}
                            {!showPositive ? <></> : positiveButtonWidget ? positiveButtonWidget : <Button variant="contained" sx={{
                                textTransform: 'initial',
                                marginInline: '2px',
                                backgroundColor: '#025196', ':hover': {
                                    backgroundColor: '#025196'
                                },
                            }} onClick={handlePositive}>{positiveText}</Button>}
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog >
        </>
    );
}

export default SimpleDialogBox