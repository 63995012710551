import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import { Button, CircularProgress, Grid, Box, Stack, Divider, LinearProgress, Typography } from '@mui/material'
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { mapCancel, mapConfirm, removeUploadGpsErrorAndMessage, uploadGps } from '../../../../../../../store/actions/projectActions';
import SmartUtils from '../../../../../../../helpers/SmartUtils';
import CustomProgress from '../../../../../../cidgets/CustomProgress';
import Error from '../../../../../../cidgets/Error';
import * as ROUTES from "../../../../../../../constants/routes";
import { MapContainer, TileLayer, Circle, Tooltip } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import { Link } from 'react-router-dom';
import SimpleDialogBox from '../../../../../../cidgets/SimpleDialogBox';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} color="success" sx={{ backgroundColor: '#E1E3E2' }} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const OriginalGpsDataMap = (props) => {
  const { originalProjectGpsDataList, proid, videosList } = props;
  const [selectedValue, setSelectedValue] = useState(10);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    };

    // Check if data is initially null or undefined
    if (originalProjectGpsDataList === null || originalProjectGpsDataList === undefined) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [originalProjectGpsDataList]);

  if (originalProjectGpsDataList === null || originalProjectGpsDataList === undefined) {
    if (loading) {
      return (
        <div style={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CustomProgress text={"Loading gps"} />
        </div>
      )
    }
    else {
      return (
        <div style={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <h6>No gps data found!</h6>
            <Link
              style={{ color: 'black', cursor: 'pointer', marginTop: '0px', textDecoration: 'underline' }}
              to={`/p${ROUTES.CONTACT}`}
              state={{ proid: proid, isProject: true }}
            >  Click here</Link>&nbsp;to contact customer support team.
          </div>
        </div>
      )
    }
  }
  const filteredPoints = selectedValue === 0
    ? originalProjectGpsDataList
    : originalProjectGpsDataList.filter(
      (_, index) => index % selectedValue === 0
    );

  const center = [Object.values(filteredPoints)[0]['lat'], Object.values(filteredPoints)[0]['lng']];
  const videoIndexList = [...new Set(filteredPoints.map(obj => obj.videoIndex))];
  const LegendsCard = () => {
    return (
      <div
        style={{
          position: 'absolute',
          bottom: '10px',
          right: '10px',
          zIndex: 1000,
          backgroundColor: 'white',
          padding: '10px',
          borderRadius: '15px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        {videoIndexList.map((eachVideoIndex, index) => {
          const video = videosList && videosList.filter((eachVideo) => eachVideo.correctedOrder === eachVideoIndex)[0];
          const videoName = video && video.name ? video.name : 'Unknown Video'; // Fallback to 'Unknown Video'

          return (
            <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <div
                style={{
                  width: '12px',
                  height: '12px',
                  borderRadius: '50%',
                  backgroundColor: SmartUtils.individualVideoMapColor(eachVideoIndex)
                }}
              />
              <div style={{ fontSize: '12px', fontWeight: 500 }}>{videoName}</div>
            </div>
          );
        })}

      </div>

    );
  };
  return (
    <div style={{ height: 'calc(60vh - 20px)', width: '100%' }}>
      <MapContainer
        center={center}
        zoom={17}
        style={{ height: 'calc(60vh - 20px)', width: '100%' }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <LegendsCard />
        {filteredPoints.map((data, index) => {
          const video = videosList && videosList.filter((eachVideo) => eachVideo.correctedOrder === data.videoIndex)[0];
          const videoName = video && video.name ? video.name : 'Unknown Video'; // Fallback to 'Unknown Video' if name is undefined

          return (
            <Circle
              pathOptions={{
                fillColor: '#FFFFFF',
                color: `${SmartUtils.individualVideoMapColor(data.videoIndex)}`,
                fillOpacity: 1,
                stroke: true,
                fill: true,
              }}
              key={index}
              center={[data.lat, data.lng]}
              radius={4}
            >
              <Tooltip>{videoName}</Tooltip>
            </Circle>
          );
        })}

      </MapContainer>

    </div>
  )
}

const GpsExtractionStep = (props) => {
  const [preview, setPreview] = useState([]);
  const { project, proid, drProjectData, originalProjectGpsDataList } = props;
  const { isGpsDistanceValid, isVideoOrder, videos } = drProjectData;
  const { status, gpsApprovalMessage, gpsMessage, isGpsUploadingOnDb, hasNoDuplicate } = drProjectData;
  const { uploadGpsStatus } = project;
  const { confirmMapLoader, cancelMapLoader } = project;
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  }
  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: {
      'application/gpx+xml': ['.gpx'],
      "application/vnd.google-earth.kml+xml": ['.kml'],
    },
    minSize: 10,
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    onDrop: acceptedFiles => {
      setPreview(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });
  const removeFile = file => () => {
    const newFiles = [...preview]
    newFiles.splice(newFiles.indexOf(file), 1)
    setPreview(newFiles)
  }

  const removeAll = () => {
    setPreview([])
  }

  useEffect(() => {
    props.removeUploadGpsErrorAndMessage();
  }, [])
  const uploadGps = (proid, gpsFile) => {
    props.uploadGps(proid, gpsFile);
  }

  useEffect(() => {
    if (status === -5) {
      setPreview([])
    }

  }, [status])

  const gpsProgress = proid in uploadGpsStatus ? uploadGpsStatus[proid]['gpsProgress'] : null
  const isGpsUploading = proid in uploadGpsStatus ? uploadGpsStatus[proid]['isGpsUploading'] : null
  const gpsErrorMessage = proid in uploadGpsStatus ? uploadGpsStatus[proid]['gpsErrorMessage'] : null
  const gpsApiCalling = proid in uploadGpsStatus ? uploadGpsStatus[proid]['gpsApiCalling'] : false;
  const gpsApiMessage = proid in uploadGpsStatus ? uploadGpsStatus[proid]['gpsApiMessage'] : '';
  const isLoading = proid in uploadGpsStatus ? uploadGpsStatus[proid]['isLoading'] : false;
  // //////console.log(isLoading, "call")
  const su = new SmartUtils();
  const files = preview.map(file => (
    <Stack spacing={1} sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '30px' }}>
      <span style={{ width: 'fit-content' }}><b>Name:</b> {file.name}</span>
      <span style={{ width: 'fit-content' }}><b>Size:</b> {su._formatBytes(`${file.size}`)}</span>
      {isLoading ? <></> : <>
        <Divider ligth sx={{ width: 'fit-content' }} />
        <span style={{ width: 'fit-content' }}><a style={{ color: 'inherit' }} href="#!" onClick={removeFile(file)}><b>Attach a different gps</b></a></span>
      </>}
    </Stack>
  ));
  const inputVideoAllowedStatus = [-3, -4, -5];
  const boxHeight = '25vh'
  const inputDiv = (<div onClick={open} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '30px' }}>
    <input {...getInputProps()} />
    <Stack>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {/* <CloudUploadIcon sx={{ fontSize: '75px' }} /> */}
      </div>

      <p>Drag and drop a GPS file here, or click to select a file</p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <em>(Only *.KML and *.GPX files will be accepted.)</em>
      </div>
      <p style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>{status === -5 ? <Error errorMessage={gpsApprovalMessage} /> : <></>}</p>
    </Stack>
  </div>)
  const afterInputDiv = (<div style={{ height: `calc(100% - 4px)`, width: '100%', backgroundColor: 'transparent', padding: '30px' }}>
    <Grid container spacing={0}>
      <Grid item xs={12} sx={{ height: `calc(100% - 4px)`, backgroundColor: '', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' }}>
        {isGpsUploadingOnDb === true ? <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', alignItems: 'center' }}><CustomProgress text={gpsMessage} /></div> : files}
      </Grid>
    </Grid>
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
      {status === 11 || isGpsUploading ?
        <Box sx={{ width: '90%', marginTop: '40px' }}>
          {gpsProgress === null ?
            <LinearProgress />
            : <LinearProgressWithLabel value={gpsProgress} />
          }
        </Box>
        :
        // isLoading || isGpsUploadingOnDb ?
        //   <>
        //     <CustomProgress />
        //   </>

        //   :
        <>
          {isGpsUploadingOnDb === true ? <></> :
            <Button size="large" variant="contained" onClick={() => { uploadGps(proid, preview[0]) }} sx={{
              marginTop: '20px',
              textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
              '&:hover': {
                backgroundColor: '#025196',
                color: '#ffffff'
              }
            }}>
              {/* Upload the gps */}
              Upload
            </Button>
          }
        </>}

    </div>
  </div>)
  if (status === -14) {
    let initialHeading;
    let initialText;

    if (isGpsDistanceValid === false && isVideoOrder === false) {
      initialHeading = "Video order & GPS distance confirmation";
      initialText = "View the uploaded video GPS and confirm the video order & GPS distance";
    }

    else if (isGpsDistanceValid === false) {
      initialHeading = "GPS distance confirmation";
      initialText = "View the uploaded video GPS and confirm";
    }

    else if (isVideoOrder === false) {
      initialHeading = "Video order confirmation";
      initialText = "Review and confirm the GPS track order detected";
    }
    else if (hasNoDuplicate === false) {
      initialHeading = "Duplicate video found";
      initialText = "Issue detected between preprocessing of video";
    }
    return (
      <>
        <SimpleDialogBox
          open={openDialog}
          onClose={handleCloseDialog}
          title={initialHeading}
          shouldTitleAppear={true}
          cancelText="Close"
          showPositive
          bodyWidget={
            <>Are you sure you want to discard these videos and upload new one?</>
          }
          positiveButtonWidget={
            <>
              {cancelMapLoader ?
                <Button
                  variant="contained"
                  sx={{
                    marginLeft: "30px",
                    textTransform: "initial",
                    backgroundColor: "#025196",
                    color: "#FFFFFF",
                    "&:hover": {
                      backgroundColor: "#025196",
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Confirming...</span>
                </Button>
                :
                <Button
                  variant="contained"
                  onClick={() => { props.mapCancel(proid) }}
                  sx={{
                    textTransform: "initial",
                    backgroundColor: "#025196",
                    color: "#FFFFFF",
                    "&:hover": {
                      backgroundColor: "#025196",
                      color: "#FFFFFF",
                    },
                  }}
                >
                  Confirm
                </Button>
              }
            </>
          }
          width='600'
        />
        {hasNoDuplicate === false ?
          <Grid container spacing={0} style={{ height: '80vh', backgroundColor: '', padding: '10px' }}>
            <Grid item xs={12} sx={{ backgroundColor: "", marginTop: '10px', height: 'calc(10vh - 20px)' }}>
              <Typography sx={{ fontWeight: "bold" }} variant="body1">
                {initialHeading}
              </Typography>
              <Typography sx={{ fontWeight: "" }} variant="body2">
                {initialText}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ backgroundColor: "", marginTop: '5px', height: "calc(70vh - 10px)" }}>
              <div style={{ backgroundColor: "", display: 'flex', justifyContent: 'center' }}>
                <Error errorMessage={gpsApprovalMessage} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: 'center',
                  // alignItems: "center",
                  // gap: "10px",
                  marginTop: '10px'
                }}
              >
                {cancelMapLoader ?
                  <Button
                    variant="contained"
                    sx={{
                      marginLeft: "30px",
                      textTransform: "initial",
                      backgroundColor: "#025196",
                      color: "#FFFFFF",
                      "&:hover": {
                        backgroundColor: "#025196",
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Confirming...</span>
                  </Button>
                  :
                  <Button
                    variant="contained"
                    onClick={() => { props.mapCancel(proid) }}
                    sx={{
                      textTransform: "initial",
                      backgroundColor: "#025196",
                      color: "#FFFFFF",
                      "&:hover": {
                        backgroundColor: "#025196",
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    Back to upload
                  </Button>
                }
              </div>
            </Grid>
          </Grid>
          :
          <Grid container spacing={0} style={{ height: '80vh', backgroundColor: '', padding: '10px' }}>

            <Grid item xs={12} sx={{ backgroundColor: "", marginTop: '10px', height: 'calc(10vh - 20px)' }}>
              <Typography sx={{ fontWeight: "bold" }} variant="body1">
                {initialHeading}
              </Typography>
              <Typography sx={{ fontWeight: "" }} variant="body2">
                {initialText}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ backgroundColor: "", marginTop: '10px', height: "calc(60vh - 20px)" }}>
              <div style={{ backgroundColor: "", height: "calc(60vh - 20px - 0px)" }}>
                <OriginalGpsDataMap videosList={videos} proid={proid} originalProjectGpsDataList={originalProjectGpsDataList} />
              </div>
            </Grid>

            <Grid container spacing={0} sx={{ backgroundColor: "", marginTop: '10px', height: "calc(10vh - 20px)", display: 'flex', alignItems: 'center' }}>

              <Grid item xs={6} sx={{ backgroundColor: "", display: 'flex', justifyContent: 'end' }}>
                <Box>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={handleOpenDialog}
                      sx={{
                        marginLeft: "10px",
                        textTransform: "initial",
                        backgroundColor: "#025196",
                        color: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#025196",
                          color: "#FFFFFF",
                        },
                      }}
                    >
                      Cancel
                    </Button>
                  </div>

                </Box>
              </Grid>

              <Grid item xs={6} sx={{ backgroundColor: "", display: 'flex', justifyContent: 'start' }}>
                <Box
                  sx={{


                    backgroundColor: ''
                  }}
                >

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {
                      confirmMapLoader ?
                        <Button
                          variant="contained"
                          sx={{
                            marginLeft: "10px",
                            textTransform: "initial",
                            backgroundColor: "#025196",
                            color: "#FFFFFF",
                            "&:hover": {
                              backgroundColor: "#025196",
                              color: "#FFFFFF",
                            },
                          }}
                        >
                          <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Confirming...</span>
                        </Button>
                        :
                        <Button
                          variant="contained"
                          onClick={() => { props.mapConfirm(proid) }}
                          sx={{
                            marginLeft: "10px",
                            textTransform: "initial",
                            backgroundColor: "#025196",
                            color: "#FFFFFF",
                            "&:hover": {
                              backgroundColor: "#025196",
                              color: "#FFFFFF",
                            },
                          }}
                        >
                          Confirm
                        </Button>
                    }
                  </div>
                </Box>
              </Grid>
            </Grid>

          </Grid>
        }
      </>
    )
  }
  else {
    return (
      <Grid container>
        <Grid item xs={12} style={{ margin: '40px' }}>
          <div style={{ backgroundColor: '', height: 'calc(100vh - 20vh - 80px)', display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
            <div style={{ height: 'calc(100vh - 20vh -80px)' }} {...getRootProps({ className: 'dropzone' })}>
              <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start', backgroundColor: '' }}>
                <Box
                  sx={{
                    textAlign: 'center',
                    minWidth: '500px',
                    maxWidth: '1000px',
                    width: '100vw',
                    height: 'fit-content',
                    backgroundColor: 'transparent',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: acceptedFiles.length > 0 ? '' : 'pointer',
                    border: '2px dashed grey',
                    borderRadius: '20px',
                    color: 'grey'
                  }}
                >
                  <div style={{ height: `calc(100% - 4px)`, width: '100%', backgroundColor: '' }}>
                    <Grid container spacing={0}>
                      <Grid xs={12} sx={{ height: `calc(100% - 4px)`, backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
                        {status === 11 ?
                          <Stack sx={{ padding: '30px' }}>
                            <CustomProgress text={"Gps extracting"} />
                          </Stack>
                          : <Stack sx={{ width: '100%' }}>
                            {
                              inputVideoAllowedStatus.includes(status) ?
                                preview.length > 0 || isGpsUploadingOnDb ?
                                  afterInputDiv :
                                  inputDiv
                                :
                                preview.length > 0 || gpsProgress || isGpsUploading ?
                                  afterInputDiv
                                  :
                                  inputDiv

                            }
                          </Stack>
                        }

                      </Grid>
                    </Grid>
                  </div>
                </Box>
              </div>
            </div>

          </div>
        </Grid>

      </Grid>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  const { proid } = ownProps
  return {
    project: state.project,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    uploadGps: (proid, gpsFile) => dispatch(uploadGps(proid, gpsFile)),
    removeUploadGpsErrorAndMessage: () => dispatch(removeUploadGpsErrorAndMessage()),
    mapConfirm: (proid) => dispatch(mapConfirm(proid)),
    mapCancel: (proid) => dispatch(mapCancel(proid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GpsExtractionStep);
