import React, { useState } from 'react'
import { Grid, Card, IconButton, Tooltip, Divider } from '@mui/material'
import RoutePlannerList from './RoutePlannerList';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MapIcon from '@mui/icons-material/Map';
import SavedRoutesPlanningSchedule from './SavedRoutesPlanningSchedule';
import RoutePlannerDisabled from './RoutePlannerDisabled';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import CustomOpenStreetMap from '../../../cidgets/CustomOpenStreetMap';
const RoutePlannerSavedRoutes = ({ removeRerunRouteCalculationErrorAndMessage, rerunRouteCalculationToDb, rerunRouteCalculationToDbLoader, rerunRouteCalculationToDbMessage, rerunRouteCalculationToDbIsDoneAndSent, setIndvidualSubRouteData, setSelectedRouteData, deleteRouteLoader, coid, deleteRoute,
    RoutePlannerData, showRoute,
    userLocation, handleCreatingNewRoute,
    showScheduler, showRouteScreen, openScheduler,
    setSubRouteDataArray }) => {
    const navigate = useNavigate();
    const [openDeleteRouteDialog, setOpenDeleteRouteDialog] = useState(false);
    const [openRerunRouteDialog, setOpenRerunRouteDialog] = useState(false);
    if (RoutePlannerData === null || RoutePlannerData === undefined) {
        return <> <RoutePlannerDisabled userLocation={userLocation} handleCreatingNewRoute={handleCreatingNewRoute} /></>
    }
    const newResultArray = RoutePlannerData
        .map(obj => obj.result)
        .filter(result => result !== undefined)
        .flat();
    const cardStyle = {
        height: "calc(100vh - 20px)",
        background: '#FFFFFF',
        margin: '10px'
    };
    const handleClick = (route) => {
        navigate(route);
    }

    return (
        <>
            <Grid container spacing={0} sx={{ height: '100vh' }}>
                <Grid item xs={4} sx={{ height: '100vh' }}>
                    <Card sx={cardStyle}>
                        <Grid container spacing={0} sx={{ height: '8%', background: '' }}>
                            <Grid item xs={6} sx={{ background: '', display: 'flex', justifyContent: 'start', alignItems: 'end', paddingLeft: '10px' }}>
                                <h6 style={{ fontWeight: 'bold' }}>Saved routes</h6>
                            </Grid>
                            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end', background: '' }}>
                                {
                                    openScheduler ? <>
                                        <Tooltip title="Go to home to view all your projects." arrow placement='top'>
                                            <IconButton aria-label="route" onClick={() => { handleClick('/home') }}>
                                                <HomeIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Create new route" arrow placement='top'>
                                            <IconButton aria-label="route" onClick={handleCreatingNewRoute}>
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Switch to map" arrow placement='top'>
                                            <IconButton aria-label="Map" onClick={showRouteScreen}>
                                                <MapIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </> :
                                        <>
                                            <Tooltip title="Go to home to view all your projects." arrow placement='top'>
                                                <IconButton aria-label="route" onClick={() => { handleClick('/home') }}>
                                                    <HomeIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Switch to calendar" arrow placement='top'>
                                                <IconButton aria-label="Calendar" onClick={showScheduler} >
                                                    <CalendarMonthIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </>

                                }

                            </Grid>
                            <Divider light sx={{ width: '100%' }} />
                        </Grid>
                        <Grid container spacing={0} sx={{ height: '92%', background: '' }}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'start' }}>
                                <RoutePlannerList
                                    setSelectedRouteData={setSelectedRouteData}
                                    openDeleteRouteDialog={openDeleteRouteDialog}
                                    setOpenDeleteRouteDialog={setOpenDeleteRouteDialog}
                                    openRerunRouteDialog={openRerunRouteDialog}
                                    setOpenRerunRouteDialog={setOpenRerunRouteDialog}
                                    deleteRoute={deleteRoute}
                                    rerunRouteCalculationToDb={rerunRouteCalculationToDb}
                                    removeRerunRouteCalculationErrorAndMessage={removeRerunRouteCalculationErrorAndMessage}
                                    coid={coid}
                                    RoutePlannerData={RoutePlannerData}
                                    showRoute={showRoute}
                                    setSubRouteDataArray={setSubRouteDataArray}
                                    deleteRouteLoader={deleteRouteLoader}
                                    rerunRouteCalculationToDbMessage={rerunRouteCalculationToDbMessage}
                                    rerunRouteCalculationToDbIsDoneAndSent={rerunRouteCalculationToDbIsDoneAndSent}
                                    rerunRouteCalculationToDbLoader={rerunRouteCalculationToDbLoader}
                                    setIndvidualSubRouteData={setIndvidualSubRouteData}
                                />
                            </Grid>

                        </Grid>
                    </Card>
                </Grid >
                {
                    openScheduler ?
                        <Grid item xs={8} sx={{ height: '100vh' }}>
                            <SavedRoutesPlanningSchedule
                                RoutePlannerData={RoutePlannerData}
                                showRouteScreen={showRouteScreen} routesData={newResultArray} availableViews={['month', 'day', 'agenda']} defaultView={"month"} />
                        </Grid >
                        :
                        <Grid item xs={8} sx={{ height: '100vh' }}>
                            <CustomOpenStreetMap
                                showDisabled={true}
                                onNavigate={handleCreatingNewRoute} text={"Create new route"} userLocation={userLocation} showButton={!openDeleteRouteDialog && !openRerunRouteDialog}
                            />
                        </Grid>
                }


            </Grid >
        </>
    )
}

export default RoutePlannerSavedRoutes