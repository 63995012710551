import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import SavedSuperSectionList from './components/SavedSuperSectionList';
import CustomOpenStreetMap from '../../../cidgets/CustomOpenStreetMap';
import { convertEncodedGeometry, removeRouteCalculationErrorAndMessage } from '../../../../store/actions/projectActions';
import SavedSuperSectionMap from './components/SavedSuperSectionMap';
import AddNewSuperSectionController from './components/AddNewSuperSectionController';
import CustomAlert from '../../../cidgets/CustomAlert';
import { archiveSuperSection } from '../../../../store/actions/networkActions';

const SuperSectionController = (props) => {
    const { superSections } = props;

    const [showSavedSuperSectionMap, setShowSavedSuperSectionMap] = useState(false);
    const [showNewSuperSectionScreen, setShowNewSuperSectionScreen] = useState(false);

    const [userLocation, setUserLocation] = useState({ lat: 51.505, lng: -0.09 });
    const [isUserLocation, setIsUserLocation] = useState(true);

    const [openUserLocationError, setOpenUserLocationError] = useState(false);
    const [userLocationErrorMessage, setUserLocationErrorMessage] = useState(false);

    useEffect(() => {
        setOpenUserLocationError(false);
        if (navigator.geolocation) {
            navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
                if (permissionStatus.state === 'denied') {
                    setOpenUserLocationError(true);
                    setUserLocationErrorMessage("Please give your location access to roadgauge");
                }
                else {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            setIsUserLocation(true);
                            setUserLocation({
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            });
                        },
                        (error) => {
                            setOpenUserLocationError(true);
                            setUserLocationErrorMessage("Please give your location access to roadgauge");
                        }
                    );
                }
            });
        } else {
            setOpenUserLocationError(true);
            setUserLocationErrorMessage("Geolocation is not supported by this browser.");

        }
    }, []);
    const goToSpecificRouteLocation = (lat, lng) => {
        setIsUserLocation(false);
        setUserLocation({
            lat: lat,
            lng: lng,
        });
    }
    const convertEncodedGeometry = (encodedGeometry) => {
        props.convertEncodedGeometry(encodedGeometry);
        setShowSavedSuperSectionMap(true);
    }

    const addNewSuperSection = () => {
        props.removeRouteCalculationErrorAndMessage();
        setShowNewSuperSectionScreen(true);
    }
    const closeAddNewSuperSection = () => {
        setShowNewSuperSectionScreen(false);
    }
    const handleCloseSavedMapWidget = () => {
        setShowSavedSuperSectionMap(false);
    }

    const goToCenterOfUserLocation = (e) => {
        setOpenUserLocationError(false);
        if (navigator.geolocation) {
            navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
                if (permissionStatus.state === 'denied') {
                    setOpenUserLocationError(true);
                    setUserLocationErrorMessage("Please give your location access to roadgauge");
                }
                else {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            setIsUserLocation(true);
                            setUserLocation({
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            });
                        },
                        (error) => {
                            setOpenUserLocationError(true);
                            setUserLocationErrorMessage(error.message);
                            // window.location.href = "app-settings:location";
                        }
                    );
                }
            });
        } else {
            setOpenUserLocationError(true);
            setUserLocationErrorMessage("Geolocation is not supported by this browser.");

        }
    }
    const onDeleteListItem = (id) => {
        props.archiveSuperSection(id);
    }
    return (
        <>
            <CustomAlert autoHideDuration={5000} text={userLocationErrorMessage} open={openUserLocationError} onClose={() => setOpenUserLocationError(false)} severity="error" />
            <Grid container >
                {showNewSuperSectionScreen ?
                    <Grid item xs={12} sx={{ height: '100vh' }}>
                        <AddNewSuperSectionController
                            userLocation={userLocation}
                            isUserLocation={isUserLocation}
                            onClose={closeAddNewSuperSection}
                            onSpecificRouteLocation={goToSpecificRouteLocation}
                            goToCenterOfUserLocation={goToCenterOfUserLocation}
                        />
                    </Grid>
                    : <>
                        <Grid item xs={4} sx={{ height: '100vh' }}>
                            <SavedSuperSectionList onDeleteListItem={onDeleteListItem} onNavigate={addNewSuperSection} data={superSections} onListViewClick={convertEncodedGeometry}
                                handleCloseSavedMapWidget={handleCloseSavedMapWidget} showSavedSuperSectionMap={showSavedSuperSectionMap} />
                        </Grid>
                        <Grid item xs={8} sx={{ height: '100vh' }}>
                            {showSavedSuperSectionMap ? <SavedSuperSectionMap /> :
                                <CustomOpenStreetMap
                                    showDisabled={true}
                                    onNavigate={addNewSuperSection} text={"Create new supersection"} userLocation={userLocation}
                                />
                            }
                        </Grid>
                    </>}
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        userData: state.firebase.profile,
        superSections: state.firestore.ordered.SuperSections,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        convertEncodedGeometry: (encodedGeometry) => dispatch(convertEncodedGeometry(encodedGeometry)),
        removeRouteCalculationErrorAndMessage: () => dispatch(removeRouteCalculationErrorAndMessage()),
        archiveSuperSection: (id) => dispatch(archiveSuperSection(id)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => {
        const coid = props.userData.coid ?? '-1';
        return [
            {
                collection: `SuperSections`,
                where: [['coid', '==', `${coid}`], ['archived', '==', false]],
                orderBy: [['creationTime', 'desc']],
            }
        ];
    })
)(SuperSectionController);
