import { Grid, MenuItem, Paper, TextField, Typography, styled } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'

const DistressFieldsAdd = (props) => {
    const fieldStyles = {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        "borderRadius": "4px",
        "padding": '10px 8px',
        "fontSize": '0.95rem',
        "textTransform": 'capitalize',
        "overflow": 'auto',
        // "backgroundColor": '#f5f5f5',
        "color": '#ccc',

    };
    const { distressPinned, dataInfo, distressData, fieldValues, setFieldValues, drProjectData } = props;
    const handleChange = (prop) => (event) => {
        setFieldValues({ ...fieldValues, [prop]: event.target.value });
    };


    const Item = styled(Paper)(({ theme }) => ({
        boxShadow: '0px 0px transparent',
        width: '100%',
        backgroundColor: 'transparent',
        ...theme.typography.body2,
        padding: theme.spacing(0.5),
    }));
    const fieldTextStyle = {
        fontSize: '15px',
        fontWeight: 'bold',
    };
    // 0"Pothole"
    // 1:"Crack"
    // 2:"Subsidence"
    // 3:"Ravelling"
    // 4:"Rutting"
    // 5:"Patch"
    // 6:"Manhole"
    // 7:"Bleeding"
    // 8:"Depression"
    // 9:"Corrugation/Shoving"
    // 10: "Polished aggregate"
    // 11: "Water bleeding and pumping"
    const editableDefectNameList = drProjectData.projectType === 4 ? ['0', '1', '5', '12', '13'] : ['0', '1', '3', '5', '10', '12', '13'];
    const showAreaFieldList = ['0', '1', '2', '5', '10', '12', '13'];
    const showDepthFieldList = ['0', '4'];
    const showTransverseSpanFieldList = ['1', '2', '3', '5', '10'];
    const showLongitudinalSpanFieldList = ['1', '2', '3', '5', '10'];
    const showTypeFieldList = ['1'];
    const showSeverityFieldList = ['0', '1', '3', '5', '10', '12', '13'];
    const showProfileFieldList = ['2'];
    const showWidthFieldList = ['4'];
    const showWheelPathFieldList = ['4'];
    const showNegativeStatusFieldList = ['-1'];
    const defectNamesMap = {
        "map": dataInfo['defectName'],
        "keys": Object.keys(dataInfo['defectName']),
        "values": Object.values(dataInfo['defectName']),
        "usefulKeys": ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'],
    }
    const defectNamesList = defectNamesMap.keys.filter(e => defectNamesMap.usefulKeys.includes(e)).map((e, i) => {
        if (editableDefectNameList.includes(e)) {
            return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
                {`${defectNamesMap.values[i]['name']}`}
            </MenuItem>
        }
    })
    const handleClick = (list) => {
        // //////console.log(list, "list");
        const key = Object.keys(defectNamesMap.map).find((k) => defectNamesMap.map[k] === list);
        // //////console.log('Clicked value:', list);
        // //////console.log('Associated key:', key);
    }
    const transversePositionMap = {
        "map": dataInfo['transversePosition'],
        "keys": Object.keys(dataInfo['transversePosition']),
        "values": Object.values(dataInfo['transversePosition']),
        "usefulKeys": ['0', '1', '2', '3', '4', '5'],
    }
    const transversePositionList = transversePositionMap.keys.filter(e => transversePositionMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${transversePositionMap.values[i]['name']}`}
        </MenuItem>
    })
    // //////console.log(transversePositionList, 'which')
    const typeMap = {
        "map": dataInfo['type'],
        "keys": Object.keys(dataInfo['type']),
        "values": Object.values(dataInfo['type']),
        "usefulKeys": ['0', '1', '2', '3', '4', '5', '6', '998'],
    }
    const typeList = typeMap.keys.filter(e => typeMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${typeMap.values[i]['name']}`}
        </MenuItem>
    })
    const severityMap = {
        "map": dataInfo['severity'],
        "keys": Object.keys(dataInfo['severity']),
        "values": Object.values(dataInfo['severity']),
        "usefulKeys": ['-1', '0', '1', '2'],
    }
    const severityList = severityMap.keys.filter(e => severityMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${severityMap.values[i]['name']}`}
        </MenuItem>
    })
    const profileMap = {
        "map": dataInfo['profile'],
        "keys": Object.keys(dataInfo['profile']),
        "values": Object.values(dataInfo['profile']),
        "usefulKeys": ['0', '1', '2'],
    }
    const profileList = profileMap.keys.filter(e => profileMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${profileMap.values[i]['name']}`}
        </MenuItem>
    })
    const wheelPathMap = {
        "map": dataInfo['wheelPath'],
        "keys": Object.keys(dataInfo['wheelPath']),
        "values": Object.values(dataInfo['wheelPath']),
        "usefulKeys": ['0', '1'],
    }
    const wheelPathList = wheelPathMap.keys.filter(e => wheelPathMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${wheelPathMap.values[i]['name']}`}
        </MenuItem>
    })
    return (
        <Grid container>
            <Grid item xs={6} >
                {/* <div style={{ backgroundColor: 'transparent', overflow: 'auto' }}> */}

                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body1" sx={fieldTextStyle}>
                                Defect category:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                id="defect name"
                                select
                                value={fieldValues.defectNameState}
                                onChange={handleChange("defectNameState")}
                                // onClick={() => { handleClick(fieldValues.defectNameState) }}
                                size="small"
                                sx={{ textTransform: 'capitalize', width: '100%' }}
                            >
                                {defectNamesList}
                            </TextField>
                        </Grid>
                    </Grid>
                </Item>

                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body1" sx={fieldTextStyle}>
                                Transverse position:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                id="transverse position"
                                select
                                value={fieldValues.transversePositionState}
                                onChange={handleChange("transversePositionState")}
                                size="small"
                                sx={{ textTransform: 'capitalize', width: '100%' }}
                            >
                                {transversePositionList}
                            </TextField>
                        </Grid>
                    </Grid>
                </Item>

                {showTypeFieldList.includes(`${fieldValues.defectNameState}`) ? <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body1" sx={fieldTextStyle}>
                                Type:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                id="type"
                                select
                                value={fieldValues.typeState}
                                onChange={handleChange("typeState")}
                                size="small"
                                sx={{ textTransform: 'capitalize', width: '100%' }}
                            >
                                {typeList}
                            </TextField>
                        </Grid>
                    </Grid>
                </Item> : <></>}


            </Grid>
            <Grid item xs={6}>
                {showSeverityFieldList.includes(`${fieldValues.defectNameState}`) ? <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }} >
                            <Typography variant="body1" sx={fieldTextStyle}>
                                Severity:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                            <TextField
                                id="severity"
                                select
                                value={fieldValues.severityState}
                                onChange={handleChange("severityState")}
                                size="small"
                                sx={{ textTransform: 'capitalize', width: '100%' }}
                            >
                                {severityList}
                            </TextField>
                        </Grid>
                    </Grid>
                </Item> : <></>}
                {showWheelPathFieldList.includes(`${fieldValues.defectNameState}`) ? <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }} >
                            <Typography variant="body1" sx={fieldTextStyle}>
                                Wheel path:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                            <TextField
                                id="wheel path"
                                select
                                value={fieldValues.wheelPathState}
                                onChange={handleChange("wheelPathState")}
                                size="small"
                                sx={{ textTransform: 'capitalize', width: '100%' }}
                            >
                                {wheelPathList}
                            </TextField>
                        </Grid>

                    </Grid>
                </Item> : <></>}
            </Grid>
        </Grid>
    )
}

export default DistressFieldsAdd