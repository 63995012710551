import { Button, Card, CardContent, CircularProgress, Container, Divider, Grid, MenuItem, Select, Slider, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SimpleDialogBox from '../../../cidgets/SimpleDialogBox';
import { connect } from 'react-redux';
import { clearPciCalcState, pciCalculation, roadConditionIndexNext, roadConditionIndexBack } from '../../../../store/actions/projectActions';
import { get } from 'lodash';
import CustomProgress from '../../../cidgets/CustomProgress';


const RoadConditonIndex = (props) => {
    const { setActiveStep, project, proid, drProjectData, showSkip = false, height = "80vh", roadConditionIndexNext, roadConditionIndexBack  } = props;
    const pciWeightages = drProjectData.pciWeightages ? drProjectData.pciWeightages : null;
    const pciBais = drProjectData.pciBais ? drProjectData.pciBais : null;
    const pciCalculating = drProjectData.pciCalculating ?? false;
    const { pciCalcMessage, pciCalcLoader, pciCalcIsDoneAndSent } = project;
    const [openDialog, setOpenDialog] = useState(false);

    const handeOpenDialog = () => {
        props.clearPciCalcState();
        setOpenDialog(true);
    }
    const handeCloseDialog = () => {
        setOpenDialog(false);
    }
    const cardStyle = {
        "border": "0.5px solid grey",
        "borderRadius": "8px",
        "backgroundColor": '#fafafa',
        "padding": showSkip ? '10px' : '0px',
        "marginTop": !showSkip ? '10px' : '0px',
    }


    useEffect(() => {
        if (pciCalcIsDoneAndSent === true) {
            handeCloseDialog();
            props.clearPciCalcState();
            if (showSkip) {
                setActiveStep(6);
            }
        }
    }, [pciCalcIsDoneAndSent])
    // Defects and their default range values
    const [defects, setDefects] = useState([
        { title: 'Potholes', value: pciWeightages ? pciWeightages['Potholes'] : 1, bais: pciBais ? pciBais['Potholes'] : 0 },
        { title: 'Rutting', value: pciWeightages ? pciWeightages['Rutting'] : 1, bais: pciBais ? pciBais['Rutting'] : 0 },
        { title: 'Subsidence', value: pciWeightages ? pciWeightages['Subsidence'] : 1, bais: pciBais ? pciBais['Subsidence'] : 0 },
        { title: 'Longitudinal Cracking', value: pciWeightages ? pciWeightages['Longitudinal Cracking'] : 1, bais: pciBais ? pciBais['Longitudinal Cracking'] : 0 },
        { title: 'Transverse Cracking', value: pciWeightages ? pciWeightages['Transverse Cracking'] : 1, bais: pciBais ? pciBais['Transverse Cracking'] : 0 },
        { title: 'Alligator Cracking', value: pciWeightages ? pciWeightages['Alligator Cracking'] : 1, bais: pciBais ? pciBais['Alligator Cracking'] : 0 },
        { title: 'Block Cracking', value: pciWeightages ? pciWeightages['Block Cracking'] : 1, bais: pciBais ? pciBais['Block Cracking'] : 0 },
    ]);

    const [selectedDefect, setSelectedDefect] = useState(defects[0]);
    const [sliderValue, setSliderValue] = useState(selectedDefect.value);
    const [sliderValueBais, setSliderValueBais] = useState(selectedDefect.bais);

    const handleSliderChange = (_, newValue) => {
        setDefects(prevDefects =>
            prevDefects.map(defect =>
                defect.title === selectedDefect.title ? { ...defect, value: newValue } : defect
            )
        );
        setSliderValue(newValue);
    };
    const handleSliderChangeBais = (_, newValue) => {
        setDefects(prevDefects =>
            prevDefects.map(defect =>
                defect.title === selectedDefect.title ? { ...defect, bais: newValue } : defect
            )
        );
        setSliderValueBais(newValue);
    };
    const handleDefectChange = (event) => {
        const selectedTitle = event.target.value;
        const defect = defects.find(defect => defect.title === selectedTitle);
        setSelectedDefect(defect);
        setSliderValue(defect.value);
        setSliderValueBais(defect.bais);
    };


    const handleSubmit = () => {
        const calculatedResult = {};
        const calculatedResultBais = {};
        defects.forEach(entry => {
            calculatedResult[entry.title] = entry.value;
            calculatedResultBais[entry.title] = entry.bais;
        });
        props.pciCalculation(proid, calculatedResult, calculatedResultBais);
        roadConditionIndexNext(proid);
    }
    return (
        <>

            <SimpleDialogBox
                open={openDialog}
                onClose={handeCloseDialog}
                width={800}
                title={"Confirm custom condition index values"}
                bodyWidget={
                    <>
                        <Typography variant="p" sx={{ fontWeight: 'bold' }}>
                            Deduct bias values
                        </Typography>
                        {defects.map((each, i) => <li key={i} style={{ margin: '5px 0px 0px 10px' }}>{each.title}: {each.bais}%</li>)}
                        <Divider sx={{ marginBlock: '10px' }} />
                        <Typography variant="p" sx={{ fontWeight: 'bold' }}>
                            Weightages values
                        </Typography>
                        {defects.map((each, i) => <li key={i} style={{ margin: '5px 0px 0px 10px' }}>{each.title}: {each.value}</li>)}
                    </>
                }
                showError={pciCalcIsDoneAndSent === false ? true : false}
                errorMessage={pciCalcIsDoneAndSent === false ? pciCalcMessage : ''}
                showPositive
                positiveButtonWidget={
                    pciCalcLoader ? <>
                        <Button variant="contained" disabled sx={{
                            textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                            "&.Mui-disabled": {
                                backgroundColor: '#025196',
                                color: '#ffffff'
                            }
                        }}>
                            <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Saving...</span>
                        </Button>
                    </>
                        :
                        < Button variant="contained" onClick={handleSubmit} sx={{
                            marginTop: '0px',
                            textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                            '&:hover': {
                                backgroundColor: '#025196',
                                color: '#ffffff'
                            }
                        }}>Confirm</Button>

                }

            />
            {pciCalculating ?
                <div style={{ height: `calc(${height} - 0px)` }} >
                    <Container maxWidth="xl">
                        <Card sx={cardStyle} elevation={2}>
                            <CardContent sx={{ flex: 1 }}>
                                <CustomProgress text={"Calculating custom condition index values"} />
                            </CardContent>
                        </Card>
                        {showSkip ? <Grid container spacing={0} sx={{ paddingTop: 5, background: '' }}>
                            <Grid item xs={12} style={{
                                display: "flex", justifyContent: "center", alignItems: 'center', background: '',
                            }}>
                                <Button className="button"
                                    variant="contained"
                                    onClick={() => {
                                        roadConditionIndexBack(proid);
                                        setActiveStep(4)
                                    }}
                                    sx={{
                                        marginRight: '5px',
                                        "&.Mui-disabled": {
                                            backgroundColor: '#025196',
                                            color: '#ffffff'
                                        },
                                        // marginInline: '2px',
                                        textTransform: 'initial',
                                        backgroundColor: '#025196',
                                        ':hover': {
                                            backgroundColor: '#025196'
                                        }
                                    }}>Back</Button>
                                <Button className="button"
                                    variant="contained"
                                    onClick={() => {
                                        roadConditionIndexNext(proid);
                                        setActiveStep(6)
                                    }}
                                    sx={{

                                        "&.Mui-disabled": {
                                            backgroundColor: '#025196',
                                            color: '#ffffff'
                                        },
                                        marginInline: '2px',
                                        textTransform: 'initial',
                                        backgroundColor: '#025196',
                                        ':hover': {
                                            backgroundColor: '#025196'
                                        }
                                    }}>Skip</Button>
                            </Grid>
                        </Grid> : <></>}
                    </Container>

                </div> :
                < div style={{ height: `calc(${height} - 0px)`, paddingTop: '0px', background: '' }}>
                    {showSkip ?
                        <Container maxWidth="xl">
                            <Card sx={cardStyle} elevation={2}>
                                <CardContent sx={{ flex: 1 }}>

                                    <div style={{ paddingTop: 0, background: '' }}>
                                        <Select
                                            value={selectedDefect.title}
                                            onChange={handleDefectChange}
                                        >
                                            {defects.map((defect, index) => (
                                                <MenuItem key={index} value={defect.title}>
                                                    {defect.title}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>


                                    <div style={{ paddingTop: 10, background: '' }}>
                                        <Typography variant="p" sx={{ fontWeight: 'bold' }}>
                                            Set custom condition index values for deduct bias
                                        </Typography>
                                    </div>
                                    <div style={{ paddingTop: 35, background: '' }}>
                                        <Slider
                                            min={-100}
                                            max={100}
                                            value={sliderValueBais}
                                            onChange={handleSliderChangeBais}
                                            aria-labelledby="range-slider"
                                            valueLabelDisplay="on"
                                            step={10}
                                            marks={[
                                                { value: -100, label: '-100%' },
                                                { value: -90, label: '-90%' },
                                                { value: -80, label: '-80%' },
                                                { value: -70, label: '-70%' },
                                                { value: -60, label: '-60%' },
                                                { value: -50, label: '-50%' },
                                                { value: -40, label: '-40%' },
                                                { value: -30, label: '-30%' },
                                                { value: -20, label: '-20%' },
                                                { value: -10, label: '-10%' },
                                                { value: 0, label: '0%' },
                                                { value: 10, label: '10%' },
                                                { value: 20, label: '20%' },
                                                { value: 30, label: '30%' },
                                                { value: 40, label: '40%' },
                                                { value: 50, label: '50%' },
                                                { value: 60, label: '60%' },
                                                { value: 70, label: '70%' },
                                                { value: 80, label: '80%' },
                                                { value: 90, label: '90%' },
                                                { value: 100, label: '100%' }
                                            ]}

                                            sx={{
                                                width: '100%',
                                                color: '#025196',
                                                '& .MuiSlider-thumb': {
                                                    height: 16,
                                                    width: 16,
                                                },
                                                '& .MuiSlider-mark': {
                                                    backgroundColor: '#025196',
                                                    height: 7,
                                                    width: 2,
                                                    boxShadow: '0px 0px 5px 2px #fafafa',
                                                },
                                                '& .MuiSlider-markLabel': {
                                                    color: '#000000',
                                                    fontWeight: 'normal',
                                                },
                                            }}
                                        />

                                    </div>

                                    <div style={{ paddingTop: 10 }}>
                                        <Typography variant="p" sx={{ fontWeight: 'bold' }}>
                                            Set custom condition index values for weightages
                                        </Typography>
                                    </div>
                                    <div style={{ paddingTop: 35, background: '' }}>
                                        <Slider
                                            min={0}
                                            max={10}
                                            value={sliderValue}
                                            onChange={handleSliderChange}
                                            aria-labelledby="range-slider"
                                            valueLabelDisplay="on"
                                            step={(sliderValue >= 1) ? 1 : 0.1}
                                            marks={[
                                                { value: 0, label: '0' },
                                                { value: 0.1, label: '' },
                                                { value: 0.2, label: '' },
                                                { value: 0.3, label: '' },
                                                { value: 0.4, label: '' },
                                                { value: 0.5, label: '' },
                                                { value: 0.6, label: '' },
                                                { value: 0.7, label: '' },
                                                { value: 0.8, label: '' },
                                                { value: 0.9, label: '' },
                                                { value: 1, label: '1' },
                                                { value: 2, label: '2' },
                                                { value: 3, label: '3' },
                                                { value: 4, label: '4' },
                                                { value: 5, label: '5' },
                                                { value: 6, label: '6' },
                                                { value: 7, label: '7' },
                                                { value: 8, label: '8' },
                                                { value: 9, label: '9' },
                                                { value: 10, label: '10' }
                                            ]}

                                            sx={{
                                                width: '100%',
                                                color: '#025196',
                                                '& .MuiSlider-thumb': {
                                                    height: 16,
                                                    width: 16,
                                                },
                                                '& .MuiSlider-mark': {
                                                    backgroundColor: '#025196',
                                                    height: 7,
                                                    width: 2,
                                                    boxShadow: '0px 0px 5px 2px #fafafa',
                                                },
                                                '& .MuiSlider-markLabel': {
                                                    color: '#000000',
                                                    fontWeight: 'normal',
                                                },
                                            }}
                                        />

                                    </div>

                                </CardContent>

                            </Card>
                            <Grid container spacing={0} sx={{ paddingTop: '15px', background: '' }}>
                                <Grid item xs={12} style={{
                                    display: "flex", justifyContent: "center", alignItems: 'center', background: '',
                                }}>
                                    <Button className="button"
                                        variant="contained"
                                        onClick={() => {
                                            roadConditionIndexBack(proid);
                                            setActiveStep(4)
                                        }}
                                        sx={{
                                            marginRight: '5px',
                                            "&.Mui-disabled": {
                                                backgroundColor: '#025196',
                                                color: '#ffffff'
                                            },
                                            // marginInline: '2px',
                                            textTransform: 'initial',
                                            backgroundColor: '#025196',
                                            ':hover': {
                                                backgroundColor: '#025196'
                                            }
                                        }}>Back</Button>
                                    <Button className="button"
                                        variant="contained"
                                        onClick={() => { 
                                            roadConditionIndexNext(proid)
                                            setActiveStep(6) }}
                                        sx={{
                                            marginRight: '5px',
                                            "&.Mui-disabled": {
                                                backgroundColor: '#025196',
                                                color: '#ffffff'
                                            },
                                            // marginInline: '2px',
                                            textTransform: 'initial',
                                            backgroundColor: '#025196',
                                            ':hover': {
                                                backgroundColor: '#025196'
                                            }
                                        }}>Skip</Button>
                                    <Button className="button"
                                        variant="contained"
                                        onClick={() => { handeOpenDialog() }}
                                        sx={{
                                            "&.Mui-disabled": {
                                                backgroundColor: '#025196',
                                                color: '#ffffff'
                                            },
                                            // marginInline: '2px',
                                            textTransform: 'initial',
                                            backgroundColor: '#025196',
                                            ':hover': {
                                                backgroundColor: '#025196'
                                            }
                                        }}>Confirm</Button>
                                </Grid>

                            </Grid>
                        </Container>
                        :
                        <Card sx={cardStyle} elevation={2}>
                            <CardContent sx={{ flex: 1 }}>

                                <div style={{ paddingTop: 0 }}>
                                    <Select
                                        value={selectedDefect.title}
                                        onChange={handleDefectChange}
                                    >
                                        {defects.map((defect, index) => (
                                            <MenuItem key={index} value={defect.title}>
                                                {defect.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>

                                <div style={{ paddingTop: 10 }}>
                                    <Typography variant="p" sx={{ fontWeight: 'bold' }}>
                                        Set custom condition index values for deduct bias
                                    </Typography>
                                </div>
                                <div style={{ paddingTop: 35, }}>
                                    <Slider
                                        min={-100}
                                        max={100}
                                        value={sliderValueBais}
                                        onChange={handleSliderChangeBais}
                                        aria-labelledby="range-slider"
                                        valueLabelDisplay="on"
                                        step={10}
                                        marks={[
                                            { value: -100, label: '-100%' },
                                            { value: -90, label: '-90%' },
                                            { value: -80, label: '-80%' },
                                            { value: -70, label: '-70%' },
                                            { value: -60, label: '-60%' },
                                            { value: -50, label: '-50%' },
                                            { value: -40, label: '-40%' },
                                            { value: -30, label: '-30%' },
                                            { value: -20, label: '-20%' },
                                            { value: -10, label: '-10%' },
                                            { value: 0, label: '0%' },
                                            { value: 10, label: '10%' },
                                            { value: 20, label: '20%' },
                                            { value: 30, label: '30%' },
                                            { value: 40, label: '40%' },
                                            { value: 50, label: '50%' },
                                            { value: 60, label: '60%' },
                                            { value: 70, label: '70%' },
                                            { value: 80, label: '80%' },
                                            { value: 90, label: '90%' },
                                            { value: 100, label: '100%' }
                                        ]}

                                        sx={{
                                            width: '100%',
                                            color: '#025196',
                                            '& .MuiSlider-thumb': {
                                                height: 16,
                                                width: 16,
                                            },
                                            '& .MuiSlider-mark': {
                                                backgroundColor: '#025196',
                                                height: 7,
                                                width: 2,
                                                boxShadow: '0px 0px 5px 2px #fafafa',
                                            },
                                            '& .MuiSlider-markLabel': {
                                                color: '#000000',
                                                fontWeight: 'normal',
                                            },
                                        }}
                                    />
                                </div>
                                <div style={{ paddingTop: 10 }}>
                                    <Typography variant="p" sx={{ fontWeight: 'bold' }}>
                                        Set custom condition index values for weightages
                                    </Typography>
                                </div>
                                <div style={{ paddingTop: 35 }}>
                                    <Slider
                                        min={0}
                                        max={10}
                                        value={sliderValue}
                                        onChange={handleSliderChange}
                                        aria-labelledby="range-slider"
                                        valueLabelDisplay="on"
                                        step={(sliderValue >= 1) ? 1 : 0.1}
                                        marks={[
                                            { value: 0, label: '0' },
                                            { value: 0.1, label: '' },
                                            { value: 0.2, label: '' },
                                            { value: 0.3, label: '' },
                                            { value: 0.4, label: '' },
                                            { value: 0.5, label: '' },
                                            { value: 0.6, label: '' },
                                            { value: 0.7, label: '' },
                                            { value: 0.8, label: '' },
                                            { value: 0.9, label: '' },
                                            { value: 1, label: '1' },
                                            { value: 2, label: '2' },
                                            { value: 3, label: '3' },
                                            { value: 4, label: '4' },
                                            { value: 5, label: '5' },
                                            { value: 6, label: '6' },
                                            { value: 7, label: '7' },
                                            { value: 8, label: '8' },
                                            { value: 9, label: '9' },
                                            { value: 10, label: '10' }
                                        ]}

                                        sx={{
                                            width: '100%',
                                            color: '#025196',
                                            '& .MuiSlider-thumb': {
                                                height: 16,
                                                width: 16,
                                            },
                                            '& .MuiSlider-mark': {
                                                backgroundColor: '#025196',
                                                height: 7,
                                                width: 2,
                                                boxShadow: '0px 0px 5px 2px #fafafa',
                                            },
                                            '& .MuiSlider-markLabel': {
                                                color: '#000000',
                                                fontWeight: 'normal',
                                            },
                                        }}
                                    />

                                </div>
                                <Grid container spacing={0} sx={{ paddingTop: '10px', }}>
                                    <Grid item xs={9}>

                                    </Grid>
                                    <Grid item xs={3} style={{
                                        display: "flex", justifyContent: "flex-end"
                                    }}>
                                        <Button className="button"
                                            variant="contained"
                                            onClick={() => { handeOpenDialog() }}
                                            sx={{

                                                "&.Mui-disabled": {
                                                    backgroundColor: '#025196',
                                                    color: '#ffffff'
                                                },
                                                marginInline: '2px',
                                                textTransform: 'initial',
                                                backgroundColor: '#025196',
                                                ':hover': {
                                                    backgroundColor: '#025196'
                                                }
                                            }}>Confirm</Button>
                                    </Grid>
                                </Grid>

                            </CardContent>

                        </Card>
                    }
                </div >}
        </>
    )
}
const mapStateToProps = (state, ownProps) => {
    const { proid } = ownProps
    return {
        project: state.project,
        drProjectData: get(
            state.firestore.data,
            `DrProject.${proid}`
        ),
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        pciCalculation: (proid, weightages, bais) => dispatch(pciCalculation(proid, weightages, bais)),
        clearPciCalcState: () => dispatch(clearPciCalcState()),
        roadConditionIndexBack: (proid) => dispatch(roadConditionIndexBack(proid)),
        roadConditionIndexNext: (proid) => dispatch(roadConditionIndexNext(proid)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoadConditonIndex);