import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createProject } from "../../../../store/actions/projectActions";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { v4 as uuidv4 } from 'uuid';
import { Collapse, Button, CardContent, CircularProgress, Container, Divider, List, ListItem, ListItemText, ListItemIcon, Grid, Tooltip, Typography, FormControlLabel, Checkbox, FormControl, } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import SimpleDialogBox from "../../../cidgets/SimpleDialogBox";
import { Select, MenuItem, InputLabel } from "@mui/material";
import Card from "@mui/material/Card";
import AssetAnalysis from "../../../../assets/AssetAnalysis.jpeg";
import SurfaceAnalysis from "../../../../assets/SurfaceAnalysis.jpeg";
import BasicImaging from "../../../../assets/BasicImaging.png";
import Scan3d from "../../../../assets/scan3d.jpg";
import ImageAnalysis from "../../../../assets/ImageAnalysis.jpg";
import AnalysisTypeIndicator from "../../../cidgets/AnalysisTypeIndicator";
import Delete from '@mui/icons-material/Delete';
import InputAdornment from "@mui/material/InputAdornment";
import { GlobalHotKeys } from "react-hotkeys";
import DemoAccountError from "../../../cidgets/DemoAccountError";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useAccessControlContext } from "../../../../contexts/AccessControlProvider";
import { set } from "lodash";

const textInputTheme = createTheme({
  palette: {
    secondary: {
      main: "#025196",
    },
  },
});

const AddProject = (props) => {
  const { companyPricingData, chargesV3 } = props
  const { permissions } = useAccessControlContext();
  const { canCreateProject } = permissions;
  const [projectType, setProjectType] = useState(undefined);
  const storedProcessingTime = localStorage.getItem('processingTime');
  const storedDrivingSide = localStorage.getItem('drivingSide');
  const storedTypeOfRoad = localStorage.getItem('typeOfRoad');
  const storedConditionIndex3D = JSON.parse(localStorage.getItem('selectedCondition3D'))
  const storedConditionIndexAdvanced = JSON.parse(localStorage.getItem('selectedConditionAdvanced'))

  const options = [
    { value: 1, label: '1 meter' },
    { value: 2, label: '2 meters' },
    { value: 5, label: '5 meters' },
    { value: 10, label: '10 meters' }
  ];
  // ADDED BY AAMIR FOR ERROR ON PROJECT CREATION WHEN PRICING NOT SET
  const [showErrorOnProjectCreateButton, setShowErrorOnProjectCreateButton] = useState(false);
  const [selectedDistance, setSelectedDistance] = useState('');
  const [selectedDistanceError, setSelectedDistanceError] = useState(false);
  // 

  // //////console.log(storedProcessingTime, storedDrivingSide, 'browser123')

  function getCurrentDate() {
    const currentDate = new Date();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const year = currentDate.getFullYear().toString();
    return `${month}/${day}/${year}`;
  }
  const conditionIndicesAdvanced = {
    "Custom Condition Index": 2,
    "Pavement Condition Index": 1,

  };
  const conditionIndices3D = {
    "Custom Condition Index": 2,
    "Pavement Condition Index": 1,
    "Road Condition Index": 0,

  };
  const [processingTime, setProcessingTime] = useState('');
  const [selectedCondition3D, setSelectedCondition3D] = useState([1]);
  const [selectedConditionAdvanced, setSelectedConditionAdvanced] = useState([1]);
  const [values, setValues] = React.useState({
    projectName: "",
    drivingSide: storedDrivingSide || "",
    typeOfRoad: storedTypeOfRoad || "carriageWay",
    cameraPosition: "",
    cameraDirection: "",
    cameraMountingHeight: "",
    cameraInclination: "",
    pavementSegment: "",
    surveyDate: getCurrentDate(),
    // processingTime: storedProcessingTime || "twoWeeks",
    identificationNo: "",
    clientName: "",
    streetName: "",
    startingAddress: "",
    endingAddress: "",
    direction: "",
    totalLanes: "",
    laneNumber: ""
  });

  const [segregatedCycleTrack, setSegregatedCycleTrack] = useState(true);

  const [error, setError] = useState(false);
  const [drivingSideError, setDrivingSideError] = useState(false);
  const [surveyDateError, setSurveyDateError] = useState(false);
  const [processingTimeError, setProcessingTimeError] = useState(false);
  const [selectedConditionError, setSelectedConditionError] = useState(false);
  const [typeOfRoadError, setTypeOfRoadError] = useState(false);
  const [cameraPositionError, setCameraPositionError] = useState(false);
  const [cameraDirectionError, setCameraDirectionError] = useState(false);
  const [cameraMountingHeightError, setCameraMountingHeightError] = useState(false);
  const [cameraInclinationError, setCameraInclinationError] = useState(false);
  const [pavementSegmentError, setPavementSegmentError] = useState(false);
  const [customCategoryError, setCustomCategoryError] = useState(false);
  const [customCategories, setCustomCategories] = useState([]);
  const [categoryInput, setCategoryInput] = useState("");
  const [isListOpen, setIsListOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [surfaceOpen, setSurfaceOpen] = useState(false);
  const [openProjectList, setOpenProjectList] = useState(false);
  const [surchargeData, setSurchargeData] = useState({});

  const assetAnalysisHeading = "Asset Analysis";
  const assetAnalysisText = "Detect and geolocate transportation assets like lampposts, traffic signs, and marker posts.";
  const surfaceAnalysisHeading = "Distress Analysis";
  const surfaceAnalysisText = "Detect, measure, and geolocate pavement distresses such as cracking, potholes, rutting, patches, among others.";
  const imageAnalysisHeading = "360 Tagger";
  const imageAnalysisText = "Open a video with its map, correct GPS errors, and tag issues and features.";
  const safetyInspectionAnalysisHeading = "Safety Analysis";
  const safetyInspectionAnalysisText = "Detect and geolocate transportation assets like lampposts, traffic signs, and marker posts.";

  const scanning3dHeading = "3D Scanning";
  const scanning3dText = "Detection, classification, and depth and surface level measurements of carriage way distresses from a rear facing video stream."
  const advancedImagingHeading = "Advanced Imaging";
  const advancedImagingText = "Detection, classification, and surface level measurements of carriage way distresses using from a rear facing video stream. No depth measurements are available"
  const basicImagingHeading = "Distress Detector";
  const basicImagingText = "Detection, classification, and coarse measurements of carriageway distresses from a front facing video stream. No depth measurements are available."

  const getSurchargeData = (projectType) => {
    // Check in companyPricingData.projectSurcharge
    if (companyPricingData && companyPricingData.projectSurcharge && companyPricingData.projectSurcharge[projectType]) {
      return companyPricingData.projectSurcharge[projectType];
    }

    // Check in companyPricingData.surcharge
    if (companyPricingData && companyPricingData.surcharge) {
      return companyPricingData.surcharge;
    }

    // Check in chargesV3.projectSurcharge
    if (chargesV3 && chargesV3.projectSurcharge && chargesV3.projectSurcharge[projectType]) {
      return chargesV3.projectSurcharge[projectType];
    }

    // Default to chargesV3.priorityMultipliers
    if (chargesV3 && chargesV3.priorityMultipliers) {
      return chargesV3.priorityMultipliers;
    }

    // Default return if no conditions are met
    return null;
  };


  useEffect(() => {
    const sortOrder = ["oneWeek", "twoDays", "oneDay", "twoWeeks"];
    const sortedData = {};
    let data = getSurchargeData(projectType);
    sortOrder.forEach(key => {
      if (data[key] !== undefined) {
        sortedData[key] = data[key];
      }
    });
    setSurchargeData(sortedData);
  }, [projectType]);
  const handleProjectType = (type) => {
    if (projectType === type) {
      setProjectType(undefined);
    } else {
      setProjectType(type);
    }
    setOpenProjectList(false)
  };
  const handleSubmit = (e) => {
    const { userData, companyData } = props;
    if (projectType === 1 || projectType === 3 || projectType === 4 || projectType === 5) {

      if (values.projectName.length === 0) {
        setError(true);
      }
      else if (selectedCondition3D.length === 0 || selectedConditionAdvanced.length === 0) {
        setSelectedConditionError(true);
      }
      else if (values.drivingSide === "") {
        setDrivingSideError(true);
      }
      else if (processingTime === "") {
        setProcessingTimeError(true);
      }

      else if (values.surveyDate === "") {
        setSurveyDateError(true);
      }
      // else if (values.cameraPosition === "") {
      //   setCameraPositionError(true);
      // }
      // else if (values.cameraDirection === "") {
      //   setCameraDirectionError(true);
      // }

      else {
        // //////console.log("project data: ", values, projectType)
        e.preventDefault();
        props.createProject(userData, companyData, {
          projectTitle: values.projectName,
          roadSide: values.drivingSide,
          camPos: values.cameraPosition,
          camDir: values.cameraDirection,
          processingTime: processingTime,
          conditionIndex: projectType === 4 ? selectedConditionAdvanced : projectType === 1 ? selectedCondition3D : null,
          surveyDate: values.surveyDate,
          typeOfRoad: values.typeOfRoad,
          cameraMountingHeight: values.cameraMountingHeight,
          cameraInclination: values.cameraInclination,
          pavementSegment: values.pavementSegment,
          projectType: projectType,
          identificationNo: values.identificationNo,
          clientName: values.clientName,
          streetName: values.streetName,
          startingAddress: values.startingAddress,
          endingAddress: values.endingAddress,
          direction: values.direction,
          totalLanes: values.totalLanes,
          laneNumber: values.laneNumber,
          segregatedCycleTrack: segregatedCycleTrack
        });
        handleClose();
        setValues({
          projectName: "",
          drivingSide: storedDrivingSide || "0",
          typeOfRoad: storedTypeOfRoad || "carriageWay",
          cameraPosition: "0",
          cameraDirection: "0",
          cameraMountingHeight: "",
          cameraInclination: "",
          pavementSegment: "",
          processingTime: storedProcessingTime || "twoWeeks",
          surveyDate: getCurrentDate(),
          identificationNo: "",
          clientName: "",
          streetName: "",
          startingAddress: "",
          endingAddress: "",
          direction: "",
          totalLanes: "",
          laneNumber: "",

        });
        if (projectType === 1) {
          setSelectedCondition3D(storedConditionIndex3D || [1]);
        }
        if (projectType === 4) {
          setSelectedConditionAdvanced(storedConditionIndexAdvanced || [1]);
        }
        // //////console.log(values.cameraDirection, values.cameraPosition, values.drivingSide);
      }
    }

    else {
      if (values.projectName.length === 0) {
        setError(true);
      }
      if (selectedDistance === '') {
        setSelectedDistanceError(true);
      }
      else {
        e.preventDefault();
        props.createProject(userData, companyData, {
          identificationNo: values.identificationNo,
          clientName: values.clientName,
          streetName: values.streetName,
          startingAddress: values.startingAddress,
          endingAddress: values.endingAddress,
          direction: values.direction,
          totalLanes: values.totalLanes,
          laneNumber: values.laneNumber,
          projectTitle: values.projectName,
          roadSide: values.drivingSide,
          camPos: values.cameraPosition,
          camDir: values.cameraDirection,
          processingTime: processingTime,
          surveyDate: values.surveyDate,
          typeOfRoad: values.typeOfRoad,
          cameraMountingHeight: values.cameraMountingHeight,
          cameraInclination: values.cameraInclination,
          pavementSegment: values.pavementSegment,
          projectType: projectType,
          categories: customCategories,
          segregatedCycleTrack: segregatedCycleTrack,
          distanceInterval: selectedDistance
        });
        handleClose();
        setSelectedDistanceError(false);
        setValues({
          projectName: "",
          drivingSide: storedDrivingSide || "0",
          typeOfRoad: storedTypeOfRoad || "carriageWay",
          cameraPosition: "0",
          cameraDirection: "0",
          cameraMountingHeight: "",
          cameraInclination: "",
          pavementSegment: "",
          processingTime: storedProcessingTime || "twoWeeks",
          surveyDate: getCurrentDate(),
          identificationNo: "",
          clientName: "",
          streetName: "",
          startingAddress: "",
          endingAddress: "",
          direction: "",
          totalLanes: "",
          laneNumber: ""
        });
        if (projectType === 1) {
          setSelectedCondition3D(storedConditionIndex3D || [1]);
        }
        if (projectType === 4) {
          setSelectedConditionAdvanced(storedConditionIndexAdvanced || [1]);
        };
      }
    }
  };



  const handleConditionChange3D = (event) => {

    setSelectedCondition3D(event.target.value);
  };
  const handleConditionChangeAdvanced = (event) => {
    setSelectedConditionAdvanced(event.target.value);
  }
  const handlePricingProjectCreationErrorClose = () => {
    setShowErrorOnProjectCreateButton(false)
  }

  const handleClickOpen = () => {
    if (companyPricingData == null) {
      // alert("No pricing document found.")
      setShowErrorOnProjectCreateButton(true)
      return
    }
    setSelectedDistance('');
    setSelectedDistanceError(false);
    setError(false);
    setCameraDirectionError(false);
    setDrivingSideError(false);
    setCameraPositionError(false);
    setCustomCategoryError(false);
    setCameraMountingHeightError(false);
    setCameraInclinationError(false);
    setPavementSegmentError(false);
    setProjectType(undefined);
    setOpen(true);
    setValues({
      projectName: "",
      drivingSide: storedDrivingSide || "",
      typeOfRoad: storedTypeOfRoad || "carriageWay",
      cameraPosition: "",
      cameraDirection: "",
      cameraMountingHeight: "",
      cameraInclination: "",
      pavementSegment: "",
      processingTime: storedProcessingTime || "twoWeeks",
      surveyDate: getCurrentDate(),
      identificationNo: "",
      clientName: "",
      streetName: "",
      startingAddress: "",
      endingAddress: "",
      direction: "",
      totalLanes: "",
      laneNumber: ""
    });
    if (projectType === 1) {
      setSelectedCondition3D(storedConditionIndex3D || [1]);
    }
    if (projectType === 4) {
      setSelectedConditionAdvanced(storedConditionIndexAdvanced || [1]);
    }
  };

  const handleDistanceChange = (event) => {
    setSelectedDistanceError(false);
    setSelectedDistance(event.target.value);
  };

  const handleClickSurfaceOpen = () => {
    setError(false);
    setCameraDirectionError(false);
    setDrivingSideError(false);
    setCameraPositionError(false);
    setCustomCategoryError(false);
    setCameraMountingHeightError(false);
    setCameraInclinationError(false);
    setPavementSegmentError(false);
    setProjectType(undefined);
    setSurfaceOpen(true);
    setValues({
      projectName: "",
      drivingSide: storedDrivingSide || "",
      typeOfRoad: storedTypeOfRoad || "carriageWay",
      cameraPosition: "",
      cameraDirection: "",
      cameraMountingHeight: "",
      cameraInclination: "",
      pavementSegment: "",
      processingTime: storedProcessingTime || "twoWeeks",
      surveyDate: getCurrentDate(),
      identificationNo: "",
      clientName: "",
      streetName: "",
      startingAddress: "",
      endingAddress: "",
      direction: "",
      totalLanes: "",
      laneNumber: ""
    });
    if (projectType === 1) {
      setSelectedCondition3D(storedConditionIndex3D || [1]);
    }
    if (projectType === 4) {
      setSelectedConditionAdvanced(storedConditionIndexAdvanced || [1]);
    }
  };

  const handleSurfaceClose = () => {
    setSurfaceOpen(false);
    setCategoryInput("");
    setCustomCategories([]);
  };

  const handleClose = () => {
    setOpen(false);
    setCategoryInput("");
    setCustomCategories([]);
  };

  const handleChange = (prop) => (e) => {
    e.preventDefault();
    setError(false);
    setCameraDirectionError(false);
    setDrivingSideError(false);
    setSurveyDateError(false);
    setProcessingTimeError(false);
    setSelectedConditionError(false);
    setTypeOfRoadError(false);
    setCameraPositionError(false);
    setCustomCategoryError(false);
    setCameraMountingHeightError(false);
    setCameraInclinationError(false);
    setPavementSegmentError(false);

    // if (prop === 'processingTime') {
    //   localStorage.setItem('processingTime', e.target.value);
    // } else 
    if (prop === 'drivingSide') {
      localStorage.setItem('drivingSide', e.target.value);
    }
    else if (prop === 'typeOfRoad') {
      localStorage.setItem('typeOfRoad', e.target.value);
    }
    else if (prop === 'selectedCondition3D') {
      localStorage.setItem('selectedCondition3D', JSON.stringify(e.target.value));
      setSelectedCondition3D(e.target.value);
    }
    else if (prop === 'selectedConditionAdvanced') {
      localStorage.setItem('selectedConditionAdvanced', JSON.stringify(e.target.value));
      setSelectedConditionAdvanced(e.target.value);
    }


    setValues({ ...values, [prop]: e.target.value });
  };

  const preventDefaultHandlers = (handlers) => {
    const newHandlers = {};
    for (const [action, handler] of Object.entries(handlers)) {
      newHandlers[action] = (event) => {
        if (event) {
          event.preventDefault();
        }
        handler();
      };
    }
    return newHandlers;
  };
  const keyShortcut = "n";
  const keyMap = {
    NEW_MAC: "shift+" + keyShortcut,
    NEW_WIN: "shift+" + keyShortcut,
  };

  const handlers = preventDefaultHandlers({
    NEW_MAC: handleClickOpen,
    NEW_WIN: handleClickOpen,
  });

  const isDemoAccount = props.userData.uid === props.charges?.demoAccount;

  const handleAddCategory = () => {
    if (categoryInput.trim() !== "") {
      const id = uuidv4();
      setCustomCategories({
        ...customCategories,
        [id]: { categoryId: id, name: categoryInput }
      });
      setCategoryInput("");
      setIsListOpen(true);
    }

  };
  const handleDeleteCategory = (id) => {
    const updatedCategories = { ...customCategories };
    delete updatedCategories[id];
    setCustomCategories(updatedCategories);
  };
  const toggleList = () => {
    setIsListOpen(!isListOpen);
  };

  const handleToggle = () => {
    setOpenProjectList(!openProjectList);
  };

  const handleChangeSegregatedCycleTrack = (event) => {
    setSegregatedCycleTrack(event.target.checked);
  };
  const disabledProject = companyPricingData && companyPricingData['canProcess'][projectType] ? companyPricingData['canProcess'][projectType] : false;
  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers}></GlobalHotKeys>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Or use shift+n to open" arrow>
          {canCreateProject ? <Button size="small" variant="contained"
            sx={{
              whiteSpace: 'nowrap',
              textTransform: "none",
              paddingInline: "18px",
              marginInline: "10px",
              display: { xs: "none", sm: "none", md: "flex" },
              backgroundColor: "#025196",
              ":hover": {
                backgroundColor: "#025196",
              },
            }}
            disabled={!canCreateProject}
            onClick={handleClickOpen}
          >
            <span>Create project</span>
          </Button> : <></>}
        </Tooltip>
        <Tooltip title="Create new project" arrow>
          {canCreateProject ? <IconButton disabled={!canCreateProject} sx={{ display: { xs: "flex", sm: "flex", md: "none" }, }} onClick={handleClickOpen}>
            <AddIcon />
          </IconButton> : <></>}
        </Tooltip>

        <SimpleDialogBox
          open={showErrorOnProjectCreateButton}
          bodyWidget={
            <div>
              <p>
                It seems like you haven't tried our paid service yet. We kindly ask you to contact us at <strong>sales@roadgauge.ai</strong> before getting started.
              </p>
            </div>
          }
          width={750}
          onClose={handlePricingProjectCreationErrorClose}
          showPositive={true}
          showCancel={false}
          onPositive={handlePricingProjectCreationErrorClose}
          positiveText="Close"
          title="Get started!"
        />

        <SimpleDialogBox
          open={open}
          onClose={handleClose}
          title="Create a new project"
          cancelText="Cancel"
          showCancelShortcutOnLeft
          desc={isDemoAccount ? null : "Select an analysis type"}
          bodyWidget={
            isDemoAccount ? (
              <DemoAccountError isDialog />
            ) : (
              <div style={{ marginTop: "18px" }}>
                <ThemeProvider theme={textInputTheme}>
                  {projectType === undefined ? (
                    <Container sx={{ display: "flex", flexWrap: "wrap" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Card elevation={0}
                            sx={{
                              height: "100%",
                              flex: "1 0 300px",
                              display: "flex",
                              flexDirection: "column",
                              "&:hover": {
                                boxShadow: "inset 0px 0px 0px 3px green",
                                backgroundColor: "#fff",
                              },
                              cursor: "pointer",
                              boxShadow:
                                projectType === 0
                                  ? "inset 0px 0px 0px 1px green"
                                  : "inset 0px 0px 0px 0.5px grey",
                              borderRadius: "8px",
                            }}
                            onClick={() => {
                              handleProjectType(0);
                            }}
                          >
                            <CardContent sx={{ flexGrow: 1 }}>
                              <h6 style={{ fontWeight: "bold" }}>
                                {assetAnalysisHeading}
                              </h6>
                              <div style={{ marginBlock: "20px" }}>
                                <img
                                  src={AssetAnalysis}
                                  width="100%"
                                  height="150px"
                                  style={{ objectFit: "cover" }}
                                />
                              </div>
                              <span>{assetAnalysisText}</span>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={6}>
                          <Card
                            elevation={0}
                            sx={{
                              height: "100%",
                              flex: "1 0 300px",
                              display: "flex",
                              flexDirection: "column",
                              "&:hover": {
                                boxShadow: "inset 0px 0px 0px 3px green",
                                backgroundColor: "#fff",
                              },
                              cursor: "pointer",
                              boxShadow:
                                projectType === 1
                                  ? "inset 0px 0px 0px 1px green"
                                  : "inset 0px 0px 0px 0.5px grey",
                              borderRadius: "8px",
                            }}
                            // onClick={() => {
                            //   handleProjectType(1);
                            // }}
                            onClick={handleClickSurfaceOpen}

                          >
                            <CardContent sx={{ flexGrow: 1 }}>
                              <h6 style={{ fontWeight: "bold" }}>
                                {surfaceAnalysisHeading}
                              </h6>
                              <div style={{ marginBlock: "20px" }}>
                                <img
                                  src={SurfaceAnalysis}
                                  width="100%"
                                  height="150px"
                                  style={{ objectFit: "cover" }}
                                />
                              </div>
                              <span>{surfaceAnalysisText}</span>
                            </CardContent>
                          </Card>
                        </Grid>

                        <SimpleDialogBox
                          open={surfaceOpen}
                          onClose={handleSurfaceClose}
                          title="Create a new project"
                          cancelText="Back"
                          showCancelShortcutOnLeft
                          desc={isDemoAccount ? null : "Select distress analysis type"}
                          bodyWidget={

                            <div style={{ marginTop: "18px" }}>
                              <ThemeProvider theme={textInputTheme}>

                                <Container sx={{ display: "flex", flexWrap: "wrap" }}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                      <Card elevation={0}
                                        sx={{
                                          height: "100%",
                                          flex: "1 0 300px",
                                          display: "flex",
                                          flexDirection: "column",
                                          "&:hover": {
                                            boxShadow: "inset 0px 0px 0px 3px green",
                                            backgroundColor: "#fff",
                                          },
                                          cursor: "pointer",
                                          boxShadow:
                                            projectType === 1
                                              ? "inset 0px 0px 0px 1px green"
                                              : "inset 0px 0px 0px 0.5px grey",
                                          borderRadius: "8px",
                                        }}
                                        onClick={() => {
                                          handleProjectType(1);
                                        }}
                                      >
                                        <CardContent sx={{ flexGrow: 1 }}>
                                          <h6 style={{ fontWeight: "bold" }}>
                                            {scanning3dHeading}
                                          </h6>
                                          <div style={{ marginBlock: "20px" }}>
                                            <img
                                              src={Scan3d}
                                              width="100%"
                                              height="150px"
                                              style={{ objectFit: "cover" }}
                                            />
                                          </div>
                                          <span>{scanning3dText}</span>
                                        </CardContent>
                                      </Card>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Card
                                        elevation={0}
                                        sx={{
                                          height: "100%",
                                          flex: "1 0 300px",
                                          display: "flex",
                                          flexDirection: "column",
                                          "&:hover": {
                                            boxShadow: "inset 0px 0px 0px 3px green",
                                            backgroundColor: "#fff",
                                          },
                                          cursor: "pointer",
                                          boxShadow:
                                            projectType === 4
                                              ? "inset 0px 0px 0px 1px green"
                                              : "inset 0px 0px 0px 0.5px grey",
                                          borderRadius: "8px",
                                        }}
                                        onClick={() => {
                                          handleProjectType(4);
                                        }}

                                      >
                                        <CardContent sx={{ flexGrow: 1 }}>
                                          <h6 style={{ fontWeight: "bold" }}>
                                            {advancedImagingHeading}
                                          </h6>
                                          <div style={{ marginBlock: "20px" }}>
                                            <img
                                              src={SurfaceAnalysis}
                                              width="100%"
                                              height="150px"
                                              style={{ objectFit: "cover" }}
                                            />
                                          </div>
                                          <span>{advancedImagingText}</span>
                                        </CardContent>
                                      </Card>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Card
                                        elevation={0}
                                        sx={{
                                          height: "100%",
                                          flex: "1 0 300px",
                                          display: "flex",
                                          flexDirection: "column",
                                          "&:hover": {
                                            boxShadow: "inset 0px 0px 0px 3px green",
                                            backgroundColor: "#fff",
                                          },
                                          cursor: "pointer",
                                          boxShadow:
                                            projectType === 5
                                              ? "inset 0px 0px 0px 1px green"
                                              : "inset 0px 0px 0px 0.5px grey",
                                          borderRadius: "8px",
                                        }}
                                        onClick={() => {
                                          handleProjectType(5);
                                        }}
                                      >
                                        <CardContent sx={{ flexGrow: 1 }}>
                                          <h6 style={{ fontWeight: "bold" }}>
                                            {basicImagingHeading}
                                          </h6>
                                          <div style={{ marginBlock: "20px" }}>
                                            <img
                                              src={BasicImaging}
                                              width="100%"
                                              height="150px"
                                              style={{ objectFit: "cover" }}
                                            />
                                          </div>
                                          <span>{basicImagingText}</span>
                                        </CardContent>
                                      </Card>
                                    </Grid>
                                  </Grid>
                                </Container>


                                {projectType !== undefined ? (
                                  <>
                                    <Grid container spacing={0}>
                                      <Grid item xs={12} sx={{ marginBlock: "10px" }} >
                                        <div>
                                          <InputLabel sx={{ color: "#1E1E1E" }}>
                                            <AnalysisTypeIndicator type={projectType} size={13} hasBorder={false} weight={"bold"} long={true} />
                                          </InputLabel>
                                        </div>
                                        <div>
                                          <a style={{ fontSize: "14px" }} href="/home/dashboard" onClick={(event) => { event.preventDefault(); setProjectType(undefined); }}>
                                            Change analysis type
                                          </a>
                                        </div>
                                      </Grid>
                                    </Grid>
                                    <Divider />
                                    <InputLabel sx={{ marginTop: "20px" }} error={error} id="driving-side-label" >
                                      {error ? "Enter a project title" : "Project title"}
                                    </InputLabel>
                                    <TextField sx={{ margin: "0px" }} error={error} autoFocus required onChange={handleChange("projectName")}
                                      margin="dense"
                                      id="Project title"
                                      type="text"
                                      fullWidth
                                      color="secondary"
                                    />

                                    {projectType === 2 ? (
                                      <>
                                        <InputLabel sx={{ marginTop: "20px" }} id="customCategory-label" >
                                          Define tag categories (optional)
                                        </InputLabel>
                                        <TextField
                                          sx={{ margin: '0px' }}
                                          margin="dense"
                                          id="customCategory"
                                          type="text"
                                          fullWidth
                                          value={categoryInput}
                                          onChange={(e) => setCategoryInput(e.target.value)}
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <Tooltip title="Add Category" arrow>
                                                  <IconButton
                                                    onClick={handleAddCategory}
                                                    edge="end"
                                                    aria-label="Add Category"
                                                    sx={{
                                                      color: "#1982C4",
                                                    }}
                                                  >
                                                    <AddIcon />
                                                  </IconButton>
                                                </Tooltip>
                                                {Object.keys(customCategories).length > 0 && (

                                                  <IconButton onClick={toggleList}>
                                                    {isListOpen ?
                                                      <Tooltip title="Hide Categories" arrow>
                                                        <ExpandLess />
                                                      </Tooltip> :
                                                      <Tooltip title="Show Categories" arrow>
                                                        <ExpandMore />
                                                      </Tooltip>}
                                                  </IconButton>
                                                )}
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                        {Object.keys(customCategories).length > 0 && (
                                          <Collapse in={isListOpen}>
                                            <List
                                              sx={{
                                                border: '1px dashed  #D4D4D4',
                                                padding: '16px',
                                                borderRadius: '5px',
                                                marginBottom: '8px',
                                                marginTop: '5px',
                                                height: '100px',
                                                overflow: 'auto',
                                              }}
                                            >
                                              {Object.keys(customCategories).map(id => (
                                                <ListItem
                                                  key={id} // Ensure a unique "key" prop

                                                >
                                                  <ListItemText primary={customCategories[id].name}
                                                    sx={{
                                                      borderBottom: '1px solid   #D4D4D4',
                                                      '&:last-child': {
                                                        borderBottom: 'none',
                                                      },
                                                    }}
                                                  />
                                                  <Tooltip title="Delete Category" arrow>
                                                    <IconButton
                                                      aria-label="Delete"

                                                      onClick={() => handleDeleteCategory(id)}
                                                    >
                                                      <Delete />
                                                    </IconButton>
                                                  </Tooltip>
                                                </ListItem>
                                              ))}
                                            </List>
                                          </Collapse>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <InputLabel
                                          sx={{
                                            marginTop: "20px",
                                          }}
                                          error={drivingSideError}
                                          id="driving-side-label"
                                        >
                                          {drivingSideError
                                            ? "Select side of driving"
                                            : "Side of driving"}
                                        </InputLabel>
                                        <Select
                                          labelId="driving-side-label"
                                          className="capitalize-text"
                                          id="driving-side"
                                          color="secondary"
                                          value={values.drivingSide}
                                          onChange={handleChange("drivingSide")}
                                          fullWidth // set full width
                                        >
                                          <MenuItem className="capitalize-text" value="0">
                                            left
                                          </MenuItem>
                                          <MenuItem className="capitalize-text" value="1">
                                            right
                                          </MenuItem>
                                        </Select>


                                        {/* <InputLabel
                                          sx={{
                                            marginTop: "20px",
                                          }}
                                          error={cameraDirectionError}
                                          id="camera-direction-label"
                                        >
                                          {" "}
                                          {cameraDirectionError
                                            ? "Select direction of the camera"
                                            : "Direction of the camera"}
                                        </InputLabel>
                                        <Select
                                          color="secondary"
                                          required
                                          labelId="camera-direction-label"
                                          className="capitalize-text"
                                          id="camera-position"
                                          value={values.cameraDirection}
                                          onChange={handleChange("cameraDirection")}
                                          fullWidth // set full width
                                        >
                                          <MenuItem className="capitalize-text" value="0">
                                            forward facing
                                          </MenuItem>
                                          <MenuItem className="capitalize-text" value="1">
                                            rear facing
                                          </MenuItem>
                                        </Select> */}
                                      </>
                                    )}

                                    <InputLabel sx={{ marginTop: "20px" }}>
                                      Client name (optional)
                                    </InputLabel>
                                    <TextField sx={{ margin: "0px" }} autoFocus onChange={handleChange("clientName")}
                                      margin="dense"
                                      id="Client Name"
                                      type="text"
                                      fullWidth
                                      color="secondary"
                                    />

                                    <InputLabel sx={{ marginTop: "20px" }}>
                                      Identification number (optional)
                                    </InputLabel>
                                    <TextField sx={{ margin: "0px" }} autoFocus onChange={handleChange("identificationNo")}
                                      margin="dense"
                                      id="Identification Number"
                                      type="text"
                                      fullWidth
                                      color="secondary"
                                    />

                                    <InputLabel sx={{ marginTop: "20px" }}>
                                      Street name (optional)
                                    </InputLabel>
                                    <TextField sx={{ margin: "0px" }} autoFocus onChange={handleChange("streetName")}
                                      margin="dense"
                                      id="Street Name"
                                      type="text"
                                      fullWidth
                                      color="secondary"
                                    />

                                    <InputLabel sx={{ marginTop: "20px" }}>
                                      Starting landmark (optional)
                                    </InputLabel>
                                    <TextField sx={{ margin: "0px" }} autoFocus onChange={handleChange("startingAddress")}
                                      margin="dense"
                                      id="Starting Address"
                                      type="text"
                                      fullWidth
                                      color="secondary"
                                    />

                                    <InputLabel sx={{ marginTop: "20px" }}>
                                      Ending landmark (optional)
                                    </InputLabel>
                                    <TextField sx={{ margin: "0px" }} autoFocus onChange={handleChange("endingAddress")}
                                      margin="dense"
                                      id="Ending Address"
                                      type="text"
                                      fullWidth
                                      color="secondary"
                                    />

                                    {projectType !== 2 && (

                                      <>

                                        <InputLabel sx={{ marginTop: "20px" }}>
                                          Direction of traffic (optional)
                                        </InputLabel>
                                        <TextField sx={{ margin: "0px" }} autoFocus onChange={handleChange("direction")}
                                          margin="dense"
                                          id="Direction"
                                          type="text"
                                          fullWidth
                                          color="secondary"
                                        />
                                        {(projectType !== 0 && projectType !== 3) && (
                                          <>
                                            <InputLabel sx={{ marginTop: "20px" }}>
                                              Number of lanes (optional)
                                            </InputLabel>
                                            <TextField sx={{ margin: "0px" }} autoFocus onChange={handleChange("totalLanes")}
                                              margin="dense"
                                              id="Total Lanes"
                                              type="text"
                                              fullWidth
                                              color="secondary"
                                            />

                                            <InputLabel sx={{ marginTop: "20px" }}>
                                              Current lane number (optional)
                                            </InputLabel>
                                            <TextField sx={{ margin: "0px" }} autoFocus onChange={handleChange("laneNumber")}
                                              margin="dense"
                                              id="Lane Number"
                                              type="text"
                                              fullWidth
                                              color="secondary"
                                            />
                                          </>
                                        )}
                                      </>
                                    )}

                                  </>
                                ) : (
                                  <></>
                                )}
                              </ThemeProvider>
                            </div >

                          }
                          width="800"
                          showPositive
                          positiveButtonWidget={
                            props.project.isProjectCreating === true ? (
                              <>
                                <Button
                                  variant="contained"
                                  disabled
                                  sx={{
                                    "&.Mui-disabled": {
                                      backgroundColor: "#025196",
                                      color: "#ffffff",
                                    },
                                    marginInline: "2px",
                                    textTransform: "initial",
                                    color: "#025196",
                                    backgroundColor: "#025196",
                                    ":hover": {
                                      backgroundColor: "#025196",
                                    },
                                  }}
                                >
                                  <CircularProgress
                                    variant="indeterminate"
                                    size={15}
                                    sx={{ color: "white" }}
                                  />
                                  <span style={{ paddingLeft: "15px" }}>
                                    Creating project...
                                  </span>
                                </Button>
                              </>
                            ) : (
                              <Button
                                variant="contained"
                                disabled={!canCreateProject ? true : projectType === undefined ? true : false}
                                sx={{
                                  marginInline: "2px",
                                  textTransform: "initial",
                                  backgroundColor: "#025196",
                                  ":hover": {
                                    backgroundColor: "#025196",
                                  },
                                }}
                                onClick={handleSubmit}
                              >
                                Create project
                              </Button>
                            )
                          }
                        />

                        <Grid item xs={6}>
                          <Card
                            elevation={0}
                            sx={{
                              height: "100%",
                              flex: "1 0 300px",
                              display: "flex",
                              flexDirection: "column",
                              "&:hover": {
                                boxShadow: "inset 0px 0px 0px 3px green",
                                backgroundColor: "#fff",
                              },
                              cursor: "pointer",
                              boxShadow:
                                projectType === 2
                                  ? "inset 0px 0px 0px 1px green"
                                  : "inset 0px 0px 0px 0.5px grey",
                              borderRadius: "8px",
                            }}
                            onClick={() => {
                              handleProjectType(2);
                            }}
                          >
                            <CardContent sx={{ flexGrow: 1 }}>
                              <h6 style={{ fontWeight: "bold" }}>
                                {imageAnalysisHeading}
                              </h6>
                              <div style={{ marginBlock: "20px" }}>
                                <img
                                  src={ImageAnalysis}
                                  width="100%"
                                  height="150px"
                                  style={{ objectFit: "cover" }}
                                />
                              </div>
                              <span>{imageAnalysisText}</span>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={6}>
                          <Card className="projectcard-container-disabled" elevation={0}
                            sx={{
                              height: "100%",
                              flex: "1 0 300px",
                              display: "flex",
                              flexDirection: "column",
                              "&:hover": {
                                boxShadow: "inset 0px 0px 0px 3px green",
                                backgroundColor: "#fff",
                              },
                              cursor: "pointer",
                              boxShadow:
                                projectType === 3
                                  ? "inset 0px 0px 0px 1px green"
                                  : "inset 0px 0px 0px 0.5px grey",
                              borderRadius: "8px",
                            }}
                            onClick={() => {
                              handleProjectType(3);
                            }}
                          >
                            <CardContent sx={{ flexGrow: 1 }}>
                              <h6 style={{ fontWeight: "bold" }}>
                                {safetyInspectionAnalysisHeading}
                              </h6>
                              <div style={{ marginBlock: "20px" }}>
                                <img
                                  src={AssetAnalysis}
                                  width="100%"
                                  height="150px"
                                  style={{ objectFit: "cover" }}
                                />
                              </div>
                              <span>{safetyInspectionAnalysisText}</span>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Container>
                  ) : (
                    <></>
                  )}

                  {projectType !== undefined ? (
                    <>
                      <Grid container spacing={0}>
                        <Grid item xs={12} sx={{ marginBlock: "10px" }} >
                          <div>
                            <InputLabel sx={{ color: "#1E1E1E" }}>
                              <AnalysisTypeIndicator type={projectType} size={13} hasBorder={false} weight={"bold"} long={true} />
                            </InputLabel>
                          </div>
                          <div>
                            <a style={{ fontSize: "14px" }} href="/home/dashboard" onClick={(event) => { event.preventDefault(); setProjectType(undefined); }}>
                              {projectType === 1 || projectType === 4 || projectType === 5 ?
                                'Change distress analysis type'
                                :
                                'Change analysis type'
                              }
                            </a>
                          </div>
                        </Grid>
                      </Grid>
                      <Divider />
                      <InputLabel sx={{ marginTop: "20px" }} error={error} id="driving-side-label" >
                        {error ? "Enter a project title*" : "Project title*"}
                      </InputLabel>
                      <TextField sx={{ margin: "0px" }} error={error} autoFocus required onChange={handleChange("projectName")}
                        margin="dense"
                        id="Project title"
                        type="text"
                        fullWidth
                        color="secondary"
                      />

                      {projectType === 2 ? (
                        <>

                          <InputLabel
                            sx={{
                              marginTop: "20px",
                            }}
                            error={surveyDateError}
                            id="survey-date-label"
                          >
                            {" "}
                            {surveyDateError
                              ? "Select Survey date*"
                              : "Survey date*"}
                          </InputLabel>

                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker', 'DatePicker']}>
                              <DatePicker
                                label="Date"
                                value={dayjs(values.surveyDate)}
                                onChange={(newValue) => setValues({ ...values, surveyDate: dayjs(newValue).format('MM/DD/YYYY') })}
                              // disablePast
                              />
                            </DemoContainer>
                          </LocalizationProvider>

                          <FormControl fullWidth sx={{ marginTop: '20px' }}>
                            <InputLabel error={selectedDistanceError} htmlFor="distance-label">Distance interval*</InputLabel>
                            <Select
                              value={selectedDistance}
                              label="Distance interval"
                              inputProps={{ name: 'distance', id: 'distance-label' }}
                              onChange={handleDistanceChange}
                            >
                              {options.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      ) : (
                        <>
                          <InputLabel
                            sx={{
                              marginTop: "20px",
                            }}
                            error={drivingSideError}
                            id="driving-side-label"
                          >
                            {drivingSideError
                              ? "Select side of driving*"
                              : "Side of driving*"}
                          </InputLabel>
                          <Select
                            labelId="driving-side-label"
                            className="capitalize-text"
                            id="driving-side"
                            color="secondary"
                            value={values.drivingSide}
                            onChange={handleChange("drivingSide")}
                            fullWidth // set full width
                          >
                            <MenuItem className="capitalize-text" value="0">
                              left
                            </MenuItem>
                            <MenuItem className="capitalize-text" value="1">
                              right
                            </MenuItem>
                          </Select>

                          {/* <InputLabel
                            sx={{
                              marginTop: "20px",
                            }}
                            error={cameraPositionError}
                            id="camera-position-label"
                          >
                            {" "}
                            {cameraPositionError
                              ? "Select camera position with respect to the vehicle"
                              : "Camera position with respect to the vehicle"}
                          </InputLabel>
                          <Select
                            color="secondary"
                            labelId="camera-position-label"
                            className="capitalize-text"
                            id="camera-position"
                            value={values.cameraPosition}
                            onChange={handleChange("cameraPosition")}
                            fullWidth // set full width
                          >
                            <MenuItem className="capitalize-text" value="0">
                              inside
                            </MenuItem>
                            <MenuItem className="capitalize-text" value="1">
                              outside
                            </MenuItem>
                          </Select> */}

                          {/* <InputLabel
                            sx={{
                              marginTop: "20px",
                            }}
                            error={cameraDirectionError}
                            id="camera-direction-label"
                          >
                            {" "}
                            {cameraDirectionError
                              ? "Select direction of the camera"
                              : "Direction of the camera"}
                          </InputLabel>
                          <Select
                            color="secondary"
                            required
                            labelId="camera-direction-label"
                            className="capitalize-text"
                            id="camera-position"
                            value={values.cameraDirection}
                            onChange={handleChange("cameraDirection")}
                            fullWidth // set full width
                          >
                            <MenuItem className="capitalize-text" value="0">
                              forward facing
                            </MenuItem>
                            <MenuItem className="capitalize-text" value="1">
                              rear facing
                            </MenuItem>
                          </Select> */}


                          <InputLabel
                            sx={{
                              marginTop: "20px",
                            }}
                            error={processingTimeError}
                            id="processing-time-label"
                          >
                            {" "}
                            {processingTimeError
                              ? "Select Processing timeline*"
                              : "Processing timeline choice*"}
                          </InputLabel>
                          <Select
                            color="secondary"
                            required
                            labelId="processing-time-label"
                            // className="capitalize-text"
                            id="processing-time"
                            value={processingTime}
                            onChange={(event) => { setProcessingTime(event.target.value) }}
                            // onChange={handleChange("processingTime")}
                            fullWidth // set full width
                          >
                            {Object.entries(surchargeData).map(([duration, surcharge]) => {
                              const label = duration.replace('oneWeek', '1 week [default]')
                                .replace('twoDays', '2 work days [25% surcharge]')
                                .replace('oneDay', '1 work day [50% surcharge]')
                                .replace('twoWeeks', '2 weeks');
                              return (
                                <MenuItem className="" value={duration}>
                                  {label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {projectType === 1 && (
                            <>
                              <InputLabel
                                sx={{
                                  marginTop: "20px",
                                }}
                                error={selectedConditionError}
                                id="condition-label"
                              >
                                {selectedConditionError
                                  ? "Select condition index*"
                                  : "Condition index*"}
                              </InputLabel>
                              <FormControl fullWidth>
                                <Select
                                  labelId="condition-label"
                                  id="condition-select"
                                  multiple
                                  value={selectedCondition3D}
                                  onChange={handleChange("selectedCondition3D")}
                                  color="secondary"
                                  renderValue={(selected) =>
                                    selected.map((index) =>
                                      Object.keys(conditionIndices3D).find((key) => conditionIndices3D[key] === index)
                                    ).join(', ')
                                  }
                                >
                                  {Object.entries(conditionIndices3D).map(([key, index]) => (
                                    <MenuItem key={key} value={index}>
                                      <Checkbox checked={selectedCondition3D.includes(index)} />
                                      <ListItemText primary={key} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </>
                          )}
                          {projectType === 4 ? (
                            <>
                              <InputLabel
                                sx={{
                                  marginTop: "20px",
                                }}
                                error={selectedConditionError}
                                id="condition-label"
                              >
                                {selectedConditionError
                                  ? "Select condition index*"
                                  : "Condition index*"}
                              </InputLabel>
                              <FormControl fullWidth>
                                <Select
                                  labelId="condition-label"
                                  id="condition-select"
                                  multiple
                                  value={selectedConditionAdvanced}
                                  onChange={handleChange("selectedConditionAdvanced")}
                                  color="secondary"
                                  renderValue={(selected) =>
                                    selected
                                      .map((index) =>
                                        Object.keys(conditionIndicesAdvanced).find(
                                          (key) => conditionIndicesAdvanced[key] === index
                                        )
                                      )
                                      .filter((label) => label)
                                      .join(', ')
                                  }
                                >
                                  {Object.entries(conditionIndicesAdvanced).map(([key, index]) => (
                                    <MenuItem key={key} value={index}>
                                      <Checkbox checked={selectedConditionAdvanced.includes(index)} />
                                      <ListItemText primary={key} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </>
                          ) : (<></>)}
                          <InputLabel
                            sx={{
                              marginTop: "20px",
                            }}
                            error={surveyDateError}
                            id="survey-date-label"
                          >
                            {" "}
                            {surveyDateError
                              ? "Select Survey date*"
                              : "Survey date*"}
                          </InputLabel>


                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker', 'DatePicker']}>
                              <DatePicker
                                label="Date"
                                value={dayjs(values.surveyDate)}
                                onChange={(newValue) => setValues({ ...values, surveyDate: dayjs(newValue).format('MM/DD/YYYY') })}
                              // disablePast
                              />
                            </DemoContainer>
                          </LocalizationProvider>



                          {projectType === 1 ? (
                            <>
                              <InputLabel
                                sx={{
                                  marginTop: "20px",
                                }}
                                error={typeOfRoadError}
                                id="processing-time-label"
                              >
                                {" "}
                                {typeOfRoadError
                                  ? "Select Type of road*"
                                  : "Type of road*"}
                              </InputLabel>
                              <Select
                                color="secondary"
                                required
                                labelId="road-type-label"
                                // className="capitalize-text"
                                id="road-type"
                                value={values.typeOfRoad}
                                onChange={handleChange("typeOfRoad")}
                                fullWidth // set full width
                              >
                                <MenuItem className="" value="carriageWay">
                                  Carriageway
                                </MenuItem>
                                <MenuItem className="" value="cycleTracks">
                                  Cycleway
                                </MenuItem>
                                {/* <MenuItem className="" value="footWay">
                                  Footway
                                </MenuItem> */}
                              </Select>

                              {values.typeOfRoad === "cycleTracks" && (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={segregatedCycleTrack}
                                      onChange={handleChangeSegregatedCycleTrack}
                                      color="secondary"
                                    />
                                  }
                                  label="Segregated cycle track"
                                />
                              )}
                            </>
                          )
                            :
                            <> </>
                          }

                        </>
                      )}

                      <Typography variant="body1" sx={{ marginTop: "20px" }}>
                        {openProjectList ? (
                          "Collapse to hide optional fields"
                        ) : (
                          "Expand to view optional fields"
                        )}
                        <IconButton onClick={handleToggle}>
                          {openProjectList ? <Tooltip title="Close"><ExpandLess /></Tooltip> : <Tooltip title="Expand to view optional fields"><ExpandMore /></Tooltip>}
                        </IconButton>
                      </Typography>

                      <Collapse in={openProjectList} timeout="auto" unmountOnExit>
                        {
                          projectType === 2 &&
                          <>
                            <InputLabel sx={{ marginTop: "20px" }} id="customCategory-label" >
                              Define tag categories
                            </InputLabel>
                            <TextField
                              sx={{ margin: '0px' }}
                              margin="dense"
                              id="customCategory"
                              type="text"
                              fullWidth
                              value={categoryInput}
                              onChange={(e) => setCategoryInput(e.target.value)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Tooltip title="Add Category" arrow>
                                      <IconButton
                                        onClick={handleAddCategory}
                                        edge="end"
                                        aria-label="Add Category"
                                        sx={{
                                          color: "#1982C4",
                                        }}
                                      >
                                        <AddIcon />
                                      </IconButton>
                                    </Tooltip>
                                    {Object.keys(customCategories).length > 0 && (

                                      <IconButton onClick={toggleList}>
                                        {isListOpen ?
                                          <Tooltip title="Hide Categories" arrow>
                                            <ExpandLess />
                                          </Tooltip> :
                                          <Tooltip title="Show Categories" arrow>
                                            <ExpandMore />
                                          </Tooltip>}
                                      </IconButton>
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {Object.keys(customCategories).length > 0 && (
                              <Collapse in={isListOpen}>
                                <List
                                  sx={{
                                    border: '1px dashed  #D4D4D4',
                                    padding: '16px',
                                    borderRadius: '5px',
                                    marginBottom: '8px',
                                    marginTop: '5px',
                                    height: '100px',
                                    overflow: 'auto',
                                  }}
                                >
                                  {Object.keys(customCategories).map(id => (
                                    <ListItem
                                      key={id} // Ensure a unique "key" prop

                                    >
                                      <ListItemText primary={customCategories[id].name}
                                        sx={{
                                          borderBottom: '1px solid   #D4D4D4',
                                          '&:last-child': {
                                            borderBottom: 'none',
                                          },
                                        }}
                                      />
                                      <Tooltip title="Delete Category" arrow>
                                        <IconButton
                                          aria-label="Delete"

                                          onClick={() => handleDeleteCategory(id)}
                                        >
                                          <Delete />
                                        </IconButton>
                                      </Tooltip>
                                    </ListItem>
                                  ))}
                                </List>
                              </Collapse>
                            )}
                          </>


                        }

                        <InputLabel sx={{ marginTop: "20px" }}>
                          Client name
                        </InputLabel>
                        <TextField sx={{ margin: "0px" }} autoFocus onChange={handleChange("clientName")}
                          margin="dense"
                          id="Client Name"
                          type="text"
                          fullWidth
                          color="secondary"
                        />

                        <InputLabel sx={{ marginTop: "20px" }}>
                          Identification number
                        </InputLabel>
                        <TextField sx={{ margin: "0px" }} autoFocus onChange={handleChange("identificationNo")}
                          margin="dense"
                          id="Identification Number"
                          type="text"
                          fullWidth
                          color="secondary"
                        />

                        <InputLabel sx={{ marginTop: "20px" }}>
                          Street name
                        </InputLabel>
                        <TextField sx={{ margin: "0px" }} autoFocus onChange={handleChange("streetName")}
                          margin="dense"
                          id="Street Name"
                          type="text"
                          fullWidth
                          color="secondary"
                        />

                        <InputLabel sx={{ marginTop: "20px" }}>
                          Starting landmark
                        </InputLabel>
                        <TextField sx={{ margin: "0px" }} autoFocus onChange={handleChange("startingAddress")}
                          margin="dense"
                          id="Starting Address"
                          type="text"
                          fullWidth
                          color="secondary"
                        />

                        <InputLabel sx={{ marginTop: "20px" }}>
                          Ending landmark
                        </InputLabel>
                        <TextField sx={{ margin: "0px" }} autoFocus onChange={handleChange("endingAddress")}
                          margin="dense"
                          id="Ending Address"
                          type="text"
                          fullWidth
                          color="secondary"
                        />

                        {projectType !== 2 && (

                          <>
                            <InputLabel sx={{ marginTop: "20px" }}>
                              Direction of traffic
                            </InputLabel>
                            <TextField sx={{ margin: "0px" }} autoFocus onChange={handleChange("direction")}
                              margin="dense"
                              id="Direction"
                              type="text"
                              fullWidth
                              color="secondary"
                            />
                            {(projectType !== 0 && projectType !== 3) && (
                              <>
                                <InputLabel sx={{ marginTop: "20px" }}>
                                  Number of lanes
                                </InputLabel>
                                <TextField sx={{ margin: "0px" }} autoFocus onChange={handleChange("totalLanes")}
                                  margin="dense"
                                  id="Total Lanes"
                                  type="text"
                                  fullWidth
                                  color="secondary"
                                />

                                <InputLabel sx={{ marginTop: "20px" }}>
                                  Current lane number
                                </InputLabel>
                                <TextField sx={{ margin: "0px" }} autoFocus onChange={handleChange("laneNumber")}
                                  margin="dense"
                                  id="Lane Number"
                                  type="text"
                                  fullWidth
                                  color="secondary"
                                />
                              </>
                            )}
                          </>
                        )}

                      </Collapse>

                    </>
                  ) : (
                    <></>
                  )}
                </ThemeProvider>
              </div >
            )
          }
          width="800"
          showPositive
          positiveButtonWidget={
            props.project.isProjectCreating === true ? (
              <>
                <Button
                  variant="contained"
                  disabled
                  sx={{
                    "&.Mui-disabled": {
                      backgroundColor: "#025196",
                      color: "#ffffff",
                    },
                    marginInline: "2px",
                    textTransform: "initial",
                    color: "#025196",
                    backgroundColor: "#025196",
                    ":hover": {
                      backgroundColor: "#025196",
                    },
                  }}
                >
                  <CircularProgress
                    variant="indeterminate"
                    size={15}
                    sx={{ color: "white" }}
                  />
                  <span style={{ paddingLeft: "15px" }}>
                    Creating project...
                  </span>
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                disabled={projectType === undefined || !disabledProject ? true : false}
                sx={{
                  marginInline: "2px",
                  textTransform: "initial",
                  backgroundColor: "#025196",
                  ":hover": {
                    backgroundColor: "#025196",
                  },
                }}
                onClick={handleSubmit}
              >
                Create project
              </Button>
            )
          }
        />

      </div >
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    project: state.project,
    userData: state.firebase.profile,
    companyData: state.firestore.data.Company,
    charges: state.firestore.data.Charges,
    companyPricingData: state.firestore.data.CompanyPricingData,
    chargesV3: state.firestore.data.ChargesV3,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createProject: (userData, companyData, projectData) =>
      dispatch(createProject(userData, companyData, projectData)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProject);