import React from 'react';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';

const StyledOutlineButton = ({ paddingInline = 0, padding = "5", disabled = false, text = "", onClick, color = "#000000", size = "small", tooltipText = "", margin = 0 }) => {
    const buttonStyle = {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: 'ellipsis',
        border: `1px solid ${color}`,
        outline: 'none',
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#000000',
        },
        '&:focus': {
            border: `1px solid ${color}`,
        },
        textTransform: 'none',
        color: color,
        marginLeft: `${margin}px`,
        padding: `${padding}px`,
        // paddingInline: `${paddingInline}px`
    };

    return (
        <Tooltip title={tooltipText.length === 0 ? `Click to ${text.toLowerCase()}` : tooltipText} arrow placement='bottom'>
            <Button
                disabled={disabled}
                variant="outlined"
                color="inherit"
                style={buttonStyle}
                size={size}
                onClick={onClick}
            >
                {text}
            </Button>
        </Tooltip>
    );
};

export default StyledOutlineButton;
