import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import AppBarSearch from "./AppBarSearch";
import { Link } from "react-router-dom";
import ProfileInfo from "./ProfileInfo";
import { useLocation } from "react-router-dom";
import AddProjectAppbar from "../../../../../home/components/project/AddProjectAppbar";
import newlogo from "../../../../../../assets/bg/roadGaugeLogo2.png";
import GeneralNotification from "./GeneralNotification";
import { Button, Chip, CircularProgress, TextField, Tooltip, AppBar, Box, Toolbar, Typography, Switch, IconButton } from "@mui/material";
import releaseNotes from '../../../../../../constants/releaseNotes.json';
import * as ROUTES from '../../../../../../constants/routes'
import { useGenerateSignedUrl } from "../../../../../../chooks/useGenerateSignedUrl";
import { db } from "../../../../../../store/LocalDatabase";
import SimpleDialogBox from "../../../../../cidgets/SimpleDialogBox";
import { removeVerifyAccessCodeMessage, signOut, updateUserActiveStatus, verifyAccessCode } from "../../../../../../store/actions/authActions";
import CreditsWidget from "../../../../../cidgets/CreditsWidget";
import { useAccessControlContext } from "../../../../../../contexts/AccessControlProvider";
import { useNetworkContext } from "../../../../../../contexts/NetworkProvider";
import CreateNetwork from "../../../../../home/components/network/CreateNetwork";
import { storage } from '../../../../../../config/firebaseConfig';
const saveAccessCode = async (accessCode) => {
  try {
    await db.accessCodes.clear();
    await db.accessCodes.add({ accessCode });
  } catch (error) {
    console.error('Error saving access code:', error);
  }
};
const getAccessCode = async () => {
  try {
    const result = await db.accessCodes.toArray();
    return result.length > 0 ? result[0].accessCode : null;
  } catch (error) {
    console.error('Error getting access code:', error);
    return null;
  }
};

const PrimaryAppBar = (props) => {
  const { permissions } = useAccessControlContext();
  const { isNetworkView, handleChangeView } = useNetworkContext();
  const { canAccessWebApp } = permissions;
  const location = useLocation();
  const locationPath = location.pathname;
  const appBarRef = useRef(null);
  const { Child, isSignedIn, isEmailVerified, themeRed, proid, companyData, isLinkedAccount, userData, datastore, auth } = props;
  const showNetwork = userData.showNetwork ? userData.showNetwork : false;
  const { verifyAccessCodeLoader, verifyAccessCodeIsDoneAndSent, verifyAccessCodeMessage } = auth;
  const { accessManagementData } = datastore;
  const { roles } = accessManagementData;
  const imagePath = companyData ? companyData.imagePath !== undefined ? companyData.imagePath : " " : " ";
  const { status, data } = useGenerateSignedUrl(imagePath)
  const isFetched = status === 'fetched';
  const { themeColor } = themeRed;
  const curentLinkArray = window.location.href.split('/');
  const [imageUrl, setImageUrl] = useState("");

  async function getImageFromStorage(filePath) {
    if (filePath === "") return "";
    const storageRef = storage.ref(filePath);
    const downloadURL = await storageRef.getDownloadURL();
    return downloadURL;
  }
  useEffect(() => {
    const fetchImage = async () => {
      if (imagePath) {
        const url = await getImageFromStorage(imagePath);
        setImageUrl(url);
      }
    };

    fetchImage();
  }, [imagePath, companyData]);
  const hideCondition = locationPath === `/home/${ROUTES.BASIC_IMAGING}` ||
    locationPath === `/home/pr/${curentLinkArray[5]}/${curentLinkArray[6]}` ||
    locationPath === `/home/net/${curentLinkArray[5]}/${curentLinkArray[6]}` ||
    locationPath === `/home/pr/${curentLinkArray[5]}/${curentLinkArray[6]}/${curentLinkArray[7]}` ||
    locationPath === `/home/pr/${proid}` ||
    locationPath === `/a/verify` ||
    locationPath === `/home/${ROUTES.ROUTE_PLANNER}` ||
    locationPath === `/home/${ROUTES.CONTROL_ROOM}` ||
    locationPath === `/home/net/${proid}` ||
    locationPath === `/home/${ROUTES.SUPER_SECTION}`;

  const displayValue = hideCondition ? "none" : "flex";
  const [role, setRole] = useState(null);
  const [verifyCode, setVerifyCode] = useState("");
  const [verifyCodeError, setVerifyCodeError] = useState(false);
  const [openVerify, setOpenVerfiy] = useState(false);

  async function saveAccessCodeToCache(accessCode) {
    saveAccessCode(accessCode);
  }
  async function getAccessCodeFromCache() {
    const retrievedAccessCode = await getAccessCode();
    return retrievedAccessCode;
  }

  useEffect(() => {
    const fetchAccessCode = async () => {
      const id = await getAccessCode();
      if (id === null) {
        handleOpenVerfiyModal();
      }
    };

    fetchAccessCode();
  }, [])
  useEffect(() => {
    const fetchAccessCode = async () => {
      const id = await getAccessCode();
      if (id !== null) {
        if (userData.isLoaded) {
          props.verifyAccessCode(userData?.uid, id);
        }
      }
    };

    fetchAccessCode();
  }, [userData]);

  useEffect(() => {
    if (verifyAccessCodeIsDoneAndSent === false) {
      // //////console.log("open modal")
      handleOpenVerfiyModal();
    }
  }, [verifyAccessCodeIsDoneAndSent])
  useEffect(() => {
    const fetchAccessCode = async () => {
      const id = await getAccessCode();
      if (verifyAccessCodeIsDoneAndSent === true) {
        if (id !== null) {
          saveAccessCodeToCache(id);
          setRole(userData?.accessCodes[id]?.role)
          handleCloseVerfiyModal();
          props.updateUserActiveStatus(userData?.uid, id);
          props.removeVerifyAccessCodeMessage();
        }
        else {
          saveAccessCodeToCache(verifyCode);
          setRole(userData?.accessCodes[verifyCode]?.role)
          handleCloseVerfiyModal();
          props.updateUserActiveStatus(userData?.uid, verifyCode);
          props.removeVerifyAccessCodeMessage();
        }
      }
    };
    fetchAccessCode();
  }, [verifyAccessCodeIsDoneAndSent])

  const handleOpenVerfiyModal = () => {
    setOpenVerfiy(true)
  }
  const handleCloseVerfiyModal = () => {
    setOpenVerfiy(false)
  }
  const handleVerifyCodeChange = (e) => {
    setVerifyCodeError(false);
    setVerifyCode(e.target.value);
  }
  const handleInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  };
  const handleVerifyCode = () => {
    if (verifyCode.length <= 0 || verifyCode.length < 9 || verifyCode.length > 9) {
      setVerifyCodeError(true);
    }
    else {
      props.verifyAccessCode(userData?.uid, verifyCode);
    }
  }
  const handleLogout = () => {
    const id = getAccessCodeFromCache();
    props.signOut(true, userData?.uid, id)
  }
  return (
    <>
      {isLinkedAccount ? <SimpleDialogBox
        open={openVerify}
        onClose={null}
        title="Verification code"
        shouldTitleAppear={true}
        showCancel={false}
        showPositive
        showError={verifyAccessCodeIsDoneAndSent === false ? true : false}
        errorMessage={verifyAccessCodeIsDoneAndSent === false ? verifyAccessCodeMessage : ''}
        bodyWidget={
          <TextField
            InputProps={{
              inputMode: 'numeric',
              onInput: handleInput,
            }}
            fullWidth label="Code" type='tel' id="verifyCode" error={verifyCodeError} helperText={verifyCodeError ? "Invalid code" : ''} value={verifyCode}
            disabled={verifyAccessCodeLoader}
            onChange={handleVerifyCodeChange} />
        }
        positiveButtonWidget={
          <>
            <Button size="medium" variant="contained"
              onClick={handleLogout}
              sx={{
                marginRight: '10px',
                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#025196',
                  color: '#FFFFFF'
                }
              }}>Logout</Button>
            {verifyAccessCodeLoader ?
              <>
                <Button variant="contained" disabled sx={{
                  textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                  "&.Mui-disabled": {
                    backgroundColor: '#025196',
                    color: '#FFFFFF'
                  }
                }}>
                  <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Verifying...</span>
                </Button>
              </>
              :
              <Button size="medium" variant="contained"
                onClick={handleVerifyCode}
                sx={{
                  textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: '#025196',
                    color: '#FFFFFF'
                  }
                }}>Verify</Button>
            }
          </>}
        width='600'
      /> : <></>}
      <AppBar ref={appBarRef}
        elevation={1}
        position="fixed"
        sx={{
          backgroundColor: "white",
          color: themeColor,
          display: displayValue,
        }}
        component="nav"
      >
        <Toolbar>
          <Link
            to="/home/dashboard"
            style={{ textDecoration: "none", color: "white" }}
          >
            <img
              style={{ marginRight: '16px' }}
              src={newlogo}
              alt="logo"
              width="40px"
              height="auto"
            />
          </Link>
          {/* </IconButton> */}
          <Link
            to="/home/dashboard"
            style={{ textDecoration: "none", color: "white" }}
          >
            <Typography
              variant="p"
              noWrap
              component="div"
              sx={{
                display: { xs: "none", sm: "none", md: 'none', lg: 'block' },
                // color: '#1e1e1e',

                color: '#025196',
                fontSize: '18px', fontWeight: 'bold'
              }}
            >
              {`${releaseNotes.name}${releaseNotes.dev ? " (dev)" : ""}`}
            </Typography>
          </Link>
          {!canAccessWebApp ? <></> : <AppBarSearch />}


          {/* {!canAccessWebApp ? <></> : companyData?.imagePath !== undefined && isFetched ? (

            <img
              style={{ marginRight: '10px' }}
              src={data}
              alt="logo"
              width="40px"
              height="auto"
            />
          ) :
            <></>
          } */}
          {!canAccessWebApp ? <></> : imageUrl ? (

            <img
              style={{ marginRight: '10px' }}
              src={imageUrl}
              alt="logo"
              width="40px"
              height="auto"
            />
          ) :
            <></>
          }
          {isNetworkView ? <Tooltip arrow placement="bottom" title="Show projects">
            <Switch
              checked={isNetworkView}
              onChange={handleChangeView}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{
                '& .MuiSwitch-thumb': {
                  backgroundColor: '#025196',
                },
                '& .Mui-checked': {
                  color: '#025196'
                },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                  backgroundColor: '#025196',
                }
              }}
            />
          </Tooltip> :
            showNetwork ?
              <Tooltip arrow placement="bottom" title="Show networks">
                <Switch
                  checked={isNetworkView}
                  onChange={handleChangeView}
                  inputProps={{ 'aria-label': 'controlled' }}
                // sx={{
                //   '& .MuiSwitch-thumb': {
                //     backgroundColor: '#025196',
                //   },
                //   '& .Mui-checked': {
                //     color: '#025196'
                //   },
                //   '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                //     backgroundColor: '#025196',
                //   }
                // }}
                />
              </Tooltip> : <></>}
          {isLinkedAccount ? role ? <Chip
            size="small"
            variant="outlined"
            label={`${roles[role]?.name} access`.toLocaleUpperCase() ?? ""}
            sx={{ borderRadius: '15px', margin: '5px', height: Number((14 * 2) / 10).toFixed(0) * 10, padding: '10px', backgroundColor: '#025196', color: '#ffffff', fontWeight: 'normal', fontSize: '14px', border: '0px' }}
          /> : <></>
            : <></>}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'flex' } }}>
            <CreditsWidget companyData={companyData} />
          </Box>
          {!isNetworkView ? <AddProjectAppbar sx={{ display: { sm: "flex" } }} />
            :
            <CreateNetwork />}
          <GeneralNotification />
          <ProfileInfo />
        </Toolbar>
      </AppBar >

      <Box component="main" >
        <Child canAccessWebApp={canAccessWebApp} isLinkedAccount={isLinkedAccount} role={role} companyData={companyData} isSignedIn={isSignedIn} isEmailVerified={isEmailVerified} appBarHeight={appBarRef?.current?.clientHeight} />
      </Box>
    </>

  );
}
const mapStateToProps = (state) => {
  return {
    companyData: state.firestore.data.Company,
    userData: state.firebase.profile,
    datastore: state.datastore,
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyAccessCode: (inkedAccountUid, accessCode) => dispatch(verifyAccessCode(inkedAccountUid, accessCode)),
    removeVerifyAccessCodeMessage: () => dispatch(removeVerifyAccessCodeMessage()),
    updateUserActiveStatus: (uid, accessCode) => dispatch(updateUserActiveStatus(uid, accessCode)),
    signOut: (uidRequired, uid, accessCode) => dispatch(signOut(uidRequired, uid, accessCode)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryAppBar);