import { Box, Button, Card, Divider, Grid, Typography, Paper, Tooltip, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { connect } from "react-redux";
import { styled } from '@mui/material/styles';
import PinOnMap from '../components/PinOnMap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as ROUTES from "../../../../../constants/routes";
import AssetVideoPlayer from '../components/AssetVideoPlayer';
import CustomProgress from '../../../../cidgets/CustomProgress';
import ImageAnnotatorV3 from "./ImageAnnotatorV3";
import SmartUtils from '../../../../../helpers/SmartUtils';

var currentTime = 0

const IndividualDistressViewer = (props) => {
  const backToProjectNavigate = useNavigate();
  const { secId } = useParams();
  const { individualDetectionData, proid, datastore, link } = props;
  // //console.log(individualDetectionData, "individualDetectionData")
  const { fps, originalVideoHeight, originalVideoWidth, projectType } = individualDetectionData;
  // const {bbox} = individualDetectionData;
  // //console.log(bbox,'box321')
  const numHeightOfHeader = 8;
  const numHeightOfFooter = 0;
  const customPadding = 15;
  const fieldAreaMargin = 20;


  const playerRef = useRef(null);
  const vidDivRef = useRef(null);
  const [vidDivDimen, setVidDivDimen] = useState({ height: 1, width: 1 });
  const [imageDimensions, setImageDimensions] = useState({ height: 1, width: 1 });
  const [videoPlayerState, setVideoPlayerState] = useState(null);

  const showAreaFieldList = ['0', '1', '2', '5', '10', '12', '13'];
  const showDepthFieldList = ['0', '4'];
  const showTransverseSpanFieldList = ['1', '2', '3', '5', '10'];
  const showLongitudinalSpanFieldList = ['1', '2', '3', '5', '10'];
  const showTypeFieldList = ['1'];
  const showSeverityFieldList = ['0', '1', '3', '5', '10', '12', '13'];
  const showProfileFieldList = ['2'];
  const showWidthFieldList = ['4'];
  const showWheelPathFieldList = ['4'];
  const showNegativeStatusFieldList = ['-1'];
  const isRectangle = individualDetectionData.isRectangle;
  useEffect(() => {
    if (playerRef) {
      playerRef.current?.subscribeToStateChange(handleBind)
    }
  });

  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  // const newBbox = bbox.map(coords => {
  //   return {
  //     x: coords[0],
  //     y: coords[1],
  //     width: coords[2],
  //     height: coords[3],
  //     fill: 'transparent'
  //   };
  // });

  //    {`${'versioning' in individualDetectionData ? 'geo' in individualDetectionData['versioning'] ? `${individualDetectionData['versioning']['geo'][0]},${individualDetectionData['versioning']['geo'][1]}` : `${individualDetectionData?.geo[0]},${individualDetectionData?.geo[1]}` : `${individualDetectionData?.geo[0]},${individualDetectionData?.geo[1]}`}`}



  // //console.log(newBbox, 'bbox123')
  const handleBoxChange = (newValue) => {
    // //console.log(newValue, "bbox coordinates2");
  };

  const handleBind = (state) => {
    // //console.log("hello")
    if ((((individualDetectionData.frameNumber / fps) + 1 / fps) - state?.currentTime) <= 1 / fps) {
      setVideoPlayerState(state);
    }
    const isVideoActive = state?.seeking === false && state?.waiting === false
    if (isVideoActive) {
      const hasTimeChanged = ((state?.currentTime ?? 0) !== (currentTime))
      if (hasTimeChanged) {
        setVideoPlayerState(state);
      }
    } else {
      // //console.log("GHAREEBON!!")
    }
  }
  const isVideoActive = videoPlayerState?.seeking === false && videoPlayerState?.waiting === false && ((((individualDetectionData.frameNumber / fps) + 1 / fps) - videoPlayerState?.currentTime) <= 1 / fps)
  const getVidWindowSize = () => {
    const newHeight = vidDivRef?.current?.clientHeight;
    const newWidth = vidDivRef?.current?.clientWidth;
    setVidDivDimen({
      height: newHeight,
      width: newWidth
    });
  };
  useEffect(() => {
    window.addEventListener("load", setVidDivDimen(vidDivRef.current.clientHeight))
    window.addEventListener("resize", getVidWindowSize, true);
  }, []);
  useEffect(() => {
    setImageDimensions({
      height: originalVideoHeight,
      width: originalVideoWidth
    })
  }, [individualDetectionData]);
  useEffect(() => {
    setImageDimensions({
      height: originalVideoHeight,
      width: originalVideoWidth
    })
  }, [vidDivDimen])


  const handleSeekTo = (timeSeek) => {
    if (playerRef) {
      playerRef.current?.seek(timeSeek + (1 / fps))
    }
  }
  const handleNextFrame = () => {
    const secondPerFrame = 1 / fps
    playerRef.current.forward(secondPerFrame)
  }

  const handlePrevFrame = () => {
    const secondPerFrame = 1 / fps
    playerRef.current.replay(secondPerFrame)
  }
  const cardStyle = {
    "border": "2px solid grey",
    "borderRadius": "8px",
    "backgroundColor": '#f5f1f1'
  }
  const Item = styled(Paper)(({ theme }) => ({
    boxShadow: '0px 0px transparent',
    width: '100%',
    backgroundColor: 'transparent',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
  }));
  const fieldStyle = {
    "border": "2px solid grey",
    "borderRadius": "5px",
    "padding": '5px',
    "fontSize": '0.8rem',
    "textTransform": 'capitalize',
    "overflow": 'auto',

  }
  const { distressData } = datastore;
  // //console.log(distressData, 'distressData')
  if (distressData === undefined || distressData === null) {
    return <></>
  }
  const { defectNames, dataInfo } = distressData;
  const { defectName, profile, severity, transversePosition, type, wheelPath } = dataInfo;
  const newBbox = individualDetectionData.bbox;

  if (dataInfo === undefined || dataInfo === null) {
    return <></>
  }

  /*
  const rectangleCondCheck = isRectangle === undefined || isRectangle === true ? true : false;
  const newBbox = rectangleCondCheck ? [
    'versioning' in individualDetectionData ?
      ('bbox' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['bbox'][0] : individualDetectionData?.bbox[0]) :
      individualDetectionData?.bbox[0],
    'versioning' in individualDetectionData ?
      ('bbox' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['bbox'][1] : individualDetectionData?.bbox[1]) :
      individualDetectionData?.bbox[1],
    'versioning' in individualDetectionData ?
      ('bbox' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['bbox'][2] : individualDetectionData?.bbox[2]) :
      individualDetectionData?.bbox[2],
    'versioning' in individualDetectionData ?
      ('bbox' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['bbox'][3] : individualDetectionData?.bbox[3]) :
      individualDetectionData?.bbox[3]
  ] : [];
*/

  function getCorner(pivotX, pivotY, diffX, diffY) {
    const distance = Math.sqrt(diffX * diffX + diffY * diffY);

    const angle = Math.atan2(diffY, diffX);

    const x = pivotX + distance * Math.cos(angle);
    const y = pivotY + distance * Math.sin(angle);

    return [x, y]
  }

  let fill;
  // if (individualDetectionData.defectName === 0) {
  //   fill = "#caee00";
  // } else if (individualDetectionData.defectName === 1) {
  //   fill = '#60EE00';
  // } else if (individualDetectionData.defectName === 3) {
  //   fill = '#00ee83';
  // } else if (individualDetectionData.defectName === 4) {
  //   fill = '#00eeda';
  // } else if (individualDetectionData.defectName === 10) {
  //   fill = '#ee1000';
  // }

  fill = SmartUtils.detectionColor(individualDetectionData.defectName);

  let bbox;

  if (isRectangle === false) {
    const pairs = [];
    for (let i = 0; i < newBbox.length; i += 2) {
      pairs.push([newBbox[i], newBbox[i + 1]]);
    }
    bbox = [{ defectName: individualDetectionData.defectName, fill, pairs }];
  }
  else {
    const x = newBbox[0];
    const y = newBbox[1];
    const width = newBbox[2];
    const height = newBbox[3];

    const topLeft = getCorner(x, y, 0, 0);
    const topRight = getCorner(x, y, width, 0);
    const bottomRight = getCorner(x, y, width, height);
    const bottomLeft = getCorner(x, y, 0, height);

    const pairs = [topLeft, topRight, bottomRight, bottomLeft];

    bbox = [{ defectName: individualDetectionData.defectName, fill, pairs }];
  }

  const handleBackToProject = () => {
    backToProjectNavigate(`/home/${ROUTES.HOME_PROJECT}/${proid}/${secId}`);
    window.location.reload();
  }
  return (
    <div style={{ height: '100vh', width: '100vw', backgroundColor: '#FAFAFA' }}>
      {/* header */}
      <div style={{ height: `${numHeightOfHeader}vh`, width: '100vw', backgroundColor: '#FAFAFA', boxShadow: '5px 10px #FAFAFA', padding: `${customPadding}px` }}>
        <Grid container >
          <Grid item xs={9} sm={9} md={9} lg={9} xl={9} sx={{
            display: 'flex', justifyContent: 'start',
          }} >
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Distress viewer
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} sx={{
            display: 'flex', justifyContent: 'end', alignItems: 'center'
          }}>
            {/* <Link to={`/home/${ROUTES.HOME_PROJECT}/${proid}/${secId}`}> */}
            <Button variant='contained' onClick={handleBackToProject} sx={{
              textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
              '&:hover': {
                backgroundColor: '#025196',
                color: '#ffffff'
              }
            }}
            >Go back to project</Button>
            {/* </Link> */}

          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
            <Box sx={{ width: "100%", marginTop: '8px' }}>
              <Divider ligth />
            </Box>
          </Grid>
        </Grid>
      </div>
      {/* Body */}
      <div style={{ height: `${(100) - (numHeightOfHeader + numHeightOfFooter)}vh`, width: '100vw', backgroundColor: 'transparent' }}>
        <Grid container spacing={0}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ backgroundColor: 'transparent', height: `${(100) - (numHeightOfHeader + numHeightOfFooter)}vh` }}>

            <Grid container spacing={0} style={{ padding: `${customPadding}px` }}>
              <Grid ref={vidDivRef} item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ backgroundColor: 'transparent', height: `${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh` }}>
                <div className="container" style={{ padding: '0px' }}>
                  <div className="inner" style={{ zIndex: 997 }}>
                    <Card sx={cardStyle}>
                      <AssetVideoPlayer fps={fps} handleNextFrame={handleNextFrame} handlePrevFrame={handlePrevFrame} playerRef={playerRef}
                        link={link} height={vidDivRef.current === null ? 0 : vidDivRef.current?.clientHeight} disableControlsFully={true} startTime={(individualDetectionData.frameNumber / fps)} shouldOffset={true} />
                    </Card>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginTop: `${fieldAreaMargin}px`, backgroundColor: 'transparent', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sx={{ backgroundColor: 'transparent', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }} >
                    <Card sx={cardStyle}>
                      <div style={{ padding: '5px', overflow: 'auto', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }}>

                        {!isVideoActive ? <div style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                          width: '100%'
                        }}><CustomProgress /></div> : <>
                          <>
                            <Item>
                              <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                  display: "flex",
                                  justifyContent: "start",
                                  alignItems: "center",
                                }} >
                                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    Defect category:
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                  <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                      {`${'versioning' in individualDetectionData ? 'defectName' in individualDetectionData['versioning'] ? defectName[individualDetectionData['versioning']['defectName']]['name'] : defectName[individualDetectionData['defectName']]['name'] : defectName[individualDetectionData['defectName']]['name']}`}
                                    </Typography>
                                  </Card>
                                </Grid>
                              </Grid>
                            </Item>
                            <Item>
                              <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                  }} >
                                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    GPS location:
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                                  <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                      {`${'versioning' in individualDetectionData ? 'geo' in individualDetectionData['versioning'] ? `${individualDetectionData['versioning']['geo'][0]},${individualDetectionData['versioning']['geo'][1]}` : `${individualDetectionData?.geo[0]},${individualDetectionData?.geo[1]}` : `${individualDetectionData?.geo[0]},${individualDetectionData?.geo[1]}`}`}
                                    </Typography>
                                  </Card>
                                </Grid>
                              </Grid>
                            </Item>
                            <Item>
                              <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                  display: "flex",
                                  justifyContent: "start",
                                  alignItems: "center",
                                }} >
                                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    Transverse position:
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                  <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                      {`${'versioning' in individualDetectionData ? 'transversePosition' in individualDetectionData['versioning'] ? transversePosition[individualDetectionData['versioning']['transversePosition']]['name'] : transversePosition[individualDetectionData['transversePosition']]['name'] : transversePosition[individualDetectionData['transversePosition']]['name']}`}
                                    </Typography>
                                  </Card>
                                </Grid>
                              </Grid>
                            </Item>

                            {showAreaFieldList.includes(`${'versioning' in individualDetectionData ? 'defectName' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['defectName'] : individualDetectionData.defectName : individualDetectionData.defectName}`) && !showNegativeStatusFieldList.includes(`${'versioning' in individualDetectionData ? 'area' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['area'] : individualDetectionData['area'] : individualDetectionData['area']}`) ? <Item>
                              <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                  display: "flex",
                                  justifyContent: "start",
                                  alignItems: "center",
                                }} >
                                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    Area (m<sup>2</sup>):
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                  <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                      {`${'versioning' in individualDetectionData ? 'area' in individualDetectionData['versioning'] ? Number(individualDetectionData['versioning']['area']).toFixed(2) : Number(individualDetectionData['area']).toFixed(2) : Number(individualDetectionData['area']).toFixed(2)}`}
                                    </Typography>
                                  </Card>
                                </Grid>
                              </Grid>
                            </Item> : <></>}

                            {showTypeFieldList.includes(`${'versioning' in individualDetectionData ? 'defectName' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['defectName'] : individualDetectionData.defectName : individualDetectionData.defectName}`) && !showNegativeStatusFieldList.includes(`${'versioning' in individualDetectionData ? 'type' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['type'] : individualDetectionData['type'] : individualDetectionData['type']}`) ? <Item>
                              <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                  display: "flex",
                                  justifyContent: "start",
                                  alignItems: "center",
                                }} >
                                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    Type:
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                  <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                      {`${'versioning' in individualDetectionData ? 'type' in individualDetectionData['versioning'] ? type[individualDetectionData['versioning']['type']]['name'] : type[individualDetectionData['type']]['name'] : type[individualDetectionData['type']]['name']}`}
                                    </Typography>
                                  </Card>
                                </Grid>
                              </Grid>
                            </Item> : <></>}
                            {showSeverityFieldList.includes(`${'versioning' in individualDetectionData ? 'defectName' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['defectName'] : individualDetectionData.defectName : individualDetectionData.defectName}`) 
                            // &&!showNegativeStatusFieldList.includes(`${'versioning' in individualDetectionData ? 'severity' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['severity'] : individualDetectionData['severity'] : individualDetectionData['severity']}`)
                              ? <Item>
                              <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                  display: "flex",
                                  justifyContent: "start",
                                  alignItems: "center",
                                }} >
                                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    Severity:
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                  <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                      {`${'versioning' in individualDetectionData ? 'severity' in individualDetectionData['versioning'] ? severity[individualDetectionData['versioning']['severity']]['name'] : severity[individualDetectionData['severity']]['name'] : severity[individualDetectionData['severity']]['name']}`}
                                    </Typography>
                                  </Card>
                                </Grid>
                              </Grid>
                            </Item> : <></>}

                            {showProfileFieldList.includes(`${'versioning' in individualDetectionData ? 'defectName' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['defectName'] : individualDetectionData.defectName : individualDetectionData.defectName}`) && !showNegativeStatusFieldList.includes(`${'versioning' in individualDetectionData ? 'profile' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['profile'] : individualDetectionData['profile'] : individualDetectionData['profile']}`) ? <Item>
                              <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                  display: "flex",
                                  justifyContent: "start",
                                  alignItems: "center",
                                }} >
                                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    Profile:
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                  <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                      {`${'versioning' in individualDetectionData ? 'profile' in individualDetectionData['versioning'] ? profile[individualDetectionData['versioning']['profile']]['name'] : profile[individualDetectionData['profile']]['name'] : profile[individualDetectionData['profile']]['name']}`}
                                    </Typography>
                                  </Card>
                                </Grid>
                              </Grid>
                            </Item> : <></>}
                            {showWidthFieldList.includes(`${'versioning' in individualDetectionData ? 'defectName' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['defectName'] : individualDetectionData.defectName : individualDetectionData.defectName}`) && !showNegativeStatusFieldList.includes(`${'versioning' in individualDetectionData ? 'width' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['width'] : individualDetectionData['width'] : individualDetectionData['width']}`) ? <Item>
                              <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                  display: "flex",
                                  justifyContent: "start",
                                  alignItems: "center",
                                }} >
                                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    Width:
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                  <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                      {`${'versioning' in individualDetectionData ? 'width' in individualDetectionData['versioning'] ? Number(individualDetectionData['versioning']['width']).toFixed(2) : Number(individualDetectionData['width']).toFixed(2) : Number(individualDetectionData['width']).toFixed(2)}`}
                                    </Typography>
                                  </Card>
                                </Grid>
                              </Grid>
                            </Item> : <></>}
                          </>
                          {showTransverseSpanFieldList.includes(`${'versioning' in individualDetectionData ? 'defectName' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['defectName'] : individualDetectionData.defectName : individualDetectionData.defectName}`) && !showNegativeStatusFieldList.includes(`${'versioning' in individualDetectionData ? 'transverseSpan' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['transverseSpan'] : individualDetectionData['transverseSpan'] : individualDetectionData['transverseSpan']}`) ? <Item>
                            <Grid container>
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                              }} >
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                  Transverse span:
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Card sx={fieldStyle}>
                                  <Typography variant="inherit">
                                    {`${'versioning' in individualDetectionData ? 'transverseSpan' in individualDetectionData['versioning'] ? Number(individualDetectionData['versioning']['transverseSpan']).toFixed(2) : Number(individualDetectionData['transverseSpan']).toFixed(2) : Number(individualDetectionData['transverseSpan']).toFixed(2)}`}
                                  </Typography>
                                </Card>
                              </Grid>
                            </Grid>
                          </Item> : <></>}
                          {showLongitudinalSpanFieldList.includes(`${'versioning' in individualDetectionData ? 'defectName' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['defectName'] : individualDetectionData.defectName : individualDetectionData.defectName}`) && !showNegativeStatusFieldList.includes(`${'versioning' in individualDetectionData ? 'longitudinalSpan' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['longitudinalSpan'] : individualDetectionData['longitudinalSpan'] : individualDetectionData['longitudinalSpan']}`) ? <Item>
                            <Grid container>
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                              }} >
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                  Longitudinal span:
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Card sx={fieldStyle}>
                                  <Typography variant="inherit">
                                    {`${'versioning' in individualDetectionData ? 'longitudinalSpan' in individualDetectionData['versioning'] ? Number(individualDetectionData['versioning']['longitudinalSpan']).toFixed(2) : Number(individualDetectionData['longitudinalSpan']).toFixed(2) : Number(individualDetectionData['longitudinalSpan']).toFixed(2)}`}
                                  </Typography>
                                </Card>
                              </Grid>
                            </Grid>
                          </Item> : <></>}
                          {showDepthFieldList.includes(`${'versioning' in individualDetectionData ? 'defectName' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['defectName'] : individualDetectionData.defectName : individualDetectionData.defectName}`) && !showNegativeStatusFieldList.includes(`${'versioning' in individualDetectionData ? 'depth' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['depth'] : individualDetectionData['depth'] : individualDetectionData['depth']}`) ? <Item>
                            <Grid container>
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                              }} >
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                  Depth (mm):
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Card sx={fieldStyle}>
                                  <Typography variant="inherit">
                                    {`${'versioning' in individualDetectionData ? 'depth' in individualDetectionData['versioning'] ? Number(individualDetectionData['versioning']['depth'] * 1000).toFixed(2) : Number(individualDetectionData['depth'] * 1000).toFixed(2) : Number(individualDetectionData['depth'] * 1000).toFixed(2)}`}
                                  </Typography>
                                </Card>
                              </Grid>
                            </Grid>
                          </Item> : <></>}
                          {showWheelPathFieldList.includes(`${'versioning' in individualDetectionData ? 'defectName' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['defectName'] : individualDetectionData.defectName : individualDetectionData.defectName}`) && !showNegativeStatusFieldList.includes(`${'versioning' in individualDetectionData ? 'wheelPath' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['wheelPath'] : individualDetectionData['wheelPath'] : individualDetectionData['wheelPath']}`) ? <Item>
                            <Grid container>
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                              }} >
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                  Wheel path:
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Card sx={fieldStyle}>
                                  <Typography variant="inherit">
                                    {`${'versioning' in individualDetectionData ? 'wheelPath' in individualDetectionData['versioning'] ? wheelPath[individualDetectionData['versioning']['wheelPath']]['name'] : wheelPath[individualDetectionData['wheelPath']]['name'] : wheelPath[individualDetectionData['wheelPath']]['name']}`}
                                  </Typography>
                                </Card>
                              </Grid>
                            </Grid>
                          </Item> : <></>}
                        </>}

                      </div>
                    </Card>
                  </Grid>
                  {/* map */}
                  <Grid item xs={6} sx={{ backgroundColor: 'transparent', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }} >
                    <Card sx={cardStyle}>
                      <div style={{ overflow: 'auto', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }}>
                        <PinOnMap isViewingAsset={true} gps={'versioning' in individualDetectionData ? 'geo' in individualDetectionData['versioning'] ? individualDetectionData['versioning']['geo'] : individualDetectionData.geo : individualDetectionData.geo} />
                      </div>
                    </Card>

                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ backgroundColor: '', height: `${(100) - (numHeightOfHeader + numHeightOfFooter)}vh` }}>
            {/* <Card sx={cardStyle}> */}
            <Grid container spacing={0} style={{ padding: `${customPadding}px` }}>
              <Grid item xs={projectType === 4 ? 12 : 6} sx={{ backgroundColor: '', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter))}vh - ${customPadding * 2}px - ${0}px)` }}>
                {/* <ImageAnnotatorV3 defaultHeight={100 - (numHeightOfHeader + numHeightOfFooter)} defaultPadding={customPadding * 2} imageUrl={individualDetectionData.stitchPath} imageHeight={individualDetectionData.HRHeight} imageWidth={individualDetectionData.HRWidth} drawMap={true} editBox={false} onValueChange={(value) => handleBoxChange(value)} nonEditableBbox={bbox} /> */}
                <ImageAnnotatorV3 defaultHeight={100 - (numHeightOfHeader + numHeightOfFooter)} defaultPadding={customPadding * 2} imageUrl={individualDetectionData?.stitchPath} imageHeight={individualDetectionData?.HRHeight} imageWidth={individualDetectionData?.HRWidth} drawMap={true} editBox={false} onValueChange={(value) => handleBoxChange(value)} nonEditableBbox={bbox} />

              </Grid>
              <Grid item xs={projectType === 4 ? 0 : 6} sx={{ backgroundColor: '', height: `${((100) - (numHeightOfHeader + numHeightOfFooter))}vh - ${customPadding * 2}px - ${0}px)` }}>
                {projectType === 4 ? <></> : <ImageAnnotatorV3 defaultHeight={100 - (numHeightOfHeader + numHeightOfFooter)} defaultPadding={customPadding * 2} imageUrl={individualDetectionData.depthMapPath} imageHeight={individualDetectionData.HRHeight} imageWidth={individualDetectionData.HRWidth} drawMap={false} editBox={false} />}
              </Grid>
            </Grid>
            {/* </Card> */}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    datastore: state.datastore,
  };
};
export default connect(mapStateToProps)(IndividualDistressViewer);