import React, { useRef, useEffect, useState } from "react"
import '../subpages/afterInference/table.css'


const DistressTable = ({ isGroupData, drProjectData, dataList = [], distressData = [], handleDistressPinned, distressPinned, fps, defectNames, dataInfo, disableHeader = false, bboxPinned }) => {
    const playerRef = useRef(null);
    const tableRef = useRef(null)
    //////console.log(bboxPinned,'bboxPinned')
    useEffect(() => {
        const element = document.getElementById(distressPinned?.defectId);
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, [distressPinned])


    const [videoPlayerState, setVideoPlayerState] = useState(null)

    useEffect(() => {
        if (playerRef) {
            playerRef.current?.subscribeToStateChange(handleBind)
        }
    });

    const handleBind = (state) => {
        setVideoPlayerState(state);
    }

    useEffect(() => {
        return () => {
            if (playerRef.current) {
                playerRef.current = null;
            }
        };
    }, [playerRef]);


    const handleSeekTo = (timeSeek) => {
        playerRef.current.seek(timeSeek)
    }
    const handlePinning = (e) => {
        handleDistressPinned(e)
        handleSeekTo(isGroupData ? (e.groupFrameNumber / fps) + (1 / fps) : (e.frameNumber / fps) + (1 / fps))
    }
    const calculateAccuracyRating = (score) => {
        if (score < 35) {
            return "Bad"
        }

        if (score >= 35 && score < 75) {
            return "Medium"
        }

        if (score >= 75 && score <= 100) {
            return "Good"
        }
    }
    const style = {
        "paddingLeft": "15px",
        "paddingRight": "15px",
        "width": "100%",
        "height": "100%",
        "overflow": "auto",
    }
    if (distressData?.length === 0) {
        return <>Loading...</>
    }
    // const roadSideMap = {
    //     "map": dataInfo['roadSides'],
    //     "keys": Object.keys(dataInfo['roadSides']),
    //     "values": Object.values(dataInfo['roadSides']),
    //     "usefulKeys": ['0', '1'],
    // }

    // const classesMap = {
    //     "map": dataInfo['classes'],
    //     "keys": Object.keys(dataInfo['classes']),
    //     "values": Object.values(dataInfo['classes']),
    //     "usefulKeys": ['0', '1', '2', '3', '4', '998'],
    //     'assetClassesWhereNameWillNotShow': ['0', '2', '3', '4', '998', '999'],
    //     "notTrafficSign": ['0', '2', '3', '4', '998', '999'],
    //     "trafficSign": ['1'],
    // }

    // const getAssetMeta = (assetId, debug = false) => {
    //     const assetMeta = assetData.filter((e) => `${e.id}`.split('_')[0] === `${assetId}`.split('_')[0])[0]
    //     if (debug) {
    //         //////console.log(assetMeta, "getAssetMeta")
    //     }
    //     return assetMeta
    // }

    // const getAssetMetaDebug = (assetId) => {
    //     const assetMeta = assetData.filter((e) => `${e.id}`.split('_')[0] === `${assetId}`.split('_')[0])[0]
    //     // //////console.log(assetMeta, "getAssetMeta")
    //     return assetMeta
    // }

    // const isAssetTrafficSign = (assetClass) => {
    //     return !classesMap.assetClassesWhereNameWillNotShow.includes(assetClass);
    // }
    // //////console.log(dataInfo, "dataInfo")
    if (dataInfo === undefined || dataInfo === null) {
        return <></>
    }
    const { profile, severity, transversePosition, type, wheelPath } = dataInfo;
    return (
        <>
            <div style={style}>
                {dataList.length > 0 ?
                    <table className="assetTable">
                        {disableHeader ? <></> : <thead style={{}}>
                            <tr>
                                <th className="" style={{ width: '5%' }}>Number</th>
                                {/* <th className="" style={{ width: '10%' }}>Distress</th> */}
                                <th className="" style={{ width: '20%' }}>Category</th>
                                <th className="" style={{ width: '10%' }}>Transverse position</th>
                                <th className="" style={{ width: '10%' }}>Severity</th>
                                <th className="" style={{ width: '5%' }}>Area (m<sup>2</sup>)
                                    {/* /Span (mxm) */}
                                </th>
                                {drProjectData.projectType !== 4 && (
                                    <th className="" style={{ width: '10%' }}>Depth (mm)</th>
                                )}
                                <th className="" style={{ width: '10%' }}>Type</th>
                                {/* <th className="" style={{ width: '10%' }}>Side of road</th> */}
                                {/* <th className="" style={{ width: '10%' }}>Time</th> */}
                                {/* <th className="" style={{ width: '10%' }} >Share</th> */}
                            </tr>
                        </thead>
                        }
                        <tbody ref={tableRef}>
                            {dataList.map((e, i) => {
                                //////console.log(e, 'karl')
                                const { id, assetId, frameTime, frameNumber, detectionScore, recognitionScore, sectionId } = e;
                                // //////console.log(parseFloat(recognitionScore),"recognitionScore")
                                // //////console.log(assetId, assetData.filter((e) => `${e.id}`.split('_')[0] === `${assetId}`.split('_')[0]))
                                // const assetMeta = getAssetMeta(assetId)
                                // const obj = {
                                //     pred: e,
                                //     meta: assetMeta

                                // }
                                // const outputId = `${sectionId}-${id}`;
                                // // //////console.log('obj==========>',obj.meta)
                                // const isTraficSignClass = isAssetTrafficSign(assetMeta?.class)
                                // const score = calculateAccuracyRating(isTraficSignClass ? recognitionScore === '10000.00' ? '100' : recognitionScore : detectionScore);

                                const check = (e.defectId === distressPinned?.defectId ?? '')
                                const bboxCheck = (e.bbox === bboxPinned ?? '')
                                //////console.log(bboxCheck,'check')

                                // const imBase64 = `data:image/png;base64, ${obj?.pred?.cropb64 ?? ''}`;
                                // const metaBase64 = `data:image/png;base64, ${obj?.meta?.base64 ?? ''}`;
                                return <tr
                                    key={e.defectId}
                                    id={e.defectId}
                                    style={{ transition: 'all ease 0.0s', ...check ? { backgroundColor: '#B0FFA4', border: '2px solid #60EE00', cursor: 'pointer' } : { cursor: 'pointer' } }}
                                    role="button"
                                    onClick={() => { handlePinning(e) }}
                                >
                                    <td style={{ width: '5%' }}>{i + 1}</td>
                                    <td style={{ width: '20%' }} className="capitalize-text">{e.defectName?.length === 0 ? <span style={{ color: 'gray' }}><i>{'unnamed'}</i></span> : defectNames[e.defectName]}</td>
                                    <td style={{ width: '10%' }} className="">{Number(e.transversePosition) === -1 || e.transversePosition?.length === 0 ? '' : transversePosition[e.transversePosition]?.name}</td>
                                    <td style={{ width: '10%' }} className="">{severity[e.severity]['name']}</td>
                                    <td style={{ width: '5%' }} className="">
                                        {Number(e.area) === -1 || e.area?.length === 0 ? 0 : Number(e.area).toFixed(2)}
                                        {/* x{Number(e.transverseSpan) === -1 || Number(e.longitudinalSpan) === -1 || e.transverseSpan.length === 0 || e.longitudinalSpan.length === 0 ? 0 : e.transverseSpan * e.longitudinalSpan} */}
                                    </td>
                                    {/* <td style={{ width: '10%' }} className="">{e.frameNumber}</td> */}
                                    {drProjectData.projectType !== 4 && (
                                        <td style={{ width: '10%' }} className="">{Number(e.depth) === -1 || e.depth?.length === 0 ? 'N/A' : Number(e.depth * 1000).toFixed(2)}</td>
                                    )}
                                    <td style={{ width: '10%' }} className="">{Number(e.defectName) === 1 ? Number(e.type) === -1 || e.type?.length === 0 ? '' : type[e.type]?.name : ''} {Number(e.defectName) === 3 || Number(e.defectName) === 10 ? Number(e.severity) === -1 || e.severity?.length === 0 ? '' : severity[e.severity]['name'] : ''}</td>
                                    {/* <td style={{ width: '20%' }} className=""><a href="#" onClick={() => { handlePinning(e) }}>{new Date((e.frameNumber / fps) * 1000).toISOString().slice(11, 19)}</a></td> */}
                                    {/* <td style={{ width: '10%' }}>
                                        <img src={imBase64} height={50} width={50} style={{ objectFit: 'cover' }} />
                                    </td>
                                   
                                    <td style={{ width: '10%' }} className="">{score}</td>
                                    <td style={{ width: '10%' }} className="capitalize-text">{roadSideMap.map[obj.pred.roadSide]}</td>
                                    <td style={{ width: '10%' }} className=""><a href="#" onClick={() => { handlePinning(obj) }}>{new Date(frameTime * 1000).toISOString().slice(11, 19)}</a></td> */}

                                </tr>
                            })}
                        </tbody>
                    </table> : <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No distresses defect found</div>
                }

            </div>
        </>
    )
}

export default DistressTable