import React, { useRef } from "react";
import { connect } from 'react-redux';
// import { actionTypes } from 'redux-firestore';
import { Grid, Stack, Typography, Toolbar } from '@mui/material';
import ProjectBriefCardV2 from "./ProjectBriefCardV2";
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import CreateNewFolderTwoToneIcon from '@mui/icons-material/CreateNewFolderTwoTone';

const AllProjectsV2 = (props) => {

    const data = props.allProjects ? props.allProjects : [];
    const cardRef = useRef();

    if (!data || data.length === 0) {
        return (
            <div style={{ width: '100%', height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Stack direction="column">
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CreateNewFolderTwoToneIcon sx={{ fontSize: '100px', filter: 'invert(17%) sepia(49%) saturate(1880%) hue-rotate(179deg) brightness(90%) contrast(92%)', }} />
                    </div>
                    <Toolbar>
                        <Typography align="center" style={{ fontSize: '18px', padding: '40px' }}>
                            To begin, you must create a project as there are no existing projects found
                        </Typography>
                    </Toolbar>
                </Stack>
            </div >
        );
    }


    return (
        <div style={{ backgroundColor: '', display: 'flex', justifyContent: 'center', padding: '20px' }}>
            <Grid container rowSpacing={3} sx={{ backgroundColor: '' }} spacing={2}>

                {data.map((projectCard, index) => (
                    <Grid sx={{ justifyContent: 'center', display: 'flex' }}
                        key={index}
                        item
                        xl={3}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                    >
                        <ProjectBriefCardV2
                            cardRef={cardRef}
                            key={index}
                            projectCard={projectCard}
                        />
                    </Grid>
                ))}
            </Grid>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        allProjects: state.firestore.ordered.Projects,
        userData: state.firebase.profile,
    };
};



const mapDispatchToProps = (dispatch) => {
    return {
        // DOCS: https://github.com/prescottprue/redux-firestore/issues/114
        // clearData: () => dispatch({ type: actionTypes.CLEAR_DATA })
        // clearData: () =>
        //     dispatch({
        //         type: actionTypes.CLEAR_DATA,
        //         preserve: {
        //             data: [
        //                 "Projects",
        //                 "Company",
        //                 "CompanyBriefings",
        //                 "Notifications",
        //                 "OutputPredictions",
        //             ],
        //             ordered: [
        //                 "Projects",
        //                 "Company",
        //                 "CompanyBriefings",
        //                 "Notifications",
        //                 "OutputPredictions",
        //             ],
        //         },
        //     }),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => {
        // var ueid = props.userData.uid + props.userData.employeeid;
        var coid = props.userData.coid;
        return [
            {
                collection: 'Projects',
                where: [['coid', '==', `${coid}`], ['archived', '==', false]],
                // where: [['accessList', 'array-contains', `${ueid}`], ['coid', '==', `${coid}`], ['archived', '==', false]],
                orderBy: [['dateCreated', 'desc']],
            }
        ]
    })
)(AllProjectsV2);

