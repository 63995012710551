//////////////////////////////////////////////////////////////////////////////////////////////
/// README: SECTION 1 => NEW PROJECT CREATION 
///
import { storage, cloudFunction } from '../../config/firebaseConfig';
import axios from 'axios';
function sleep(time, callback) {
    var stop = new Date().getTime();
    while (new Date().getTime() < stop + time);
    callback();
}

const getVideoOrder = (videoName, videoMap) => {
    const videoInfo = videoMap[videoName];
    if (videoInfo) {
        return videoInfo.order;
    } else {
        return null; // Return null if the video name is not found
    }
};

export const setCurrentProjectId = (proid) => {
    // return {
    //     type: "SET_CURRENT_PROJECT_ID",
    //     proid,
    // }
    return async (dispatch, getState, { getFirestore }) => {
        return dispatch({ type: "SET_CURRENT_PROJECT_ID", proid });
    }
}

export const openNewProjectModal = () => {
    return {
        type: "OPEN_NEW_PROJECT_MODAL",
    }
};

export const deleteProject = (proid) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: 'START_DELETE_PROJECT'
        })
        setTimeout(async () => {
            const firestore = getFirestore();
            // await firestore.collection('Projects').doc(proid).delete();
            await firestore.collection('Projects').doc(proid).update({
                archived: true,
                archivalTime: firestore.FieldValue.serverTimestamp(),
            })
            return dispatch({ type: 'PROJECT_DELETED' });
        }, 1000)
    };
}
export const analysisSetting = (selectedDefects, conditionMethod, proid) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        await firestore.collection('Projects').doc(proid).update({
            selectedDefects: selectedDefects,
            conditionMethod: conditionMethod
        });
    };
}
/*
export const updateProject = (proid, projectName) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: 'START_UPDATE_PROJECT'
        })
        const fireStore = getFirestore();
        await fireStore.collection('Projects').doc(proid).update({
            name: `${projectName}` ?? ""
        });
        return dispatch({ type: 'PROJECT_UPDATED' });
    };
}
*/


export const createProject = (userData, companyData, projectData) => {
    // //////console.log(userData, companyData, projectData, "projectData")
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const creatorUid = getState().firebase.auth.uid;
        dispatch({ type: "ADD_NEW_PROJECT" });
        // generate id
        // THIS TIMEOUT IS TO SHOW THEM WE ARE DOING SOMETHING ~Aamir Vakeel

        // //////console.log("type:", typeof projectData.customCategories);
        // //////console.log(" projectData.customCategories data:", projectData.customCategories);
        setTimeout(async () => {
            const firestore = getFirestore();
            // const newProid = `webproj_${companyData.coid}_` + firestore.collection('Projects').doc().id;
            const newProid = `pr${projectData.projectType}` + firestore.collection('Projects').doc().id
            const ueid = `${userData.uid}${userData.employeeid}`;
            await firestore.collection('Projects').doc(newProid).set({
                showFreshIntroMob: true,
                showFreshIntroWeb: true,
                profilePic: companyData.profilePic ?? "",
                proid: `${newProid}`,
                projectTitle: `${projectData.projectTitle}` ?? "",
                roadSide: `${projectData.roadSide}` ?? "",
                camPos: `${projectData.camPos}` ?? "",
                camDir: `${projectData.camDir}` ?? "",

                identificationNo: `${projectData.identificationNo}` ?? "",
                clientName: `${projectData.clientName}` ?? "",
                streetName: `${projectData.streetName}` ?? "",
                startingAddress: `${projectData.startingAddress}` ?? "",
                endingAddress: `${projectData.endingAddress}` ?? "",
                direction: `${projectData.direction}` ?? "",
                totalLanes: `${projectData.totalLanes}` ?? "",
                laneNumber: `${projectData.laneNumber}` ?? "",

                categories: projectData.categories ?? null,
                projectTitleLC: `${projectData.projectTitle}`.toLowerCase() ?? "",
                companyName: `${companyData.companyName}` ?? "",
                creatorUid: `${userData.uid}` ?? "",
                creatorEmployeeid: `${userData.employeeid}` ?? "",
                coid: `${companyData.coid}` ?? "",
                accessList: firestore.FieldValue.arrayUnion(`${ueid}`),
                projectType: projectData.projectType ?? null,
                cameraMountingHeight: projectData.cameraMountingHeight ?? null,
                cameraInclination: projectData.cameraInclination ?? null,
                // pavementSegment: projectData.pavementSegment ?? null,
                processingTime: projectData.processingTime ?? null,
                typeOfRoad: projectData.typeOfRoad ?? null,
                surveyDate: projectData.surveyDate ?? null,
                segregatedCyclepath: projectData.segregatedCycleTrack ?? null,
                accessMap: {
                    [ueid]: {
                        access: 0,
                        name: `${userData.name}` ?? "",
                        uid: `${userData.uid}` ?? "",
                        employeeid: `${userData.employeeid}` ?? "",
                    },
                },
                status: 0,
                dateCreated: firestore.FieldValue.serverTimestamp(),
                archived: false,
                conditionIndex: projectData.conditionIndex ?? [0],
            });
            if (projectData.projectType === 2) {
                await firestore.collection('Projects').doc(newProid).update({
                    distanceInterval: projectData.distanceInterval
                })
            }
            // window.open(`/home/pr/${newProid}`, '_self');

            /// finally dispatch success action
            return dispatch({
                type: "NEW_PROJECT_ADDED",
            });

        }, 1000)
    }
};

export const updateProject = (proid, projectData) => {

    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: 'START_UPDATE_PROJECT'
        })
        let currentProjectData;
        const firestore = getFirestore();
        await firestore.collection('Projects').doc(proid).get().then((doc) => {
            if (doc.exists) {
                currentProjectData = doc.data();
            } else {
                currentProjectData = null;
            }
        }).catch((error) => {
            currentProjectData = null;
            ////console.log("Error getting document:", error);
        });

        if (currentProjectData.processingTime !== projectData.processingTime) {
            await firestore.collection('Projects').doc(proid).update({
                processingTime: projectData.processingTime ?? null,
                calculatingPrice: true,
            });
        }
        await firestore.collection('Projects').doc(proid).update({
            projectTitle: `${projectData.projectTitle}` ?? "",
            roadSide: `${projectData.roadSide}` ?? "",
            identificationNo: `${projectData.identificationNo}` ?? "",
            clientName: `${projectData.clientName}` ?? "",
            streetName: `${projectData.streetName}` ?? "",
            startingAddress: `${projectData.startingAddress}` ?? "",
            endingAddress: `${projectData.endingAddress}` ?? "",
            direction: `${projectData.direction}` ?? "",
            totalLanes: `${projectData.totalLanes}` ?? "",
            laneNumber: `${projectData.laneNumber}` ?? "",
            categories: projectData.categories ?? null,
            processingTime: projectData.processingTime ?? null,
            typeOfRoad: projectData.typeOfRoad ?? null,
            surveyDate: projectData.surveyDate ?? null,
            projectType: projectData.projectType,
            conditionIndex: projectData.conditionIndex ?? [1],
        });

        return dispatch({ type: 'PROJECT_UPDATED' });
    };
};

export const deleteProcessingTime = (proid, projectType) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        await firestore.collection('Projects').doc(proid).update({
            ['processingTime']: firebase.firestore.FieldValue.delete(),
            projectType: projectType,
        });
    };
};

export const updateProcessingTime = (proid, processingTime) => {
    //console.log(proid, processingTime, "processingTime")
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: 'START_UPDATE_PROCESSING'
        })

        const fireStore = getFirestore();
        await fireStore.collection('Projects').doc(proid).update({
            processingTime: processingTime ?? null,
        });
        await fireStore.collection('Projects').doc(proid).update({
            calculatingPrice: true,
        });
        return dispatch({ type: 'PROCESSING_UPDATED' });
    };
};


export const clearUpdateProjectState = () => {

    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: 'CLEAR_UPDATE_PROJECT_STATE'
        })
    };
};
export const updateProjectProcessingTime = (proid) => {

    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        // dispatch({
        //     type: 'START_UPDATE_PROJECT'
        // })

        const fireStore = getFirestore();
        await fireStore.collection('Projects').doc(proid).update({
            calculatingPrice: true,
        });
        // return dispatch({ type: 'PROJECT_UPDATED' });
    };
};

// export const addNewProject = () => {
//     return (dispatch) => {
//         dispatch({
//             type: "ADD_NEW_PROJECT"
//         })
//     }
// }


/// video url uploading to storage
// export const uploadVideoFrames = (eachFrameBase64, proid, index, totalNoOfFrames, latlongArray) => {
//     return async (dispatch, getState, { getFirebase, getFirestore }) => {
//         //////console.log("Uploading start", index)
//         dispatch({ type: "ADD_NEW_FRAME_URL" });
//         const firebase = getFirebase();
//         const firestore = getFirestore();
//         var reference = `Projects/${proid}/original/${proid}_image_${index + 1}.png`;
//         var storageReference = storage.ref(reference);
//         var metadata = {
//             contentType: 'image/png',
//         };


//         //////console.log("kkkkk")
//         await storageReference.putString(eachFrameBase64, 'data_url', metadata);
//         await storageReference.getDownloadURL().then(async (downloadURL) => {
//             //////console.log(downloadURL, "downloadurl")
//             await firebase.database().ref().child(`Projects/${proid}/all_images/${proid}_image_${index + 1}`).update({
//                 id: `${proid}_image_${index + 1}`,
//                 origName: `image_${index + 1}`,
//                 origPath: reference,
//                 imgUrl: downloadURL,
//                 gpsLat: latlongArray[0],
//                 gpsLong: latlongArray[1],

//             });
//             await firebase.database().ref().child(`Projects/${proid}/total_count`).update({
//                 totalOriginalImages: totalNoOfFrames,
//                 totalPredImages: 0

//             });
//             //////console.log(downloadURL);
//             return downloadURL;
//         }).catch((err) => {
//             console.error(err);
//             return null;
//         });
//     }
// };

export const uploadAllVideoFramesReset = () => {
    return {
        type: "RESET_PROJECT",
    }
}

// export const setCurrentGpsCoordinate = (gpsList) => {
//     return async (dispatch, getState, { getFirebase, getFirestore }) => {
//       return dispatch({ 
//             type: "SET_CURRENT_GPS_COORDINATE",
//             currentImageGpsCoordinate: gpsList,
//          });

//     }
// }

export const newVideoAttach = () => {
    // //////console.log("Dispatching video resetter")
    return async (dispatch, getState, { getFirestore }) => {
        return dispatch({ type: "RESET_VIDEO_UPLOAD_PARAMS" });
    }
}

export const newGpsAttach = () => {
    // //////console.log("Dispatching gps resetter")
    return async (dispatch, getState, { getFirestore }) => {
        return dispatch({ type: "RESET_GPS_UPLOAD_PARAMS" });
    }
}
export const uploadEditGpsData = (gpsList, proid, projectType) => {
    return async (dispatch, getState, { getFirebase }) => {
        dispatch({
            type: "UPLOAD_EDIT_GPS_START",
            uploadEditGpsLoader: true,
            uploadEditGpsIsDoneAndSent: null,
        });
        const firebase = getFirebase();
        if (projectType === 2) {
            await firebase.database().ref().child(`FilteredGpsData360/${proid}`).set({ data: gpsList });
        }
        else {
            await firebase.database().ref().child(`GpsData/${proid}`).set({ gps: gpsList });
        }
        await cloudFunction.httpsCallable('project-updateGpsFilesOnCall')(
            {
                "proid": proid
            })
            .then(async (res) => {
                //////console.log(res, "uploadEditGpsData")
                dispatch({
                    type: "UPLOAD_EDIT_GPS_COMPLETED",
                    uploadEditGpsLoader: false,
                    uploadEditGpsIsDoneAndSent: res.data.isDoneAndSent,
                });
            }).catch(async (error) => {
                dispatch({
                    type: "UPLOAD_EDIT_GPS_END",
                    uploadEditGpsLoader: false,
                    uploadEditGpsIsDoneAndSent: false,
                });
            });
        // 

    }
}
export const uploadEditGpsDataAfterInference = (gpsList, proid, projectType) => {
    return async (dispatch, getState, { getFirebase }) => {
        dispatch({
            type: "UPLOAD_EDIT_GPS_START",
            uploadEditGpsLoader: true,
            uploadEditGpsIsDoneAndSent: null,
        });
        const firebase = getFirebase();
        if (projectType === 2) {
            await firebase.database().ref().child(`FilteredGpsData360/${proid}`).set({ data: gpsList });
        }
        else {
            await firebase.database().ref().child(`GpsData/${proid}`).set({ gps: gpsList });
        }
        await cloudFunction.httpsCallable('gpsEditor360-updateDefectsGps360')(
            {
                "proid": proid
            })
            .then(async (res) => {
                dispatch({
                    type: "UPLOAD_EDIT_GPS_COMPLETED",
                    uploadEditGpsLoader: false,
                    uploadEditGpsIsDoneAndSent: res.data.isDoneAndSent,
                });
            }).catch(async (error) => {
                dispatch({
                    type: "UPLOAD_EDIT_GPS_END",
                    uploadEditGpsLoader: false,
                    uploadEditGpsIsDoneAndSent: false,
                });
            });
        // 

    }
}

export const removeUploadEditGpsMsgAndError = () => {
    return async (dispatch, getState, { getFirebase }) => {
        dispatch({
            type: "REMOVE_UPLOAD_EDIT_GPS_ERROR_MSG",
        });
    }
}
export const uploadAllVideoFramesGps = (proid, gpsList) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: "PROJECT_PICTURE_GPS_UPLOADING" });

        const firebase = getFirebase();
        const firestore = getFirestore();

        for (const eachGps of gpsList) {
            const index = gpsList.indexOf(eachGps);

            await firebase.database().ref().child(`Projects/${proid}/all_images/${proid}_image_${index}`).update({
                gpsLat: eachGps[0],
                gpsLong: eachGps[1],
            });

        }

        await firebase.database().ref().child(`Projects/${proid}/total_count`).update({
            hasGpsData: gpsList != null,
        });
        await firestore.collection('Projects').doc(proid).update({
            hasGpsData: gpsList != null,
        })

        return dispatch({ type: "PROJECT_PICTURE_GPS_UPLOADED" });
    }
}

// export const uploadAllVideoFrames = (listOfImageObject, proid, gpsList, videoTimeSeconds, videoFrames) => {
//     return async (dispatch, getState, { getFirebase, getFirestore }) => {
//         console.table("Images Object List => ", listOfImageObject);

//         const firebase = getFirebase();
//         const firestore = getFirestore();

//         const rtdbRef = `Projects/${proid}`
//         const quality = 10;
//         const width = 0;
//         const height = 0;
//         const format = 'jpeg';

//         await firebase.database().ref().child(rtdbRef).set({

//         });

//         dispatch({ type: "PROJECT_PICTURE_UPLOADING" });

//         const corrListOfImageObject = [...listOfImageObject];
//         var failedUploads = [];
//         var downloadUrls = [];
//         var lrDownloadUrls = [];
//         for (const eachImageObject of corrListOfImageObject) {
//             const index = corrListOfImageObject.indexOf(eachImageObject)
//             const reference = `Projects/${proid}/original/${proid}_image_${index}.png`;
//             const lrReference = `Projects/${proid}/lr_original/${proid}_image_${index}.png`
//             //////console.log("IN THE LOOP", index)
//             const fileMetadata = {
//                 contentType: 'image/png',
//                 // metadata: {
//                 //     frame: index,
//                 //     rtdbRef: rtdbRef,
//                 //     firestoreRef: {
//                 //         collection: 'Project',
//                 //         doc: proid,
//                 //     },
//                 //     storageRef: reference,
//                 //     lrStorageRef: lrReference,
//                 // }
//             };

//             const storageReference = storage.ref(reference);
//             const blob = await fetch(eachImageObject.image).then(r => r.blob());
//             await storageReference.put(blob, fileMetadata);
//             const downloadUrl = await storageReference.getDownloadURL().then(async (downloadUrl) => {
//                 downloadUrls = [...downloadUrls, downloadUrl]
//                 return downloadUrl;
//             }).catch((err) => {
//                 failedUploads = [...failedUploads, { index, eachImageObject }];
//                 return null;
//             });

//             const lrStorageReference = storage.ref(lrReference);
//             const lrBlob = await fromBlob(blob, quality, width, height, format)
//             // //////console.log(lrBlob);
//             await lrStorageReference.put(lrBlob, fileMetadata);
//             const lrDownloadUrl = await lrStorageReference.getDownloadURL().then(async (lrDownloadUrl) => {
//                 lrDownloadUrls = [...lrDownloadUrls, lrDownloadUrl]
//                 return lrDownloadUrl;
//             })

//             const resultDownloadUrls = `Org: ${downloadUrl}, Lr: ${lrDownloadUrl}`
//             // //////console.log(resultDownloadUrls)
//             dispatch({
//                 type: "PROJECT_PICTURE_UPLOADING_STATUS",
//                 pictureIndexStatus: index + 1,
//                 downloadURL: resultDownloadUrls,
//             });

//             await firebase.database().ref().child(`Projects/${proid}/all_images/${proid}_image_${index}`).update({
//                 id: `${proid}_image_${index}`,
//                 origName: `image_${index}`,
//                 origPath: reference,
//                 lrPath: lrReference,
//                 imgUrl: downloadUrl,
//                 lrImgUrl: lrDownloadUrl,
//                 gpsLat: eachImageObject.lat,
//                 gpsLong: eachImageObject.long,
//                 frameNumber: index,
//             });
//         }

//         await firebase.database().ref().child(`Projects/${proid}/total_count`).set({
//             failedUploads: failedUploads,
//             totalFailedUploads: failedUploads.length,
//             totalOriginalImages: corrListOfImageObject.length,
//             totalPredImages: 0,
//             hasGpsData: gpsList != null,
//             hasImageData: downloadUrls.length === corrListOfImageObject.length,
//             videoTimeSeconds: videoTimeSeconds,
//             videoFrames: videoFrames,
//         });

//         await firestore.collection('Projects').doc(proid).update({
//             hasGpsData: gpsList != null,
//         })

//         if (lrDownloadUrls.length > 0) {
//             await firestore.collection('Projects').doc(proid).set({
//                 displayPicture: lrDownloadUrls[0],
//                 hasImageData: downloadUrls.length === corrListOfImageObject.length,
//                 videoTimeSeconds: videoTimeSeconds,
//                 videoFrames: videoFrames,
//             }, { merge: true });
//         }

//         return dispatch({
//             type: "PROJECT_PICTURE_UPLOADED",
//             failedUploads: failedUploads,
//             showInferenceButton: failedUploads.length === 0,
//         });
//     }
// };

export const closePapiModal = () => {
    // //////console.log("Dispatching infr closer")
    return async (dispatch, getState, { getFirestore }) => {
        return dispatch({ type: "CLOSE_PAPI_MESSAGE_MODAL" });
    }
}

export const removeInferenceErrorAndMessage = () => {
    return (dispatch, getState, { getFirebase }) => {
        // //////console.log("clear  GPS error")
        dispatch({
            type: "CLEAR_INFERENCE_ERROR_MSG"
        })
    }
}
export const removeHaltErrorAndMessage = () => {
    return (dispatch, getState, { getFirebase }) => {
        // //////console.log("clear  GPS error")
        dispatch({
            type: "CLEAR_HALT_ERROR_MSG"
        })
    }
}
export const makeInferenceRequest = (proid) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        /// dispatch to indicate initiation of request
        dispatch({
            type: "INFERENCE_CALL_INIT",
            inferenceMessage: "",
            inferenceLoader: true,
            infernenceIsDoneAndSent: null,
            inferenceid: null
        });
        await cloudFunction.httpsCallable('transcode-createTranscodeLowResJobOnCall')(
            {
                "proid": proid

            })
            .then(async (res) => {
                await cloudFunction.httpsCallable('queueManagementV2-createAnalysisRequestOnCall')(
                    {
                        "proid": proid,
                        "isTest": '0'
                    })
                    .then(async (res) => {
                        dispatch({
                            type: "INFERENCE_CALL_COMPLETED",
                            inferenceMessage: res.data.responseMsg,
                            infernenceIsDoneAndSent: res.data.isDoneAndSent,
                            inferenceLoader: false,
                            inferenceid: res.data.reqid,
                        });
                    }).catch(async (error) => {
                        console.error(error);
                        dispatch({
                            type: "INFERENCE_CALL_ERROR",
                            inferenceMessage: "API failed to be called",
                            infernenceIsDoneAndSent: false,
                            inferenceLoader: false,
                            inferenceid: null
                        });
                    });
            }).catch(async (error) => {
                console.error(error);
                dispatch({
                    type: "PAPI_CALL_ERROR",
                    papiMessage: "API failed to be called",
                    papiIsDoneAndSent: false,
                    showPapiLoader: false,
                    papid: -1,
                    infernenceIsDoneAndSent: false,
                    inferenceLoader: false,
                    inferenceid: null
                });
            });

    }
};
export const makeAdvanceInferenceRequestWithTransferCredit = (proid) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        /// dispatch to indicate initiation of request
        dispatch({
            type: "INFERENCE_CALL_INIT",
            inferenceMessage: "",
            inferenceLoader: true,
            infernenceIsDoneAndSent: null,
            inferenceid: null
        });
        await cloudFunction
            .httpsCallable("productV2-transferCreditsToProjectJarV2")({
                transferToProid: proid,
            })
            .then(async (res) => {
                // console.log(res,"res1")
                await cloudFunction.httpsCallable('queueManagementV2-createAnalysisRequestOnCall')(
                    {
                        "proid": proid
                    })
                    .then(async (res) => {
                        // console.log(res,"res2")
                        // //////console.log(res, "res-makeAdvanceInferenceRequest")
                        dispatch({
                            type: "INFERENCE_CALL_COMPLETED",
                            inferenceMessage: res.data.responseMsg,
                            infernenceIsDoneAndSent: res.data.isDoneAndSent,
                            inferenceLoader: false,
                            inferenceid: res.data.reqid,
                        });
                    }).catch(async (error) => {
                        console.error(error);
                        /// dispatch to indicate unsuccessful request
                        dispatch({
                            type: "INFERENCE_CALL_ERROR",
                            inferenceMessage: "API failed to be called",
                            infernenceIsDoneAndSent: false,
                            inferenceLoader: false,
                            inferenceid: null
                        });
                    });
            }).catch(() => {
                dispatch({
                    type: "INFERENCE_CALL_ERROR",
                    inferenceMessage: "API failed to be called",
                    infernenceIsDoneAndSent: false,
                    inferenceLoader: false,
                    inferenceid: null
                });
            })

    }
};
export const makeAdvanceInferenceRequest = (proid) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        /// dispatch to indicate initiation of request
        dispatch({
            type: "INFERENCE_CALL_INIT",
            inferenceMessage: "",
            inferenceLoader: true,
            infernenceIsDoneAndSent: null,
            inferenceid: null
        });
        await cloudFunction.httpsCallable('queueManagementV2-createAnalysisRequestOnCall')(
            {
                "proid": proid
            })
            .then(async (res) => {
                // //////console.log(res, "res-makeAdvanceInferenceRequest")
                dispatch({
                    type: "INFERENCE_CALL_COMPLETED",
                    inferenceMessage: res.data.responseMsg,
                    infernenceIsDoneAndSent: res.data.isDoneAndSent,
                    inferenceLoader: false,
                    inferenceid: res.data.reqid,
                });
            }).catch(async (error) => {
                console.error(error);
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "INFERENCE_CALL_ERROR",
                    inferenceMessage: "API failed to be called",
                    infernenceIsDoneAndSent: false,
                    inferenceLoader: false,
                    inferenceid: null
                });
            });
    }
};
export const makeHaltProcessingRequestOnProject = (proid) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        /// dispatch to indicate initiation of request
        dispatch({
            type: "HALT_PROCESSING_CALL_INIT",
            haltMessage: "",
            haltLoader: true,
            haltIsDoneAndSent: null
        });
        await cloudFunction.httpsCallable('queueManagementV2-haltProcessingOnCall')(
            {
                "proid": proid
            })
            .then(async (res) => {
                // //////console.log(res, "res-makeHaltProcessingRequestOnProject")
                dispatch({
                    type: "HALT_PROCESSING_CALL_COMPLETED",
                    haltMessage: res.data.responseMsg,
                    haltIsDoneAndSent: res.data.isDoneAndSent,
                    haltLoader: false,
                    inferenceid: res.data.reqid,
                });
            }).catch(async (error) => {
                // console.error(error);
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "HALT_PROCESSING_CALL_ERROR",
                    haltMessage: "API failed to be called",
                    haltIsDoneAndSent: false,
                    haltLoader: false
                });
            });
    }
};

export const makeVisualizerInferenceRequest = (proid) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        /// dispatch to indicate initiation of request
        dispatch({
            type: "VISUALIZER_INFERENCE_CALL_INIT",
            inferenceMessage: "",
            inferenceLoader: true,
            infernenceIsDoneAndSent: null,
            inferenceid: null
        });
        await cloudFunction.httpsCallable('pipeline360-process360Video')(
            {
                "proid": proid

            })
            .then(async (res) => {
                // //////console.log(res, 'zam123')
                dispatch({
                    type: "VISUALIZER_INFERENCE_CALL_COMPLETED",
                    inferenceMessage: res.data.responseMsg,
                    infernenceIsDoneAndSent: res.data.isDoneAndSent,
                    inferenceLoader: false,
                    inferenceid: res.data.reqid,
                });

            }).catch(async (error) => {
                // console.error(error, 'zam123');
                /// dispatch to indicate unsuccessful request
                // await firestore.collection('Projects').doc(proid).update({
                //     status: 5,
                // });
                dispatch({
                    type: "VISUALIZER_INFERENCE_CALL_ERROR",
                    inferenceMessage: "API failed to be called",
                    infernenceIsDoneAndSent: false,
                    inferenceLoader: false,
                    inferenceid: null
                });
            });
    }
};



export const makeAssetAnalysisInferenceRequest = (proid) => {
    //////console.log(fromFrame, toFrame, proid)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        /// dispatch to indicate initiation of request
        dispatch({
            type: "PAPI_CALL_INIT",
            papiMessage: "Request created and is processing...",
            showPapiLoader: true,
            papiIsDoneAndSent: false,
            papid: 0,
        });
        await firestore.collection('Projects').doc(proid).update({
            status: 6,
        });
        await cloudFunction.httpsCallable('queueManagement-addProjectToAnalysisQueue')(
            {
                "proid": proid,
            })
            .then(async (res) => {
                // //////console.log(res, "res")
                await cloudFunction.httpsCallable('transcode-createTranscodeLowResJobOnCall')(
                    {
                        "proid": proid,
                    })
                    .then(async (res) => {
                        dispatch({
                            type: "PAPI_CALL_COMPLETED",
                            papiMessage: res.data.responseMsg,
                            papiIsDoneAndSent: res.data.isDoneAndSent,
                            showPapiLoader: false,
                            papid: -1,
                        });
                    }).catch(async (error) => {
                        console.error(error);
                        /// dispatch to indicate unsuccessful request
                        await firestore.collection('Projects').doc(proid).update({
                            status: -12,
                            hasTranscoded: 0
                        });
                        dispatch({
                            type: "PAPI_CALL_ERROR",
                            papiMessage: "API failed to be called",
                            papiIsDoneAndSent: false,
                            showPapiLoader: false,
                            papid: -1,
                        });
                    });
            }).catch(async (error) => {
                console.error(error);
                /// dispatch to indicate unsuccessful request
                await firestore.collection('Projects').doc(proid).update({
                    status: 5,
                });
                dispatch({
                    type: "PAPI_CALL_ERROR",
                    papiMessage: "API failed to be called",
                    papiIsDoneAndSent: false,
                    showPapiLoader: false,
                    papid: -1,
                });
            });
    }
};
export const makeSurfaceAnalysisInferenceRequest = (proid) => {
    //////console.log(fromFrame, toFrame, proid)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {

        const firestore = getFirestore();
        /// dispatch to indicate initiation of request
        dispatch({
            type: "PAPI_SURFACE_CALL_INIT",
            papiSurfaceMessage: "Request created and is processing...",
            showPapiSurfaceLoader: true,
            papiSurfaceIsDoneAndSent: false,
            papidSurface: 0,
        });
        await firestore.collection('Projects').doc(proid).update({
            status: 6,
        });
        // transcode-createTranscodeJobOnCall

        await cloudFunction.httpsCallable('queueManagement-addProjectToAnalysisQueue')(
            {
                "proid": proid,
            })
            .then(async (res) => {
                await cloudFunction.httpsCallable('transcode-createTranscodeLowResJobOnCall')(
                    {
                        "proid": proid,
                    })
                    .then(async (res) => {
                        dispatch({
                            type: "PAPI_SURFACE_CALL_COMPLETED",
                            papiSurfaceMessage: res.data.responseMsg,
                            papiSurfaceIsDoneAndSent: res.data.isDoneAndSent,
                            showPapiSurfaceLoader: false,
                            papidSurface: -1,
                        });
                    }).catch(async (error) => {
                        console.error(error);
                        /// dispatch to indicate unsuccessful request
                        await firestore.collection('Projects').doc(proid).update({
                            status: -12,
                            hasTranscoded: 0
                        });
                        dispatch({
                            type: "PAPI_SURFACE_CALL_ERROR",
                            papiSurfaceMessage: "API failed to be called",
                            papiSurfaceIsDoneAndSent: false,
                            showPapiSurfaceLoader: false,
                            papidSurface: -1,
                        });
                    });
            }).catch(async (error) => {
                console.error(error);
                /// dispatch to indicate unsuccessful request
                await firestore.collection('Projects').doc(proid).update({
                    status: 5,
                });
                dispatch({
                    type: "PAPI_SURFACE_CALL_ERROR",
                    papiSurfaceMessage: "API failed to be called",
                    papiSurfaceIsDoneAndSent: false,
                    showPapiSurfaceLoader: false,
                    papidSurface: -1,
                });
            });
    }
};
export const uploadVideo = (proid, videoFile) => {
    // //////console.log(proid, videoFile, "data");
    return async (dispatch, getState, { getFirebase, getFirestore }) => {

        const firebase = getFirebase();
        const firestore = getFirestore();


        const reference = `Projects/${proid}/${videoFile.name}`;
        // const thumbnailReference = `Projects/${proid}/thumbnail.jpg`;

        dispatch({
            type: "PROJECT_VIDEO_UPLOAD_PROGRESS",
            progress: 0.1,
            proid,
            errorMessage: ''
        });

        // const thumbnailMetadata = {
        //     contentType: 'image/png',
        // };

        const storageReference = storage.ref(reference);
        // const storageThumbnailReference = storage.ref(thumbnailReference);

        const uploadTask = storageReference.put(videoFile);

        // const thumbnailBlob = await fetch(thumbnail).then(r => r.blob());
        // const quality = 20;
        // const width = 0;
        // const height = 0;
        // const format = 'jpeg';
        // const lrThumbnailBlob = await fromBlob(thumbnailBlob, quality, width, height, format)

        // const uploadThumbnailTask = await storageThumbnailReference.put(lrThumbnailBlob, thumbnailMetadata);
        // var thumbnailDownloadUrl = await uploadThumbnailTask.ref.getDownloadURL().then(async (downloadURL) => {
        //     return downloadURL;
        // }).catch(error => {
        //     //////console.log(error);
        //     return ""
        // })

        // //////console.log(thumbnailDownloadUrl)
        // return;

        await firestore.collection('Projects').doc(proid).update({
            status: 1,
            thumbnail: '',
            // thumbnail: thumbnailDownloadUrl,
            videoMessage: 'Video uploading...',
            isVideoUploadingOnDB: true,
        });

        var startTime = Date.now()
        var estimatedTimeRemaining = null;
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot) => {
                // //////console.log(snapshot, "snapshot")
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // //////console.log('Upload is ' + progress + '% done');

                // Calculate the elapsed time
                var currentTime = Date.now()
                var elapsedTime = (currentTime - startTime) / 1000; // Elapsed time in seconds

                // Calculate the upload speed in bytes per second
                var uploadSpeed = snapshot.bytesTransferred / elapsedTime;

                // Calculate the remaining bytes to upload
                var remainingBytes = snapshot.totalBytes - snapshot.bytesTransferred;

                // Calculate the estimated time remaining in seconds
                estimatedTimeRemaining = remainingBytes / uploadSpeed;
                estimatedTimeRemaining = isFinite(estimatedTimeRemaining) ? estimatedTimeRemaining : 0
                // //////console.log('Time remaining ' + estimatedTimeRemaining + 'seconds');

                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        // //////console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        // //////console.log('Upload is running');
                        break;
                }
                return dispatch({
                    type: "PROJECT_VIDEO_UPLOAD_PROGRESS",
                    progress,
                    estimatedTimeRemaining,
                    proid,
                    errorMessage: ''
                });
            },
            async (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                }
                await firestore.collection('Projects').doc(proid).update({
                    status: -1,
                    videoMessage: null,
                    isVideoUploadingOnDB: false,
                });
                return dispatch({
                    type: "PROJECT_VIDEO_UPLOAD_PROGRESS",
                    progress: 0,
                    proid,
                    errorMessage: error
                });
            },
            async () => {
                // Upload completed successfully, now we can get the download URL
                const videoDownloadLink = await uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    return downloadURL
                });
                // //////console.log('File available at', videoDownloadLink);
                await firestore.collection('Projects').doc(proid).update({
                    status: 2,
                    videoPath: reference, //TODO: to enable back
                    videoLink: videoDownloadLink
                });

            }
        );
        // const downloadUrl = await storageReference.getDownloadURL().then(async (downloadUrl) => {
        //     return downloadUrl;
        // }).catch((err) => {
        //     //////console.log(err);
        //     return null;
        // });

        // const resultDownloadUrls = `Org: ${downloadUrl}`
        return dispatch({
            type: "PROJECT_VIDEO_UPLOAD_PROGRESS",
            progress: 100,
            proid,
            errorMessage: ''
        });
    }
};

export const uploadAdvanceImagingVideo = (proid, videoFile) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {

        const firebase = getFirebase();
        const firestore = getFirestore();
        const reference = `Projects/${proid}/${videoFile.name}`;
        let videos = [
            {
                "order": 0,
                "name": `${videoFile.name}`,
                "isUploaded": false
            }
        ];

        dispatch({
            type: "PROJECT_VIDEO_UPLOAD_PROGRESS",
            progress: 0.1,
            proid,
            errorMessage: ''
        });

        const storageReference = storage.ref(reference);
        const uploadTask = storageReference.put(videoFile);

        await firestore.collection('Projects').doc(proid).update({
            status: 1,
            thumbnail: '',
            videoMessage: 'Video uploading...',
            isVideoUploadingOnDB: true,
        });
        await firestore.collection('Projects').doc(proid).update({ videos });

        var startTime = Date.now()
        var estimatedTimeRemaining = null;

        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot) => {

                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // //////console.log('Upload is ' + progress + '% done');

                // Calculate the elapsed time
                var currentTime = Date.now()
                var elapsedTime = (currentTime - startTime) / 1000; // Elapsed time in seconds

                // Calculate the upload speed in bytes per second
                var uploadSpeed = snapshot.bytesTransferred / elapsedTime;

                // Calculate the remaining bytes to upload
                var remainingBytes = snapshot.totalBytes - snapshot.bytesTransferred;

                // Calculate the estimated time remaining in seconds
                estimatedTimeRemaining = remainingBytes / uploadSpeed;
                estimatedTimeRemaining = isFinite(estimatedTimeRemaining) ? estimatedTimeRemaining : 0
                // //////console.log('Time remaining ' + estimatedTimeRemaining + 'seconds');

                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        // //////console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        // //////console.log('Upload is running');
                        break;
                }
                return dispatch({
                    type: "PROJECT_VIDEO_UPLOAD_PROGRESS",
                    progress,
                    estimatedTimeRemaining,
                    proid,
                    errorMessage: ''
                });
            },
            async (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                }
                await firestore.collection('Projects').doc(proid).update({
                    status: -1,
                    videoMessage: null,
                    isVideoUploadingOnDB: false,
                });
                return dispatch({
                    type: "PROJECT_VIDEO_UPLOAD_PROGRESS",
                    progress: 0,
                    proid,
                    errorMessage: error
                });
            },
            async () => {

                const videoDownloadLink = await uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    return downloadURL
                });
                videos[0].videoPath = reference;
                videos[0].videoLink = videoDownloadLink;
                videos[0].isUploaded = true;
                await firestore.collection('Projects').doc(proid).update({
                    status: 2,
                    videos,
                    isVideoUploadingOnDB: false,
                    videoMessage: 'Video Completed successfully'
                    // videoPath: reference,
                    // videoLink: videoDownloadLink
                });

            }
        );
        return dispatch({
            type: "PROJECT_VIDEO_UPLOAD_PROGRESS",
            progress: 100,
            proid,
            errorMessage: ''
        });
    }
};
export const uploadAllVideos = (proid, videoFiles) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {

        const firebase = getFirebase();
        const firestore = getFirestore();

        let videos = [];
        let frontendVideoOrder = [];
        videoFiles.map((video, index) => {
            const { name } = video;
            frontendVideoOrder[index] = name;
            // const videoName = `${name}`.split('.')[0];
            videos[index] = {
                order: index,
                name,
                isUploaded: false
            };
        })

        // //////console.log(videos, "vid", frontendVideoOrder)
        const uploadedVideoLinks = [];
        const uploadedVideoReferences = [];
        const uploadPromises = [];
        const updateData = {
            status: 1,
            videoMessage: 'Video uploading...',
            isVideoUploadingOnDB: true,
        }

        await firestore.collection('Projects').doc(proid).update(updateData);
        await firestore.collection('Projects').doc(proid).update({ videos });
        await firestore.collection('Projects').doc(proid).update({ frontendVideoOrder });
        //  for (const videoFile of videoFiles)
        videoFiles.map((videoFile, index) => {
            const reference = `Projects/${proid}/${videoFile.name}`;
            const storageReference = storage.ref(reference);
            const uploadTask = storageReference.put(videoFile);
            const startTime = Date.now();
            let estimatedTimeRemaining = null;

            const uploadPromise = new Promise(async (resolve, reject) => {
                uploadTask.on(
                    firebase.storage.TaskEvent.STATE_CHANGED,
                    (snapshot) => {
                        var state = getState().project;
                        // //////console.log(state.uploadVideoStatusAction, "statecheck")
                        const isCancel = state.uploadVideoStatusAction &&
                            state.uploadVideoStatusAction[proid] &&
                            state.uploadVideoStatusAction[proid][videoFile.name] &&
                            state.uploadVideoStatusAction[proid][videoFile.name]['isCancel'] !== undefined
                            ? state.uploadVideoStatusAction[proid][videoFile.name]['isCancel']
                            : false;
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        // //////console.log(isCancel, "isCancel")
                        // if (isCancel === true) {
                        //     //////console.log(progress, "snapshot", snapshot.state);
                        //     uploadTask.cancel();
                        // }
                        // if (progress >= 90) {
                        //     uploadTask.cancel();
                        // }

                        const currentTime = Date.now();
                        const elapsedTime = (currentTime - startTime) / 1000;
                        const uploadSpeed = snapshot.bytesTransferred / elapsedTime;
                        const remainingBytes = snapshot.totalBytes - snapshot.bytesTransferred;
                        estimatedTimeRemaining = remainingBytes / uploadSpeed;
                        estimatedTimeRemaining = isFinite(estimatedTimeRemaining) ? estimatedTimeRemaining : 0;
                        // //////console.log(estimatedTimeRemaining, "estimated time remaining")
                        switch (snapshot.state) {
                            case firebase.storage.TaskState.PAUSED:
                                // //////console.log("video paused")
                                break;
                            case firebase.storage.TaskState.RUNNING:
                                // //////console.log("video running")
                                break;
                        }

                        // Dispatch progress for the current video file
                        return dispatch({
                            type: "PROJECT_360_VIDEO_UPLOAD_PROGRESS",
                            progress,
                            estimatedTimeRemaining,
                            proid,
                            errorMessage: '',
                            videoFileName: videoFile.name,
                        });
                    },
                    async (error) => {

                        await firestore.collection('Projects').doc(proid).update({
                            status: -1,
                            ['videoMessage']: firebase.firestore.FieldValue.delete(),
                            gpsApprovalMessage: error.message,
                            isVideoUploadingOnDB: false,
                        });

                        // Dispatch an error for the current video file
                        dispatch({
                            type: "PROJECT_VIDEO_UPLOAD_PROGRESS",
                            progress: 0,
                            proid,
                            errorMessage: error,
                        });

                        reject(error);
                    },
                    async () => {
                        const videoDownloadLink = await uploadTask.snapshot.ref.getDownloadURL();
                        // const videoOrder = getVideoOrder(`${videoFile.name}`.split('.')[0], videos);
                        // uploadedVideoLinks.push(videoDownloadLink);
                        // uploadedVideoReferences.push(reference); // Store the reference
                        const videoOrder = getVideoOrder(index, videos);
                        if (videoOrder !== null) {
                            uploadedVideoLinks[videoOrder] = videoDownloadLink;
                            uploadedVideoReferences[videoOrder] = reference;
                        }
                        resolve(videoDownloadLink);
                    }
                );
            });

            uploadPromises.push(uploadPromise);
        })

        try {
            let index = 0;
            const videoDownloadLinks = await Promise.all(uploadPromises);
            for (const key in videos) {
                if (videos.hasOwnProperty(key)) {
                    videos[key].videoPath = uploadedVideoReferences[index];
                    videos[key].videoLink = videoDownloadLinks[index];
                    videos[key].isUploaded = true;
                }
                index++;
            }
            // All videos have been uploaded successfully
            // //////console.log(videos, "videos upload new")
            const finalUpdateData = {
                status: 2,
                videos,
                isVideoUploadingOnDB: false,
                videoPath: uploadedVideoReferences,
                videoMessage: 'Videos Completed successfully'
            };
            // //////console.log(finalUpdateData, "finalUpdateData")

            await firestore.collection('Projects').doc(proid).update(finalUpdateData);

            // Dispatch progress for all videos completed
            dispatch({
                type: "PROJECT_VIDEO_UPLOAD_PROGRESS",
                progress: 100,
                proid,
                errorMessage: '',
            });
        } catch (error) {
            // console.error('Error uploading videos:', error);
            await firestore.collection('Projects').doc(proid).update({
                status: -1,
                ['videoMessage']: firebase.firestore.FieldValue.delete(),
                gpsApprovalMessage: error.message,
                isVideoUploadingOnDB: false,
            });
        }
    }
}
export const uploadAllVideosV2 = (proid, videoFiles, videoList, videoLength) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {

        const firebase = getFirebase();
        const firestore = getFirestore();
        let videos = [];
        // let frontendVideoOrder = [];
        videoFiles.map((video, index) => {
            const { name } = video;
            // frontendVideoOrder[index] = name;
            videos[index] = {
                name,
                isUploaded: false
            };
        });

        const uploadedVideoLinks = videoList ? videoList.map((eachVideo) => eachVideo.videoLink) : [];
        const uploadedVideoReferences = videoList ? videoList.map((eachVideo) => eachVideo.videoPath) : [];
        const uploadPromises = [];
        let videosCount = videoLength || 0;
        const updateData = {
            status: 1,
            videoMessage: 'Video uploading...',
            isVideoUploadingOnDB: true,
            videosCount: videosCount + videoFiles.length
        }
        let existingVideos = videoList ? videoList : [];
        const newVideos = videos.map((video, index) => ({
            ...video,
            order: videosCount + index
        }));
        const updatedVideosList = [...existingVideos, ...newVideos];
        await firestore.collection('Projects').doc(proid).update(updateData);
        await firestore.collection('Projects').doc(proid).update({ videos: updatedVideosList });
        // await firestore.collection('Projects').doc(proid).update({ frontendVideoOrder: firestore.FieldValue.arrayUnion(videoFiles.map((each) => each.name)) });
        videoFiles.map((videoFile, index) => {
            const reference = `Projects/${proid}/${videoFile.name}`;
            const storageReference = storage.ref(reference);
            const uploadTask = storageReference.put(videoFile);
            const startTime = Date.now();
            let estimatedTimeRemaining = null;

            const uploadPromise = new Promise(async (resolve, reject) => {
                uploadTask.on(
                    firebase.storage.TaskEvent.STATE_CHANGED,
                    (snapshot) => {
                        // var state = getState().project;
                        // const isCancel = state.uploadVideoStatusAction &&
                        //     state.uploadVideoStatusAction[proid] &&
                        //     state.uploadVideoStatusAction[proid][videoFile.name] &&
                        //     state.uploadVideoStatusAction[proid][videoFile.name]['isCancel'] !== undefined
                        //     ? state.uploadVideoStatusAction[proid][videoFile.name]['isCancel']
                        //     : false;

                        // if (isCancel === true) {
                        //     //////console.log(progress, "snapshot", snapshot.state);
                        //     uploadTask.cancel();
                        // }
                        // if (progress >= 90) {
                        //     uploadTask.cancel();
                        // }
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        const currentTime = Date.now();
                        const elapsedTime = (currentTime - startTime) / 1000;
                        const uploadSpeed = snapshot.bytesTransferred / elapsedTime;
                        const remainingBytes = snapshot.totalBytes - snapshot.bytesTransferred;
                        estimatedTimeRemaining = remainingBytes / uploadSpeed;
                        estimatedTimeRemaining = isFinite(estimatedTimeRemaining) ? estimatedTimeRemaining : 0;
                        switch (snapshot.state) {
                            case firebase.storage.TaskState.PAUSED:
                                break;
                            case firebase.storage.TaskState.RUNNING:
                                break;
                        }

                        // Dispatch progress for the current video file
                        return dispatch({
                            type: "PROJECT_360_VIDEO_UPLOAD_PROGRESS",
                            progress,
                            estimatedTimeRemaining,
                            proid,
                            errorMessage: '',
                            videoFileName: videoFile.name,
                        });
                    },
                    async (error) => {

                        await firestore.collection('Projects').doc(proid).update({
                            status: -1,
                            ['videoMessage']: firebase.firestore.FieldValue.delete(),
                            gpsApprovalMessage: error.message,
                            isVideoUploadingOnDB: false,
                        });

                        // Dispatch an error for the current video file
                        dispatch({
                            type: "PROJECT_VIDEO_UPLOAD_PROGRESS",
                            progress: 0,
                            proid,
                            errorMessage: error,
                        });

                        reject(error);
                    },
                    async () => {
                        const videoDownloadLink = await uploadTask.snapshot.ref.getDownloadURL();
                        const videoOrder = getVideoOrder(existingVideos.length + index, updatedVideosList);
                        if (videoOrder !== null) {
                            uploadedVideoLinks[videoOrder] = videoDownloadLink;
                            uploadedVideoReferences[videoOrder] = reference;
                        }
                        resolve(videoDownloadLink);
                    }
                );
            });

            uploadPromises.push(uploadPromise);
        })
        try {
            let index = 0;
            const videoDownloadLinks = await Promise.all(uploadPromises);
            for (const key in newVideos) {
                if (newVideos.hasOwnProperty(key)) {
                    let videoToUpdate = updatedVideosList.find(v => v.order === newVideos[key].order);
                    if (videoToUpdate) {
                        videoToUpdate.videoPath = uploadedVideoReferences[videosCount + index];
                        videoToUpdate.videoLink = uploadedVideoLinks[videosCount + index];
                        videoToUpdate.isUploaded = true;
                    }
                    index++;
                }
            }
            const finalUpdateData = {
                status: 2,
                videos: updatedVideosList,
                isVideoUploadingOnDB: false,
                // videoPath: firestore.FieldValue.arrayUnion(uploadedVideoReferences),
                videoMessage: 'Videos Completed successfully' // Completion message
            };
            await firestore.collection('Projects').doc(proid).update(finalUpdateData);

            // Dispatch progress for all videos completed
            dispatch({
                type: "PROJECT_VIDEO_UPLOAD_PROGRESS",
                progress: 100,
                proid,
                errorMessage: '',
            });
        } catch (error) {
            // console.error('Error uploading videos:', error);
            await firestore.collection('Projects').doc(proid).update({
                status: -1,
                ['videoMessage']: firebase.firestore.FieldValue.delete(),
                gpsApprovalMessage: error.message,
                isVideoUploadingOnDB: false,
            });
        }
    }
}

export const uploadVideoNext = (proid) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        await firestore.collection('Projects').doc(proid).update({
            status: 5,
        });
    }
};

export const cancelVideoUpload = (proid, videoFileName) => {
    // //////console.log(proid, videoFileName, "cancelVideoUpload");
    return (dispatch, getState, { getFirebase }) => {
        dispatch({
            type: "CANCEL_VIDEO_UPLOAD",
            proid,
            videoFileName,
        })
    }
}

export const wholeVideoSelection = (videoSelectionData) => {
    // //////console.log(videoSelectionData, 'map123')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        dispatch({
            type: "WHOLE_SELECT_VIDEO_CALL_INIT",
            wholeSelectVideoMessage: "Request created and is processing...",
            wholeSelectVideoLoader: true,
            wholeSelectVideoIsDoneAndSent: null,
        });

        // dispatch({ type: "PROJECT_GPS_UPLOADING" });
        cloudFunction.httpsCallable('project-createVideoSelectionOnCall')(
            {
                "videoSelectionData": JSON.stringify(videoSelectionData)
            })
            .then((res) => {
                /// dispatch to indicate successful request
                // //////console.log('brain', res)
                dispatch({
                    type: "WHOLE_SELECT_VIDEO_COMPLETE",
                    wholeSelectVideoMessage: res.data.responseMsg,
                    wholeSelectVideoIsDoneAndSent: res.data.isDoneAndSent,
                    wholeSelectVideoLoader: false,
                });
            }).catch((error) => {
                console.error(error);
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "WHOLE_SELECT_VIDEO_ERROR",
                    wholeSelectVideoMessage: "API failed to be called",
                    wholeSelectVideoIsDoneAndSent: false,
                    wholeSelectVideoLoader: false,
                });
            });

    }
};
export const removeWholeVideoSelectionErrorAndMessage = () => {
    return (dispatch, getState, { getFirebase }) => {
        // //////console.log("clear error")
        dispatch({
            type: "CLEAR__WHOLE_VIDEO_SELECTION_ERROR_MSG"
        })
    }
}
export const videoSelection = (videoSelectionData) => {
    // //////console.log(videoSelectionData, 'map123')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        dispatch({
            type: "SELECT_VIDEO_CALL_INIT",
            selectVideoMessage: "Request created and is processing...",
            selectVideoLoader: true,
            selectVideoIsDoneAndSent: null,
        });

        // dispatch({ type: "PROJECT_GPS_UPLOADING" });
        cloudFunction.httpsCallable('project-createVideoSelectionOnCall')(
            {
                "videoSelectionData": JSON.stringify(videoSelectionData)
            })
            .then((res) => {
                /// dispatch to indicate successful request
                // //////console.log('brain', res)
                dispatch({
                    type: "SELECT_VIDEO_COMPLETE",
                    selectVideoMessage: res.data.responseMsg,
                    selectVideoIsDoneAndSent: res.data.isDoneAndSent,
                    selectVideoLoader: false,
                });
            }).catch((error) => {
                console.error(error);
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "SELECT_VIDEO_ERROR",
                    selectVideoMessage: "API failed to be called",
                    selectVideoIsDoneAndSent: false,
                    selectVideoLoader: false,
                });
            });

    }
};
export const removeVideoSelectionErrorAndMessage = () => {
    return (dispatch, getState, { getFirebase }) => {
        // //////console.log("clear error")
        dispatch({
            type: "CLEAR_VIDEO_SELECTION_ERROR_MSG"
        })
    }
}


export const gpsEditNext = (proid) => {
    //console.log(proid, 'hello5')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        await firestore.collection('Projects').doc(proid).update({
            isGpsEditDone: true,
        });
    }
};

export const videoSelectorBack = (proid) => {
    //console.log(proid, 'map456')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        await firestore.collection('Projects').doc(proid).update({
            isGpsEditDone: false,
        });

    }
};

export const videoSelectorNext = (proid) => {
    //console.log(proid, 'map789')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        await firestore.collection('Projects').doc(proid).update({
            isVideoSelectionDone: true,
        });

    }
};

export const roadConditionIndexBack = (proid) => {
    //console.log(proid, 'kashif')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        await firestore.collection('Projects').doc(proid).update({
            // isProjectUpdated: false,
            isVideoSelectionDone: false,
        });

    }
};

export const roadConditionIndexNext = (proid) => {
    //console.log(proid, 'kashif')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        await firestore.collection('Projects').doc(proid).update({
            isroadConditionIndexUpdated: true,
        });

    }
};

export const confirmDetailsBack = (proid, projectType) => {
    //console.log(proid,projectType, 'map123')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        if (projectType === 2) {
            await firestore.collection('Projects').doc(proid).update({
                isGpsEditDone: false,
            });
        }
        if (projectType === 5) {
            await firestore.collection('Projects').doc(proid).update({
                isVideoSelectionDone: false,
            });
        }

        else {
            await firestore.collection('Projects').doc(proid).update({
                // isroadConditionIndexUpdated: false,
                isVideoSelectionDone: false,
            });
        }
    }
};

export const confirmDetailsNext = (proid) => {
    //console.log(proid, 'map456')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        await firestore.collection('Projects').doc(proid).update({
            isProjectUpdated: true,
        });

    }
};

export const setupProjectBack = (proid) => {
    //console.log(proid, 'kashif')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        await firestore.collection('Projects').doc(proid).update({
            isProjectUpdated: false
        });

    }
};


export const mapConfirm = (proid) => {
    // //////console.log(proid, 'map123')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        dispatch({
            type: "CONFIRM_MAP_CALL_INIT",
            confirmMapMessage: "Request created and is processing...",
            confirmMapLoader: true,
            confirmMapIsDoneAndSent: null,
        });

        // dispatch({ type: "PROJECT_GPS_UPLOADING" });
        await firestore.collection('Projects').doc(proid).update({
            status: 5,
        });

        dispatch({
            type: "CONFIRM_MAP_CALL_COMPLETE",
            confirmMapMessage: "Request completed..",
            confirmMapLoader: false,
            confirmMapIsDoneAndSent: true,
        });

    }
};

export const mapCancel = (proid) => {
    // //////console.log(proid, 'map123')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        dispatch({
            type: "CANCEL_MAP_CALL_INIT",
            cancelMapMessage: "Request created and is processing...",
            cancelMapLoader: true,
            cancelMapIsDoneAndSent: null,
        });

        await firestore.collection('Projects').doc(proid).update({
            status: -1,
            ['videos']: firebase.firestore.FieldValue.delete(),
            ['videoPath']: firebase.firestore.FieldValue.delete(),
            ['isVideoUploadingOnDB']: firebase.firestore.FieldValue.delete(),
            ['videoMessage']: firebase.firestore.FieldValue.delete(),
        });

        dispatch({
            type: "CANCEL_MAP_CALL_COMPLETE",
            cancelMapMessage: "Request completed..",
            cancelMapLoader: false,
            cancelMapIsDoneAndSent: true,
        });

    }
};
export const clearVideoState = (proid) => {
    // //////console.log(proid, 'map123')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        dispatch({
            type: "CLEAR_VIDEO_CALL_INIT",
            clearVideoMessage: "Request created and is processing...",
            clearVideoLoader: true,
            clearVideoIsDoneAndSent: null,
        });

        await firestore.collection('Projects').doc(proid).update({
            // status: 0,
            // ['videos']: firebase.firestore.FieldValue.delete(),
            // ['videoPath']: firebase.firestore.FieldValue.delete(),
            ['isVideoUploadingOnDB']: firebase.firestore.FieldValue.delete(),
            ['videoMessage']: firebase.firestore.FieldValue.delete(),
            // ['frontendVideoOrder']: firebase.firestore.FieldValue.delete(),
        });

        dispatch({
            type: "CLEAR_VIDEO_CALL_COMPLETE",
            clearVideoMessage: "Request completed..",
            clearVideoLoader: false,
            clearVideoIsDoneAndSent: true,
        });

    }
};
export const clearReuploadVideoState = (proid) => {
    // //////console.log(proid, 'map123')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        dispatch({
            type: "CLEAR_VIDEO_CALL_INIT",
            clearVideoMessage: "Request created and is processing...",
            clearVideoLoader: true,
            clearVideoIsDoneAndSent: null,
        });

        await firestore.collection('Projects').doc(proid).update({
            status: 0,
            ['isGpsEditDone']: firebase.firestore.FieldValue.delete(),
            ['videoMessage']: firebase.firestore.FieldValue.delete(),
            ['isVideoUploadingOnDB']: false,
        });

        dispatch({
            type: "CLEAR_VIDEO_CALL_COMPLETE",
            clearVideoMessage: "Request completed..",
            clearVideoLoader: false,
            clearVideoIsDoneAndSent: true,
        });

    }
};
export const removeUploadVideoErrorAndMessage = () => {
    return (dispatch, getState, { getFirebase }) => {
        // //////console.log("clear error")
        dispatch({
            type: "CLEAR_UPLOAD_ERROR_MSG"
        })
    }
}
export const uploadGps = (proid, gpsFile) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const fileFormat = gpsFile.name.split('.').pop();
        const firebase = getFirebase();
        const firestore = getFirestore();
        const fileName = `gps.${fileFormat}`;
        const reference = `Projects/${proid}/${fileName}`;

        // dispatch({ type: "PROJECT_GPS_UPLOADING" });
        dispatch({
            type: "PROJECT_GPS_UPLOAD_PROGRESS",
            progress: 0.1,
            proid,
            errorMessage: '',
            gpsApiCalling: false,
            gpsApiMessage: '',
            isLoading: true
        });

        const storageReference = storage.ref(reference);
        const uploadTask = storageReference.put(gpsFile);

        await firestore.collection('Projects').doc(proid).update({
            status: 11,
            gpsMessage: 'GPS uploading',
            isGpsUploadingOnDb: true,
        });
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot) => {
                // //////console.log(snapshot, "snapshot")
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // //////console.log('Upload is ' + progress + '% done');

                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        // //////console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        // //////console.log('Upload is running');
                        break;
                }
                return dispatch({
                    type: "PROJECT_GPS_UPLOAD_PROGRESS",
                    progress,
                    proid,
                    errorMessage: '',
                    gpsApiCalling: false,
                    gpsApiMessage: '',
                    isLoading: true
                });
            },
            async (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                }
                await firestore.collection('Projects').doc(proid).update({
                    status: -4,
                    gpsMessage: null
                });
                return dispatch({
                    type: "PROJECT_GPS_UPLOAD_PROGRESS",
                    progress: 0,
                    proid,
                    errorMessage: error,
                    gpsApiCalling: false,
                    gpsApiMessage: '',
                    isLoading: false
                });
            },
            async () => {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    // //////console.log('File available at', downloadURL);
                });
                await firestore.collection('Projects').doc(proid).update({
                    status: 4,
                });
                dispatch({
                    type: "PROJECT_GPS_UPLOAD_PROGRESS",
                    progress: 100,
                    proid,
                    errorMessage: '',
                    gpsApiCalling: true,
                    gpsApiMessage: '',
                    isLoading: true,

                });
                await cloudFunction.httpsCallable('gpsParse-convertGpsToCustomOnCall')(
                    {
                        "reference": reference,
                        "fileFormat": fileFormat,
                        "proid": proid

                    })
                    .then((res) => {
                        /// dispatch to indicate successful request
                        // //////console.log("res", res)
                        dispatch({
                            type: "PROJECT_GPS_UPLOAD_PROGRESS",
                            progress: 100,
                            proid,
                            errorMessage: '',
                            gpsApiCalling: false,
                            gpsApiMessage: res.data.responseMsg,
                            isLoading: false

                        });
                    }).catch(async (error) => {
                        console.error("error", error.message);
                        await firestore.collection('Projects').doc(proid).update({
                            status: -4,
                        });
                        dispatch({
                            type: "PROJECT_GPS_UPLOAD_PROGRESS",
                            progress: 100,
                            proid,
                            errorMessage: '',
                            gpsApiCalling: false,
                            gpsApiMessage: error.message,
                            isLoading: false,
                            gpsMessage: null

                        });
                    });

            }
        );

        // const resultDownloadUrls = `Org: ${downloadUrl}`
        return dispatch({
            type: "PROJECT_GPS_UPLOAD_PROGRESS",
            progress: 100,
            proid,
            errorMessage: '',
            gpsApiCalling: false,
            gpsApiMessage: '',
            isLoading: false

        });
    }
};
export const removeUploadGpsErrorAndMessage = () => {
    return (dispatch, getState, { getFirebase }) => {
        // //////console.log("clear  GPS error")
        dispatch({
            type: "CLEAR_GPS_ERROR_MSG"
        })
    }
}

export const addUserToNotificationDocument = (id, uid) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        await firestore.collection('Notifications').doc(id).update({
            readList: firestore.FieldValue.arrayUnion(uid),
        });

    }
}

export const generateReport = (proid) => {
    // //////console.log(proid, "data")
    //////console.log(fromFrame, toFrame, proid)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "GENERATING_REPORT",
            genReportMessage: "",
            showGenReportLoader: true,
            genReportIsDoneAndSent: false,
        });

        cloudFunction.httpsCallable('reporting-getProjectReportTriggerOnCall')(
            {
                "proid": proid,
            })
            .then((res) => {
                /// dispatch to indicate successful request
                // //////console.log(res)
                dispatch({
                    type: "GENERATED_REPORT",
                    genReportMessage: res.data.responseMsg,
                    genReportIsDoneAndSent: res.data.isDoneAndSent,
                    showGenReportLoader: false,
                });
            }).catch((error) => {
                // console.error(error);
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "GENERATED_REPORT_ERROR",
                    genReportMessage: "API failed to be called",
                    genReportIsDoneAndSent: false,
                    showGenReportLoader: false,
                });
            });
    }
}
export const generateReportDistress = (proid, selectedDefects, conditionMethod) => {
    // //////console.log(proid, selectedDefects, "data125")
    //////console.log(fromFrame, toFrame, proid)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "GENERATING_REPORT_DISTRESS",
            genReportDistressMessage: "",
            showGenReportDistressLoader: true,
            genReportDistressIsDoneAndSent: false,
            isModalClose: null
        });

        cloudFunction.httpsCallable('reporting-getProjectReportTriggerOnCall')(
            {
                "proid": proid,
                "useTestData": '1',
                "detectionTypeFilter": selectedDefects.join(),
                "conditionParameter": conditionMethod
            })
            .then((res) => {
                /// dispatch to indicate successful request
                // //////console.log(res, "results123")
                dispatch({
                    type: "GENERATED_REPORT_DISTRESS",
                    genReportDistressMessage: res.data.responseMsg,
                    genReportDistressIsDoneAndSent: res.data.isDoneAndSent,
                    showGenReportDistressLoader: false,
                    isModalClose: true
                });
            }).catch((error) => {
                console.error(error, "error");
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "GENERATED_REPORT_DISTRESS_ERROR",
                    genReportDistressMessage: "API failed to be called",
                    genReportDistressIsDoneAndSent: false,
                    showGenReportDistressLoader: false,
                    isModalClose: null
                });
            });
    }
}

export const deleteVideos = (proid, newList) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: "DELETE_VIDEOS_START",
            deleteVideosMessage: "",
            deleteVideosLoader: true,
            deleteVideosIsDoneAndSent: false,
        });

        cloudFunction.httpsCallable('project-deleteVideosFromProject')(
            {
                "proid": proid,
                "newList": newList
            })
            .then((res) => {
                dispatch({
                    type: "DELETE_VIDEOS_SUCCESS",
                    deleteVideosMessage: res.data.responseMsg,
                    deleteVideosIsDoneAndSent: res.data.isDoneAndSent,
                    deleteVideosLoader: false,
                });
            }).catch((error) => {
                console.error(error, "error");
                dispatch({
                    type: "DELETE_VIDEOS_ERROR",
                    deleteVideosMessage: "API failed to be called",
                    deleteVideosIsDoneAndSent: false,
                    deleteVideosLoader: false,
                });
            });
    }
}
export const clearDeleteVideos = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: "CLEAR_DELETE_VIDEOS",
        });
    }
}
export const resetReportDistressDialog = () => {
    //////console.log('cancer')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "RESET_REPORT_DISTRESS",
            isModalClose: null
        });
    }
}

export const moveAssetToArchive = (proid, sectionId, detectionId) => {
    // //////console.log(proid, sectionId, detectionId, "data")
    //////console.log(fromFrame, toFrame, proid)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "ASSET_ARCHIVE",
            assetArchiveMessage: "Request created and is processing...",
            showAssetArchiveLoader: true,
            assetArchiveIsDoneAndSent: false,
        });

        cloudFunction.httpsCallable('inference-archiveDetectionOnCall')(
            {
                "proid": proid,
                "sectionId": sectionId,
                "detectionId": detectionId,
                "reverse": "false"
            })
            .then((res) => {
                /// dispatch to indicate successful request
                dispatch({
                    type: "ASSET_ARCHIVE_CALL_COMPLETED",
                    assetArchiveMessage: res.data.responseMsg,
                    assetArchiveIsDoneAndSent: res.data.isDoneAndSent,
                    showAssetArchiveLoader: false,
                });
            }).catch((error) => {
                console.error(error);
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "ASSET_ARCHIVE_CALL_ERROR",
                    assetArchiveMessage: "API failed to be called",
                    assetArchiveIsDoneAndSent: false,
                    showAssetArchiveLoader: false,
                });
            });
    }
}
export const unarchiveAsset = (proid, sectionId, detectionId) => {
    // //////console.log(proid, sectionId, detectionId, "d---")
    //////console.log(fromFrame, toFrame, proid)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "ASSET_ARCHIVE",
            assetArchiveMessage: "Request created and is processing...",
            showAssetArchiveLoader: true,
            assetArchiveIsDoneAndSent: false,
        });

        cloudFunction.httpsCallable('inference-archiveDetectionOnCall')(
            {
                "proid": proid,
                "sectionId": sectionId,
                "detectionId": detectionId,
                "reverse": "true"
            })
            .then((res) => {
                /// dispatch to indicate successful request
                dispatch({
                    type: "ASSET_ARCHIVE_CALL_COMPLETED",
                    assetArchiveMessage: res.data.responseMsg,
                    assetArchiveIsDoneAndSent: res.data.isDoneAndSent,
                    showAssetArchiveLoader: false,
                });
            }).catch((error) => {
                console.error(error);
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "ASSET_ARCHIVE_CALL_ERROR",
                    assetArchiveMessage: "API failed to be called",
                    assetArchiveIsDoneAndSent: false,
                    showAssetArchiveLoader: false,
                });
            });
    }
}
export const moveSafetyFeatureToArchive = (proid, sectionId, detectionId) => {
    //////console.log(fromFrame, toFrame, proid)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "FEATURE_ARCHIVE",
            featureArchiveMessage: "Request created and is processing...",
            showFeatureArchiveLoader: true,
            featureArchiveIsDoneAndSent: false,
        });

        cloudFunction.httpsCallable('safety-archiveDetection')(
            {
                "proid": proid,
                "sectionId": sectionId,
                "detectionId": detectionId,
                "reverse": "false"
            })
            .then((res) => {
                /// dispatch to indicate successful request
                dispatch({
                    type: "FEATURE_ARCHIVE_CALL_COMPLETED",
                    featureArchiveMessage: res.data.responseMsg,
                    featureArchiveIsDoneAndSent: res.data.isDoneAndSent,
                    showFeatureArchiveLoader: false,
                });
            }).catch((error) => {
                console.error(error);
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "FEATURE_ARCHIVE_CALL_ERROR",
                    featureArchiveMessage: "API failed to be called",
                    featureArchiveIsDoneAndSent: false,
                    showFeatureArchiveLoader: false,
                });
            });
    }
}
export const clearArchiveErrorAndMsg = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: "CLEAR_ARCHIVE_ERROR_AND_MSG"
        });
    }
}
export const moveBasicImagingFeatureToArchive = (proid, sectionId, detectionId) => {
    console.log(proid, sectionId, detectionId, "data archive")
    //////console.log(fromFrame, toFrame, proid)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "BASIC_FEATURE_ARCHIVE",
            basicFeatureArchiveMessage: "Request created and is processing...",
            showBasicFeatureArchiveLoader: true,
            basicFeatureArchiveIsDoneAndSent: null,
        });

        cloudFunction.httpsCallable('basicImaging-archiveDetection')(
            {
                "proid": proid,
                "sectionId": sectionId,
                "detectionId": detectionId,
                "reverse": false
            })
            .then((res) => {
                console.log(res, "archive");
                //////console.log(res, "res")
                /// dispatch to indicate successful request
                dispatch({
                    type: "BASIC_FEATURE_ARCHIVE_CALL_COMPLETED",
                    basicFeatureArchiveMessage: res.data.responseMsg,
                    basicFeatureArchiveIsDoneAndSent: res.data.isDoneAndSent,
                    showBasicFeatureArchiveLoader: false,
                });
            }).catch((error) => {
                console.error(error);
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "BASIC_FEATURE_ARCHIVE_CALL_ERROR",
                    basicFeatureArchiveMessage: "API failed to be called",
                    basicFeatureArchiveIsDoneAndSent: false,
                    showBasicFeatureArchiveLoader: false,
                });
            });
    }
}
export const unarchiveBasicImagingFeature = (proid, sectionId, detectionId) => {
    //////console.log(fromFrame, toFrame, proid)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "BASIC_FEATURE_UNARCHIVE",
            basicFeatureUnArchiveMessage: "Request created and is processing...",
            showBasicFeatureUnArchiveLoader: true,
            basicFeatureUnArchiveIsDoneAndSent: null,
        });

        cloudFunction.httpsCallable('basicImaging-archiveDetection')(
            {
                "proid": proid,
                "sectionId": sectionId,
                "detectionId": detectionId,
                "reverse": true
            })
            .then((res) => {
                //////console.log(res, "res")
                /// dispatch to indicate successful request
                dispatch({
                    type: "BASIC_FEATURE_UNARCHIVE_CALL_COMPLETED",
                    basicFeatureUnArchiveMessage: res.data.responseMsg,
                    basicFeatureUnArchiveIsDoneAndSent: res.data.isDoneAndSent,
                    showBasicFeatureUnArchiveLoader: false,
                });
            }).catch((error) => {
                console.error(error);
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "BASIC_FEATURE_UNARCHIVE_CALL_ERROR",
                    basicFeatureUnArchiveMessage: "API failed to be called",
                    basicFeatureUnArchiveIsDoneAndSent: false,
                    showBasicFeatureUnArchiveLoader: false,
                });
            });
    }
}
export const unarchiveSafetyFeature = (proid, sectionId, detectionId) => {
    //////console.log(fromFrame, toFrame, proid)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "FEATURE_ARCHIVE",
            featureArchiveMessage: "Request created and is processing...",
            showFeatureArchiveLoader: true,
            featureArchiveIsDoneAndSent: null,
        });

        cloudFunction.httpsCallable('safety-archiveDetection')(
            {
                "proid": proid,
                "sectionId": sectionId,
                "detectionId": detectionId,
                "reverse": "true"
            })
            .then((res) => {
                /// dispatch to indicate successful request
                dispatch({
                    type: "FEATURE_ARCHIVE_CALL_COMPLETED",
                    featureArchiveMessage: res.data.responseMsg,
                    featureArchiveIsDoneAndSent: res.data.isDoneAndSent,
                    showFeatureArchiveLoader: false,
                });
            }).catch((error) => {
                console.error(error);
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "FEATURE_ARCHIVE_CALL_ERROR",
                    featureArchiveMessage: "API failed to be called",
                    featureArchiveIsDoneAndSent: false,
                    showFeatureArchiveLoader: false,
                });
            });
    }
}
export const basicImagingConfirmValidityProject = (proid) => {
    //////console.log(fromFrame, toFrame, proid)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "BASIC_CONFIRM_VALIDITY_PROJECT",
            basicConfirmValidityProjectMessage: "Request created and is processing...",
            showBasicConfirmValidityProjectLoader: true,
            basicConfirmValidityProjectIsDoneAndSent: null,
        });

        cloudFunction.httpsCallable('basicImaging-confirmValidityProject')(
            {
                "proid": proid,
            })
            .then((res) => {
                //////console.log(res, "res")
                /// dispatch to indicate successful request
                dispatch({
                    type: "BASIC_CONFIRM_VALIDITY_PROJECT_COMPLETED",
                    basicConfirmValidityProjectMessage: res.data.responseMsg,
                    basicConfirmValidityProjectIsDoneAndSent: res.data.isDoneAndSent,
                    showBasicConfirmValidityProjectLoader: false,
                });
            }).catch((error) => {
                console.error(error);
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "BASIC_CONFIRM_VALIDITY_PROJECT_ERROR",
                    basicConfirmValidityProjectMessage: "API failed to be called",
                    basicConfirmValidityProjectIsDoneAndSent: false,
                    showBasicConfirmValidityProjectLoader: false,
                });
            });
    }
}
export const clearValidConfirmErrorAndMsg = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: "BASIC_VALID_CONFIRM",
        });
    }
}
export const basicImagingValidConfirm = (proid, sectionId, detectionId) => {
    //////console.log(fromFrame, toFrame, proid)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "BASIC_VALID_CONFIRM",
            basicValidConfirmMessage: "Request created and is processing...",
            showBasicValidConfirmLoader: true,
            basicValidConfirmIsDoneAndSent: null,
        });

        cloudFunction.httpsCallable('basicImaging-makeValidAndConfirm')(
            {
                "proid": proid,
                "sectionId": sectionId,
                "detectionId": detectionId,
            })
            .then((res) => {
                //////console.log(res, "res")
                /// dispatch to indicate successful request
                dispatch({
                    type: "BASIC_VALID_CONFIRM_COMPLETED",
                    basicValidConfirmMessage: res.data.responseMsg,
                    basicValidConfirmIsDoneAndSent: res.data.isDoneAndSent,
                    showBasicValidConfirmLoader: false,
                });
            }).catch((error) => {
                console.error(error);
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "BASIC_VALID_CONFIRM_ERROR",
                    basicValidConfirmMessage: "API failed to be called",
                    basicValidConfirmIsDoneAndSent: false,
                    showBasicValidConfirmLoader: false,
                });
            });
    }
}
export const clearUpdateErrorAndMsg = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: "CLEAR_UPDATE_DEFECT",
        });
    }
}
export const updateBasicImagingDefect = (proid, sectionId, detectionId, priority, defectType, validity, bbox) => {
    //////console.log(fromFrame, toFrame, proid)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "UPDATE_DEFECT",
            updateDefectMessage: "Request created and is processing...",
            showUpdateDefectLoader: true,
            updateDefectDoneAndSent: null,
        });

        cloudFunction.httpsCallable('basicImaging-updateDefect')(
            {
                "proid": proid,
                "sectionId": sectionId,
                "detectionId": detectionId,
                "priority": priority,
                "defectType": defectType,
                "validity": validity,
                "bbox": bbox
            })
            .then((res) => {
                console.log(res, "res")
                //////console.log(res, "res")
                /// dispatch to indicate successful request
                dispatch({
                    type: "UPDATE_DEFECT_COMPLETED",
                    updateDefectMessage: res.data.responseMsg,
                    updateDefectDoneAndSent: res.data.isDoneAndSent,
                    showUpdateDefectLoader: false,
                });
            }).catch((error) => {
                console.error(error);
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "UPDATE_DEFECT_ERROR",
                    updateDefectMessage: "API failed to be called",
                    updateDefectDoneAndSent: false,
                    showUpdateDefectLoader: false,
                });
            });
    }
}
export const clearAddDefectErrorAndMsg = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: "CLEAR_ADD_DEFECT_ERROR_AND_MSG",
        });
    }
}
export const addBasicImagingDefect = (proid, sectionId, priority, defectType, bbox, gps, frameNumber) => {
    //////console.log(proid, sectionId, priority, defectType, bbox, gps, frameNumber, "addBasicImagingDefect")
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "ADD_DEFECT",
            addDefectMessage: "",
            showAddDefectLoader: true,
            addDefectDoneAndSent: null,
        });

        cloudFunction.httpsCallable('basicImaging-addDefect')(
            {
                "proid": proid,
                "sectionId": sectionId,
                "priority": priority,
                "defectType": defectType,
                "bbox": bbox,
                "gps": gps,
                "frameNumber": frameNumber,
            })
            .then((res) => {
                //////console.log(res, "res")
                /// dispatch to indicate successful request
                dispatch({
                    type: "ADD_DEFECT_COMPLETED",
                    addDefectMessage: res.data.responseMsg,
                    addDefectDoneAndSent: res.data.isDoneAndSent,
                    showAddDefectLoader: false,
                });
            }).catch((error) => {
                console.error(error);
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "ADD_DEFECT_ERROR",
                    addDefectMessage: "API failed to be called",
                    addDefectDoneAndSent: false,
                    showAddDefectLoader: false,
                });
            });
    }
}

export const safetyConfirmDelta = (proid, sectionId, detectionId) => {
    //////console.log(fromFrame, toFrame, proid)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "CONFIRM_DELTA",
            confirmDeltaMessage: "Request created and is processing...",
            showConfirmDeltaLoader: true,
            confirmDeltaIsDoneAndSent: false,
        });

        cloudFunction.httpsCallable('safety-confirmDelta')(
            {
                "proid": proid,
                "sectionId": sectionId,
                "detectionId": detectionId
            })
            .then((res) => {
                // //////console.log(res, "response", proid, sectionId, detectionId)
                /// dispatch to indicate successful request
                dispatch({
                    type: "CONFIRM_DELTA_CALL_COMPLETED",
                    confirmDeltaMessage: res.data.responseMsg,
                    confirmDeltaIsDoneAndSent: res.data.isDoneAndSent,
                    showConfirmDeltaLoader: false,
                });
            }).catch((error) => {
                console.error(error);
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "CONFIRM_DELTA_CALL_ERROR",
                    confirmDeltaMessage: "API failed to be called",
                    confirmDeltaIsDoneAndSent: false,
                    showConfirmDeltaLoader: false,
                });
            });
    }
}

export const getDownSamplingData = (proid) => {
    //////console.log(proid, "finalSectionId");
    //////console.log(fromFrame, toFrame, proid)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "DOWNSAMPLING_CALL_START",
            downSamplingMessage: "Request created and is processing...",
            showDownSamplingLoader: true,
            downSamplingIsDoneAndSent: false,
            downSamplingData: null,
        });

        cloudFunction.httpsCallable('inference-getDownsamplingStartEndIndexOnCall')(
            {
                "proid": proid
            })
            .then((res) => {
                // //////console.log(res, "response")
                /// dispatch to indicate successful request
                dispatch({
                    type: "DOWNSAMPLING_CALL_COMPLETED",
                    downSamplingData: res.data,
                    downSamplingMessage: res.data.responseMsg,
                    downSamplingIsDoneAndSent: res.data.isDoneAndSent,
                    showDownSamplingLoader: false,
                });
            }).catch((error) => {
                console.error(error);
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "DOWNSAMPLING_CALL_ERROR",
                    downSamplingMessage: "API failed to be called",
                    downSamplingIsDoneAndSent: false,
                    showDownSamplingLoader: false,
                    downSamplingData: null
                });
            });
    }
}
export const resetArchiveFeature = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "RESET_ARCHIVE_FEATURE",
        });
    }
}

export const resetAsset = (proid, sectionId, detectionId) => {
    //////console.log(proid, sectionId, detectionId)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "RESET_ASSET",
            resetAssetArchiveMessage: "Request created and is processing...",
            showResetAssetArchiveLoader: true,
            resetAssetArchiveIsDoneAndSent: false,
        });

        cloudFunction.httpsCallable('inference-deleteVersioningOnCall')(
            {
                "proid": proid,
                "sectionId": sectionId,
                "detectionId": detectionId,
            })
            .then((res) => {
                /// dispatch to indicate successful request
                dispatch({
                    type: "RESET_ASSET_CALL_COMPLETED",
                    resetAssetArchiveMessage: res.data.responseMsg,
                    resetAssetArchiveIsDoneAndSent: res.data.isDoneAndSent,
                    showResetAssetArchiveLoader: false,
                });
            }).catch((error) => {
                console.error(error);
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "RESET_ASSET_CALL_ERROR",
                    resetAssetArchiveMessage: "API failed to be called",
                    resetAssetArchiveIsDoneAndSent: false,
                    showResetAssetArchiveLoader: false,
                });
            });
    }
}

export const getIndividualDetection = (proid, outputId) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "INDIVIDUAL_DETECTION",
            individualDetectionMessage: "Fetching data",
            showindividualDetectionLoader: true,
            individualDetectionIsDoneAndSent: null,
            individualDetectionData: null,
        });

        cloudFunction.httpsCallable('inference-getIndividualDetectionOnCall')(
            {
                "proid": proid,
                "outputId": outputId,
            })
            .then((res) => {
                /// dispatch to indicate successful request
                //////console.log(res, "getIndividualDetectionOnCall-res")
                dispatch({
                    type: "INDIVIDUAL_DETECTION_CALL_COMPLETED",
                    individualDetectionMessage: res.data.responseMsg,
                    individualDetectionIsDoneAndSent: res.data.isDoneAndSent,
                    showindividualDetectionLoader: false,
                    individualDetectionData: res.data.responseData,
                });
            }).catch((error) => {
                console.error(error);
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "INDIVIDUAL_DETECTION_CALL_ERROR",
                    individualDetectionMessage: "API failed to be called",
                    individualDetectionIsDoneAndSent: false,
                    showindividualDetectionLoader: false,
                    individualDetectionData: null
                });
            });
    }
}
export const removeGetIndividualDetectionErrorAndMessage = () => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({
            type: "CLEAR_INDIVIDUAL_DETECTION_ERROR_MSG"
        })
    }
}
export const updateAsset = (proid, sectionId, detectionId, roadSide, assetId) => {
    //////console.log(proid, sectionId, detectionId, roadSide, assetId, "data")
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "UPDATE_ASSET",
            updateAssetArchiveMessage: "Request created and is processing...",
            showUpdateAssetArchiveLoader: true,
            updateAssetArchiveIsDoneAndSent: false,
        });
        //////console.log("Function chala !")
        cloudFunction.httpsCallable('inference-versioningDetectionOnCall')(
            {
                "proid": proid,
                "sectionId": sectionId,
                "detectionId": detectionId,
                "roadSide": roadSide,
                "assetId": assetId
            })
            .then((res) => {
                //////console.log(res, "res")
                /// dispatch to indicate successful request
                dispatch({
                    type: "UPDATE_ASSET_CALL_COMPLETED",
                    updateAssetArchiveMessage: res.data.responseMsg,
                    updateAssetArchiveIsDoneAndSent: res.data.isDoneAndSent,
                    showUpdateAssetArchiveLoader: false,
                });
            }).catch((error) => {
                console.error(error, 'eroor');
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "UPDATE_ASSET_CALL_ERROR",
                    updateAssetArchiveMessage: "API failed to be called",
                    updateAssetArchiveIsDoneAndSent: false,
                    showUpdateAssetArchiveLoader: false,
                });
            });
    }
}

export const updateDistress = (proid, sectionId, detectionId, bbox, defectClass, defectProperties) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "UPDATE_DISTRESS",
            updateDistressArchiveMessage: "Request created and is processing...",
            showUpdateDistressArchiveLoader: true,
            updateDistressArchiveIsDoneAndSent: false,
            isEditModalClose: null
        });

        cloudFunction.httpsCallable('distress-distressEditingOnCall')(
            {
                "proid": proid,
                "sectionId": sectionId,
                "detectionId": detectionId,
                "bbox": bbox,
                "defectClass": defectClass,
                "defectProperties": defectProperties
            })
            .then((res) => {
                //////console.log(res, "distress update api response");
                /// dispatch to indicate successful request
                dispatch({
                    type: "UPDATE_DISTRESS_CALL_COMPLETED",
                    updateDistressArchiveMessage: res.data.responseMsg,
                    updateDistressArchiveIsDoneAndSent: res.data.isDoneAndSent,
                    showUpdateDistressArchiveLoader: false,
                    isEditModalClose: true
                });
            }).catch((error) => {
                console.error(error, 'distress update api eroor');
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "UPDATE_DISTRESS_CALL_ERROR",
                    updateDistressArchiveMessage: "API failed to be called",
                    updateDistressArchiveIsDoneAndSent: false,
                    showUpdateDistressArchiveLoader: false,
                    isEditModalClose: null
                });
            });
    }
}

export const resetEditDistressDialog = () => {
    //////console.log('cancer')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "RESET_EDIT_DISTRESS",
            isEditModalClose: null
        });
    }
}

export const addDistress = (proid, sectionId, bbox, defectClass, transversePosition, defectProperties) => {
    //////console.log(proid, sectionId, bbox, defectClass, transversePosition, defectProperties, 'chal jaao')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "ADD_DISTRESS",
            addDistressArchiveMessage: "Request created and is processing...",
            showAddDistressArchiveLoader: true,
            addDistressArchiveIsDoneAndSent: false,
            isAddModalClose: null
        });

        //////console.log('working')


        cloudFunction.httpsCallable('distress-distressAddingOnCall')(
            {
                "proid": proid,
                "sectionId": sectionId,
                "bbox": bbox,
                "defectClass": defectClass,
                "transversePosition": transversePosition,
                "defectProperties": defectProperties
            })
            .then((res) => {
                //////console.log(res, "add api response");
                /// dispatch to indicate successful request
                dispatch({
                    type: "ADD_DISTRESS_CALL_COMPLETED",
                    addDistressArchiveMessage: res.data.responseMsg,
                    addDistressArchiveIsDoneAndSent: res.data.isDoneAndSent,
                    showAddDistressArchiveLoader: false,
                    isAddModalClose: true
                });
            }).catch((error) => {
                console.error(error, 'api error');
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "ADD_DISTRESS_CALL_ERROR",
                    addDistressArchiveMessage: "API failed to be called",
                    addDistressArchiveIsDoneAndSent: false,
                    showAddDistressArchiveLoader: false,
                    isAddModalClose: null
                });
            });
    }
}
export const addDistressV2 = (proid, sectionId, bbox, defectType, frameNumber, gps, type) => {
    console.log(proid, sectionId, bbox, defectType, frameNumber, gps, type, "data add")
    //////console.log(proid, sectionId, bbox, defectClass, transversePosition, defectProperties, 'chal jaao')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "ADD_DISTRESS",
            addDistressArchiveMessage: "Request created and is processing...",
            showAddDistressArchiveLoader: true,
            addDistressArchiveIsDoneAndSent: null,
            isAddModalClose: null
        });
        await axios.post('https://basic-distress-helper-76531320480.us-central1.run.app/addDistress', {
            "proid": proid,
            "sectionId": sectionId,
            "bbox": bbox,
            "defectType": Number(defectType),
            "frameNumber": frameNumber,
            "gpsLat": gps.gpsLat,
            "gpsLng": gps.gpsLng,
            "type": type,
        })
            .then((res) => {
                console.log(res, "add res")
                //////console.log(res, "add api response");
                /// dispatch to indicate successful request
                dispatch({
                    type: "ADD_DISTRESS_CALL_COMPLETED",
                    addDistressArchiveMessage: res.data.responseMsg,
                    addDistressArchiveIsDoneAndSent: res.data.isDoneAndSent,
                    showAddDistressArchiveLoader: false,
                    isAddModalClose: true
                });
            }).catch((error) => {
                console.error(error, 'api error');
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "ADD_DISTRESS_CALL_ERROR",
                    addDistressArchiveMessage: "API failed to be called",
                    addDistressArchiveIsDoneAndSent: false,
                    showAddDistressArchiveLoader: false,
                    isAddModalClose: null
                });
            });
    }
}
export const updateDistressV2 = (proid, sectionId, bbox, defectType, frameNumber, gps, type, defectId) => {
    console.log(proid, sectionId, bbox, defectType, frameNumber, gps.gpsLat, gps.gpsLng, type, defectId, "update data")
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "UPDATE_DISTRESS",
            updateDistressArchiveMessage: "Request created and is processing...",
            showUpdateDistressArchiveLoader: true,
            updateDistressArchiveIsDoneAndSent: null,
            isEditModalClose: null
        });
        await axios.post('https://basic-distress-helper-76531320480.us-central1.run.app/updateDistress', {
            "proid": proid,
            "sectionId": sectionId,
            "bbox": bbox,
            "defectType": Number(defectType),
            "frameNumber": frameNumber,
            "gpsLat": gps.gpsLat,
            "gpsLng": gps.gpsLng,
            "type": type,
            "defectId": defectId,
        })
            .then((res) => {
                console.log(res, "res", res.data)
                //////console.log(res, "distress update api response");
                /// dispatch to indicate successful request
                dispatch({
                    type: "UPDATE_DISTRESS_CALL_COMPLETED",
                    updateDistressArchiveMessage: res.data.responseMsg,
                    updateDistressArchiveIsDoneAndSent: res.data.isDoneAndSent,
                    showUpdateDistressArchiveLoader: false,
                    isEditModalClose: true
                });
            }).catch((error) => {
                console.error(error, 'distress update api eroor');
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "UPDATE_DISTRESS_CALL_ERROR",
                    updateDistressArchiveMessage: "API failed to be called",
                    updateDistressArchiveIsDoneAndSent: false,
                    showUpdateDistressArchiveLoader: false,
                    isEditModalClose: null
                });
            });
    }
}

export const resetAddDistressDialog = () => {
    //////console.log('cancer')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "RESET_ADD_DISTRESS",
            isAddModalClose: null
        });
    }
}

export const moveDistressToArchive = (proid, sectionId, detectionId) => {
    //////console.log(proid, sectionId, detectionId, "data567")
    //////console.log(fromFrame, toFrame, proid)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "DISTRESS_ARCHIVE",
            distressArchiveMessage: "Request created and is processing...",
            showDistressArchiveLoader: true,
            distressArchiveIsDoneAndSent: false,
            isArchiveModalClose: null
        });

        cloudFunction.httpsCallable('distress-archiveDistressOnCall')(
            {
                "proid": proid,
                "sectionId": sectionId,
                "detectionId": detectionId,
                "reverse": "false"
            })
            .then((res) => {
                //////console.log(res, 'output')
                /// dispatch to indicate successful request
                dispatch({
                    type: "DISTRESS_ARCHIVE_CALL_COMPLETED",
                    distressArchiveMessage: res.data.responseMsg,
                    distressArchiveIsDoneAndSent: res.data.isDoneAndSent,
                    showDistressArchiveLoader: false,
                    isArchiveModalClose: true
                });
            }).catch((error) => {
                console.error(error);
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "DISTRESS_ARCHIVE_CALL_ERROR",
                    distressArchiveMessage: "API failed to be called",
                    distressArchiveIsDoneAndSent: false,
                    showDistressArchiveLoader: false,
                    isArchiveModalClose: null
                });
            });
    }
}

export const resetArchiveDistressDialog = () => {
    //////console.log('cancer')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "RESET_ARCHIVE_DISTRESS",
            isArchiveModalClose: null
        });
    }
}

export const removeDistressErrorAndMessage = () => {
    return (dispatch, getState, { getFirebase }) => {
        //////console.log("clear  GPS error")
        dispatch({
            type: "CLEAR_DISTRESS_ERROR_MSG"
        })
    }
}
export const unarchiveDistress = (proid, sectionId, detectionId) => {
    //////console.log(proid, sectionId, detectionId, "d---")
    //////console.log(fromFrame, toFrame, proid)
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "DISTRESS_ARCHIVE",
            distressArchiveMessage: "Request created and is processing...",
            showDistressArchiveLoader: true,
            distressArchiveIsDoneAndSent: false,
        });

        cloudFunction.httpsCallable('distress-archiveDistressOnCall')(
            {
                "proid": proid,
                "sectionId": sectionId,
                "detectionId": detectionId,
                "reverse": "true"
            })
            .then((res) => {
                /// dispatch to indicate successful request
                dispatch({
                    type: "DISTRESS_ARCHIVE_CALL_COMPLETED",
                    distressArchiveMessage: res.data.responseMsg,
                    distressArchiveIsDoneAndSent: res.data.isDoneAndSent,
                    showDistressArchiveLoader: false,
                });
            }).catch((error) => {
                console.error(error);
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "DISTRESS_ARCHIVE_CALL_ERROR",
                    distressArchiveMessage: "API failed to be called",
                    distressArchiveIsDoneAndSent: false,
                    showDistressArchiveLoader: false,
                });
            });
    }
}

export const addNewAsset = (proid, sectionId, archived, assetId, bbox, frameNumber, frameTime, geo, geoTime, isGPS, roadSide) => {
    //    //////console.log(proid, sectionId, archived, assetId, bbox, frameNumber, frameTime, geo, geoTime, isGPS, roadSide)
    // //////console.log("Function chalna start huwa!")
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        // //////console.log("Function chal pra ha!")
        dispatch({
            type: "ADD_NEW_ASSET",
            addNewAssetMessage: "Request created and is processing...",
            showAddNewAssetLoader: true,
            addNewAssetIsDoneAndSent: true,
        });
        // //////console.log("Function chala")
        cloudFunction.httpsCallable('inference-addNewAssetOnCall')(
            {
                "proid": proid,
                "sectionId": sectionId,
                "archived": archived,
                "assetId": assetId,
                "bbox": bbox,
                "frameNumber": frameNumber,
                "frameTime": frameTime,
                "geo": geo,
                "geoTime": geoTime,
                "isGPS": isGPS,
                "roadSide": roadSide
            })
            .then((res) => {
                // //////console.log(res, "responsexxx")
                /// dispatch to indicate successful request
                dispatch({
                    type: "ADD_NEW_ASSET_CALL_COMPLETED",
                    addNewAssetMessage: res.data.responseMsg,
                    addNewAssetIsDoneAndSent: res.data.isDoneAndSent,
                    showAddNewAssetLoader: false,
                });
            }).catch((error) => {
                console.error(error, "errorxxx");
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "ADD_NEW_ASSET_CALL_ERROR",
                    addNewAssetMessage: "API failed to be called",
                    addNewAssetIsDoneAndSent: false,
                    showAddNewAssetLoader: false,
                });
            });
    }
}

export const addFeature = (proid, sectionId, id, categoryInput, condition, comments, panAngle, tiltAngle, depthAngle, zoomLevel) => {
    //////console.log(proid, sectionId, id, categoryInput, condition, comments, panAngle, tiltAngle, depthAngle, zoomLevel, 'chalo')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "ADD_FEATURE",
            addFeatureArchiveMessage: "Request created and is processing...",
            showAddFeatureArchiveLoader: true,
            addFeatureArchiveIsDoneAndSent: false,
            isAddFeatureModalClose: null
        });

        cloudFunction.httpsCallable('feature-featureAddingOnCall')(
            {
                "proid": proid,
                "markerId": id,
                "sectionId": sectionId,
                "featureCategory": categoryInput,
                "condition": condition,
                "freeText": comments,
                "tilt": tiltAngle,
                "pan": panAngle,
                "depth": depthAngle,
                "zoom": zoomLevel
            })
            .then((res) => {
                //////console.log(res, "add api response");
                /// dispatch to indicate successful request
                dispatch({
                    type: "ADD_FEATURE_CALL_COMPLETED",
                    addFeatureArchiveMessage: res.data.responseMsg,
                    addFeatureArchiveIsDoneAndSent: res.data.isDoneAndSent,
                    showAddFeatureArchiveLoader: false,
                    isAddFeatureModalClose: true
                });
            }).catch((error) => {
                console.error(error, 'api error');
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "ADD_FEATURE_CALL_ERROR",
                    addFeatureArchiveMessage: "API failed to be called",
                    addFeatureArchiveIsDoneAndSent: false,
                    showAddFeatureArchiveLoader: false,
                    isAddFeatureModalClose: null
                });
            });
    }
}

export const editFeature = (proid, sectionId, id, editingFeature, panAngle, tiltAngle, depthAngle, zoomLevel) => {
    //////console.log(proid, sectionId, id, editingFeature.id, editingFeature.featureCategory, editingFeature.condition, editingFeature.freeText, panAngle, tiltAngle, depthAngle, zoomLevel, 'chalo')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "EDIT_FEATURE",
            editFeatureArchiveMessage: "Request created and is processing...",
            showEditFeatureArchiveLoader: true,
            editFeatureArchiveIsDoneAndSent: false,
            isEditFeatureModalClose: null
        });

        cloudFunction.httpsCallable('feature-featureEditOnCall')(
            {
                "proid": proid,
                "markerId": id,
                "sectionId": sectionId,
                "featureId": editingFeature.id,
                "featureCategory": editingFeature.featureCategory,
                "condition": editingFeature.condition,
                "freeText": editingFeature.freeText,
                "tilt": tiltAngle,
                "pan": panAngle,
                "depth": depthAngle,
                "zoom": zoomLevel
            })
            .then((res) => {
                //////console.log(res, "edit api response");
                /// dispatch to indicate successful request
                dispatch({
                    type: "EDIT_FEATURE_CALL_COMPLETED",
                    editFeatureArchiveMessage: res.data.responseMsg,
                    editFeatureArchiveIsDoneAndSent: res.data.isDoneAndSent,
                    showEditFeatureArchiveLoader: false,
                    isEditFeatureModalClose: true
                });
            }).catch((error) => {
                console.error(error, 'api error');
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "EDIT_FEATURE_CALL_ERROR",
                    editFeatureArchiveMessage: "API failed to be called",
                    editFeatureArchiveIsDoneAndSent: false,
                    showEditFeatureArchiveLoader: false,
                    isEditFeatureModalClose: null
                });
            });
    }
}

export const archiveFeature = (proid, sectionId, id, archivingFeature) => {
    //////console.log(proid, sectionId, id, archivingFeature, 'chalo')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "ARCHIVE_FEATURE",
            archiveFeatureArchiveMessage: "Request created and is processing...",
            showArchiveFeatureArchiveLoader: true,
            archiveFeatureArchiveIsDoneAndSent: false,
            isArchiveFeatureModalClose: null
        });
        cloudFunction.httpsCallable('feature-featureArchiveOnCall')(
            {
                "proid": proid,
                "sectionId": sectionId,
                "markerId": id,
                "featureId": archivingFeature,
                "reverse": "false"
            })
            .then((res) => {
                //////console.log(res, "archive api response");
                /// dispatch to indicate successful request
                dispatch({
                    type: "ARCHIVE_FEATURE_CALL_COMPLETED",
                    archiveFeatureArchiveMessage: res.data.responseMsg,
                    archiveFeatureArchiveIsDoneAndSent: res.data.isDoneAndSent,
                    showArchiveFeatureArchiveLoader: false,
                    isArchiveFeatureModalClose: true
                });
            }).catch((error) => {
                console.error(error, 'api error');
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "ARCHIVE_FEATURE_CALL_ERROR",
                    archiveFeatureArchiveMessage: "API failed to be called",
                    archiveFeatureArchiveIsDoneAndSent: false,
                    showArchiveFeatureArchiveLoader: false,
                    isArchiveFeatureModalClose: null
                });
            });
    }
}

export const unarchiveFeature = (proid, sectionId, id, featureData) => {
    //////console.log(proid, sectionId, id, featureData, 'chalo')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "UNARCHIVE_FEATURE",
            unarchiveFeatureArchiveMessage: "Request created and is processing...",
            showUnarchiveFeatureArchiveLoader: true,
            unarchiveFeatureArchiveIsDoneAndSent: false,
            isUnarchiveFeatureModalClose: null
        });
        cloudFunction.httpsCallable('feature-featureArchiveOnCall')(
            {
                "proid": proid,
                "sectionId": sectionId,
                "markerId": id,
                "featureId": featureData,
                "reverse": "true"
            })
            .then((res) => {
                //////console.log(res, "unarchive api response");
                /// dispatch to indicate successful request
                dispatch({
                    type: "UNARCHIVE_FEATURE_CALL_COMPLETED",
                    unarchiveFeatureArchiveMessage: res.data.responseMsg,
                    unarchiveFeatureArchiveIsDoneAndSent: res.data.isDoneAndSent,
                    showUnarchiveFeatureArchiveLoader: false,
                    isUnarchiveFeatureModalClose: true
                });
            }).catch((error) => {
                console.error(error, 'api error');
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "UNARCHIVE_FEATURE_CALL_ERROR",
                    unarchiveFeatureArchiveMessage: "API failed to be called",
                    unarchiveFeatureArchiveIsDoneAndSent: false,
                    showUnarchiveFeatureArchiveLoader: false,
                    isUnarchiveFeatureModalClose: null
                });
            });
    }
}

export const addCategory = (proid, textInput) => {
    //////console.log(proid, textInput, 'simson')
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "ADD_CATEGORY",
            addCategoryArchiveMessage: "Request created and is processing...",
            showAddCategoryArchiveLoader: true,
            addCategoryArchiveIsDoneAndSent: false,
            isAddCategoryModalClose: null
        });

        //////console.log('working')
        cloudFunction.httpsCallable('category-categoryAddingOnCall')(
            {
                "proid": proid,
                "name": textInput,
            })
            .then((res) => {
                //////console.log(res, "add api response");
                /// dispatch to indicate successful request
                dispatch({
                    type: "ADD_CATEGORY_CALL_COMPLETED",
                    addCategoryArchiveMessage: res.data.responseMsg,
                    addCategoryArchiveIsDoneAndSent: res.data.isDoneAndSent,
                    showAddCategoryArchiveLoader: false,
                    isAddCategoryModalClose: true
                });
            }).catch((error) => {
                console.error(error, 'api error');
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "ADD_CATEGORY_CALL_ERROR",
                    addCategoryArchiveMessage: "API failed to be called",
                    addCategoryArchiveIsDoneAndSent: false,
                    showAddCategoryArchiveLoader: false,
                    isAddCategoryModalClose: null
                });
            });
    }
}

export const removeAddNewAssetErrorAndMessage = () => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({
            type: "CLEAR_ADD_NEW_ASSET_ERROR_MSG"
        })
    }
}

export const videoEstimationCost = (proid) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "VIDEO_ESTIMATION_COST_REQUEST",
            videoEstimationMessage: "",
            videoEstimationLoader: true,
            videoEstimationIsDoneAndSent: null,
        });
        //////console.log("Function chala !")


        cloudFunction.httpsCallable('project-performCreditCalculations')(
            {
                "proid": proid,
            })
            .then((res) => {
                //////console.log(res, "res")
                /// dispatch to indicate successful request
                dispatch({
                    type: "VIDEO_ESTIMATION_COST_COMPLETED",
                    videoEstimationMessage: res.data.responseMsg,
                    videoEstimationIsDoneAndSent: res.data.isDoneAndSent,
                    videoEstimationLoader: false,
                });
            }).catch((error) => {
                console.error(error, 'eroor');
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "VIDEO_ESTIMATION_COST_ERROR",
                    videoEstimationMessage: "API failed to be called",
                    videoEstimationIsDoneAndSent: false,
                    videoEstimationLoader: false,
                });
            });
    }
}
export const removeVideoEstimationErrorAndMessage = () => {
    return (dispatch, getState, { getFirebase }) => {
        // //////console.log("clear  GPS error")
        dispatch({
            type: "CLEAR_VIDEO_ESTIMATION_ERROR_MSG"
        })
    }
}

export const routeCalculation = (coordinatesList) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "ROUTE_CALCULATION_REQUEST_START",
            routeCalculationData: null,
            routeCalculationMessage: "",
            routeCalculationLoader: true,
            routeCalculationIsDoneAndSent: null,
        });
        cloudFunction.httpsCallable('routePlanner-routeDifferentCoordinates')(
            {
                "coordinates": coordinatesList,
            })
            .then((res) => {
                //////console.log(res.data, "responsse")
                /// dispatch to indicate successful request
                dispatch({
                    type: "ROUTE_CALCULATION_REQUEST_COMPLETED",
                    routeCalculationData: res.data.routePlannerData,
                    routeCalculationMessage: res.data.responseMsg,
                    routeCalculationIsDoneAndSent: res.data.isDoneAndSent,
                    routeCalculationLoader: false,
                });
            }).catch((error) => {
                console.error(error, 'eroor');
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "ROUTE_CALCULATION_REQUEST_ERROR",
                    routeCalculationData: null,
                    routeCalculationMessage: "API failed to be called",
                    routeCalculationIsDoneAndSent: false,
                    routeCalculationLoader: false,
                });
            });
    }
}
export const deleteRoute = (coid, id) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        dispatch({
            type: "DELETE_ROUTE_START",
            deleteRouteLoader: true
        })
        setTimeout(async () => {
            await firestore.collection(`Companies/${coid}/RoutePlanner`).doc(id).delete();
            return dispatch({
                type: "DELETE_ROUTE_COMPLETE",
                deleteRouteLoader: false,
            });
        }, 1000);
    };
}
export const removeRouteCalculationErrorAndMessage = () => {
    return (dispatch, getState, { getFirebase }) => {
        //////console.log("clear  GPS error")
        dispatch({
            type: "CLEAR_ROUTE_CALCULATION_ERROR_MSG"
        })
    }
}
export const saveRouteCalculationToDb = (routePlannerData, coid, title) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "SAVE_ROUTE_CALCULATION_TO_DB_REQUEST_START",
            saveRouteCalculationToDbMessage: "",
            saveRouteCalculationToDbLoader: true,
            saveRouteCalculationToDbIsDoneAndSent: null,
        });
        cloudFunction.httpsCallable('routePlanner-routePlannerDataSaveV2')(
            {
                "routePlannerData": routePlannerData,
                "coid": coid,
                "title": title
            })
            .then((res) => {
                // //////console.log(res.data, "responsse-saveRouteCalculationToDb")
                /// dispatch to indicate successful request
                dispatch({
                    type: "SAVE_ROUTE_CALCULATION_TO_DB_REQUEST_COMPLETED",
                    saveRouteCalculationToDbMessage: res.data.responseMsg,
                    saveRouteCalculationToDbIsDoneAndSent: res.data.isDoneAndSent,
                    saveRouteCalculationToDbLoader: false,
                });
            }).catch((error) => {
                // console.error(error, 'eroor');
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "SAVE_ROUTE_CALCULATION_TO_DB_REQUEST_ERROR",
                    saveRouteCalculationToDbMessage: "API failed to be called",
                    saveRouteCalculationToDbIsDoneAndSent: false,
                    saveRouteCalculationToDbLoader: false,
                });
            });
    }
}
export const rerunRouteCalculationToDb = (routePlannerId, coid) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "RERUN_ROUTE_CALCULATION_TO_DB_REQUEST_START",
            rerunRouteCalculationToDbMessage: "",
            rerunRouteCalculationToDbLoader: true,
            rerunRouteCalculationToDbIsDoneAndSent: null,
        });
        cloudFunction.httpsCallable('routePlanner-rePlannerV2')(
            {
                "routePlannerId": routePlannerId,
                "coid": coid
            })
            .then((res) => {
                // //////console.log(res.data, "responsse-saveRouteCalculationToDb")
                /// dispatch to indicate successful request
                dispatch({
                    type: "RERUN_ROUTE_CALCULATION_TO_DB_REQUEST_COMPLETED",
                    rerunRouteCalculationToDbMessage: res.data.responseMsg,
                    rerunRouteCalculationToDbIsDoneAndSent: res.data.isDoneAndSent,
                    rerunRouteCalculationToDbLoader: false,
                });
            }).catch((error) => {
                // console.error(error, 'eroor');
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "RERUN_ROUTE_CALCULATION_TO_DB_REQUEST_ERROR",
                    rerunRouteCalculationToDbMessage: "API failed to be called",
                    rerunRouteCalculationToDbIsDoneAndSent: false,
                    rerunRouteCalculationToDbLoader: false,
                });
            });
    }
}
export const removeRerunRouteCalculationErrorAndMessage = () => {
    return (dispatch, getState, { getFirebase }) => {
        //////console.log("clear  GPS error")
        dispatch({
            type: "CLEAR_RERUN_ROUTE_CALCULATION_ERROR_MSG"
        })
    }
}
export const convertEncodedGeometry = (encodedGeometry) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "CONVERT_ENCODED_GEOMETRY_REQUEST_START",
            convertedCoordinatesData: null,
            convertEncodedGeometryMessage: "",
            convertEncodedGeometryLoader: true,
            convertEncodedGeometryIsDoneAndSent: null,
        });
        cloudFunction.httpsCallable('routePlanner-geometryCoversion')(
            {
                "encodedGeometry": encodedGeometry,

            })
            .then((res) => {
                // //////console.log(res.data, "responsse")
                /// dispatch to indicate successful request
                dispatch({
                    type: "CONVERT_ENCODED_GEOMETRY_REQUEST_COMPLETED",
                    convertedCoordinatesData: res.data.coordinates,
                    convertEncodedGeometryMessage: res.data.responseMsg,
                    convertEncodedGeometryIsDoneAndSent: res.data.isDoneAndSent,
                    convertEncodedGeometryLoader: false,
                });
            }).catch((error) => {
                // console.error(error, 'error');
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "CONVERT_ENCODED_GEOMETRY_REQUEST_ERROR",
                    convertedCoordinatesData: null,
                    convertEncodedGeometryMessage: "API failed to be called",
                    convertEncodedGeometryIsDoneAndSent: false,
                    convertEncodedGeometryLoader: false,
                });
            });
    }
}
export const depthCrossSectionalProfile = (points, proid, sectionId) => {
    // //////console.log(points, proid, sectionId, "DEPTH_CROSS_data")
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "DEPTH_CROSS_SECTIONAL_PROFILE_START",
            depthCrossSectionalProfileMessage: "",
            depthCrossSectionalProfileLoader: true,
            depthCrossSectionalProfileIsDoneAndSent: null,
            depthCrossSectionalData: null,
        });
        cloudFunction.httpsCallable('distress-crossSectionalPlottingOnCall')(
            {
                "proid": proid,
                "sectionId": sectionId,
                "coordinates": JSON.stringify(points),
            })
            .then((res) => {
                // //////console.log(res.data, "DEPTH_CROSS_responsse")
                /// dispatch to indicate successful request
                dispatch({
                    type: "DEPTH_CROSS_SECTIONAL_PROFILE_COMPLETED",
                    depthCrossSectionalProfileMessage: res.data.responseMsg,
                    depthCrossSectionalProfileIsDoneAndSent: res.data.isDoneAndSent,
                    depthCrossSectionalProfileLoader: false,
                    depthCrossSectionalData: res.data.responseData
                });
            }).catch((error) => {
                console.error(error, 'eroor');
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "DEPTH_CROSS_SECTIONAL_PROFILE_ERROR",
                    depthCrossSectionalProfileMessage: "API failed to be called",
                    depthCrossSectionalProfileIsDoneAndSent: false,
                    depthCrossSectionalProfileLoader: false,
                    depthCrossSectionalData: null,
                });
            });
    }
}
export const saveDepthCrossSectionalPoints = (points, proid, sectionId) => {
    // //////console.log(points, proid, sectionId, "saveDepthCrossSectionalPoints")
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "SAVE_DEPTH_CROSS_SECTIONAL_POINTS_START",
            saveDepthCrossSectionalPointsMessage: "",
            saveDepthCrossSectionalPointsLoader: true,
            saveDepthCrossSectionalPointsIsDoneAndSent: null,
        });
        cloudFunction.httpsCallable('distress-crossSectionalParameters')(
            {
                "proid": proid,
                "sectionId": sectionId,
                "coordinates": JSON.stringify(points),
            })
            .then((res) => {
                // //////console.log(res.data, "responsse saveDepthCrossSectionalPoints")
                /// dispatch to indicate successful request
                dispatch({
                    type: "SAVE_DEPTH_CROSS_SECTIONAL_POINTS_COMPLETED",
                    saveDepthCrossSectionalPointsMessage: res.data.responseMsg,
                    saveDepthCrossSectionalPointsIsDoneAndSent: res.data.isDoneAndSent,
                    saveDepthCrossSectionalPointsLoader: false,
                });
            }).catch((error) => {
                console.error(error, 'eroor');
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "SAVE_DEPTH_CROSS_SECTIONAL_POINTS_ERROR",
                    saveDepthCrossSectionalPointsMessage: "API failed to be called",
                    saveDepthCrossSectionalPointsIsDoneAndSent: false,
                    saveDepthCrossSectionalPointsLoader: false,
                });
            });
    }
}
export const mirrorProject = (proid, email) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "MIRROR_PROJECT_REQUEST_START",
            mirrorProjectMessage: "",
            mirrorProjectLoader: true,
            mirrorProjectIsDoneAndSent: null,
        });
        // project-forkFreshProjectOnCall
        cloudFunction.httpsCallable('project-forkFreshProjectToUserOnCall')(
            {
                "fromProid": proid,
                "email": email
            })
            .then((res) => {
                // //////console.log(res, "mirror project api response");
                dispatch({
                    type: "MIRROR_PROJECT_REQUEST_COMPLETED",
                    mirrorProjectMessage: res.data.responseMsg,
                    mirrorProjectIsDoneAndSent: res.data.isDoneAndSent,
                    mirrorProjectLoader: false,
                });
            }).catch((error) => {
                console.error(error, 'mirror project api error');
                dispatch({
                    type: "MIRROR_PROJECT_REQUEST_ERROR",
                    mirrorProjectMessage: "API failed to be called",
                    mirrorProjectIsDoneAndSent: false,
                    mirrorProjectLoader: null,
                });
            });
    }
}

export const resetMirrorProjectErrOrMsg = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: "RESET_MIRROR_PROJECT",
        });
    }
}

export const saveMirrorProjectEmail = (uid, email) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        await firestore
            .collection("Users")
            .doc(uid)
            .update({
                userEmailList: firestore.FieldValue.arrayUnion(`${email}`),
            });
    }
}
export const assignedFeatures = (didList, uid, code, deadline) => {
    //////console.log(didList, uid, code, deadline, "assignedFeatures")
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "ASSIGNED_FEATURE_REQUEST_START",
            assignedFeatureMessage: "",
            assignedFeatureLoader: true,
            assignedFeatureIsDoneAndSent: null,
        });
        cloudFunction.httpsCallable('globalDefects-assignDefect')(
            {
                "didList": JSON.stringify(didList),
                "uid": uid,
                "code": code,
                "deadline": deadline
            })
            .then((res) => {
                //////console.log(res.data, "responsse")
                /// dispatch to indicate successful request
                dispatch({
                    type: "ASSIGNED_FEATURE_REQUEST_COMPLETED",
                    assignedFeatureMessage: res.data.responseMsg,
                    assignedFeatureIsDoneAndSent: res.data.isDoneAndSent,
                    assignedFeatureLoader: false,
                });
            }).catch((error) => {
                console.error(error, 'eroor');
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "ASSIGNED_FEATURE_REQUEST_ERROR",
                    assignedFeatureMessage: "API failed to be called",
                    assignedFeatureIsDoneAndSent: false,
                    assignedFeatureLoader: false,
                });
            });
    }
}
export const clearAssignedFeaturesState = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: "CLEAR_ASSIGNED_FEATURE",
        });
    }
}

export const clearApproveRejectState = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: "CLEAR_APPROVE_REJECT_FEATURE",
        });
    }
}
export const approveRejectFeatures = (didList, approval) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "APPROVE_REJECT_FEATURE_REQUEST_START",
            approveRejectMessage: "",
            approveRejectLoader: true,
            approveRejectIsDoneAndSent: null,
        });
        cloudFunction.httpsCallable('globalDefects-approveRejectMulti')(
            {
                "didList": JSON.stringify(didList),
                "approval": approval
            })
            .then((res) => {
                //////console.log(res.data, "responsse")
                /// dispatch to indicate successful request
                dispatch({
                    type: "APPROVE_REJECT_REQUEST_COMPLETED",
                    approveRejectMessage: res.data.responseMsg,
                    approveRejectIsDoneAndSent: res.data.isDoneAndSent,
                    approveRejectLoader: false,
                });
            }).catch((error) => {
                console.error(error, 'eroor');
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "APPROVE_REJECT_REQUEST_ERROR",
                    approveRejectMessage: "API failed to be called",
                    approveRejectIsDoneAndSent: false,
                    approveRejectLoader: false,
                });
            });
    }
}
export const archiveBasicImagingFeature = (did, archive) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        /// dispatch to indicate initiation of request
        dispatch({
            type: "ARCHIVE_FEATURE_REQUEST_START",
            archiveFeatureMessage: "",
            archiveFeatureLoader: true,
            archiveFeatureIsDoneAndSent: null,
        });
        cloudFunction.httpsCallable('globalDefects-archiveUnarchiveSingle')(
            {
                "did": did,
                "archive": archive
            })
            .then((res) => {
                //////console.log(res.data, "responsse")
                /// dispatch to indicate successful request
                dispatch({
                    type: "ARCHIVE_FEATURE_REQUEST_COMPLETED",
                    archiveFeatureMessage: res.data.responseMsg,
                    archiveFeatureIsDoneAndSent: res.data.isDoneAndSent,
                    archiveFeatureLoader: false,
                });
            }).catch((error) => {
                console.error(error, 'eroor');
                /// dispatch to indicate unsuccessful request
                dispatch({
                    type: "ARCHIVE_FEATURE_REQUEST_ERROR",
                    archiveFeatureMessage: "API failed to be called",
                    archiveFeatureIsDoneAndSent: false,
                    archiveFeatureLoader: false,
                });
            });
    }
}
export const clearBasicImagingArchiveState = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: "CLEAR_ARCHIVE_FEATURE",
        });
    }
}

export const pciCalculation = (proid, weightages, bais) => {
    return async (dispatch) => {

        dispatch({
            type: "PCI_CALC_START",
            pciCalcMessage: "",
            pciCalcLoader: true,
            pciCalcIsDoneAndSent: null,
        });

        cloudFunction.httpsCallable('cvCalcHelper-requestPciCalculation')(
            {
                "proid": proid,
                "weightages": JSON.stringify(weightages),
                "bais": JSON.stringify(bais)
            })
            .then((res) => {
                //console.log(res, "res")
                dispatch({
                    type: "PCI_CALC_COMPLETED",
                    pciCalcMessage: res.data.responseMsg,
                    pciCalcIsDoneAndSent: res.data.isDoneAndSent,
                    pciCalcLoader: false,
                });
            }).catch((error) => {
                console.error(error);
                dispatch({
                    type: "PCI_CALC_ERROR",
                    pciCalcMessage: "API failed to be called",
                    pciCalcIsDoneAndSent: false,
                    pciCalcLoader: false,
                });
            });
    }
}

export const clearPciCalcState = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: "CLEAR_PCI_CALC",
        });
    }
}