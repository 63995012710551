import { Grid, MenuItem, Paper, TextField, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'

const DistressFieldsEdit = (props) => {
    const fieldStyles = {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        "borderRadius": "4px",
        "padding": '10px 8px',
        "fontSize": '0.95rem',
        "textTransform": 'capitalize',
        "overflow": 'auto',
        // "backgroundColor": '#f5f5f5',
        "color": '#ccc',

    };
    const { distressPinned, dataInfo, distressData, fieldValues, setFieldValues, drProjectData } = props;
    // //////console.log(distressPinned, "distressPinnedxxx")
    useEffect(() => {
        setFieldValues({
            defectNameState: distressPinned?.defectName ?? -1,
            transversePositionState: distressPinned?.transversePosition ?? -1,
            gpsCoordinatesState: `${distressPinned?.geo[0]},${distressPinned?.geo[1]}` ?? -1,
            typeState: distressPinned?.type ?? -1,
            severityState: distressPinned?.severity ?? -1,
            frameNumberState: distressPinned?.frameNumber ?? -1,
            areaState: distressPinned?.area ?? -1,
            depthState: distressPinned?.depth ?? -1,
            transverseSpanState: distressPinned?.transverseSpan ?? -1,
            longitudinalSpanState: distressPinned?.longitudinalSpan ?? -1,
            profileState: distressPinned?.profile ?? -1,
            widthState: distressPinned?.width ?? -1,
            wheelPathState: distressPinned?.wheelPath ?? -1,
        });

    }, [distressPinned]);

    const handleChange = (prop) => (event) => {
        setFieldValues({ ...fieldValues, [prop]: event.target.value });
    };



    const Item = styled(Paper)(({ theme }) => ({
        boxShadow: '0px 0px transparent',
        width: '100%',
        backgroundColor: 'transparent',
        ...theme.typography.body2,
        padding: theme.spacing(0.5),
    }));
    const fieldTextStyle = {
        fontSize: '15px',
        fontWeight: 'bold',
    };
    // 0"Pothole"
    // 1:"Crack"
    // 2:"Subsidence"
    // 3:"Ravelling"
    // 4:"Rutting"
    // 5:"Patch"
    // 6:"Manhole"
    // 7:"Bleeding"
    // 8:"Depression"
    // 9:"Corrugation/Shoving"
    // 10: "Polished aggregate"
    // 11: "Water bleeding and pumping"
    const editableDefectNameList = drProjectData.projectType === 4 ? ['0', '1', '5', '12', '13'] : ['0', '1', '3', '5', '10', '12', '13'];
    const showAreaFieldList = ['0', '1', '2', '5', '10', '12', '13'];
    const showDepthFieldList = ['0', '4'];
    const showTransverseSpanFieldList = ['1', '2', '3', '5', '10'];
    const showLongitudinalSpanFieldList = ['1', '2', '3', '5', '10'];
    const showTypeFieldList = ['1'];
    const showSeverityFieldList = ['0', '1', '3', '5', '10', '12', '13'];
    const showProfileFieldList = ['2'];
    const showWidthFieldList = ['4'];
    const showWheelPathFieldList = ['4'];
    const showNegativeStatusFieldList = ['-1'];
    const defectNamesMap = {
        "map": dataInfo['defectName'],
        "keys": Object.keys(dataInfo['defectName']),
        "values": Object.values(dataInfo['defectName']),
        "usefulKeys": ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'],
    }
    const defectNamesList = defectNamesMap.keys.filter(e => defectNamesMap.usefulKeys.includes(e)).map((e, i) => {
        if (editableDefectNameList.includes(e)) {
            return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
                {`${defectNamesMap.values[i]['name']}`}
            </MenuItem>
        }
    })
    const handleClick = (list) => {
        // //////console.log(list, "list");
        const key = Object.keys(defectNamesMap.map).find((k) => defectNamesMap.map[k] === list);
        // //////console.log('Clicked value:', list);
        // //////console.log('Associated key:', key);
    }
    const transversePositionMap = {
        "map": dataInfo['transversePosition'],
        "keys": Object.keys(dataInfo['transversePosition']),
        "values": Object.values(dataInfo['transversePosition']),
        "usefulKeys": ['0', '1', '2', '3', '4', '5', '12', '13'],
    }
    const transversePositionList = transversePositionMap.keys.filter(e => transversePositionMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${transversePositionMap.values[i]['name']}`}
        </MenuItem>
    })
    const typeMap = {
        "map": dataInfo['type'],
        "keys": Object.keys(dataInfo['type']),
        "values": Object.values(dataInfo['type']),
        "usefulKeys": ['0', '1', '2', '3', '4', '5', '6', '998'],
    }
    const typeList = typeMap.keys.filter(e => typeMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${typeMap.values[i]['name']}`}
        </MenuItem>
    })
    const severityMap = {
        "map": dataInfo['severity'],
        "keys": Object.keys(dataInfo['severity']),
        "values": Object.values(dataInfo['severity']),
        "usefulKeys": ['-1','0', '1', '2'],
    }
    const severityList = severityMap.keys.filter(e => severityMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${severityMap.values[i]['name']}`}
        </MenuItem>
    })
    const profileMap = {
        "map": dataInfo['profile'],
        "keys": Object.keys(dataInfo['profile']),
        "values": Object.values(dataInfo['profile']),
        "usefulKeys": ['0', '1', '2'],
    }
    const profileList = profileMap.keys.filter(e => profileMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${profileMap.values[i]['name']}`}
        </MenuItem>
    })
    const wheelPathMap = {
        "map": dataInfo['wheelPath'],
        "keys": Object.keys(dataInfo['wheelPath']),
        "values": Object.values(dataInfo['wheelPath']),
        "usefulKeys": ['0', '1'],
    }
    const wheelPathList = wheelPathMap.keys.filter(e => wheelPathMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${wheelPathMap.values[i]['name']}`}
        </MenuItem>
    })
    return (
        <Grid container>
            <Grid item xs={6} >
                {/* <div style={{ backgroundColor: 'transparent', overflow: 'auto' }}> */}

                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body1" sx={fieldTextStyle}>
                                Defect category:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                id="defect name"
                                select
                                value={fieldValues.defectNameState}
                                onChange={handleChange("defectNameState")}
                                // onClick={() => { handleClick(fieldValues.defectNameState) }}
                                size="small"
                                sx={{ textTransform: 'capitalize', width: '100%' }}
                            >
                                {defectNamesList}
                            </TextField>
                        </Grid>
                    </Grid>
                </Item>
                {showAreaFieldList.includes(`${fieldValues.defectNameState}`) && !showNegativeStatusFieldList.includes(`${fieldValues.areaState}`) ?
                    <Item>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                            }} >
                                <Typography variant="body1" sx={fieldTextStyle}>
                                    Area (m<sup>2</sup>):
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                {/* <TextField
                                    // className={fieldStyle.inputField}
                                    disabled
                                    id="outlined-size-small"
                                    value={Number(fieldValues.areaState).toFixed(2)}
                                    onChange={handleChange("areaState")}
                                    size="small"
                                /> */}
                                <div style={fieldStyles}>
                                    <Typography variant="inherit">
                                        {Number(fieldValues.areaState).toFixed(2)}
                                    </Typography>
                                </div>

                            </Grid>
                        </Grid>
                    </Item> : <></>}
                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body1" sx={fieldTextStyle}>
                                Transverse position:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                id="transverse position"
                                select
                                value={fieldValues.transversePositionState}
                                onChange={handleChange("transversePositionState")}
                                size="small"
                                sx={{ textTransform: 'capitalize', width: '100%' }}
                                disabled={true}
                            >
                                {transversePositionList}
                            </TextField>
                        </Grid>
                    </Grid>
                </Item>
                {showTypeFieldList.includes(`${fieldValues.defectNameState}`) ? <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body1" sx={fieldTextStyle}>
                                Type:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                id="type"
                                select
                                value={fieldValues.typeState}
                                onChange={handleChange("typeState")}
                                size="small"
                                sx={{ textTransform: 'capitalize', width: '100%' }}
                            >
                                {typeList}
                            </TextField>
                        </Grid>
                    </Grid>
                </Item> : <></>}
                {showSeverityFieldList.includes(`${fieldValues.defectNameState}`) ? <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                            sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                            }} >
                            <Typography variant="body1" sx={fieldTextStyle}>
                                Severity:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                            <TextField
                                id="severity"
                                select
                                value={`${fieldValues.severityState}`}
                                onChange={handleChange("severityState")}
                                size="small"
                                sx={{ textTransform: 'capitalize', width: '100%' }}
                            >
                                {severityList}
                            </TextField>
                        </Grid>
                    </Grid>
                </Item> : <></>}
                {showProfileFieldList.includes(`${fieldValues.defectNameState}`) ? <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                            sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                            }} >
                            <Typography variant="body1" sx={fieldTextStyle}>
                                Profile:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                            <TextField
                                id="profile"
                                select
                                value={fieldValues.profileState}
                                onChange={handleChange("profileState")}
                                size="small"
                                sx={{ textTransform: 'capitalize', width: '100%' }}
                            >
                                {profileList}
                            </TextField>
                        </Grid>
                    </Grid>
                </Item> : <></>}
                {showWidthFieldList.includes(`${fieldValues.defectNameState}`) && !showNegativeStatusFieldList.includes(`${fieldValues.widthState}`) ? <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                            sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                            }} >
                            <Typography variant="body1" sx={fieldTextStyle}>
                                Width:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                            {/* <TextField
                                disabled
                                id="outlined-size-small"
                                value={Number(fieldValues.widthState).toFixed(2)}
                                onChange={handleChange("widthState")}
                                size="small"
                            /> */}
                            <div style={fieldStyles}>
                                <Typography variant="inherit" >
                                    {Number(fieldValues.widthState).toFixed(2)}
                                </Typography>
                            </div>

                        </Grid>
                    </Grid>
                </Item> : <></>}
                {/* </div> */}
            </Grid>

            {/* second section */}
            <Grid item xs={6} >
                {/* <div style={{ backgroundColor: 'transparent', overflow: 'auto' }}> */}
                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }} >
                            <Typography variant="body1" sx={fieldTextStyle}>
                                GPS location:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >

                            {/* <TextField
                                // className={fieldStyle.inputField}
                                disabled
                                id="outlined-size-small"
                                value={fieldValues.gpsCoordinatesState}
                                onChange={handleChange("gpsCoordinatesState")}
                                size="small"
                                variant="outlined"
                            /> */}
                            <div style={fieldStyles}>
                                <Typography variant="inherit">
                                    {fieldValues.gpsCoordinatesState}
                                </Typography>
                            </div>


                        </Grid>
                    </Grid>
                </Item>
                {showTransverseSpanFieldList.includes(`${fieldValues.defectNameState}`) && !showNegativeStatusFieldList.includes(`${fieldValues.transverseSpanState}`) ? <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }} >
                            <Typography variant="body1" sx={fieldTextStyle}>
                                Transverse span:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            {/* <TextField
                                disabled
                                id="outlined-size-small"
                                value={Number(fieldValues.transverseSpanState).toFixed(2)}
                                onChange={handleChange("transverseSpanState")}
                                size="small"
                            /> */}
                            <div style={fieldStyles}>
                                <Typography variant="inherit">
                                    {Number(fieldValues.transverseSpanState).toFixed(2)}
                                </Typography>
                            </div>

                        </Grid>
                    </Grid>
                </Item> : <></>}
                {showLongitudinalSpanFieldList.includes(`${fieldValues.defectNameState}`) && !showNegativeStatusFieldList.includes(`${fieldValues.longitudinalSpanState}`) ? <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }} >
                            <Typography variant="body1" sx={fieldTextStyle}>
                                Longitudinal span:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            {/* <TextField
                                disabled
                                id="outlined-size-small"
                                value={Number(fieldValues.longitudinalSpanState).toFixed(2)}
                                onChange={handleChange("longitudinalSpanState")}
                                size="small"
                            /> */}
                            <div style={fieldStyles}>
                                <Typography variant="inherit" >
                                    {Number(fieldValues.longitudinalSpanState).toFixed(2)}
                                </Typography>
                            </div>

                        </Grid>
                    </Grid>
                </Item> : <></>}
                {showDepthFieldList.includes(`${fieldValues.defectNameState}`) && !showNegativeStatusFieldList.includes(`${fieldValues.depthState}`) ? <Item>
                    {
                        drProjectData.projectType === 4 ?
                            <></>
                            :
                            <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }} >
                                    <Typography variant="body1" sx={fieldTextStyle}>
                                        Depth (mm):
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                                    {/* <TextField
                                disabled
                                id="outlined-size-small"
                                value={Number(fieldValues.depthState * 1000).toFixed(2)}
                                onChange={handleChange("depthState")}
                                size="small"
                            /> */}
                                    <div style={fieldStyles}>
                                        <Typography variant="inherit" >
                                            {Number(fieldValues.depthState * 1000).toFixed(2)}
                                        </Typography>
                                    </div>

                                </Grid>
                            </Grid>
                    }
                </Item> : <></>}
                {showWheelPathFieldList.includes(`${fieldValues.defectNameState}`) && !showNegativeStatusFieldList.includes(`${fieldValues.wheelPathState}`) ? <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }} >
                            <Typography variant="body1" sx={fieldTextStyle}>
                                Wheel path:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                            {/* <TextField
                                disabled
                                id="outlined-size-small"
                                value={Number(fieldValues.wheelPathState).toFixed(2)}
                                onChange={handleChange("wheelPathState")}
                                size="small"
                            /> */}
                            {/* <div style={fieldStyles}>
                                <Typography variant="inherit" >
                                    {Number(fieldValues.wheelPathState).toFixed(2)}
                                </Typography>
                            </div> */}

                            <TextField
                                id="wheel path"
                                select
                                value={fieldValues.wheelPathState}
                                onChange={handleChange("wheelPathState")}
                                size="small"
                                sx={{ textTransform: 'capitalize', width: '100%' }}
                            >
                                {wheelPathList}
                            </TextField>

                        </Grid>
                    </Grid>
                </Item> : <></>}
                {/* </div> */}
            </Grid>
        </Grid>
    )
}

export default DistressFieldsEdit