import React, { useState } from 'react'
import { Box } from "@mui/material";
import Grid from '@mui/material/Grid';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Snackbar, Toolbar, Tooltip, Typography } from "@mui/material";
import { Dialog } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import Card from '@mui/material/Card';
import { Divider } from "@mui/material";
import AssetPredictionEditor from './AssetPredictionEditor';
import { useRef, useEffect } from 'react';
import { useKeyPress } from '../../../../../../chooks/useKeyPress';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import IosShareIcon from '@mui/icons-material/IosShare';
import { useAccessControlContext } from '../../../../../../contexts/AccessControlProvider';

const PinnedAsset = (props) => {
    const { permissions } = useAccessControlContext();
    const { canCopyDefectLinkAsset, canEditDefectAsset } = permissions;
    const { previousAsset, assetIndex, nextAsset, timeList,
        handleClickOpenDialog, openDialog, handleCloseDialog, handleQuality,
        qualities, proid, handlePinned, fps, realFps, link, pinned, gpsData, gpsDataCombined, classesMap, isTraficSignClass, roadSideMap, imageBase64, cropBase64, playerRef,
        originalVideoHeight, originalVideoWidth } = props;
    // //////console.log(pinned,"pinned")
    const [open, setOpen] = useState(false)

    const handleShareClick = (outputId) => {
        setOpen(true)
        navigator.clipboard.writeText(`${window.location.href}/${outputId}`)
    }
    const NEXT = useKeyPress('ArrowRight')
    const PREV = useKeyPress('ArrowLeft')

    useEffect(() => {
        if (NEXT) {
            if (!(assetIndex === timeList.length - 1)) {
                nextAsset()
            }
        } else if (PREV) {
            if (!(assetIndex === 0)) {
                previousAsset()
            }
        }
    }, [NEXT, PREV])

    const cardLightStyle = {
        "border": "0px solid grey",
        "borderRadius": "8px",
        "padding": "5px",
        "backgroundColor": '#f5f1f1'
    }
    const fieldStyle = {
        "border": "2px solid grey",
        "borderRadius": "5px",
        "padding": '5px',
        "fontSize": '0.8rem',
        "textTransform": 'capitalize',
        "overflow": 'auto',
    }
    const Item = styled(Paper)(({ theme }) => ({
        boxShadow: '0px 0px transparent',
        width: '100%',
        backgroundColor: 'transparent',
        ...theme.typography.body2,
        padding: theme.spacing(0.5),
    }));

    const ref = useRef(null)
    const imageHeight = ref?.current?.clientHeight ?? 0
    // //////console.log(pinned, "pinned")
    const outputId = `${pinned.pred.sectionId}-${pinned.pred.id}`;
    // //////console.log(outputId, "outputId")
    return (
        <>
            <Snackbar
                open={open}
                onClose={() => setOpen(false)}
                autoHideDuration={2000}
                message="Copied the link to clipboard"
            />
            <div ref={ref} id='table-pinned' style={{ height: `100%`, backgroundColor: 'transparent' }}>

                <Box sx={{ paddingInline: '15px', height: { xs: '40%', md: '20%' }, backgroundColor: 'transparent', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <Grid container>
                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6} sx={{
                            display: 'flex', justifyContent: 'start', alignItems: 'center'
                        }} >
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
                                <IconButton onClick={previousAsset} disabled={assetIndex === 0 ? true : false}>
                                    <Tooltip title="Previous asset"><KeyboardArrowLeftIcon sx={{ color: assetIndex === 0 ? 'grey' : '#1E1E1E' }} /></Tooltip>
                                </IconButton>
                                <Typography variant="h6" component="span" sx={{ fontWeight: 'bold', fontSize: { xs: '10px', sm: '10px', md: '16px' }, padding: `0px ${15 + 10}px 0px ${15 + 10}px` }}>Asset {assetIndex + 1} / {timeList.length}</Typography>

                                <IconButton onClick={nextAsset} disabled={assetIndex === timeList.length - 1 ? true : false}>
                                    <Tooltip title="Next asset"><KeyboardArrowRightIcon sx={{ color: assetIndex === timeList.length - 1 ? 'grey' : '#1E1E1E' }} /></Tooltip>
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid item xs={8} sm={8} md={6} lg={6} xl={6} sx={{
                            display: 'flex', justifyContent: 'end', alignItems: 'center'
                        }}>
                            {/* <Typography
                                variant="body1"
                                onClick={() => { handleShareClick(outputId) }}
                                sx={{
                                    cursor: "pointer",
                                    // textDecoration: "underline",
                                    color: "black",
                                    marginRight: '20px'

                                }}
                            >
                                Share asset
                            </Typography> */}
                            {canEditDefectAsset ? <Typography
                                variant="body1"
                                onClick={handleClickOpenDialog}
                                sx={{
                                    cursor: "pointer",
                                    textDecoration: "none",
                                    color: "#000000",
                                    marginRight: '20px'

                                }}
                            >
                                Edit this asset
                            </Typography> : <></>}

                            <Dialog fullScreen open={openDialog} onClose={handleCloseDialog}>
                                <AssetPredictionEditor
                                    originalVideoHeight={originalVideoHeight}
                                    originalVideoWidth={originalVideoWidth}
                                    setQualities={handleQuality}
                                    qualities={qualities}
                                    proid={proid}
                                    handlePinned={handlePinned}
                                    fps={fps} realFps={realFps}
                                    link={link} pinned={pinned} handleCloseDialog={handleCloseDialog}
                                    dataList={timeList} gpsData={gpsData} gpsDataCombined={gpsDataCombined} />
                            </Dialog>
                            {canCopyDefectLinkAsset ? <Tooltip title='Copy the link of this asset'>
                                <LinkIcon
                                    sx={{
                                        color: "#1E1E1E",
                                        marginInline: "25px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => { handleShareClick(outputId) }}
                                />
                            </Tooltip> : <></>}
                            <Tooltip title='Unpin this asset'>
                                <ClearIcon
                                    sx={{
                                        color: "#1E1E1E",
                                        // marginLeft: "20px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => { handlePinned(pinned) }}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>

                <div style={{ marginLeft: '15px', marginRight: '15px', height: '5%', backgroundColor: 'transparent', display: 'flex', justifyContent: 'start', alignItems: 'start' }}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <Box sx={{ width: "100%" }}>
                                <Divider light />
                            </Box>
                        </Grid>
                    </Grid>
                </div>

                <Box sx={{ paddingInline: '15px', height: { xs: '50%', md: '70%' }, width: '100%', backgroundColor: 'transparent', display: 'flex', justifyContent: 'start', alignItems: 'start' }}>

                    <div style={{ backgroundColor: 'transparent', float: 'left', width: '60%', height: '100%' }}>
                        <Card sx={{ ...cardLightStyle, height: '87%', width: '100%', overflow: 'auto' }} elevation={0}>
                            <div style={{ padding: '5px', height: '100%', overflow: 'auto', backgroundColor: 'transparent' }}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                        <div style={{ backgroundColor: 'transparent', overflow: 'auto' }}>
                                            <Item>
                                                <Grid container>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                                        display: "flex",
                                                        justifyContent: "start",
                                                        alignItems: "center",
                                                    }} >
                                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                            Asset category:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <Card sx={fieldStyle}>
                                                            <Typography variant="inherit">
                                                                {`${classesMap.map[pinned.meta?.class]}`.replace('_', ' ')}
                                                            </Typography>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            {isTraficSignClass ?
                                                <Item>
                                                    <Grid container>
                                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "start",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                                Asset name:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                                                            <Card sx={{ ...fieldStyle, overflow: 'auto' }}>
                                                                <Typography variant="inherit">
                                                                    {pinned.meta?.name.length === 0 ? "unnamed" : pinned.meta?.name}
                                                                </Typography>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                                </Item>
                                                : <></>
                                            }


                                            <Item>
                                                <Grid container>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "start",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                            {isTraficSignClass ? 'Recognition' : 'Detection'} quality:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                                                        <Card sx={fieldStyle}>
                                                            <Typography variant="inherit">
                                                                {isTraficSignClass ? pinned.pred.recognitionScore === '10000.00' ? '100' : pinned.pred.recognitionScore : pinned.pred.detectionScore}
                                                            </Typography>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </Item>

                                            <Item>
                                                <Grid container>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "start",
                                                            alignItems: "center",
                                                        }} >
                                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                            GPS location:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                                                        <Card sx={fieldStyle}>
                                                            <Typography variant="inherit">
                                                                {pinned.pred.geo[0]},{pinned.pred.geo[1]}
                                                            </Typography>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                            <Item>
                                                <Grid container>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "start",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                            Road side:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                                                        <Card sx={fieldStyle}>
                                                            <Typography variant="inherit">
                                                                {roadSideMap.map[pinned.pred.roadSide]}
                                                            </Typography>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Card>
                    </div>
                    {/* </Card> */}
                    <div style={{ overflow: 'auto', backgroundColor: 'transparent', float: 'left', height: '100%', width: '40%', display: 'flex', alignItems: 'start', justifyContent: 'center' }}>

                        <div style={{ width: '100%', display: 'table', paddingLeft: '10px' }}>
                            <div style={{ display: 'table-row', height: `calc(${imageHeight * 0.4}px)` }}>
                                <Tooltip title="Original image" arrow>
                                    <div style={{ width: '50%', display: 'table-cell', background: 'transparent', paddingRight: '5px' }}>
                                        <img style={{ width: '100%', height: `calc(${imageHeight * 0.4}px)`, objectFit: 'contain' }} src={cropBase64} />
                                    </div>
                                </Tooltip>
                                {imageBase64 ?
                                    <Tooltip title="Recognition" arrow>
                                        <div style={{ display: 'table-cell', background: 'transparent' }}>
                                            <img style={{ width: '100%', height: `calc(${imageHeight * 0.4}px)`, paddingLeft: '5px', objectFit: 'contain' }} src={'data:image/png;base64,' + imageBase64 ?? ''} />
                                        </div>
                                    </Tooltip>
                                    :
                                    <React.Fragment />}
                            </div>
                        </div>

                    </div>
                </Box>
            </div>
        </>
    )
}
export default PinnedAsset
