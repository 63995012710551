import React, { useEffect, useState, useRef } from "react"
import './table.css'
import { Card, Checkbox } from '@mui/material'
import CustomTooltip from '../../../../../cidgets/CustomTooltip';

const PotholeTable = ({ defectImageURLs, basicImagingData,
    playerRef, dataList = [], assetData = [], handlePinned, pinned, disableHeader = false }) => {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [videoPlayerState, setVideoPlayerState] = useState(null);
    const tableRef = useRef(null);
    const { defectNames, dataInfo } = basicImagingData;
    const { typeIndex } = dataInfo;
    const style = {
        "paddingLeft": "15px",
        "paddingRight": "15px",
        "width": "100%",
        "height": "100%",
        "overflow": "auto",
    }

    useEffect(() => {
        const element = document.getElementById(pinned?.pred?.id);
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, [pinned])
    useEffect(() => {
        if (playerRef) {
            playerRef.current?.subscribeToStateChange(handleBind)
        }
    });
    useEffect(() => {
        return () => {
            if (playerRef.current) {
                playerRef.current = null;
            }
        };
    }, [playerRef]);
    const handleBind = (state) => {
        setVideoPlayerState(state);
    }


    const handlePinning = (e) => {
        handlePinned(e)
    }
    if (assetData.length === 0) {
        return <>Loading...</>
    }
    return (
        <>
            <div style={style}>
                {dataList.length > 0 ?
                    <table className="assetTable">
                        {disableHeader ? <></> : <thead style={{}}>
                            <tr>
                                <th className="" style={{ width: '10%', fontWeight: '700' }}>Number</th>
                                <th className="" style={{ width: '30%', fontWeight: '700' }}>Distress</th>
                                <th className="" style={{ width: '50%', fontWeight: '700' }}>Distress type</th>
                                {/* <th className="" style={{ width: '20%', fontWeight: '700' }}>Priority</th> */}
                                <th className="" style={{ width: '10%', fontWeight: '700' }}>Confirm data</th>
                            </tr>
                        </thead>
                        }
                        <tbody ref={tableRef}>
                            {dataList.map((e, i) => {
                                const { id, defectName, isValid } = e;

                                const obj = {
                                    pred: e,
                                }
                                const check = (obj.pred.id === pinned?.pred?.id ?? '');
                                const defectValue = defectName === 1 ? typeIndex?.[e.typeIndex]?.value : '';
                                const displayName = defectNames?.[defectName] || <span style={{ color: '#000000', fontWeight: '400' }}><i>unnamed</i></span>;
                                return <tr
                                    key={id}
                                    id={id}
                                    style={{
                                        transition: 'all ease 0.0s', ...check ? { backgroundColor: '#B0FFA4', border: '2px solid #60EE00', cursor: 'pointer' } : { cursor: 'pointer' },
                                    }}
                                    role="button"
                                >
                                    <td style={{ width: '10%', fontWeight: '400' }}
                                        onClick={() => handlePinning(obj)}
                                    >
                                        <div style={{
                                            width: '40px', height: '40px', borderRadius: '50%',
                                            display: 'flex', alignItems: 'center',
                                            justifyContent: 'center',
                                            color: "black",
                                            fontSize: '16px',
                                            fontWeight: '400'
                                        }}>{i + 1}</div>
                                    </td>
                                    <td style={{ width: '30%', fontWeight: '400' }}
                                        onClick={() => handlePinning(obj)}
                                    >
                                        <CustomTooltip
                                            placement="top"
                                            arrow
                                            title={
                                                <Card
                                                    elevation={5}>
                                                    <img src={defectImageURLs[id]} width="100px" style={{ maxHeight: '200px' }} />
                                                </Card>
                                            }
                                            widget={
                                                <img src={defectImageURLs[id]} height={50} width={50} style={{ objectFit: 'cover' }} />
                                            }
                                        />
                                    </td>
                                    <td
                                        onClick={() => handlePinning(obj)}
                                        style={{ width: '50%', fontWeight: '400', color: '#000000' }} className="capitalize-text">
                                        {defectNames && typeIndex ? `${defectValue} ${displayName}` : displayName}
                                    </td>
                                    {/* <td
                                        onClick={() => handlePinning(obj)}
                                        style={{ width: '30%', fontWeight: '400', color: '#000000' }} className="capitalize-text">
                                        {typeIndex && e.typeIndex ? typeIndex[e.typeIndex]['value'] ?? '-' : '-'}
                                    </td> */}

                                    {/* <td style={{ width: '20%', color: '#000000', fontWeight: '400' }} className="capitalize-text"
                                        onClick={() => handlePinning(obj)}
                                    >
                                        {`${priorityMap?.map[priority].name}`}
                                    </td> */}
                                    <td
                                        onClick={() => handlePinning(obj)}
                                        style={{ width: '10%', fontWeight: '400' }}><Checkbox checked={isValid} disabled  {...label}
                                            sx={{
                                                '&.Mui-checked.Mui-disabled': {
                                                    color: '#f0f0f0',
                                                    '& .MuiSvgIcon-root': {
                                                        backgroundColor: '#606060',
                                                    },
                                                },
                                                '&.Mui-disabled': {
                                                    color: '#606060',
                                                    backgroundColor: 'transparent',
                                                    '& .MuiSvgIcon-root': {
                                                        backgroundColor: 'transparent',
                                                    },
                                                },
                                                position: 'static',
                                            }}
                                        /></td>
                                </tr>
                            })}
                        </tbody>
                    </table> : <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No data found</div>
                }

            </div >
        </>
    );
}
export default PotholeTable;