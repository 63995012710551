import React from 'react'
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import Chip from '@mui/material/Chip';
const PotholeTableFilters = (props) => {
    const { qualities, handleQuality } = props;
    return (
        <Box style={{ backgroundColor: '' }} sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'row', alignItems: 'center', justifyContent: 'start', padding: '20px' }}>
            {/* <Typography sx={{ fontWeight: '700', fontSize: '14px', color: '#00000' }}>Filters applied ({qualities.length}/3) : </Typography> */}
            <Box sx={{ marginLeft: '10px' }}>
                <Chip size='small' label={qualities.includes(2) ? "Low priority X" : "Low priority"} variant="outlined" onClick={() => handleQuality(2)} sx={{ cursor: 'pointer', borderColor: '#1538B2', backgroundColor: `#D7DDE1`, color: '#1538B2', '&:hover': { borderColor: '#1538B2', backgroundColor: `#D7DDE1`, color: '#1538B2' } }} />
                <Chip size='small' label={qualities.includes(1) ? "Medium priority X" : "Medium priority"} variant="outlined" onClick={() => handleQuality(1)} sx={{ marginLeft: '10px', cursor: 'pointer', borderColor: '#1538B2', backgroundColor: `#D7DDE1`, color: '#1538B2', '&:hover': { borderColor: '#1538B2', backgroundColor: `#D7DDE1`, color: '#1538B2' } }} />
                <Chip size='small' label={qualities.includes(0) ? "High priority X" : "High priority"} variant="outlined" onClick={() => handleQuality(0)} sx={{ marginLeft: '10px', cursor: 'pointer', borderColor: '#1538B2', backgroundColor: `#D7DDE1`, color: '#1538B2', '&:hover': { borderColor: '#1538B2', backgroundColor: `#D7DDE1`, color: '#1538B2' } }} />
            </Box>
        </Box>
    )
}
export default PotholeTableFilters