import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as ROUTES from '../constants/routes';

const networkContext = createContext();
export const useNetworkContext = () => {
    return useContext(networkContext);
};

const NetworkProvider = ({ children }) => {
    const location = useLocation();
    const locationPathname = location.pathname === '/home/dashboard-network' ? true : false;
    const [isNetworkView, setIsNetworkView] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        setIsNetworkView(locationPathname);
    }, [locationPathname])
    const handleChangeView = (event) => {
        const checked = event.target.checked;
        if (checked) {
            navigate(`home/${ROUTES.HOME_DASHBOARD_NETWORK}`);
        }
        else {
            navigate(`home/${ROUTES.HOME_DASHBOARD}`);
        }

        setIsNetworkView(event.target.checked);
    };
    return (
        <networkContext.Provider value={{ isNetworkView, handleChangeView }}>
            {children}
        </networkContext.Provider>
    );
};
export default NetworkProvider;