import { Box, Button, Grid, IconButton, Toolbar, Typography, Divider, Card, Stack, MenuItem, styled, Paper, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { connect } from "react-redux";
import circle from '../../../../../../assets/selectorIcons/circle.svg'
import rectangle from '../../../../../../assets/selectorIcons/rectangle.svg'
import triangle from '../../../../../../assets/selectorIcons/triangle.svg'
import circleFilled from '../../../../../../assets/selectorIcons/circle_filled.svg'
import rectangleFilled from '../../../../../../assets/selectorIcons/rectangle_filled.svg'
import triangleFilled from '../../../../../../assets/selectorIcons/triangle_filled.svg'
import PinOnMap from '../../components/PinOnMap';
import { addNewAsset } from '../../../../../../store/actions/projectActions';
import CustomProgress from '../../../../../cidgets/CustomProgress';
import Error from '../../../../../cidgets/Error';
import AddNewAssetVideoPlayer from '../afterInference/AddNewAssetVideoPlayer';
import PotholeAddNewFields from './PotholeAddNewFields';
import SmartUtils from '../../../../../../helpers/SmartUtils';


const AddNewPotholeFeature = (props) => {
    const layerRef = useRef(null)
    const baseUrl = "https://dr-utils-2zusqszkmq-uc.a.run.app";
    const { projectPredictions, clearAddDefectErrorAndMsg, addDefectDoneAndSent, showAddDefectLoader, addDefectMessage, addBasicImagingDefect, basicImagingDataInfo, allPolyPoints, datastore, startTime, playerRef, handleClose, fps, link, originalVideoWidth, originalVideoHeight, project, proid } = props;
    const { addNewAssetMessage, showAddNewAssetLoader, addNewAssetIsDoneAndSent } = project;
    const { dataInfo, assetData } = datastore;
    const vidDivRef = useRef(null);
    const numHeightOfHeader = 8;
    const numHeightOfFooter = 8;
    const customPadding = 15;
    const fieldAreaMargin = 20;
    const assetClassesWhereNameWillNotShow = ['', '0', '2', '3', '4', '998', '999'];
    const cardStyle = {
        "border": "2px solid grey",
        "borderRadius": "8px",
        "backgroundColor": '#f5f1f1'
    }
    const [sectionId, setSectionId] = useState(null);
    const Item = styled(Paper)(({ theme }) => ({
        boxShadow: '0px 0px transparent',
        width: '100%',
        backgroundColor: 'transparent',
        ...theme.typography.body2,
        padding: theme.spacing(0.5),
    }));
    const fieldTextStyle = {
        fontSize: '15px',
        fontWeight: 'bold',
    }
    function mapIt(sectionDocument) {
        let sortedDetections = Object.values(sectionDocument.detections);
        sortedDetections.sort((x, y) => x.frameTime - y.frameTime);
        if (sortedDetections.length == 0) {
            return {
                id: sectionDocument.id,
                sectionId: sectionDocument.sectionId,
                initTime: -1,
                endTime: -1,
            };
        }
        return {
            id: sectionDocument.id,
            sectionId: sectionDocument.sectionId,
            initTime: sortedDetections[0].frameTime,
            endTime: sortedDetections.at(-1).frameTime,
        };
    }
    useEffect(() => {
        if (projectPredictions) {
            const selectedSection = projectPredictions.map((eachObj) => mapIt(eachObj));
            const pickedSections = selectedSection.filter((eachSegment) =>
                SmartUtils.checkInRange(Number((eachSegment.initTime)).toFixed(5), Number((eachSegment.endTime)).toFixed(5), Number(startTime).toFixed(5)));
            if (pickedSections.length > 0) {
                const sectionId = pickedSections[0]['id'];
                setSectionId(sectionId)
            }
        }
    }, [projectPredictions])
    const gpsObj = allPolyPoints.length !== 0 ? allPolyPoints.reduce((prev, curr) => {
        return (Math.abs(curr.time * 1000 - startTime * 1000) < Math.abs(prev.time * 1000 - startTime * 1000) ? curr : prev);
    }) : [];
    const [vidDivDimen, setVidDivDimen] = useState({ height: 1, width: 1 })
    const [imageDimensions, setImageDimensions] = useState({ height: 1, width: 1 });
    const [svgDimensions, setSvgDimensions] = useState({ height: 1, width: 1, scale: 1, offsetTop: 0, offsetLeft: 0 });
    const [bbox, setBbox] = useState({ downXY1: [], downXY2: [], size: 0, isEnabled: false })
    const [hbbox, setHbbox] = useState({ downX: 0, downY: 0 })
    const [svgHover, setSvgHover] = useState(false)
    const [coordinates, setCoordinates] = useState([]);
    const [searchKeys, setSearchKeys] = useState("");
    const [primaryColor, setPrimaryColor] = useState("");
    const [secondaryColor, setSecondaryColor] = useState("");
    const [primaryShape, setPrimaryShape] = useState("");
    const [searchResults, setSearchResults] = useState(undefined);
    const [doSimilarity, setDoSimilarity] = useState("0");
    const [fieldValues, setFieldValues] = useState({
        type: '',
        basicImagingClassState: '',
        sideState: '',
        priorityState: '',
        lateralPositionState: '',
        clearanceHeightState: 'To be calculated',
        distanceFromTheLaneEdgeState: 'To be calculated',
        depthState: 'To be calculated',
        lengthState: 'To be calculated',
        widthState: 'To be calculated',

        assetClassState: '',
        assetNameState: '',
        detectionQualityState: 100,
        recognitionQualityState: 100,
        gpsCoordinatesState: `${gpsObj.lat},${gpsObj.lng}` ?? '',
        roadSideState: '',
        frameNumberState: Math.floor(fps * startTime) ?? '',
    });
    useEffect(() => {
        clearAddDefectErrorAndMsg();
    }, [])
    const [newSelectedAsset, setNewSelectedAsset] = useState(undefined);

    const handleChange = (prop) => (event) => {
        setFieldValues({ ...fieldValues, [prop]: event.target.value });
    };
    const handleSearch = (e) => {
        setSearchKeys(e.target.value)
    }

    const handlePrimaryColor = async (e) => {
        const isSelected = e === primaryColor
        setPrimaryColor(isSelected ? '' : e)
    }

    const handleSecondaryColor = async (e) => {
        const isSelected = e === secondaryColor
        setSecondaryColor(isSelected ? '' : e)
    }

    const handlePrimaryShape = async (e) => {
        const isSelected = e === primaryShape
        setPrimaryShape(isSelected ? '' : e)
    }

    const handleSelectNewAssetId = (id) => {
        if (newSelectedAsset === undefined) {
            const data = assetData.filter((e) => `${e.id}` === id);
            setNewSelectedAsset(data[0]);
        }
        else if (newSelectedAsset.id === id) {
            setNewSelectedAsset(undefined)
        }
        else {
            const data = assetData.filter((e) => `${e.id}` === id);
            setNewSelectedAsset(data[0]);
        }

    }
    const handleUploadNewAsset = () => {
        let assetId;
        let archived = "0";
        // let sectionId = "section0";
        let isGps = true;
        let gpsCoordinates = fieldValues.gpsCoordinatesState.split(',');
        if (coordinates.length < 4 || fieldValues.basicImagingClassState === '' || fieldValues.frameNumberState === '' || fieldValues.gpsCoordinatesState === '') {
            // return <Error errorMessage={"Fields empty"} />
            alert("Add all required details")
        }
        else if (coordinates.length === 4 || fieldValues.basicImagingClassState !== '' || fieldValues.frameNumberState !== '' || fieldValues.gpsCoordinatesState !== '') {
            addBasicImagingDefect(proid, sectionId, fieldValues.priorityState, fieldValues.basicImagingClassState, coordinates, gpsCoordinates, fieldValues.frameNumberState)
        }
        // else if (classesMap.notTrafficSign.includes(fieldValues.assetClassState)) {
        //     let gpsCoordinates = fieldValues.gpsCoordinatesState.split(',');
        //     assetId = dataInfo['classesId'][fieldValues.assetClassState]
        //     props.addNewAsset(proid, sectionId, archived, assetId, coordinates, fieldValues.frameNumberState, startTime, gpsCoordinates, gpsObj.geoTime, isGps, fieldValues.roadSideState)
        // }
        // else if (classesMap.trafficSign.includes(fieldValues.assetClassState)) {
        //     let gpsCoordinates = fieldValues.gpsCoordinatesState.split(',');
        //     if (newSelectedAsset) {
        //         assetId = newSelectedAsset.id
        //         props.addNewAsset(proid, sectionId, archived, assetId, coordinates, fieldValues.frameNumberState, startTime, gpsCoordinates, gpsObj.geoTime, isGps, fieldValues.roadSideState)
        //     }
        //     else {
        //         alert('Select an asset!');
        //     }
    }

    const getVidWindowSize = () => {
        const newHeight = vidDivRef?.current?.clientHeight;
        const newWidth = vidDivRef?.current?.clientWidth;
        setVidDivDimen({
            height: newHeight,
            width: newWidth
        });
    };

    const sendRequest = async () => {
        const response = await fetch(baseUrl + `/thoroughSearch?keywords=${searchKeys}&primaryShape=${primaryShape}&primaryColor=${primaryColor}&secondaryColor=${secondaryColor}&doSimilarity=${doSimilarity}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error! status: ${response.status}`);
        }

        const result = await response.json();
        // //////console.log(result)
        setSearchResults(result)
    }
    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            // //////console.log(searchKeys)
            if (searchKeys.length > 0) {
                await sendRequest()
            }
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [searchKeys])

    useEffect(() => {
        async function getData() {
            await sendRequest()
        }
        getData()
    }, [primaryShape, primaryColor, secondaryColor]);

    useEffect(() => {
        window.addEventListener("load", setVidDivDimen(vidDivRef.current.clientHeight))
        window.addEventListener("resize", getVidWindowSize, true);
    }, [])
    useEffect(() => {
        const divHeight = vidDivRef?.current?.clientHeight
        const divWidth = vidDivRef?.current?.clientWidth - 0;
        const imageHeight = originalVideoHeight
        const imageWidth = originalVideoWidth

        const asDiv = (divHeight / divWidth)
        const asImg = (imageHeight / imageWidth)
        const ratio = asDiv / asImg

        let svgWidth = 0
        let svgHeight = 0
        if (ratio >= 1) {
            svgWidth = divWidth
            svgHeight = asImg * divWidth
        } else {
            svgHeight = divHeight
            svgWidth = (1 / asImg) * divHeight
        }
        setSvgDimensions({
            width: svgWidth,
            height: svgHeight,
            scale: imageHeight / svgHeight,
            offsetTop: (divHeight - svgHeight) / 2,
            offsetLeft: (divWidth - svgWidth) / 2,
        })
    }, [imageDimensions])
    // useEffect(() => {
    //   setImageDimensions({
    //     height: originalVideoHeight,
    //     width: originalVideoWidth,
    //   })
    // }, [pinned]);
    useEffect(() => {
        setImageDimensions({
            height: originalVideoHeight,
            width: originalVideoWidth,
        })
    }, [vidDivDimen])
    const svgScaleFactor = 1
    const svgColor = `rgba(96,238,0, ${svgHover ? 0.2 : 0.2})`
    const svgStrokeColor = 'rgba(96,238,0, 0.9)'
    const svgPredColor = `rgba(240,240,10, ${svgHover ? 0.2 : 0.2})`
    const svgPredStrokeColor = 'rgba(240,240,10, 0.9)'
    const searchedSigns = searchResults !== undefined ? searchResults['signs'] : []
    const getRect = (x1y1 = [], x2y2 = [], shouldScale = true, pred = false) => {
        if (x1y1.length !== 2 || x2y2.length !== 2) {
            return <></>
        }
        const x1 = x1y1[0] / (shouldScale ? svgDimensions.scale : 1)
        const y1 = x1y1[1] / (shouldScale ? svgDimensions.scale : 1)
        const x2 = x2y2[0] / (shouldScale ? svgDimensions.scale : 1)
        const y2 = x2y2[1] / (shouldScale ? svgDimensions.scale : 1)
        return <path d={`M ${x1}, ${y1} ${x1}, ${y2} ${x2}, ${y2} ${x2}, ${y1} z`} style={{ fill: pred ? svgPredColor : svgColor, strokeWidth: '3', stroke: pred ? svgPredStrokeColor : svgStrokeColor }} />
    }
    const getDistanceBetweenPoints = (x1, y1, x2, y2) => {
        var a = x1 - x2;
        var b = y1 - y2;
        return Math.floor(Math.sqrt(a * a + b * b));
    }
    useEffect(() => {
        if (bbox.downXY1.length === 2 && bbox.downXY2.length === 2) {
            const tlx = bbox.downXY1[0]
            const tly = bbox.downXY1[1]
            const brx = bbox.downXY2[0]
            const bry = bbox.downXY2[1]
            const h = getDistanceBetweenPoints(tlx, tly, tlx, bry)
            const w = getDistanceBetweenPoints(tlx, tly, brx, tly)
            setCoordinates([tlx, tly, w, h])

        }
    }, [bbox])
    const handleMouseDown = (e) => {
        let downXY1 = bbox.downXY1
        let downXY2 = bbox.downXY2
        if (downXY1.length !== 2) {
            // //////console.log("1st")
            downXY1 = [hbbox.downX, hbbox.downY]
        } else if (downXY2.length !== 2) {
            // //////console.log("2nd")
            downXY2 = [hbbox.downX, hbbox.downY]
        } else {
            downXY1 = []
            downXY2 = []
        }
        setBbox({
            downXY1,
            downXY2,
            size: 0,
        })
    }
    const handleMouseMove = (e) => {
        // const { layerX, layerY } = e.nativeEvent;
        // //////console.log(layerRef.current.offsetTop)
        const event = e.nativeEvent;
        // //////console.log(event)
        const offsetX = event.layerX
        const offsetY = event.layerY
        const layerX = event.clientX - layerRef.current.offsetLeft - svgDimensions.offsetLeft//event.target.getBoundingClientRect().left;//offsetX;
        const layerY = event.clientY - layerRef.current.offsetTop - svgDimensions.offsetTop//event.target.getBoundingClientRect().top;//offsetY;

        // //////console.log("svg", svgDimensions.offsetLeft, svgDimensions.offsetTop)
        // //////console.log("ly", layerX, layerY)
        // //////console.log("cy", event.clientX, event.clientY)
        // //////console.log("ly-svg", ((layerX) - svgDimensions.offsetLeft), ((layerY) - svgDimensions.offsetTop))
        // //////console.log("svg", svgDimensions)
        // //////console.log("lt", event.target.getBoundingClientRect().left, event.target.getBoundingClientRect().top, event.target.clientTop, event.target.clientLeft)
        // //////console.log(event.target.getBoundingClientRect())


        setHbbox({
            // downX: ((layerX) - svgDimensions.offsetLeft) * svgDimensions.scale,
            // downY: ((layerY) - svgDimensions.offsetTop) * svgDimensions.scale,
            downX: ((layerX)) * svgDimensions.scale,
            downY: ((layerY)) * svgDimensions.scale,
        })
    }
    if (dataInfo === undefined) {
        return <div ref={vidDivRef}>
            <p>not found</p>
        </div>
    }
    const isTrafficSignClass = !assetClassesWhereNameWillNotShow.includes(fieldValues.assetClassState);
    const classesMap = {
        "map": dataInfo['classes'],
        "keys": Object.keys(dataInfo['classes']),
        "values": Object.values(dataInfo['classes']),
        'assetClassesWhereNameWillNotShow': ['0', '2', '3', '4', '998', '999'],
        "usefulKeys": ['0', '1', '2', '3', '4', '998', '999'],
        "notTrafficSign": ['0', '2', '3', '4', '998', '999'],
        "trafficSign": ['1'],
    }
    const shouldAssetNameEmpty = classesMap.trafficSign.includes(fieldValues?.assetClassState);
    const assetClasses = classesMap.keys.filter(e => classesMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${classesMap.values[i]}`.replace('_', ' ')}
        </MenuItem>
    })
    const roadSideMap = {
        "map": dataInfo['roadSides'],
        "keys": Object.keys(dataInfo['roadSides']),
        "values": Object.values(dataInfo['roadSides']),
        "usefulKeys": ['0', '1'],
    }
    const roadSideMenuItems = roadSideMap.keys.filter(e => roadSideMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${roadSideMap.values[i]}`.replace('_', ' ')}
        </MenuItem>
    });
    const colorMap = {
        "map": dataInfo['color'],
        "keys": Object.keys(dataInfo['color']),
        "values": Object.values(dataInfo['color']),
        "usefulKeys": ['0', '1', '2', '3', '4', '5'],
        'primaryCollections': {
            '0': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="red" strokeWidth="3" fill="white" />
            </svg>,
            '1': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="black" strokeWidth="3" fill="white" />
            </svg>,
            '2': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="#dba102" strokeWidth="3" fill="white" />
            </svg>,
            '3': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="blue" strokeWidth="3" fill="white" />
            </svg>,
            '4': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="grey" strokeWidth="3" fill="white" />
            </svg>,
            '5': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="green" strokeWidth="3" fill="white" />
            </svg>,
            '6': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="brown" strokeWidth="3" fill="white" />
            </svg>,


        },
        'secondaryCollections': {
            '0': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="red" strokeWidth="3" fill="white" />
            </svg>,
            '1': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="black" strokeWidth="3" fill="white" />
            </svg>,
            '2': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="#dba102" strokeWidth="3" fill="white" />
            </svg>,
            '3': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="blue" strokeWidth="3" fill="white" />
            </svg>,
            '4': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="grey" strokeWidth="3" fill="white" />
            </svg>,
            '5': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="green" strokeWidth="3" fill="white" />
            </svg>,
            '6': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="brown" strokeWidth="3" fill="white" />
            </svg>,


        },
        'primaryFilled': {
            '0': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="red" strokeWidth="3" fill="red" />
            </svg>,
            '1': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="black" strokeWidth="3" fill="black" />
            </svg>,
            '2': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="#dba102" strokeWidth="3" fill="#dba102" />
            </svg>,
            '3': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="blue" strokeWidth="3" fill="blue" />
            </svg>,
            '4': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="grey" strokeWidth="3" fill="grey" />
            </svg>,
            '5': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="green" strokeWidth="3" fill="green" />
            </svg>,
            '6': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="brown" strokeWidth="3" fill="brown" />
            </svg>
        },
        'secondaryFilled': {
            '0': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="red" strokeWidth="3" fill="red" />
            </svg>,
            '1': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="black" strokeWidth="3" fill="black" />
            </svg>,
            '2': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="#dba102" strokeWidth="3" fill="#dba102" />
            </svg>,
            '3': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="blue" strokeWidth="3" fill="blue" />
            </svg>,
            '4': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="grey" strokeWidth="3" fill="grey" />
            </svg>,
            '5': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="green" strokeWidth="3" fill="green" />
            </svg>,
            '6': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="brown" strokeWidth="3" fill="brown" />
            </svg>
        }
    };
    const shapeMap = {
        "map": dataInfo['shape'],
        "keys": Object.keys(dataInfo['shape']),
        "values": Object.values(dataInfo['shape']),
        "usefulKeys": ['0', '2', '3'],  // 0 tri  2 cir  3 rect
        "components": {
            '0': triangle,
            '2': circle,
            '3': rectangle
        },
        'filledComponents': {
            '0': triangleFilled,
            '2': circleFilled,
            '3': rectangleFilled
        }
    };
    // //////console.log(addNewAssetIsDoneAndSent, addNewAssetMessage, "dataaaaaaa")
    return (
        <div style={{ height: '100vh', width: '100vw', backgroundColor: '#FAFAFA' }}>
            <div style={{ height: `${numHeightOfHeader}vh`, width: '100vw', backgroundColor: '#FAFAFA', boxShadow: '5px 10px #FAFAFA', padding: `${customPadding}px` }}>
                <Grid container >
                    <Grid item xs={9} sm={9} md={9} lg={9} xl={9} sx={{
                        display: 'flex', justifyContent: 'start',
                    }} >
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            Add new distress
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3} sx={{
                        display: 'flex', justifyContent: 'end', alignItems: 'center'
                    }}>
                        <CloseIcon
                            sx={{
                                color: "#1E1E1E",
                                cursor: "pointer",
                                margin: '0 5px 0 0'
                            }}
                            onClick={showAddDefectLoader ? null : handleClose}
                        >
                        </CloseIcon>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <Box sx={{ width: "100%", marginTop: '8px' }}>
                            <Divider light />
                        </Box>
                    </Grid>
                </Grid>
            </div>
            <div style={{ height: `${(100) - (numHeightOfHeader + numHeightOfFooter)}vh`, width: '100vw', backgroundColor: 'transparent' }}>
                <Grid container spacing={0}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ backgroundColor: 'transparent', height: `${(100) - (numHeightOfHeader + numHeightOfFooter)}vh` }}>

                        <Grid container spacing={0} style={{ padding: `${customPadding}px` }}>
                            <Grid className={showAddDefectLoader ? 'asset-container-disabled' : ''} ref={vidDivRef} item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ backgroundColor: 'transparent', height: `${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh` }}>
                                <div ref={layerRef} className="container" style={{ padding: '0px' }}>
                                    <div className="inner" style={{ zIndex: 997 }}>
                                        <Card sx={cardStyle}>
                                            <AddNewAssetVideoPlayer fps={fps} startTime={startTime} link={link} height={vidDivRef.current === null ? 0 : vidDivRef.current?.clientHeight} disableControlsFully={true} shouldOffset={true} />
                                        </Card>
                                    </div>
                                    <div className="inner" style={{ zIndex: 998, width: vidDivRef.current === null ? 0 : vidDivRef.current?.clientWidth }}>
                                        <div style={{
                                            height: '100%', width: '100%', backgroundColor: '', textAlign: 'center', display: 'flex',
                                            justifyContent: 'center', alignItems: 'center', marginTop: '-0px'
                                        }}>
                                            <svg height={svgDimensions.height} width={svgDimensions.width} style={{
                                                backgroundColor: 'rgba(34,55,245, 0.0)',
                                            }}
                                                onMouseDownCapture={(e) => { handleMouseDown(e) }} onMouseMoveCapture={(e) => { handleMouseMove(e) }}
                                            >
                                                {getRect(bbox.downXY1, bbox.downXY2)}
                                                {bbox.downXY2.length === 2 ? <></> : getRect(bbox.downXY1, [hbbox.downX, hbbox.downY])}
                                                <circle cx={hbbox.downX / svgDimensions.scale} cy={hbbox.downY / svgDimensions.scale} r={bbox.downXY2.length === 2 ? 3 : 2} fill={bbox.downXY2.length === 2 ? 'red' : bbox.downXY1.length === 2 ? svgStrokeColor : 'black'} />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginTop: `${fieldAreaMargin}px`, backgroundColor: 'transparent', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }}>
                                <Grid container spacing={2}>
                                    <Grid className={showAddDefectLoader ? 'asset-container-disabled' : ''} item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ backgroundColor: 'transparent', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }} >

                                        <Card sx={cardStyle}>
                                            <div style={{ padding: '5px', overflow: 'auto', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }}>

                                                {/* <>
                                                    <Stack
                                                        sx={{ width: "100%" }}
                                                        direction={{ xs: "row", sm: "row", md: "row", lg: "row", xl: "row" }}
                                                    >
                                                        <Item>
                                                            <Grid container>
                                                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                                                    display: "flex",
                                                                    justifyContent: "start",
                                                                    alignItems: "center",
                                                                }} >
                                                                    <Typography variant="body1" sx={fieldTextStyle}>
                                                                        Asset category
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                    <TextField
                                                                        id="asset class"
                                                                        select
                                                                        value={fieldValues.assetClassState}
                                                                        onChange={handleChange("assetClassState")}
                                                                        size="small"
                                                                        sx={{ textTransform: 'capitalize', width: '100%' }}
                                                                    >
                                                                        {assetClasses}
                                                                    </TextField>
                                                                </Grid>
                                                            </Grid>
                                                        </Item>
                                                    </Stack>
                                                    {isTrafficSignClass ?
                                                        <Item>
                                                            <Grid container>
                                                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "start",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <Typography variant="body1" sx={fieldTextStyle}>
                                                                        Asset name:
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                                                                    <TextField
                                                                        disabled
                                                                        id="outlined-size-small"
                                                                        // value={fieldValues.assetNameState}
                                                                        value={newSelectedAsset ? newSelectedAsset.name : shouldAssetNameEmpty ? '' : fieldValues.assetNameState}
                                                                        onChange={handleChange("assetNameState")}
                                                                        size="small"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Item> : <></>}

                                                    <Item>
                                                        <Grid container>
                                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "start",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <Typography variant="body1" sx={fieldTextStyle}>
                                                                    {isTrafficSignClass ? 'Recognition' : 'Detection'} quality:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                                                                <TextField
                                                                    id="outlined-size-small"
                                                                    disabled
                                                                    value={isTrafficSignClass ? fieldValues.recognitionQualityState : fieldValues.detectionQualityState}
                                                                    // onChange={handleChange("detectionQualityState")}
                                                                    size="small"

                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Item>

                                                    <Item>
                                                        <Grid container>
                                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "start",
                                                                    alignItems: "center",
                                                                }} >
                                                                <Typography variant="body1" sx={fieldTextStyle}>
                                                                    GPS location:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                                                                <TextField
                                                                    disabled
                                                                    id="outlined-size-small"
                                                                    value={fieldValues.gpsCoordinatesState}
                                                                    onChange={handleChange("gpsCoordinatesState")}
                                                                    size="small"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Item>
                                                    <Item>
                                                        <Grid container>
                                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "start",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <Typography variant="body1" sx={fieldTextStyle}>
                                                                    Road side:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                                                                <TextField
                                                                    select
                                                                    id="outlined-size-small"
                                                                    value={fieldValues.roadSideState}
                                                                    onChange={handleChange("roadSideState")}
                                                                    size="small"
                                                                    sx={{ textTransform: 'capitalize', width: '100%' }}
                                                                >
                                                                    {roadSideMenuItems}
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>
                                                    </Item>
                                                    <Item>
                                                        <Grid container>
                                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "start",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <Typography variant="body1" sx={fieldTextStyle}>
                                                                    Frame number:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                                                                <TextField
                                                                    disabled
                                                                    id="outlined-size-small"
                                                                    value={fieldValues.frameNumberState}
                                                                    onChange={handleChange("frameNumberState")}
                                                                    size="small"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Item>
                                                </> */}
                                                <PotholeAddNewFields fieldValues={fieldValues} handleChange={handleChange} basicImagingDataInfo={basicImagingDataInfo} />
                                            </div>
                                        </Card>

                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ backgroundColor: 'transparent', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }}>
                                        <Card sx={cardStyle}>
                                            <div style={{ overflow: 'auto', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }}>
                                                <PinOnMap isAddingAsset={true} gpsObj={gpsObj} />
                                            </div>

                                        </Card>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid
                        className={'container-disabled'}
                        //  className={showAddNewAssetLoader ? 'asset-container-disabled' : isTrafficSignClass ? "" : "asset-container-disabled"} 
                        item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ backgroundColor: 'transparent', height: `${(100) - (numHeightOfHeader + numHeightOfFooter)}vh` }}>
                        <Grid container spacing={0} style={{ padding: `${customPadding}px ${customPadding}px ${customPadding}px 0px ` }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginBottom: '10px', backgroundColor: 'rgba(255,0,255,0.0)', height: `${((100) - (numHeightOfHeader + numHeightOfFooter)) * (10 / 100)}vh` }}>
                                <div style={{ display: 'flex', alignItems: 'start', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) * (10 / 100)}vh - 0px)`, marginBlock: '0px', backgroundColor: 'transparent' }}>
                                    <TextField sx={{
                                        border: "2px solid grey",
                                        borderRadius: "8px",
                                        backgroundColor: '#f5f1f1', width: '100%'
                                    }} id="fullWidth" type="text" name="seconds" onChange={handleSearch} placeholder="Try searching 20 speed limit or train sign" value={searchKeys} />
                                </div>

                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ backgroundColor: '', height: `${((100) - (numHeightOfHeader + numHeightOfFooter)) * (20 / 100)}vh` }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ backgroundColor: '', height: `${((100) - (numHeightOfHeader + numHeightOfFooter)) * (20 / 100)}vh` }} >
                                        <Card
                                            sx={{ ...cardStyle, padding: '10px' }}
                                        >
                                            <div style={{ overflow: 'auto', backgroundColor: 'transparent', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) * (20 / 100)}vh - 20px)` }}>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Filter with color</Typography>
                                                <Stack
                                                    sx={{ width: "100%" }}
                                                    direction={{ xs: "row", sm: "row", md: "row", lg: "row", xl: "row" }}
                                                    justifyContent="space-between"
                                                    alignItems="center"

                                                >
                                                    <Typography variant="subtitle2">Primary:</Typography>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        {colorMap.keys.filter((e) => colorMap.usefulKeys.includes(e)).map((e, i) => {

                                                            return <Tooltip key={e} title={primaryColor != e ? 'Select' : 'Unselect'} arrow>
                                                                <IconButton key={e} onClick={() => { handlePrimaryColor(e) }}>
                                                                    {
                                                                        primaryColor != e ? colorMap.primaryCollections[e] : colorMap.primaryFilled[e]
                                                                    }
                                                                </IconButton>
                                                            </Tooltip>
                                                        })}
                                                    </Box>
                                                </Stack>
                                                <Stack
                                                    sx={{ width: "100%" }}
                                                    direction={{ xs: "row", sm: "row", md: "row", lg: "row", xl: "row" }}
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    spacing={0}
                                                >
                                                    <Typography variant="subtitle2">Secondry:</Typography>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        {colorMap.keys.filter((e) => colorMap.usefulKeys.includes(e)).map((e, i) => {


                                                            return <Tooltip key={e} title={secondaryColor != e ? 'Select' : 'Unselect'} arrow>
                                                                <IconButton key={e} onClick={() => { handleSecondaryColor(e) }}>
                                                                    {
                                                                        secondaryColor != e ? colorMap.secondaryCollections[e] : colorMap.secondaryFilled[e]
                                                                    }
                                                                </IconButton>
                                                            </Tooltip>
                                                        })}

                                                    </Box>
                                                </Stack>
                                            </div>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ backgroundColor: 'transparent', height: `${((100) - (numHeightOfHeader + numHeightOfFooter)) * (20 / 100)}vh` }}>

                                        <Card sx={{ ...cardStyle, padding: '10px' }}>
                                            <div style={{ overflow: 'auto', backgroundColor: 'transparent', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) * (20 / 100)}vh - 20px)` }}>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold' }} >Filter with sign shape</Typography>
                                                <Stack
                                                    sx={{ width: "100%" }}
                                                    direction={{ xs: "row", sm: "row", md: "row", lg: "row", xl: "row" }}
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >
                                                    <Typography variant="subtitle2">Select:</Typography>
                                                    <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                                        {shapeMap.keys.filter((e) => shapeMap.usefulKeys.includes(e)).map((e, i) => {
                                                            return <Tooltip key={e} title={primaryShape != e ? 'Select' : 'Unselect'} arrow>
                                                                <IconButton key={e} onClick={() => { handlePrimaryShape(e) }}>
                                                                    <img src={primaryShape != e ? shapeMap.components[e] : shapeMap.filledComponents[e]} alt=" no data" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        })}
                                                    </Box>
                                                </Stack>
                                            </div>
                                        </Card>

                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ overflow: 'auto', backgroundColor: '', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) * (70 / 100)}vh - ${customPadding * 2}px)`, paddingBlock: `${customPadding * 2}px` }}>
                                <Grid container columnGap={0} rowSpacing={2}>
                                    {searchedSigns.map((e, i) => {
                                        const base64 = `data:image/png;base64, ${e['base64']}`;
                                        const bestMatcher = e['id'] === (newSelectedAsset?.id ?? '-1')
                                        const styles = bestMatcher ? {
                                            border: "4px solid green",
                                        } : {
                                            border: "0.5px solid grey",
                                        }
                                        return (

                                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>

                                                <span role="button" key={e['id']} style={{ cursor: 'pointer' }} onClick={() => { handleSelectNewAssetId(e['id']) }}>
                                                    <div style={{ width: `${vidDivRef.current.clientWidth / 6}px`, height: `${vidDivRef.current.clientWidth / 6}px` }}>
                                                        <div style={{ width: `${(vidDivRef.current.clientWidth / 6) - 4}px`, height: `${(vidDivRef.current.clientWidth / 6) - 4}px`, border: styles.border, borderRadius: "8px", padding: '10px' }}>
                                                            <img src={base64} key={e['id']} style={{ objectFit: 'contain' }} alt={e['id']} width="100%" height="100%" />
                                                        </div>

                                                    </div>
                                                </span>
                                            </Grid>


                                        )


                                    })}
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div style={{ height: `${numHeightOfFooter}vh`, width: '100vw', backgroundColor: 'transparent' }}>
                <Grid container >
                    <Grid item xs={3} sx={{ paddingInline: '15px', display: 'flex', alignItems: 'center', justifyContent: 'start', backgroundColor: 'transparent', height: `${numHeightOfFooter}vh` }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'start' }}>
                            {addDefectDoneAndSent === false ? <Error errorMessage={addDefectMessage} /> : <></>}
                        </Box>
                    </Grid>
                    <Grid item xs={9} sx={{ paddingInline: '10px', display: 'flex', alignItems: 'center', justifyContent: 'end', backgroundColor: 'transparent', height: `${numHeightOfFooter}vh` }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'end' }}>
                            {showAddDefectLoader ?
                                <>
                                    <Button variant="contained" disabled sx={{
                                        textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                        "&.Mui-disabled": {
                                            backgroundColor: '#025196',
                                            color: '#ffffff'
                                        }
                                    }}>
                                        <CustomProgress color={`white`} size={15} /><span style={{ paddingLeft: '15px' }}>Adding...</span>
                                    </Button>
                                </>
                                :

                                <Button size="medium" variant="contained"
                                    onClick={handleUploadNewAsset}
                                    sx={{
                                        textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                        fontWeight: '700',
                                        '&:hover': {
                                            backgroundColor: '#025196',
                                            color: '#FFFFFF',
                                            fontWeight: '700',
                                        }
                                    }}>Add to project</Button>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        datastore: state.datastore,
        project: state.project,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addNewAsset: (proid, sectionId, archived, assetId, bbox, frameNumber, frameTime, geo, geoTime, isGPS, roadSide) => dispatch(addNewAsset(proid, sectionId, archived, assetId, bbox, frameNumber, frameTime, geo, geoTime, isGPS, roadSide)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewPotholeFeature);