import React, { useState, useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, Tooltip, CircularProgress, Stack, Typography, Box, Divider, TextField, IconButton, Button, Chip, Card, Checkbox } from "@mui/material";
import AssetVideoPlayer from "../../components/AssetVideoPlayer";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { getIndividualDetection, moveBasicImagingFeatureToArchive, removeGetIndividualDetectionErrorAndMessage, resetAsset, safetyConfirmDelta, updateAsset } from "../../../../../../store/actions/projectActions";
import { connect } from "react-redux";
import circle from '../../../../../../assets/selectorIcons/circle.svg'
import rectangle from '../../../../../../assets/selectorIcons/rectangle.svg'
import triangle from '../../../../../../assets/selectorIcons/triangle.svg'
import circleFilled from '../../../../../../assets/selectorIcons/circle_filled.svg'
import rectangleFilled from '../../../../../../assets/selectorIcons/rectangle_filled.svg'
import triangleFilled from '../../../../../../assets/selectorIcons/triangle_filled.svg'
import CustomProgress from "../../../../../cidgets/CustomProgress";
import PinOnMap from "../../components/PinOnMap";
import DetailPotholeEditFields from "./DetailPotholeEditFields";
import { useAccessControlContext } from "../../../../../../contexts/AccessControlProvider";

const DetailedPotholeEdit = (props) => {
    const layerRef = useRef(null);
    const { permissions } = useAccessControlContext();
    const { canArchiveDefectBasic, canConfirmDefectBasic } = permissions;
    const playerRef = useRef(null);
    const vidDivRef = useRef(null);
    const [vidDivDimen, setVidDivDimen] = useState({ height: 1, width: 1 })
    const [newSelectedAsset, setNewSelectedAsset] = useState(undefined);
    const baseUrl = "https://dr-utils-2zusqszkmq-uc.a.run.app";
    const svgScaleFactor = 1
    const { basicImagingDataInfo, handlePinned, fps, link, pinned, handleCloseDialog, datastore, project, qualities, proid,
        originalVideoHeight, originalVideoWidth, updateBasicImagingDefect, showUpdateDefectLoader } = props;
    const { featureArchiveIsDoneAndSent, showBasicFeatureArchiveLoader } = project;
    var { dataList } = props;

    const { dataInfo, assetData } = datastore;
    const [searchKeys, setSearchKeys] = useState("");
    const [compareDeltaCheck, setCompareDeltaCheck] = useState(undefined);
    const [searchResults, setSearchResults] = useState(undefined)
    const [primaryShape, setPrimaryShape] = useState("")
    const [primaryColor, setPrimaryColor] = useState("")
    const [secondaryColor, setSecondaryColor] = useState("")
    const [doSimilarity, setDoSimilarity] = useState("0")
    const [fieldValues, setFieldValues] = useState(undefined);
    const assetClassesWhereNameWillNotShow = ['0', '2', '3', '4', '998', '999'];
    const [imageDimensions, setImageDimensions] = useState({ height: 1, width: 1 });
    const [svgDimensions, setSvgDimensions] = useState({ height: 1, width: 1, scale: 1, offsetTop: 0, offsetLeft: 0 });
    const [assetCurrentIndex, setAssetCurrentIndex] = useState(0);
    const [bbox, setBbox] = useState({ downXY1: [], downXY2: [], size: 0, isEnabled: false })
    const [hbbox, setHbbox] = useState({ downX: 0, downY: 0 })
    const [addNewBbox, setAddNewBbox] = useState(false)
    const [coordinates, setCoordinates] = useState(pinned?.pred?.bbox ?? []);
    useEffect(() => {
        if (featureArchiveIsDoneAndSent === true) {
            handleCloseDialog();
            handlePinned(null);
        }
    }, [featureArchiveIsDoneAndSent])
    useEffect(() => {
        return () => {
            if (playerRef.current) {
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    useEffect(() => {
        handleSeekTo(pinned?.pred?.frameTime ?? 0);
        setCompareDeltaCheck(pinned.pred.isValid);
        setFieldValues({
            basicImagingClassState: pinned.pred.defectName,
            sideState: pinned.pred.side ?? '',
            priorityState: pinned.pred.priority ?? '',
            lateralPositionState: pinned.pred.lateralPosition ?? '',
            type: pinned.pred.typeIndex ?? '',
            assetClassState: pinned.meta?.class ?? '998',
            assetNameState: pinned.meta?.name ?? '',
            detectionQualityState: pinned.pred.detectionScore ?? 0,
            recognitionQualityState: pinned.pred.recognitionScore ?? 0,
            gpsCoordinatesState: `${pinned.pred.geo[0]},${pinned.pred.geo[1]}` ?? '',
            roadSideState: pinned.pred.roadSide ?? '',
            frameNumberState: pinned.pred.frameNumber ?? '',
        });
    }, [pinned])

    const handleCompareDeltaChange = (event) => {
        setCompareDeltaCheck(event.target.checked);
    };
    const handleReset = () => {
        setAddNewBbox(false);
        setCompareDeltaCheck(pinned.pred.isValid);
        setCoordinates(pinned?.pred?.bbox ?? [])
        setBbox({ downXY1: [], downXY2: [], size: 0, isEnabled: false })
        setHbbox({ downX: 0, downY: 0 })
        setFieldValues({
            type: pinned.pred.typeIndex ?? '',
            basicImagingClassState: pinned.pred.defectName,
            sideState: pinned.pred.side ?? '',
            priorityState: pinned.pred.priority ?? '',
            lateralPositionState: pinned.pred.lateralPosition ?? '',
            assetClassState: pinned.meta?.class ?? '998',
            assetNameState: pinned.meta?.name ?? '',
            detectionQualityState: pinned.pred.detectionScore ?? 0,
            recognitionQualityState: pinned.pred.recognitionScore ?? 0,
            gpsCoordinatesState: `${pinned.pred.geo[0]},${pinned.pred.geo[1]}` ?? '',
            roadSideState: pinned.pred.roadSide ?? '',
            frameNumberState: pinned.pred.frameNumber ?? '',
        });
    }
    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            // //////console.log(searchKeys)
            if (searchKeys.length > 0) {
                await sendRequest()
            }
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [searchKeys])

    useEffect(() => {
        async function getData() {
            await sendRequest()
        }
        getData()
    }, [primaryShape, primaryColor, secondaryColor]);

    const getVidWindowSize = () => {
        const newHeight = vidDivRef?.current?.clientHeight;
        const newWidth = vidDivRef?.current?.clientWidth;
        setVidDivDimen({
            height: newHeight,
            width: newWidth
        });
    };

    useEffect(() => {
        window.addEventListener("load", setVidDivDimen(vidDivRef?.current?.clientHeight))
        window.addEventListener("resize", getVidWindowSize, true);
    }, [])


    useEffect(() => {
        const divHeight = vidDivRef?.current?.clientHeight
        const divWidth = vidDivRef?.current?.clientWidth - 0;
        const imageHeight = originalVideoHeight
        const imageWidth = originalVideoWidth

        const asDiv = (divHeight / divWidth)
        const asImg = (imageHeight / imageWidth)
        const ratio = asDiv / asImg
        // //////console.log(asDiv, asImg, ratio, "ratio", vidDivRef);

        let svgWidth = 0
        let svgHeight = 0
        if (ratio >= 1) {
            svgWidth = divWidth
            svgHeight = asImg * divWidth
        } else {
            svgHeight = divHeight
            svgWidth = (1 / asImg) * divHeight
        }
        // //////console.log("Width:", divWidth, imageWidth, svgWidth)
        // //////console.log("Height:", divHeight, imageHeight, svgHeight)
        // //////console.log("Top:", (divHeight - svgHeight) / 2, "left:", (divWidth - svgWidth) / 2)
        setSvgDimensions({
            width: svgWidth,
            height: svgHeight,
            scale: imageHeight / svgHeight,
            offsetTop: (divHeight - svgHeight) / 2,
            offsetLeft: (divWidth - svgWidth) / 2,
        })
    }, [imageDimensions])
    useEffect(() => {
        setImageDimensions({
            height: originalVideoHeight,//playerRef?.current?.videoHeight,
            width: originalVideoWidth,//playerRef?.current?.videoWidth,
        })
    }, [pinned]);
    useEffect(() => {
        setImageDimensions({
            height: originalVideoHeight,//playerRef?.current?.videoHeight,
            width: originalVideoWidth,//playerRef?.current?.videoWidth,
        })
    }, [vidDivDimen])

    const handleSeekTo = (timeSeek) => {
        if (playerRef) {
            playerRef.current?.seek(timeSeek + (1 / fps))
        }
    }
    const sendRequest = async () => {
        const response = await fetch(baseUrl + `/thoroughSearch?keywords=${searchKeys}&primaryShape=${primaryShape}&primaryColor=${primaryColor}&secondaryColor=${secondaryColor}&doSimilarity=${doSimilarity}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error! status: ${response.status}`);
        }

        const result = await response.json();
        // //////console.log(result)
        setSearchResults(result)
    }
    const handleSearch = (e) => {
        setSearchKeys(e.target.value)
    }
    const handlePrimaryShape = async (e) => {
        const isSelected = e === primaryShape
        setPrimaryShape(isSelected ? '' : e)
    }
    const handlePrimaryColor = async (e) => {
        const isSelected = e === primaryColor
        setPrimaryColor(isSelected ? '' : e)
    }
    const handleSecondaryColor = async (e) => {
        const isSelected = e === secondaryColor
        setSecondaryColor(isSelected ? '' : e)
    }
    const handleChange = (prop) => (event) => {
        setFieldValues({ ...fieldValues, [prop]: event.target.value });
    };
    const index = (element) => element.id === pinned.pred.id;
    var assetIndex = dataList.findIndex(index);
    const getDistanceBetweenPoints = (x1, y1, x2, y2) => {
        var a = x1 - x2;
        var b = y1 - y2;
        return Math.floor(Math.sqrt(a * a + b * b));
    }
    useEffect(() => {
        if (assetIndex === -1) {
            nextAsset();
        }
    }, [assetIndex])
    useEffect(() => {
        if (bbox.downXY1.length === 2 && bbox.downXY2.length === 2) {
            const tlx = bbox.downXY1[0]
            const tly = bbox.downXY1[1]
            const brx = bbox.downXY2[0]
            const bry = bbox.downXY2[1]
            const h = getDistanceBetweenPoints(tlx, tly, tlx, bry)
            const w = getDistanceBetweenPoints(tlx, tly, brx, tly)
            setCoordinates([tlx, tly, w, h])

        }
    }, [bbox])
    const searchedSigns = searchResults !== undefined ? searchResults['signs'] : []
    const nextAsset = () => {

        if (assetIndex === -1) {

            if (dataList.length === 0) {
                handleCloseDialog();
            }

            if (assetCurrentIndex === dataList.length) {
                assetIndex = assetCurrentIndex - 1;
                const nextPred = dataList[assetIndex];
                const obj = {
                    pred: nextPred,
                }
                handlePinned(obj);
            }

            else {
                const nextPred = dataList[assetCurrentIndex];
                const obj = {
                    pred: nextPred
                }
                handlePinned(obj);
            }

        }
        else if (assetIndex < dataList.length) {
            assetIndex = assetIndex + 1;
            setAssetCurrentIndex(assetIndex);
            const nextPred = dataList[assetIndex];
            const obj = {
                pred: nextPred
            }
            handlePinned(obj);
        }

    }
    const previousAsset = () => {

        if (assetIndex >= 0) {
            assetIndex = assetIndex - 1;
            setAssetCurrentIndex(assetIndex);
            const nextPred = dataList[assetIndex];
            const obj = {
                pred: nextPred
            }
            handlePinned(obj);
        }
    }

    if (dataInfo === undefined) {
        return <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CustomProgress text={"Loading data"} />
        </div>
    }




    const shapeMap = {
        "map": dataInfo['shape'],
        "keys": Object.keys(dataInfo['shape']),
        "values": Object.values(dataInfo['shape']),
        "usefulKeys": ['0', '2', '3'],  // 0 tri  2 cir  3 rect
        "components": {
            // '0': <img src={triangle} alt="triangle" />,
            // '2': <img src={circle} alt="circle" />,
            // '3': <img src={rectangle} alt="rectangle" />
            '0': triangle,
            '2': circle,
            '3': rectangle
        },
        'filledComponents': {
            // '0': <img src={triangleFilled} />,
            // '2': <img src={circleFilled} />,
            // '3': <img src={rectangleFilled} />\
            '0': triangleFilled,
            '2': circleFilled,
            '3': rectangleFilled
        }
    }

    const handleSelectNewAssetId = (id) => {

        if (newSelectedAsset === undefined) {
            const data = assetData.filter((e) => `${e.id}` === id);
            setNewSelectedAsset(data[0]);
        }
        if (newSelectedAsset.id === id) {
            setNewSelectedAsset(undefined)
        }
        else {
            const data = assetData.filter((e) => `${e.id}` === id);
            setNewSelectedAsset(data[0]);
        }

    }

    const colorMap = {
        "map": dataInfo['color'],
        "keys": Object.keys(dataInfo['color']),
        "values": Object.values(dataInfo['color']),
        "usefulKeys": ['0', '1', '2', '3', '4', '5'],
        'primaryCollections': {
            '0': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="red" strokeWidth="3" fill="white" />
            </svg>,
            '1': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="black" strokeWidth="3" fill="white" />
            </svg>,
            '2': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="#dba102" strokeWidth="3" fill="white" />
            </svg>,
            '3': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="blue" strokeWidth="3" fill="white" />
            </svg>,
            '4': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="grey" strokeWidth="3" fill="white" />
            </svg>,
            '5': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="green" strokeWidth="3" fill="white" />
            </svg>,
            '6': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="brown" strokeWidth="3" fill="white" />
            </svg>,


        },
        'secondaryCollections': {
            '0': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="red" strokeWidth="3" fill="white" />
            </svg>,
            '1': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="black" strokeWidth="3" fill="white" />
            </svg>,
            '2': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="#dba102" strokeWidth="3" fill="white" />
            </svg>,
            '3': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="blue" strokeWidth="3" fill="white" />
            </svg>,
            '4': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="grey" strokeWidth="3" fill="white" />
            </svg>,
            '5': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="green" strokeWidth="3" fill="white" />
            </svg>,
            '6': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="brown" strokeWidth="3" fill="white" />
            </svg>,


        },
        'primaryFilled': {
            '0': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="red" strokeWidth="3" fill="red" />
            </svg>,
            '1': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="black" strokeWidth="3" fill="black" />
            </svg>,
            '2': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="#dba102" strokeWidth="3" fill="#dba102" />
            </svg>,
            '3': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="blue" strokeWidth="3" fill="blue" />
            </svg>,
            '4': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="grey" strokeWidth="3" fill="grey" />
            </svg>,
            '5': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="green" strokeWidth="3" fill="green" />
            </svg>,
            '6': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="brown" strokeWidth="3" fill="brown" />
            </svg>
        },
        'secondaryFilled': {
            '0': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="red" strokeWidth="3" fill="red" />
            </svg>,
            '1': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="black" strokeWidth="3" fill="black" />
            </svg>,
            '2': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="#dba102" strokeWidth="3" fill="#dba102" />
            </svg>,
            '3': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="blue" strokeWidth="3" fill="blue" />
            </svg>,
            '4': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="grey" strokeWidth="3" fill="grey" />
            </svg>,
            '5': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="green" strokeWidth="3" fill="green" />
            </svg>,
            '6': <svg height={svgScaleFactor * 20} width={svgScaleFactor * 20}>
                <circle cx={svgScaleFactor * 10} cy={svgScaleFactor * 10} r={svgScaleFactor * 8} stroke="brown" strokeWidth="3" fill="brown" />
            </svg>
        }
    }
    if (fieldValues === undefined) {
        return <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CustomProgress text={"Loading data"} />
        </div>
    }

    const handleNextFrame = () => {
        const secondPerFrame = 1 / fps
        playerRef.current.forward(secondPerFrame)
    }

    const handlePrevFrame = () => {
        const secondPerFrame = 1 / fps
        playerRef.current.replay(secondPerFrame)
    }

    const numHeightOfHeader = 8;
    const numHeightOfFooter = 8;
    const customPadding = 15;
    const fieldAreaMargin = 20;
    const getRectWh = (xy = [], wh = [], shouldScale = true, pred = false) => {
        if (xy.length !== 2 || wh.length !== 2) {
            return <></>
        }
        const x1 = xy[0] / (shouldScale ? svgDimensions.scale : 1)
        const y1 = xy[1] / (shouldScale ? svgDimensions.scale : 1)
        const x2 = (wh[0] + xy[0]) / (shouldScale ? svgDimensions.scale : 1)
        const y2 = (wh[1] + xy[1]) / (shouldScale ? svgDimensions.scale : 1)
        return <path d={`M ${x1}, ${y1} ${x1}, ${y2} ${x2}, ${y2} ${x2}, ${y1} z`} className="bbox-path" />
    }
    const cardStyle = {
        "border": "2px solid grey",
        "borderRadius": "8px",
        "backgroundColor": '#f5f1f1'
    }
    const isTrafficSignClass = !assetClassesWhereNameWillNotShow.includes(fieldValues.assetClassState);

    const handleBboxDelete = () => {
        setAddNewBbox(true);
        setBbox({ downXY1: [], downXY2: [], size: 0, isEnabled: false })
        setHbbox({ downX: 0, downY: 0 })
    }
    const handleMouseDown = (e) => {
        console.log(e, "handleMouseDown")
        let downXY1 = bbox.downXY1
        let downXY2 = bbox.downXY2
        if (downXY1.length !== 2) {
            downXY1 = [hbbox.downX, hbbox.downY]
        } else if (downXY2.length !== 2) {
            downXY2 = [hbbox.downX, hbbox.downY]
        } else {
            downXY1 = []
            downXY2 = []
        }
        setBbox({
            downXY1,
            downXY2,
            size: 0,
        })
    }
    const handleMouseMove = (e) => {
        console.log(e, "handleMouseMove")
        const event = e.nativeEvent;
        const layerX = event.clientX - layerRef.current.offsetLeft - svgDimensions.offsetLeft//event.target.getBoundingClientRect().left;//offsetX;
        const layerY = event.clientY - layerRef.current.offsetTop - svgDimensions.offsetTop//event.target.getBoundingClientRect().top;//offsetY;
        setHbbox({
            downX: ((layerX)) * svgDimensions.scale,
            downY: ((layerY)) * svgDimensions.scale,
        })
    }
    const svgColor = `rgba(96,238,0, ${0.2})`
    const svgStrokeColor = 'rgba(96,238,0, 0.9)'
    const svgPredColor = `rgba(240,240,10, ${0.2})`
    const svgPredStrokeColor = 'rgba(240,240,10, 0.9)'
    const getRect = (x1y1 = [], x2y2 = [], shouldScale = true, pred = false) => {
        if (x1y1.length !== 2 || x2y2.length !== 2) {
            return <></>
        }
        const x1 = x1y1[0] / (shouldScale ? svgDimensions.scale : 1)
        const y1 = x1y1[1] / (shouldScale ? svgDimensions.scale : 1)
        const x2 = x2y2[0] / (shouldScale ? svgDimensions.scale : 1)
        const y2 = x2y2[1] / (shouldScale ? svgDimensions.scale : 1)
        return <path d={`M ${x1}, ${y1} ${x1}, ${y2} ${x2}, ${y2} ${x2}, ${y1} z`} style={{ fill: pred ? svgPredColor : svgColor, strokeWidth: '3', stroke: pred ? svgPredStrokeColor : svgStrokeColor }} />
    }
    return (
        <div style={{ height: '100vh', width: '100vw', backgroundColor: '#FAFAFA' }}>
            <div style={{ height: `${numHeightOfHeader}vh`, width: '100vw', backgroundColor: '#FAFAFA', boxShadow: '5px 10px #FAFAFA', padding: `${customPadding}px` }}>
                <Grid container spacing={0}>
                    <Grid item xs={9} sx={{
                        display: 'flex', justifyContent: 'start',
                    }} >
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            Distress editor
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sx={{
                        display: 'flex', justifyContent: 'end', alignItems: 'center'
                    }}>
                        <CloseIcon
                            sx={{
                                color: "#1E1E1E",
                                cursor: "pointer",
                                margin: '0 5px 0 0'
                            }}
                            onClick={handleCloseDialog}
                        >
                        </CloseIcon>
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12} >
                        <Box sx={{ width: "100%", marginTop: '8px' }}>
                            <Divider ligth />
                        </Box>
                    </Grid>
                </Grid>
            </div>
            <div style={{ height: `${(100) - (numHeightOfHeader + numHeightOfFooter)}vh`, width: '100vw', backgroundColor: 'transparent' }}>
                <Grid container spacing={0}>
                    <Grid item xs={6} sx={{ backgroundColor: 'transparent', height: `${(100) - (numHeightOfHeader + numHeightOfFooter)}vh` }}>
                        <Grid container spacing={0} style={{ padding: `${customPadding}px` }}>
                            <Grid ref={vidDivRef} item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ backgroundColor: 'transparent', height: `${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh` }}>
                                <div ref={layerRef} className="container" style={{ padding: '0px' }}>
                                    <div className="inner" style={{ zIndex: 997 }}>
                                        <Card sx={cardStyle}>
                                            <AssetVideoPlayer fps={fps} handleNextFrame={handleNextFrame} handlePrevFrame={handlePrevFrame} playerRef={playerRef}
                                                link={link} pinned={pinned} height={vidDivRef.current === null ? 0 : vidDivRef.current?.clientHeight} disableControlsFully={true} startTime={pinned.pred.frameTime} shouldOffset={true} />
                                        </Card>
                                    </div>
                                    {!addNewBbox ?

                                        <div onClick={handleBboxDelete} className="inner" style={{ zIndex: 998, width: vidDivRef.current === null ? 0 : vidDivRef.current?.clientWidth }}>
                                            <div style={{
                                                height: '100%', width: '100%', backgroundColor: '', textAlign: 'center', display: 'flex',
                                                justifyContent: 'center', alignItems: 'center', marginTop: '-0px'
                                            }}>
                                                <svg height={svgDimensions.height} width={svgDimensions.width} style={{
                                                    backgroundColor: 'rgba(34,55,245, 0.0)',
                                                }}
                                                >
                                                    <Tooltip title="Click to edit bounding box" arrow placement="bottom" >
                                                        {getRectWh([coordinates[0], coordinates[1]], [coordinates[2], coordinates[3]])}
                                                    </Tooltip>

                                                </svg>
                                            </div>
                                        </div>

                                        :
                                        <div className="inner" style={{ zIndex: 998, width: vidDivRef.current === null ? 0 : vidDivRef.current?.clientWidth }}>
                                            <div style={{
                                                height: '100%', width: '100%', backgroundColor: '', textAlign: 'center', display: 'flex',
                                                justifyContent: 'center', alignItems: 'center', marginTop: '-0px'
                                            }}>
                                                <svg height={svgDimensions.height} width={svgDimensions.width} style={{
                                                    backgroundColor: 'rgba(34,55,245, 0.0)',
                                                }}
                                                    onMouseDownCapture={(e) => { handleMouseDown(e) }} onMouseMoveCapture={(e) => { handleMouseMove(e) }}
                                                >
                                                    {getRect(bbox.downXY1, bbox.downXY2)}
                                                    {bbox.downXY2.length === 2 ? <></> : getRect(bbox.downXY1, [hbbox.downX, hbbox.downY])}
                                                    <circle cx={hbbox.downX / svgDimensions.scale} cy={hbbox.downY / svgDimensions.scale} r={bbox.downXY2.length === 2 ? 3 : 2} fill={bbox.downXY2.length === 2 ? 'red' : bbox.downXY1.length === 2 ? svgStrokeColor : 'black'} />
                                                </svg>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Grid>
                            {/* https://stackoverflow.com/questions/73335540/sx-prop-not-applying-calc-function */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginTop: `${fieldAreaMargin}px`, backgroundColor: 'transparent', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ backgroundColor: 'transparent', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }} >

                                        <Card sx={cardStyle}>
                                            <div style={{ padding: '5px', overflow: 'auto', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }}>
                                                <DetailPotholeEditFields isQuickEdit={true} handleChange={handleChange} basicImagingDataInfo={basicImagingDataInfo} fieldValues={fieldValues} pinned={pinned} />
                                            </div>
                                        </Card>

                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ backgroundColor: 'transparent', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }}>
                                        <Card sx={cardStyle}>
                                            <div style={{ overflow: 'auto', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }}>
                                                <PinOnMap pinned={pinned} />
                                            </div>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid className={isTrafficSignClass ? "" : "container-disabled"} item xs={6} sx={{ backgroundColor: 'transparent', height: `${(100) - (numHeightOfHeader + numHeightOfFooter)}vh` }}>
                        <Grid container spacing={0} style={{ padding: `${customPadding}px ${customPadding}px ${customPadding}px 0px ` }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginBottom: '10px', backgroundColor: 'rgba(255,0,255,0.0)', height: `${((100) - (numHeightOfHeader + numHeightOfFooter)) * (10 / 100)}vh` }}>
                                <div style={{ display: 'flex', alignItems: 'start', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) * (10 / 100)}vh - 0px)`, marginBlock: '0px', backgroundColor: 'transparent' }}>
                                    <TextField sx={{
                                        border: "2px solid grey",
                                        borderRadius: "8px",
                                        backgroundColor: '#f5f1f1', width: '100%'
                                    }} id="fullWidth" type="text" name="seconds" onChange={handleSearch} placeholder="Try searching 20 speed limit or train sign" value={searchKeys} />
                                </div>

                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ backgroundColor: '', height: `${((100) - (numHeightOfHeader + numHeightOfFooter)) * (20 / 100)}vh` }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ backgroundColor: '', height: `${((100) - (numHeightOfHeader + numHeightOfFooter)) * (20 / 100)}vh` }} >
                                        <Card
                                            sx={{ ...cardStyle, padding: '10px' }}
                                        >
                                            <div style={{ overflow: 'auto', backgroundColor: 'transparent', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) * (20 / 100)}vh - 20px)` }}>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Filter with color</Typography>
                                                <Stack
                                                    sx={{ width: "100%" }}
                                                    direction={{ xs: "row", sm: "row", md: "row", lg: "row", xl: "row" }}
                                                    justifyContent="space-between"
                                                    alignItems="center"

                                                >
                                                    <Typography variant="subtitle2">Primary:</Typography>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        {colorMap.keys.filter((e) => colorMap.usefulKeys.includes(e)).map((e, i) => {

                                                            return <Tooltip key={e} title={primaryColor != e ? 'Select' : 'Unselect'} arrow>
                                                                <IconButton key={e} onClick={() => { handlePrimaryColor(e) }}>
                                                                    {
                                                                        primaryColor != e ? colorMap.primaryCollections[e] : colorMap.primaryFilled[e]
                                                                    }
                                                                </IconButton>
                                                            </Tooltip>
                                                        })}
                                                    </Box>
                                                </Stack>
                                                <Stack
                                                    sx={{ width: "100%" }}
                                                    direction={{ xs: "row", sm: "row", md: "row", lg: "row", xl: "row" }}
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    spacing={0}
                                                >
                                                    <Typography variant="subtitle2">Secondry:</Typography>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        {colorMap.keys.filter((e) => colorMap.usefulKeys.includes(e)).map((e, i) => {


                                                            return <Tooltip key={e} title={secondaryColor != e ? 'Select' : 'Unselect'} arrow>
                                                                <IconButton key={e} onClick={() => { handleSecondaryColor(e) }}>
                                                                    {
                                                                        secondaryColor != e ? colorMap.secondaryCollections[e] : colorMap.secondaryFilled[e]
                                                                    }
                                                                </IconButton>
                                                            </Tooltip>
                                                        })}

                                                    </Box>
                                                </Stack>
                                            </div>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ backgroundColor: 'transparent', height: `${((100) - (numHeightOfHeader + numHeightOfFooter)) * (20 / 100)}vh` }}>

                                        <Card sx={{ ...cardStyle, padding: '10px' }}>
                                            <div style={{ overflow: 'auto', backgroundColor: 'transparent', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) * (20 / 100)}vh - 20px)` }}>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold' }} >Filter with sign shape</Typography>
                                                <Stack
                                                    sx={{ width: "100%" }}
                                                    direction={{ xs: "row", sm: "row", md: "row", lg: "row", xl: "row" }}
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >
                                                    <Typography variant="subtitle2">Select:</Typography>
                                                    <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                                        {shapeMap.keys.filter((e) => shapeMap.usefulKeys.includes(e)).map((e, i) => {
                                                            return <Tooltip key={e} title={primaryShape != e ? 'Select' : 'Unselect'} arrow>
                                                                <IconButton key={e} onClick={() => { handlePrimaryShape(e) }}>
                                                                    <img src={primaryShape != e ? shapeMap.components[e] : shapeMap.filledComponents[e]} alt=" no data" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        })}
                                                    </Box>
                                                </Stack>
                                            </div>
                                        </Card>

                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ overflow: 'auto', backgroundColor: '', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) * (70 / 100)}vh - ${customPadding * 2}px)`, paddingBlock: `${customPadding * 2}px` }}>
                                <Grid container columnGap={0} rowSpacing={2}>
                                    {searchedSigns.map((e, i) => {
                                        const base64 = `data:image/png;base64, ${e['base64']}`;
                                        const bestMatcher = e['id'] === (newSelectedAsset?.id ?? '-1')
                                        const styles = bestMatcher ? {
                                            border: "4px solid green",
                                        } : {
                                            border: "0.5px solid grey",
                                        }
                                        return (

                                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>

                                                <span role="button" key={e['id']} style={{ cursor: 'pointer' }} onClick={() => { handleSelectNewAssetId(e['id']) }}>
                                                    <div style={{ width: `${vidDivRef.current.clientWidth / 6}px`, height: `${vidDivRef.current.clientWidth / 6}px` }}>
                                                        <div style={{ width: `${(vidDivRef.current.clientWidth / 6) - 4}px`, height: `${(vidDivRef.current.clientWidth / 6) - 4}px`, border: styles.border, borderRadius: "8px", padding: '10px' }}>
                                                            <img src={base64} key={e['id']} style={{ objectFit: 'contain' }} alt={e['id']} width="100%" height="100%" />
                                                        </div>

                                                    </div>
                                                </span>
                                            </Grid>
                                        )
                                    })}
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div style={{ height: `${numHeightOfFooter}vh`, width: '100vw', backgroundColor: 'transparent' }}>
                <Grid container spacing={0}>
                    <Grid item xs={8} sx={{ backgroundColor: 'transparent', height: `${numHeightOfFooter}vh`, }} >
                        <Grid container spacing={0}>
                            <Grid item xs={3} sx={{ alignItems: 'center', backgroundColor: '', height: `${numHeightOfFooter}vh`, display: 'flex', justifyContent: 'start', paddingLeft: `${customPadding}px` }} >
                                {dataList.length > 0 ? <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
                                    <Card sx={{ borderRadius: '50px', border: '1px solid #000000' }}>
                                        <IconButton size="small" onClick={previousAsset} disabled={assetIndex === 0 ? true : false}>
                                            <KeyboardArrowLeftIcon sx={{ color: '#1E1E1E' }} />
                                        </IconButton>
                                    </Card>
                                    <Typography variant="h6" component="span" sx={{ fontWeight: 'bold', fontSize: { xs: '10px', sm: '10px', md: '14px' }, padding: `0px ${customPadding + 10}px 0px ${customPadding + 10}px` }}>Distress {assetIndex + 1} / {dataList.length}</Typography>
                                    <Card sx={{ borderRadius: '50px', border: '1px solid #000000' }} >
                                        <IconButton size="small" onClick={nextAsset} disabled={assetIndex === dataList.length - 1 ? true : false}>
                                            <KeyboardArrowRightIcon sx={{ color: '#1E1E1E' }} />
                                        </IconButton>
                                    </Card>
                                </Box> : <p>no data</p>}
                            </Grid>
                            <Grid item xs={3} sx={{ alignItems: 'center', backgroundColor: '', height: `${numHeightOfFooter}vh`, display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #929292', background: '#FFFFFF', borderRadius: '7px' }}>
                                    <Typography variant='body2' sx={{ marginLeft: '10px', color: '#606060', fontWeight: '700' }}>Cofirm data</Typography>
                                    <Checkbox size='small' onChange={handleCompareDeltaChange} checked={compareDeltaCheck} disabled={!canConfirmDefectBasic ? true : false} disableRipple={true} sx={{
                                        color: '#606060',
                                        '&.Mui-checked.Mui-disabled': {
                                            color: '#f0f0f0',
                                            '& .MuiSvgIcon-root': {
                                                backgroundColor: '#606060',
                                            },
                                        },
                                        '&.Mui-checked': {
                                            color: '#606060',
                                            '& .MuiSvgIcon-root': {
                                                backgroundColor: '#f0fof0',
                                            },
                                        },
                                    }} />
                                </Box>
                            </Grid>
                            {/* <Grid item xs={7} sx={{ alignItems: 'center', backgroundColor: '', height: `${numHeightOfFooter}vh`, display: 'flex', justifyContent: 'center' }} >
                                <Box sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: { xs: '10px', sm: '10px', md: '14px' } }}>Filters applied ({qualities.length}/3) : </Typography>
                                    <Box sx={{ marginLeft: '5px' }}>
                                        <Chip size='small' label={qualities.includes(2) ? "Low priority X" : "Low priority"} variant="outlined" sx={{ cursor: '', borderColor: '#1538B2', backgroundColor: `#D7DDE1`, color: '#1538B2', '&:hover': { borderColor: '#1538B2', backgroundColor: `#D7DDE1`, color: '#1538B2' } }} />
                                        <Chip size='small' label={qualities.includes(1) ? "Medium priority X" : "Medium priority"} variant="outlined" sx={{ marginLeft: '10px', cursor: '', borderColor: '#1538B2', backgroundColor: `#D7DDE1`, color: '#1538B2', '&:hover': { borderColor: '#1538B2', backgroundColor: `#D7DDE1`, color: '#1538B2' } }} />
                                        <Chip size='small' label={qualities.includes(0) ? "High priority X" : "High priority"} variant="outlined" sx={{ marginLeft: '10px', cursor: '', borderColor: '#1538B2', backgroundColor: `#D7DDE1`, color: '#1538B2', '&:hover': { borderColor: '#1538B2', backgroundColor: `#D7DDE1`, color: '#1538B2' } }} />
                                    </Box>
                                </Box>
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item xs={4} sx={{ backgroundColor: 'transparent', height: `${numHeightOfFooter}vh` }}>
                        <Grid container spacing={0}>
                            <Grid item xs={3} sx={{ alignItems: 'center', backgroundColor: '', height: `${numHeightOfFooter}vh`, display: 'flex', justifyContent: 'start', paddingLeft: `${0}px` }} >
                                {/* <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', md: 'flex' }, marginTop: '5px', marginBottom: '5px' }} /> */}
                                <Box sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'row', alignItems: 'center', justifyContent: 'start', marginLeft: '0px' }} onClick={handleReset}>
                                    <Typography sx={{ textDecoration: 'underline', color: '#0000FF', cursor: 'pointer' }}>Reset changes</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={3} sx={{ alignItems: 'center', backgroundColor: '', height: `${numHeightOfFooter}vh`, display: 'flex', justifyContent: 'start', }} >

                                {showBasicFeatureArchiveLoader ?
                                    <>
                                        <Button variant="text" disabled
                                            sx={{
                                                textTransform: 'initial', color: '#000000', "&.Mui-disabled": {
                                                    backgroundColor: '#FAFAFA',
                                                    color: '#000000',
                                                }
                                            }}>
                                            <CircularProgress variant="indeterminate" size={15} sx={{ color: '#000000' }} /><span style={{ paddingLeft: '15px' }}>Moving...</span>
                                        </Button>
                                    </>
                                    : canArchiveDefectBasic ? <Box sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onClick={() => { props.moveBasicImagingFeatureToArchive(proid, pinned.pred.sectionId, pinned.pred.id) }}>
                                        <Typography sx={{ textDecoration: 'underline', color: '#0000FF', cursor: 'pointer' }}>Move to archive</Typography>
                                    </Box> : <></>}

                            </Grid>

                            <Grid tem xs={6} sx={{ alignItems: 'center', backgroundColor: '', height: `${numHeightOfFooter}vh`, display: 'flex', justifyContent: 'center', paddingLeft: `${0}px` }} >
                                <Box sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
                                    {showUpdateDefectLoader ?
                                        <>
                                            <Button variant="contained" disabled sx={{
                                                textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                                "&.Mui-disabled": {
                                                    backgroundColor: '#025196',
                                                    color: '#ffffff'
                                                }
                                            }}>
                                                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Saving...</span>
                                            </Button>
                                        </>
                                        :

                                        <Button size="small" variant="contained"
                                            onClick={() => {
                                                updateBasicImagingDefect(proid, pinned.pred.sectionId, pinned.pred.id,
                                                    fieldValues.priorityState, fieldValues.basicImagingClassState, compareDeltaCheck, coordinates)
                                            }}
                                            sx={{
                                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                                fontWeight: '700',
                                                '&:hover': {
                                                    backgroundColor: '#025196',
                                                    color: '#FFFFFF',
                                                    fontWeight: '700',
                                                }
                                            }}>Save changes</Button>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div >
    )
};
const mapStateToProps = (state) => {
    return {
        datastore: state.datastore,
        project: state.project,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        moveBasicImagingFeatureToArchive: (proid, sectionId, detectionId) => dispatch(moveBasicImagingFeatureToArchive(proid, sectionId, detectionId)),
        resetAsset: (proid, sectionId, detectionId) => dispatch(resetAsset(proid, sectionId, detectionId)),
        updateAsset: (proid, sectionId, detectionId, roadSide, assetId) => dispatch(updateAsset(proid, sectionId, detectionId, roadSide, assetId)),
        getIndividualDetection: (proid, outputId) => dispatch(getIndividualDetection(proid, outputId)),
        removeGetIndividualDetectionErrorAndMessage: () => dispatch(removeGetIndividualDetectionErrorAndMessage()),
        safetyConfirmDelta: (proid, sectionId, detectionId) => dispatch(safetyConfirmDelta(proid, sectionId, detectionId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailedPotholeEdit);
