import React, { useState, useRef, useEffect } from 'react'
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Box, Button, Card, Checkbox, CircularProgress, Dialog, Divider, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { useKeyPress } from '../../../../../../chooks/useKeyPress';
import DetailedPotholeEdit from './DetailedPotholeEdit';
import CustomProgress from '../../../../../cidgets/CustomProgress';
import PotholeEditFields from './PotholeEditFields';
import { useAccessControlContext } from '../../../../../../contexts/AccessControlProvider';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import HistoryIcon from '@mui/icons-material/History';

const PinnedPothole = (props) => {
    const { permissions } = useAccessControlContext();
    const { canEditDefectBasic, canConfirmDefectBasic } = permissions;
    const {
        isQuickEdit, setIsQuickEdit,
        defectImageURLs,
        drProjectData,
        showUpdateDefectLoader,
        showBasicValidConfirmLoader,
        resetArchiveFeature,
        updateDefectDoneAndSent,
        basicValidConfirmIsDoneAndSent,
        updateBasicImagingDefect,
        clearUpdateErrorAndMsg,
        basicImagingValidConfirm,
        basicImagingDataInfo,
        clearArchiveErrorAndMsg,
        basicFeatureArchiveIsDoneAndSent,
        clearValidConfirmErrorAndMsg,
        previousAsset, assetIndex, nextAsset, timeList,
        handleClickOpenDialog, openDialog, handleCloseDialog, pinned, handlePinned,
        imageBase64,
        handleQuality, qualities, proid, fps, realFps, link, gpsData, gpsDataCombined,
        originalVideoHeight, originalVideoWidth } = props;
    const label = { inputProps: { 'aria-label': 'Checkbox feature' } };
    const [fieldValues, setFieldValues] = useState(undefined);
    const [compareDeltaCheck, setCompareDeltaCheck] = useState(undefined);
    const isConfirmed = pinned?.pred?.isConfirmed ? pinned?.pred?.isConfirmed : false;
    const confirmationComplete = drProjectData.confirmationComplete ? drProjectData.confirmationComplete : false;
    const showValidConfirm = isConfirmed === false && confirmationComplete === true ? true : false;
    const handleCompareDeltaChange = (event) => {
        setCompareDeltaCheck(event.target.checked);
    };
    const handleChange = (prop) => (event) => {
        setFieldValues({ ...fieldValues, [prop]: event.target.value });
    };
    const NEXT = useKeyPress('ArrowRight')
    const PREV = useKeyPress('ArrowLeft')

    useEffect(() => {
        if (updateDefectDoneAndSent === true) {
            setIsQuickEdit(false);
            handleCloseDialog();
            clearUpdateErrorAndMsg();
        }
    }, [updateDefectDoneAndSent])
    useEffect(() => {
        if (basicFeatureArchiveIsDoneAndSent === true) {
            handleCloseDialog();
            handlePinned(null);
            clearArchiveErrorAndMsg();
        }
    }, [basicFeatureArchiveIsDoneAndSent])
    useEffect(() => {
        if (basicValidConfirmIsDoneAndSent === true) {
            handleCloseDialog();
            clearValidConfirmErrorAndMsg();
        }
    }, [basicValidConfirmIsDoneAndSent])
    useEffect(() => {
        if (NEXT) {
            if (!(assetIndex === timeList.length - 1)) {
                nextAsset()
            }
        } else if (PREV) {
            if (!(assetIndex === 0)) {
                previousAsset()
            }
        }
    }, [NEXT, PREV])
    const cardLightStyle = {
        "border": "0px solid grey",
        "borderRadius": "8px",
        "padding": "5px",
        "backgroundColor": '#f5f1f1'
    }
    const ref = useRef(null)
    const imageHeight = ref?.current?.clientHeight ?? 0


    useEffect(() => {
        setCompareDeltaCheck(pinned.pred.isValid);
        setFieldValues({
            basicImagingClassState: pinned.pred.defectName,
            sideState: pinned.pred.side ?? '',
            priorityState: pinned.pred.priority ?? '',
            lateralPositionState: pinned.pred.lateralPosition ?? '',
            type: pinned.pred.typeIndex ?? ''
        });
    }, [pinned])

    const handleDiscardChanges = () => {
        setIsQuickEdit(false);
        setCompareDeltaCheck(pinned.pred.isValid);
        setFieldValues({
            basicImagingClassState: pinned.pred.defectName,
            sideState: pinned.pred.side ?? '',
            priorityState: pinned.pred.priority ?? '',
            lateralPositionState: pinned.pred.lateralPosition ?? '',
              type: pinned.pred.typeIndex ?? ''
        });
    }

    const handleSaveChanges = () => {
        updateBasicImagingDefect(proid, pinned.pred.sectionId, pinned.pred.id, fieldValues.priorityState, fieldValues.basicImagingClassState, compareDeltaCheck, pinned?.pred?.bbox ?? [])
    }
    const handleConfirmValid = () => {
        basicImagingValidConfirm(proid, pinned.pred.sectionId, pinned.pred.id)
    }
    if (fieldValues === undefined) {
        return <CustomProgress text={"Loading data"} />
    }
    const handleDetailEdit = () => {
        handleClickOpenDialog();
        resetArchiveFeature();
    }
    return (
        <>
            <div ref={ref} id='table-pinned' style={{ height: `100%`, backgroundColor: 'transparent' }}>
                <Box sx={{ paddingInline: '15px', height: { xs: '45%', md: '25%' }, display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <Grid container spacing={0} sx={{}}>
                        <Grid item xs={4} sm={4} md={6} lg={showValidConfirm ? 6 : 7} xl={showValidConfirm ? 6 : 7} sx={{
                            display: 'flex', justifyContent: 'start', alignItems: 'end',
                        }} >
                            <Grid container spacing={0} sx={{
                                display: 'flex', justifyContent: 'start', alignItems: 'end',
                            }} >
                                <Grid item xs={showValidConfirm ? 12 : 12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                    <Box >
                                        <IconButton onClick={previousAsset} disabled={assetIndex === 0 ? true : false}>
                                            <Tooltip title="Previous distress"><KeyboardArrowLeftIcon sx={{ color: assetIndex === 0 ? 'grey' : '#1E1E1E' }} /></Tooltip>
                                        </IconButton>
                                        <Typography variant="h6" component="span" sx={{ fontWeight: 'bold', fontSize: { xs: '10px', sm: '10px', md: '16px' }, padding: `0px ${15 + 10}px 0px ${15 + 10}px` }}>Distress {assetIndex + 1} / {timeList.length}</Typography>
                                        <IconButton onClick={nextAsset} disabled={assetIndex === timeList.length - 1 ? true : false}>
                                            <Tooltip title="Next distress"><KeyboardArrowRightIcon sx={{ color: assetIndex === timeList.length - 1 ? 'grey' : '#1E1E1E' }} /></Tooltip>
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={8} sm={8} md={6} lg={showValidConfirm ? 6 : 5} xl={showValidConfirm ? 6 : 5} sx={{
                            display: 'flex', justifyContent: 'end', alignItems: 'center',
                        }}>
                            {showValidConfirm ?
                                showBasicValidConfirmLoader ? <Button variant="contained" disabled sx={{
                                    textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                    "&.Mui-disabled": {
                                        backgroundColor: '#025196',
                                        color: '#ffffff'
                                    }
                                }}>
                                    <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Confirming...</span>
                                </Button> :
                                    <Button variant="contained" size="small" sx={{
                                        marginInline: '10px',
                                        textTransform: 'initial',
                                        backgroundColor: '#025196', ':hover': {
                                            backgroundColor: '#025196'
                                        },
                                    }} onClick={handleConfirmValid}>Confirm</Button>
                                : <></>}
                            {showValidConfirm ?
                                <></> :
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: isQuickEdit ? '1px solid #FFFFFF' : '', backgroundColor: isQuickEdit ? '' : '#FFFFFF', borderRadius: '7px' }}>
                                    <Checkbox {...label} size='small' onChange={handleCompareDeltaChange} checked={compareDeltaCheck} disabled={!canConfirmDefectBasic ? true : isQuickEdit ? false : true} disableRipple={true}
                                        sx={{
                                            color: '#606060',
                                            '&.Mui-checked.Mui-disabled': {
                                                color: '#f0f0f0',
                                                '& .MuiSvgIcon-root': {
                                                    backgroundColor: '#606060',
                                                },
                                            },
                                            '&.Mui-checked': {
                                                color: '#606060',
                                                '& .MuiSvgIcon-root': {
                                                    backgroundColor: '#f0fof0',
                                                },
                                            },
                                        }} />

                                </Box>
                            }

                            {isQuickEdit ? <></> :
                                <Tooltip title="Quick Edit">
                                    <IconButton
                                        size="small"
                                        onClick={() => setIsQuickEdit(true)}
                                        disabled={!canEditDefectBasic}
                                        sx={{
                                            marginLeft: '10px',
                                            marginRight: '10px',

                                        }}
                                    >
                                        <HistoryIcon />
                                    </IconButton>
                                </Tooltip>
                            }


                            {!isQuickEdit && (
                                <Tooltip title="Detailed Edit">
                                    <IconButton
                                        size="small"
                                        onClick={handleDetailEdit}
                                        disabled={!canEditDefectBasic}
                                        sx={{
                                            marginRight: '10px',

                                        }}
                                    >
                                        <ArrowCircleRightOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {isQuickEdit ? <Button variant="contained" size="small" sx={{
                                marginInline: '10px',
                                textTransform: 'initial',
                                backgroundColor: '#6C6C6C', ':hover': {
                                    backgroundColor: '#6C6C6C'
                                },
                            }} onClick={() => { handleDiscardChanges() }}>Discard changes</Button> : <></>}
                            {isQuickEdit ? showUpdateDefectLoader ? <Button variant="contained" disabled sx={{
                                textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                "&.Mui-disabled": {
                                    backgroundColor: '#025196',
                                    color: '#ffffff'
                                }
                            }}>
                                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Saving...</span>
                            </Button> :
                                <Button variant="contained" size="small" sx={{
                                    marginInline: '10px',
                                    textTransform: 'initial',
                                    backgroundColor: '#025196', ':hover': {
                                        backgroundColor: '#025196'
                                    },
                                }} onClick={handleSaveChanges}>Save changes</Button>
                                : <></>}

                            <Dialog fullScreen open={openDialog} onClose={handleCloseDialog}>
                                <DetailedPotholeEdit
                                    updateBasicImagingDefect={props.updateBasicImagingDefect}
                                    showUpdateDefectLoader={showUpdateDefectLoader}
                                    showBasicValidConfirmLoader={showBasicValidConfirmLoader}
                                    handleConfirmValid={handleConfirmValid}
                                    drProjectData={drProjectData}
                                    basicImagingDataInfo={basicImagingDataInfo}
                                    originalVideoHeight={originalVideoHeight}
                                    originalVideoWidth={originalVideoWidth}
                                    setQualities={handleQuality}
                                    qualities={qualities}
                                    proid={proid}
                                    handlePinned={handlePinned}
                                    fps={fps} realFps={realFps}
                                    link={link} pinned={pinned} handleCloseDialog={handleCloseDialog}
                                    dataList={timeList} gpsData={gpsData} gpsDataCombined={gpsDataCombined} />
                            </Dialog>
                            <Tooltip title='Unpin this distress'>
                                <ClearIcon
                                    sx={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => { handlePinned(pinned) }}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>

                <div style={{ marginLeft: '15px', marginRight: '15px', height: '5%', backgroundColor: 'transparent', display: 'flex', justifyContent: 'start', alignItems: 'start' }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <Box sx={{ width: "100%" }}>
                                <Divider light />
                            </Box>
                        </Grid>
                    </Grid>
                </div>

                <Box sx={{ paddingInline: '15px', height: { xs: '50%', md: '70%' }, width: '100%', backgroundColor: 'transparent', display: 'flex', justifyContent: 'start', alignItems: 'start' }}>

                    <div style={{ backgroundColor: 'transparent', float: 'left', width: '60%', height: '100%' }}>
                        <Card sx={{ ...cardLightStyle, height: '87%', width: '100%', overflow: 'auto' }} elevation={0}>
                            <PotholeEditFields isQuickEdit={isQuickEdit} handleChange={handleChange} basicImagingDataInfo={basicImagingDataInfo}
                                fieldValues={fieldValues} pinned={pinned} />
                        </Card>
                    </div>

                    {/* <div style={{ overflow: 'auto', backgroundColor: 'transparent', float: 'left', height: '100%', width: '40%', display: 'flex', alignItems: 'start', justifyContent: 'center' }}>
                        <div style={{ width: '100%', display: 'table', paddingLeft: '10px' }}>
                            <div style={{ display: 'table-row', height: `calc(${imageHeight * 0.4}px)` }}>
                                <Tooltip title="Original image" arrow>
                                    <div style={{ width: '50%', display: 'table-cell', background: 'transparent', paddingRight: '5px' }}>
                                        <img style={{ width: '100%', height: `calc(${imageHeight * 0.4}px)`, objectFit: 'contain' }} src={defectImageURLs[pinned.pred.id]} />
                                    </div>
                                </Tooltip>
                                {imageBase64 ?
                                    <Tooltip title="Recognition" arrow>
                                        <div style={{ display: 'table-cell', background: 'transparent' }}>
                                            <img style={{ width: '100%', height: `calc(${imageHeight * 0.4}px)`, paddingLeft: '5px', objectFit: 'contain' }} src={'data:image/png;base64,' + imageBase64 ?? ''} />
                                        </div>
                                    </Tooltip>
                                    :
                                    <React.Fragment />}
                            </div>
                        </div>

                    </div> */}
                </Box >
            </div >
        </>
    )
}
export default PinnedPothole
