import React, { useState } from "react";
import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
} from "@mui/material";
const DeleteVideos = ({ videoList, onDelete }) => {

    const [checkedVideos, setCheckedVideos] = useState([]);
    const handleToggle = (id) => () => {
        const currentIndex = checkedVideos.indexOf(id);
        const newChecked = [...checkedVideos];

        if (currentIndex === -1) {
            newChecked.push(id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        const newList = [...videoList].sort((a, b) => a.correctedOrder - b.correctedOrder).filter((video) => !newChecked.includes(video.correctedOrder));
        setCheckedVideos(newChecked);
        onDelete(newList);
    };

    return (
        <div>
            <List>
                {videoList && [...videoList].sort((a, b) => a.correctedOrder - b.correctedOrder).map((video) => (
                    <ListItem
                        key={video.correctedOrder}
                        button
                        onClick={handleToggle(video.correctedOrder)}
                    >
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={checkedVideos.indexOf(video.correctedOrder) !== -1}
                                tabIndex={-1}
                                disableRipple
                            />
                        </ListItemIcon>
                        <ListItemText primary={video.name} />
                    </ListItem>
                ))}
            </List>
        </div>
    )
}

export default DeleteVideos