import React, { useEffect, useState } from "react";
import { Avatar, Button, CircularProgress } from "@mui/material";
import { Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import { connect } from "react-redux";
import { changePassword, removeChangePasswordMessage, signOut } from "../../../../../../store/actions/authActions";
import { updateCompanyDetails } from '../../../../../../store/actions/companyActions';
import { Link } from "react-router-dom";
import * as ROUTES from "../../../../../../constants/routes";
import Divider from '@mui/material/Divider';
import Box from "@mui/material/Box";
import { db } from "../../../../../../store/LocalDatabase";
import { useAccessControlContext } from "../../../../../../contexts/AccessControlProvider";
import SimpleDialogBox from "../../../../../cidgets/SimpleDialogBox";
import UpdateUserDetails from "./UpdateUserDetails";
import { useNetworkContext } from "../../../../../../contexts/NetworkProvider";

// Function to retrieve the access code
export const getAccessCode = async () => {
  try {
    const result = await db.accessCodes.toArray();
    return result.length > 0 ? result[0].accessCode : null;
  } catch (error) {
    console.error('Error getting access code:', error);
    return null;
  }
};

const ProfileInfo = (props) => {
  // README: isProjectDashbaorad is passed from topbar to make avatar green.
  const { userData, themeRed, isProjectDashboard, basicImagingGlobalDefects, company, companyData, auth } = props;
  //console.log(auth.passwordChangeError, 'auth')
  // const accessCode = localStorage.getItem('hashedId')
  const [accessCode, setAccessCode] = useState(null);
  const { permissions } = useAccessControlContext();
  const { isNetworkView } = useNetworkContext();
  const { accountButton, surveyPlannerButton, canSeeGlobalDefects } = permissions;
  // //////console.log(userData, "u")
  const { name, email, nameInitial, uid, coid, dev, accessControlRoom } = userData;
  const { appTitle, themeColor } = themeRed;
  const [openDetails, setOpenDetails] = useState(false);

  const [showImg, setShowImg] = useState(null)
  const [fileName, setFileName] = useState('');
  const [values, setValues] = useState({
    image: null,
  });

  const [fullNameError, setFullNameError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [imageError, setImageError] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');

  const [accountTitle, setAccountTitle] = useState(companyData.companyName ?? "");
  const [fullName, setFullName] = useState(userData.name ?? "");
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');

  useEffect(() => {
    if (auth.passwordChangeError === null && (company.isDetailsUpdated === true || auth.isPasswordUpdated === true)) {
      handleDetailsClose();
    }
  }, [company.isDetailsUpdated, auth.isPasswordUpdated]);

  useEffect(() => {
    const fetchAccessCode = async () => {
      const id = await getAccessCode();
      setAccessCode(id);
    };

    fetchAccessCode();
  }, [])
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: '#025196',
        width: 35, height: 35,
        fontSize: 16,
      },
      children: name,
    };
  }



  const handleDetailsOpen = () => {
    setOpenDetails(true);
  };

  const handleDetailsClose = () => {
    setOpenDetails(false);
  };

  const handleClearState = () => {
    setFullNameError("");
    setCompanyNameError("");
    setImageError(false);
    setCurrentPasswordError("");
    setPasswordError("");
  }
  const handleClearStateFields = () => {
    setAccountTitle(companyData.companyName ?? "");
    setFullName(userData.name ?? "");
    setNewPassword("");
    setCurrentPassword("");
    setShowImg(null);
    setValues({
      image: null,
    })
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    props.removeChangePasswordMessage();
    handleClearState();
    if (!fullName.trim()) {
      setFullNameError("required");
      return;
    }

    if (!/[a-zA-Z]/.test(fullName)) {
      setFullNameError("invalidCharacters");
      return;
    }

    if (fullName.length > 30) {
      setFullNameError("maxLength");
      return;
    }

    setFullNameError("");

    if (!accountTitle.trim()) {
      setCompanyNameError("required");
      return;
    }

    if (!/[a-zA-Z]/.test(accountTitle)) {
      setCompanyNameError("invalidCharacters");
      return;
    }

    if (accountTitle.length > 30) {
      setCompanyNameError("maxLength");
      return;
    }

    setCompanyNameError("");

    if (!values.image && !companyData.imagePath) {
      setImageError(true);
      return;
    }
    setImageError(false);

    if (currentPassword.trim() === '' && newPassword) {
      setCurrentPasswordError('Please enter a current password');
      return;
    }

    if (newPassword.trim() === '' && currentPassword) {
      setPasswordError('Please enter a new password');
      return;
    }

    if (newPassword.trim().length < 6 && currentPassword) {
      setPasswordError('Password should be at least 6 characters long');
      return;
    }

    let trimmedfullName = fullName.trim()
    let trimmedOrgName = accountTitle.trim()

    props.updateCompanyDetails(
      companyData.coid,
      values.image,
      trimmedOrgName,
      trimmedfullName,
      uid,
    );

    if (currentPassword.trim() !== '' && newPassword.trim() !== '') {
      props.changePassword(currentPassword, newPassword);
    }
    setCurrentPassword('');
    setNewPassword('');

  }


  return (
    <>
      <SimpleDialogBox
        open={openDetails}
        onClose={handleDetailsClose}
        title="Update profile "
        width={1000}
        disableEscape={true}
        errorMessage={auth.passwordChangeError ? auth.passwordChangeMessage : ""}
        showError={auth.passwordChangeError ? true : false}
        bodyWidget={
          <UpdateUserDetails
            handleClearState={handleClearState}
            handleClearStateFields={handleClearStateFields}
            userData={userData}
            setShowImg={setShowImg}
            setValues={setValues}
            setFileName={setFileName}
            fullName={fullName}
            fullNameError={fullNameError}
            setFullName={setFullName}
            setFullNameError={setFullNameError}
            companyNameError={companyNameError}
            accountTitle={accountTitle}
            setAccountTitle={setAccountTitle}
            setCompanyNameError={setCompanyNameError}
            setCurrentPassword={setCurrentPassword}
            setCurrentPasswordError={setCurrentPasswordError}
            setNewPassword={setNewPassword}
            setPasswordError={setPasswordError}
            handleSubmit={handleSubmit}
            showImg={showImg}
            imageError={imageError}
            currentPassword={currentPassword}
            currentPasswordError={currentPasswordError}
            newPassword={newPassword}
            passwordError={passwordError}
          />
        }
        showPositive
        positiveButtonWidget={
          <>
            {company.isDetailsUpdating === true ? (
              <Button variant="contained" disabled sx={{
                "&.Mui-disabled": {
                  backgroundColor: '#025196',
                  color: '#D9D9D9'
                },
                marginInline: '2px',
                textTransform: 'initial',
                color: '#025196',
                backgroundColor: '#025196',
                ':hover': {
                  backgroundColor: '#025196'
                }
              }} >
                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} />
                <span style={{ paddingLeft: '15px' }}>Updating...</span>
              </Button>
            ) : (
              <Button variant="contained" sx={{
                marginInline: '2px',
                textTransform: 'initial',
                backgroundColor: '#025196',
                ':hover': {
                  backgroundColor: '#025196'
                },
              }} onClick={handleSubmit}>Update</Button>
            )}
          </>
        }
      />

      {/* <Box> */}
      <IconButton onClick={handleClick} sx={{
        ':hover': {
          backgroundColor: 'transparent'
        }
      }}>
        <Box sx={{ backgroundColor: themeColor, borderRadius: "20px", }}>
          <Avatar {...stringAvatar(nameInitial)} />
        </Box>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "auto",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            paddingInline: '10px',
            paddingBlock: '10px',
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{ padding: '10px' }}>
          <Typography variant="h6" style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
            {name}
          </Typography>
          <div style={{ fontSize: '14px' }}>{email}</div>
        </Box>

        <Divider light />
        <Box sx={{ padding: '10px', '&:hover': { textDecoration: 'underline' } }}>
          <Link
            style={{ color: 'black', textDecoration: 'none' }}
            to={`/home/dashboard`}
          >
            <span style={{ fontSize: '16px' }}>Home</span>
          </Link>
        </Box>
        <Divider light />
        {accountButton && (
          <Box sx={{ padding: '10px', '&:hover': { textDecoration: 'underline' } }}>
            <Link
              style={{ color: 'black', textDecoration: 'none' }}
              to={`/home/${ROUTES.ACCOUNT}`}
            >
              <span style={{ fontSize: '16px' }}>Account</span>
            </Link>
          </Box>
        )}
        <Divider light />
        <Box sx={{ padding: '10px', '&:hover': { textDecoration: 'underline' } }}>
          <Link
            style={{ color: 'black', textDecoration: 'none' }}
            to={`/p${ROUTES.CONTACT}`}
          >
            <span style={{ fontSize: '16px' }}>Support</span>
          </Link>
        </Box>
        {surveyPlannerButton && (
          <Box>
            <Divider light />
            <Box sx={{ padding: '10px', '&:hover': { textDecoration: 'underline' } }}>
              <Link
                style={{ color: 'black', textDecoration: 'none' }}
                to={`/home/${ROUTES.ROUTE_PLANNER}`}
              >
                <span style={{ fontSize: '16px' }}>Survey planner</span>
              </Link>
            </Box>
          </Box>
        )}
        {canSeeGlobalDefects && basicImagingGlobalDefects && (
          <Box>
            <Divider light />
            <Box sx={{ padding: '10px', '&:hover': { textDecoration: 'underline' } }}>
              <Link
                style={{ color: 'black', textDecoration: 'none' }}
                to={`/home/${ROUTES.BASIC_IMAGING}`}
              >
                <span style={{ fontSize: '16px' }}>Defects</span>
              </Link>
            </Box>
          </Box>
        )}
        {isNetworkView && (
          <Box>
            <Divider light />
            <Box sx={{ padding: '10px', '&:hover': { textDecoration: 'underline' } }}>
              <Link
                style={{ color: 'black', textDecoration: 'none' }}
                to={`/home/${ROUTES.SUPER_SECTION}`}
              >
                <span style={{ fontSize: '16px' }}>Supersections</span>
              </Link>
            </Box>
          </Box>
        )}
        {accessControlRoom && (
          <Box>
            <Divider light />
            <Box sx={{ padding: '10px', '&:hover': { textDecoration: 'underline' } }}>
              <Link
                style={{ color: 'black', textDecoration: 'none' }}
                to={`/home/${ROUTES.CONTROL_ROOM}`}
              >
                <span style={{ fontSize: '16px' }}>Control room</span>
              </Link>
            </Box>
          </Box>
        )}

        <Box>
          <Divider light />
          <Box sx={{ padding: '10px', '&:hover': { textDecoration: 'underline' } }}>
            {/* <Button onClick={handleDetailsOpen} style={{ color: 'black', textDecoration: 'none' }}>
              <span style={{ fontSize: '16px' }}>Update profile</span>
            </Button> */}

            <Link
              style={{ color: 'black', textDecoration: 'none' }}
              to={`/home/dashboard`}
              onClick={handleDetailsOpen}
            >
              <span style={{ fontSize: '16px' }}>Update profile</span>
            </Link>
          </Box>
        </Box>

        <Divider light />
        <Box component="div" sx={{ fontFamily: 'Roboto, sans-serif', padding: '10px', color: 'darkred', fontWeight: 'bolder', cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
          onClick={userData.linkedToUid ? () => { props.signOut(true, userData.uid, accessCode) } : () => { props.signOut() }}>
          Sign out
        </Box>
      </Menu>

    </>
  );
};
const mapStateToProps = (state) => {
  return {
    themeRed: state.themeRed,
    isEmailVerified: state.firebase.auth.emailVerified,
    userData: state.firebase.profile,
    userAuth: state.firebase.auth,
    auth: state.auth,
    basicImagingGlobalDefects: state.firestore.data.Defects,
    company: state.company,
    companyData: state.firestore.data.Company,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signOut: (uidRequired, uid, accessCode) => dispatch(signOut(uidRequired, uid, accessCode)),
    updateCompanyDetails: (coid, image, companyName, fullName, uid) => dispatch(updateCompanyDetails(coid, image, companyName, fullName, uid)),
    changePassword: (currentPassword, newPassword) => dispatch(changePassword(currentPassword, newPassword)),
    removeChangePasswordMessage: () => dispatch(removeChangePasswordMessage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo);