import { Button, Divider, List, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'

const EditGpsMarkerList = ({ pointA, pointB, handleRemovePoints, handleRemove, newPointsAdd, handleRemoveAllPoints }) => {

    return (
        <div>
            {pointA !== null ? <List>
                <ListItem>
                    <ListItemText primary={`Start pin`} />
                    <Button
                        sx={{
                            border: `1px solid #000000`,
                            outline: 'none',
                            '&:focus': {
                                color: '#000000',
                                border: `1px solid #000000`,
                            },
                            '&:hover': {
                                backgroundColor: 'transparent',
                                color: '#000000',
                            },
                            textTransform: 'none',
                            color: '#000000',

                        }}
                        variant="outlined"
                        size='small'
                        onClick={() => handleRemovePoints(1)}
                    >
                        Remove
                    </Button>
                </ListItem>
            </List> : <></>}
            {pointB !== null ? <List>
                <ListItem>
                    <ListItemText primary={`End pin`} />
                    <Button
                        sx={{
                            border: `1px solid #000000`,
                            outline: 'none',
                            '&:focus': {
                                color: '#000000',
                                border: `1px solid #000000`,
                            },
                            '&:hover': {
                                backgroundColor: 'transparent',
                                color: '#000000',
                            },
                            textTransform: 'none',
                            color: '#000000',

                        }}
                        variant="outlined"
                        size='small'
                        onClick={() => handleRemovePoints(2)}
                    >
                        Remove
                    </Button>
                </ListItem>
            </List> : <></>}
            {pointA !== null && pointB !== null && newPointsAdd.length > 0 ? <Divider light /> : <></>}
            {newPointsAdd.map((each, index) => (
                <List>
                    <ListItem key={index}>
                        <ListItemText primary={`Pin ${index + 1}`} />
                        <Button
                            sx={{
                                border: `1px solid #000000`,
                                outline: 'none',
                                '&:focus': {
                                    color: '#000000',
                                    border: `1px solid #000000`,
                                },
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    color: '#000000',
                                },
                                textTransform: 'none',
                                color: '#000000',

                            }}
                            variant="outlined"
                            size='small'
                            onClick={() => handleRemove(index)}
                        >
                            Remove
                        </Button>
                    </ListItem>
                </List>
            ))}

            {/* remove all */}
            {/* <div style={{ backgroundColor: '', display: 'flex', justifyContent: 'flex-end' }}>
                {(pointA !== null || pointB !== null) && (
                    <Button
                        sx={{
                            marginTop: '10px',
                            border: `1px solid #000000`,
                            outline: 'none',
                            '&:focus': {
                                color: '#000000',
                                border: `1px solid #000000`,
                            },
                            '&:hover': {
                                color: '#000000',
                                border: `1px solid #000000`,
                            },
                            textTransform: 'none',
                            color: '#000000',
                        }}
                        variant="outlined"
                        size='small'
                        onClick={handleRemoveAllPoints}
                    >
                        Remove All
                    </Button>
                )}
            </div> */}
        </div>
    )
}

export default EditGpsMarkerList