import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Polyline, Marker, useMap, Popup, useMapEvents, LayersControl } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { Button, Pagination, Typography, Grid, TextField, CircularProgress, Snackbar, Alert, Tooltip, Tabs, Tab, IconButton } from '@mui/material';
import { Tooltip as MuiTooltip } from '@mui/material'
import "leaflet/dist/leaflet.css";
import markerImage from '../../../../../../../assets/pin_sm.svg';
import markerImage1 from '../../../../../../../assets/pin_sm1.svg';
import markerImage2 from '../../../../../../../assets/pin_sm2.svg';
import markerImage3 from '../../../../../../../assets/pin_sm3.svg';
import L, { icon } from 'leaflet';
import CustomProgress from '../../../../../../cidgets/CustomProgress';
import { Link } from 'react-router-dom';
import * as ROUTES from "../../../../../../../constants/routes";
import { downsampleGpsOnDistance, runGpsModificationFunction, runGpsModificationStartEnd } from '../../../../../../../helpers/GpsEditCalcHelper';
import { connect } from 'react-redux';
import { uploadEditGpsData, removeUploadEditGpsMsgAndError, gpsEditNext, deleteVideos, clearReuploadVideoState, uploadEditGpsDataAfterInference } from '../../../../../../../store/actions/projectActions';
import SimpleDialogBox from '../../../../../../cidgets/SimpleDialogBox';
import EditGpsMarkerList from './EditGpsMarkerList';
import { makeStyles } from "@mui/styles";
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { useAccessControlContext } from '../../../../../../../contexts/AccessControlProvider';
import AccessControlAccountError from '../../../../../../cidgets/AccessControlAccountError';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteVideos from './DeleteVideos';
import Error from '../../../../../../cidgets/Error';
const EditGpsHeader = ({
    handleRemovePoints, handleRemove, newPointsAdd, setNewPointsAdd, setAddPointKey,
    addUserPoint, addPointA, addPointB, pointA, pointB, setAddPointA, setAddPointB, setAddUserPoint, toggleDragging, dragEnabled, handleRemoveAllPoints }) => {
    const [addMarkersDialog, setAddMarkersDialog] = useState(false);
    const handleOpenDialog = () => {
        setAddMarkersDialog(true);
    }
    const handleCloseDialog = () => {
        setAddMarkersDialog(false);
    }
    return (
        <div >
            <SimpleDialogBox
                open={addMarkersDialog}
                onClose={handleCloseDialog}
                title="Remove pins"
                shouldTitleAppear={true}
                cancelText="Close"
                positiveText='Remove all'
                showPositive={true}
                positiveButtonWidget={
                    // pointA !== null || pointB !== null) && (
                    <Button
                        variant="contained" sx={{
                            marginInline: '2px',
                            textTransform: 'initial',
                            backgroundColor: '#025196', ':hover': {
                                backgroundColor: '#025196'
                            },
                        }}
                        disabled={newPointsAdd.length === 0}
                        onClick={handleRemoveAllPoints}
                    >
                        Remove all
                    </Button>

                }
                bodyWidget={
                    <EditGpsMarkerList pointA={pointA} pointB={pointB} handleRemovePoints={handleRemovePoints} handleRemove={handleRemove} newPointsAdd={newPointsAdd} setNewPointsAdd={setNewPointsAdd} setAddPointKey={setAddPointKey} handleRemoveAllPoints={handleRemoveAllPoints} />
                }
                width='600'
            />
            <Grid container spacing={0}>
                <Grid item xs={2} sx={{ background: '', display: 'flex', alignItems: 'center', height: '10vh', paddingLeft: '10px' }}>
                    <div>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Edit GPS</Typography>
                        <Typography variant='body2' >Edit the GPS tracks</Typography>
                    </div>
                </Grid>
                <Grid item xs={8} sx={{ background: '', display: 'flex', alignItems: 'center', height: '10vh', justifyContent: 'center' }}>
                    {pointA === null ?
                        <Tooltip title={"Click on the start pin"} arrow placement='bottom'>
                            <Button
                                disabled={dragEnabled ? true : pointA === null ? false : true}
                                onClick={() => { setAddPointA(!addPointA) }}
                                variant="contained"
                                sx={{
                                    textTransform: "initial",
                                    backgroundColor: "#025196",
                                    color: !addPointA ? "#ffffff" : "#FFFFFF",
                                    "&:hover": {
                                        backgroundColor: "#025196",
                                        color: !addPointA ? "#ffffff" : "#FFFFFF",
                                    },
                                }}
                            >
                                Select start pin
                            </Button>
                        </Tooltip>
                        : <div>
                            <TextField id="outlined-basic" label="Start pin" variant="outlined" disabled value={`${pointA.lat},${pointA.lng}`} />
                        </div>}
                    {pointB === null ?
                        <Tooltip title={"Click on the end pin"} arrow placement='bottom'>
                            <Button
                                disabled={dragEnabled ? true : pointA === null || pointB !== null ? true : false}
                                onClick={() => { setAddPointB(!addPointB) }}
                                variant="contained"
                                sx={{
                                    marginLeft: "10px",
                                    textTransform: "initial",
                                    backgroundColor: "#025196",
                                    color: !addPointB ? "#ffffff" : "#FFFFFF",
                                    "&:hover": {
                                        backgroundColor: "#025196",
                                        color: !addPointB ? "#ffffff" : "#FFFFFF",
                                    },
                                }}
                            >
                                Select end pin
                            </Button>
                        </Tooltip>
                        :
                        <div>
                            <TextField sx={{ marginLeft: '10px' }} id="outlined-basic" label="End pin" variant="outlined" disabled value={`${pointB.lat},${pointB.lng}`} />
                        </div>}
                    <Tooltip title={"Click on the map to define a different path"} arrow placement='bottom'>
                        <Button
                            disabled={pointA === null || pointB === null ? true : false}
                            onClick={() => { setAddUserPoint(!addUserPoint) }}
                            variant="contained"
                            sx={{
                                marginLeft: '10px',
                                textTransform: "initial",
                                backgroundColor: "#025196",
                                color: !addUserPoint ? "#ffffff" : "#FFFFFF",
                                "&:hover": {
                                    backgroundColor: "#025196",
                                    color: !addUserPoint ? "#ffffff" : "#FFFFFF",
                                },
                            }}
                        >
                            Create new path
                        </Button>
                    </Tooltip>
                    {pointA !== null || pointB !== null ?
                        // <Tooltip title={"Remove selected pins"} arrow placement='bottom'>
                        <Button
                            onClick={() => { handleOpenDialog() }}
                            variant="contained"
                            sx={{
                                marginLeft: '10px',
                                textTransform: "initial",
                                backgroundColor: "#025196",
                                color: "#FFFFFF",
                                "&:hover": {
                                    backgroundColor: "#025196",
                                    color: "#FFFFFF",
                                },
                            }}
                        >
                            Remove pins
                        </Button>
                        // </Tooltip>
                        : <></>}

                    <Tooltip title={`${dragEnabled ? 'Disable' : 'Enable'} dragging of first and last pins`} arrow placement='bottom'>
                        <Button
                            onClick={toggleDragging}
                            variant="contained"
                            sx={{
                                marginLeft: "10px",
                                textTransform: "initial",
                                backgroundColor: "#025196",
                                color: !addPointB ? "#ffffff" : "#FFFFFF",
                                "&:hover": {
                                    backgroundColor: "#025196",
                                    color: !addPointB ? "#ffffff" : "#FFFFFF",
                                },
                            }}
                        >
                            {dragEnabled ? "Disable Dragging" : "Enable Dragging"}
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={2}></Grid>
            </Grid>
        </div>
    )
}
const EditGpsScreen = (props) => {
    const { originalAllCoordinates, handleMapZoom, removeUploadEditGpsMsgAndError, uploadEditGpsLoader,
        uploadEditGpsIsDoneAndSent, setDisableHeader, showButton,
        allCoordinates, proid,
        filteredCoordinates, setGpsMode,
        uploadEditGpsData, uploadEditGpsDataAfterInference, zoomLevel, drProjectData, visualizerProjectPredictions } = props
    const iconSize = [26 / 1, 38 / 1];
    const [pointA, setPointA] = useState(null);
    const [pointB, setPointB] = useState(null);
    const [startPoint, setStartPoint] = useState(null);
    const [endPoint, setEndPoint] = useState(null);
    const [newPointsAdd, setNewPointsAdd] = useState([]);
    const [modifiedFilteredCoordinates, setModifiedFilteredCorrdinates] = useState([...filteredCoordinates]);
    const centerCoordinate = filteredCoordinates[0];
    const [addMarkerPolyline, setAddMarkerPolyline] = useState([]);
    const individualPolylinePositions = modifiedFilteredCoordinates.map(coordinate => [coordinate.lat, coordinate.lng]);
    const userAddMarkerPolyline = addMarkerPolyline.length > 0 && addMarkerPolyline.map(each => [each.lat, each.lng])
    const [mapKey, setMapKey] = useState(0);
    const [addPointKey, setAddPointKey] = useState(0);
    const [downSampleCoordinates, setDownSampleCorrdinates] = useState([]);
    const [addPointA, setAddPointA] = useState(false);
    const [addPointB, setAddPointB] = useState(false);
    const [addUserPoint, setAddUserPoint] = useState(false);
    const [startPointAIndex, setStartPointAIndex] = useState(null);
    const [endPointBIndex, setEndPointBIndex] = useState(null);
    const [isSaveConfirm, setIsSaveConfirm] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    // AAMIR
    const [dragEnabled, setDragEnabled] = useState(false);

    const toggleDragEnabled = (e) => {
        setDragEnabled(!dragEnabled);
    }
    // ////console.log(modifiedFilteredCoordinates, newPointsAdd, startPoint, endPoint,'prac')
    const handleOpenAlert = () => {
        setOpenAlert(true);
    }
    const handleCloseAlert = () => {
        setOpenAlert(false);
    }
    useEffect(() => {
        removeUploadEditGpsMsgAndError();
    }, [])


    const clearStateValues = () => {
        setPointA(null);
        setPointB(null);
        setStartPoint(null);
        setEndPoint(null);
        setNewPointsAdd([]);
        setAddMarkerPolyline([]);
        setAddPointA(false);
        setAddPointB(false);
        setAddUserPoint(false);
        setStartPointAIndex(null);
        setEndPointBIndex(null);
        setAddPointKey((prevKey) => prevKey + 1);
        setMapKey((prevKey) => prevKey + 1);
    }
    const handleReset = () => {
        setModifiedFilteredCorrdinates([...filteredCoordinates]);
        setIsSaveConfirm(false);
        // setMapKey((prevKey) => prevKey + 1);
        clearStateValues();
    }
    useEffect(() => {
        if (uploadEditGpsIsDoneAndSent === true) {
            clearStateValues();
            setIsSaveConfirm(false);
            removeUploadEditGpsMsgAndError();
        }
    }, [uploadEditGpsIsDoneAndSent])
    useEffect(() => {
        if (modifiedFilteredCoordinates) {
            const updatedDownSampleCoordinates = downsampleGpsOnDistance(modifiedFilteredCoordinates, 5);
            setDownSampleCorrdinates(updatedDownSampleCoordinates);
        }
    }, [modifiedFilteredCoordinates])

    // //console.log(addMarkerPolyline, "addMarkerPolyline")

    const handlePointClick = (e, index) => {

        if (addPointA) {
            if (pointA === null) {
                if (endPointBIndex !== null) {
                    if (index === endPointBIndex) {
                        handleOpenAlert();
                        setAlertMessage(`Start pin cannot be same as end pin`)
                    }
                    else {
                        setStartPoint(modifiedFilteredCoordinates.findIndex(each => each.millis === downSampleCoordinates[index].millis));
                        setPointA(e.latlng);
                        setAddPointA(false);
                        setStartPointAIndex(index);
                        // setAddPointB(true);
                    }
                }
                else {
                    setStartPoint(modifiedFilteredCoordinates.findIndex(each => each.millis === downSampleCoordinates[index].millis));
                    setPointA(e.latlng);
                    setAddPointA(false);
                    setStartPointAIndex(index);
                    // setAddPointB(true);
                }

            }
            else {
                handleOpenAlert();
                setAlertMessage(`Start pin is already selected`);
            }
        }
        else if (addPointB) {
            if (pointB === null) {
                // a=2 b=3 a=2  b=1
                if (index === startPointAIndex) {
                    handleOpenAlert();
                    setAlertMessage(`End pin cannot be same as start pin`);
                }
                else {
                    setEndPoint(modifiedFilteredCoordinates.findIndex(each => each.millis === downSampleCoordinates[index].millis));
                    setPointB(e.latlng);
                    setAddPointB(false);
                    setEndPointBIndex(index);
                    // setAddPointA(true);
                }
            }
            else {
                handleOpenAlert();
                setAlertMessage(`End pin is already selected`);
            }
        }
        else {
            setAlertMessage(`No selection enabled so this pin cannot be selected`);
            handleOpenAlert();

        }
    }

    const handleDrag = (event, index) => {
        // console.log("HANDLE_DRAG_END", index, event)
        // console.log("HANDLE_DRAG_END_2", modifiedFilteredCoordinates[index], event.target._latlng)
        // TODO: INTEGRATE GPS MODIFICATION FUNCTIONALITY HERE

        let updatedGpsEdit = runGpsModificationStartEnd(modifiedFilteredCoordinates, event.target._latlng, modifiedFilteredCoordinates.findIndex(x => x.millis === downSampleCoordinates[index].millis), modifiedFilteredCoordinates.findIndex(x => x.millis === downSampleCoordinates[index == 0 ? index + 1 : index - 1].millis));
        setModifiedFilteredCorrdinates(updatedGpsEdit);
        setMapKey((prevKey) => prevKey + 1);
        setIsSaveConfirm(true);
        clearStateValues();
    }
    const handleAddPoint = (e) => {
        if (addUserPoint) {
            if (pointA !== null && pointB !== null) {

                const newMarker = e.latlng;
                const updateNewPointsAdd = [...newPointsAdd, newMarker];
                setNewPointsAdd(updateNewPointsAdd);

                const updatedPointPolylineData = [pointA, ...updateNewPointsAdd, pointB]
                setAddMarkerPolyline(updatedPointPolylineData);
                // setAddPointKey((prevKey) => prevKey + 1);
                setMapKey((prevKey) => prevKey + 1);
                // setAddUserPoint(false);

            }
        }
        else {
            if (pointA && pointB) {
                handleOpenAlert();
                setAlertMessage(`Enable pin addition to add a pin`);
            }
        }
    }
    const handleRemovePoints = (type) => {
        if (type === 1) {
            setStartPoint(null);
            setPointA(null);
            setAddPointA(false);
            setStartPointAIndex(null);

            setNewPointsAdd([]);
            setAddMarkerPolyline([]);
            setAddPointKey((prevKey) => prevKey + 1);
        }
        else if (type === 2) {
            setEndPoint(null);
            setPointB(null);
            setAddPointB(false);
            setEndPointBIndex(null);

            setNewPointsAdd([]);
            setAddMarkerPolyline([]);
            setAddPointKey((prevKey) => prevKey + 1);
        }
    }
    const handleRemoveAllPoints = () => {
        setNewPointsAdd([]);
        setAddMarkerPolyline([]);
        setAddPointKey((prevKey) => prevKey + 1);
    };
    const MapClickHandler = () => {
        useMapEvents({
            click: handleAddPoint,
        });
        return null;
    };
    const handleGpsEdit = () => {
        if (startPoint === null || endPoint === null || newPointsAdd.length === 0) {
            handleOpenAlert();
            setAlertMessage(`No selection found to adjust route`);
        }
        else {
            let updatedGpsEdit = runGpsModificationFunction(modifiedFilteredCoordinates, newPointsAdd, startPoint, endPoint);
            setModifiedFilteredCorrdinates(updatedGpsEdit);
            setMapKey((prevKey) => prevKey + 1);
            setIsSaveConfirm(true);
            clearStateValues();
        }
    }
    const handleConfirm = () => {
        let startIndex = originalAllCoordinates.findIndex((obj) => obj.millis === modifiedFilteredCoordinates[0].millis);
        let updatedAllCoordinates = originalAllCoordinates;
        modifiedFilteredCoordinates.map((each, index) => {
            updatedAllCoordinates[startIndex + index]['lat'] = each.lat
            updatedAllCoordinates[startIndex + index]['lng'] = each.lng
        });
        if (visualizerProjectPredictions.length > 0) {
            uploadEditGpsDataAfterInference(updatedAllCoordinates, proid, drProjectData.projectType);
        }
        else {
            uploadEditGpsData(updatedAllCoordinates, proid, drProjectData.projectType);
        }
    }
    const handleRemove = (setIndex) => {
        const updatedMarkerList = newPointsAdd.filter((each, index) => index !== setIndex)
        setNewPointsAdd(updatedMarkerList);
        const updatedPointPolylineData = [pointA, ...updatedMarkerList, pointB]
        setAddMarkerPolyline(updatedPointPolylineData);
        setAddPointKey((prevKey) => prevKey + 1);
    };
    // console.log(addPointKey, mapKey, "keyss")
    return (
        <>
            <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleCloseAlert}>
                <Alert
                    onClose={handleCloseAlert}
                    severity="warning"
                    variant="outlined"
                    sx={{ width: '100%' }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
            <div style={{ padding: '10px' }}>
                <div style={{ height: '10vh', background: '' }}>
                    <EditGpsHeader
                        addUserPoint={addUserPoint}
                        addPointA={addPointA}
                        addPointB={addPointB}
                        pointA={pointA}
                        pointB={pointB}
                        setAddPointA={setAddPointA}
                        setAddPointB={setAddPointB}
                        setAddUserPoint={setAddUserPoint}
                        handleRemovePoints={handleRemovePoints} handleRemove={handleRemove} newPointsAdd={newPointsAdd} setNewPointsAdd={setNewPointsAdd} setAddPointKey={setAddPointKey} handleRemoveAllPoints={handleRemoveAllPoints}
                        toggleDragging={toggleDragEnabled} dragEnabled={dragEnabled}
                    />
                </div>
                <MapContainer attributionControl={false} maxZoom={25} center={[centerCoordinate.lat, centerCoordinate.lng]} zoom={zoomLevel} style={{ cursor: 'default', height: `calc(${showButton ? '80vh' : '63vh'} - 20px - 5px)`, width: '100%', marginTop: '10px' }}
                    whenReady={(map) => {
                        map.target.on('zoom', handleMapZoom);
                    }}
                >
                    <ReactLeafletGoogleLayer apiKey='AIzaSyA1Zq5IDPlPl_i4MnG9wCBg8s6XQrfB9Ig' type={'roadmap'} maxZoom={25} maxNativeZoom={22} />
                    <MapClickHandler />
                    <Polyline
                        key={addPointKey}
                        positions={userAddMarkerPolyline}
                        color="darkorange" weight={5} stroke={true}
                    />
                    <Polyline key={mapKey} positions={individualPolylinePositions} color="green" />
                    <MarkerClusterGroup chunkedLoading disableClusteringAtZoom={20} >
                        {downSampleCoordinates.map((coordinate, index) => (
                            <Marker
                                icon={L.icon({
                                    iconAnchor: [iconSize[0] / 2, iconSize[1]],
                                    popupAnchor: [-0, -0],
                                    iconUrl:
                                        coordinate.lat === pointA?.lat && coordinate.lng === pointA?.lng
                                            ? markerImage1
                                            : coordinate.lat === pointB?.lat && coordinate.lng === pointB?.lng
                                                ? markerImage2
                                                : markerImage,
                                    iconSize: iconSize,
                                })}
                                key={index + mapKey} position={[coordinate.lat, coordinate.lng]}
                                draggable={dragEnabled &&
                                    (index == 0 || index == (downSampleCoordinates.length - 1)
                                    )}
                                eventHandlers={{
                                    click: (event) => handlePointClick(event, index),
                                    dragend: (event) => handleDrag(event, index)
                                }}
                            // eventHandlers={{
                            //     dragend: (event) => handleDrag(event, index)
                            // }}
                            >
                                {/* <Popup>{index}</Popup> */}
                            </Marker>
                        ))}
                    </MarkerClusterGroup>
                    {pointA && (
                        <Marker position={pointA} title="Start point" icon={L.icon({
                            iconAnchor: [iconSize[0] / 2, iconSize[1]],
                            popupAnchor: [-0, -0],
                            iconUrl: markerImage1,
                            iconSize: iconSize,

                        })}>
                            {/* <Popup>
                            <span>Point A</span>
                        </Popup> */}
                        </Marker>
                    )}
                    {pointB && (
                        <Marker position={pointB} title="End point" icon={L.icon({
                            iconAnchor: [iconSize[0] / 2, iconSize[1]],
                            popupAnchor: [-0, -0],
                            iconUrl: markerImage2,
                            iconSize: iconSize,

                        })}>
                            {/* <Popup>
                            <span>Point B</span>
                        </Popup> */}
                        </Marker>
                    )}

                    {newPointsAdd.map((eachPoint, index) => (
                        <Marker
                            icon={L.icon({
                                iconAnchor: [iconSize[0] / 2, iconSize[1]],
                                popupAnchor: [-0, -0],
                                iconUrl: markerImage3,
                                iconSize: iconSize,

                            })}
                            title={`Marker ${index + 1}`}
                            key={index + addPointKey} position={[eachPoint.lat, eachPoint.lng]}
                        />
                    ))}
                </MapContainer>
                <div style={{ height: 'calc(10vh - 10px)', width: '100%' }}>
                    <Grid container spacing={0} sx={{ height: '100%', }}>
                        <Grid item xs={12} sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Tooltip title={"Adjust the newly created path"} arrow placement='top'>
                                <Button
                                    disabled={startPoint === null || endPoint === null || newPointsAdd.length === 0}
                                    onClick={() => { handleGpsEdit() }}
                                    variant="contained"
                                    sx={{
                                        textTransform: "initial",
                                        backgroundColor: "#025196",
                                        color: "#FFFFFF",
                                        "&:hover": {
                                            backgroundColor: "#025196",
                                            color: "#FFFFFF",
                                        },
                                    }}
                                >
                                    Adjust path
                                </Button>
                            </Tooltip >
                            <Tooltip title={"Rest to original path"} arrow placement='top'>
                                <Button
                                    onClick={() => { handleReset() }}
                                    disabled={isSaveConfirm ? false : true}
                                    variant="contained"
                                    sx={{
                                        marginLeft: '10px',
                                        textTransform: "initial",
                                        backgroundColor: "#025196",
                                        color: "#FFFFFF",
                                        "&:hover": {
                                            backgroundColor: "#025196",
                                            color: "#FFFFFF",
                                        },
                                    }}
                                >
                                    Reset path
                                </Button>
                            </Tooltip>
                            {uploadEditGpsLoader ?
                                < Button variant="contained" disabled sx={{
                                    marginLeft: '10px',
                                    textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                    "&.Mui-disabled": {
                                        backgroundColor: '#025196',
                                        color: '#FFFFFF'
                                    }
                                }}>
                                    <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Saving...</span>
                                </Button>
                                :
                                <Tooltip title={"Save the new adjusted path"} arrow placement='top'>
                                    <Button
                                        onClick={() => { handleConfirm() }}
                                        disabled={isSaveConfirm ? false : true}
                                        variant="contained"
                                        sx={{
                                            marginLeft: '10px',
                                            textTransform: "initial",
                                            backgroundColor: "#025196",
                                            color: "#FFFFFF",
                                            "&:hover": {
                                                backgroundColor: "#025196",
                                                color: "#FFFFFF",
                                            },
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Tooltip>

                            }
                            <Tooltip title={"Back to view GPS"} arrow placement='top'>
                                <Button
                                    onClick={() => { setGpsMode(false); setDisableHeader(false) }}
                                    variant="contained"
                                    sx={{
                                        marginLeft: '10px',
                                        textTransform: "initial",
                                        backgroundColor: "#025196",
                                        color: "#FFFFFF",
                                        "&:hover": {
                                            backgroundColor: "#025196",
                                            color: "#FFFFFF",
                                        },
                                    }}
                                >
                                    Back
                                </Button>
                            </Tooltip>

                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    )
}
const EditGpsMap = (props) => {
    const useStyles = makeStyles((theme) => ({
        tab: {
            minWidth: 'auto',
            textTransform: 'none',
            fontWeight: 'bold',
            fontSize: '14px',
            '&:hover': {
                backgroundColor: 'rgba(128, 128, 128, 0.2)',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
            },
        },
        activeTab: {
            color: "white",
            backgroundColor: "#025196",
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            borderTop: '3px solid #025196',
            borderRight: '3px solid #025196',
            borderLeft: '3px solid #025196',
            borderBottom: 'none',
            '&:hover': {
                backgroundColor: "#025196",
            }
        },

    }));

    const { visualizerProjectPredictions, showButton = true, removeUploadEditGpsMsgAndError, project, setDisableHeader, uploadEditGpsData, uploadEditGpsDataAfterInference, allCoordinates, proid, setActiveStep, gpsEditNext, drProjectData } = props;
    const { permissions, role } = useAccessControlContext();
    const { canViewGps, canUpdateGps } = permissions;
    const [loading, setLoading] = useState(true);
    const itemsPerPage = 5000;
    const [currentPage, setCurrentPage] = useState(1);
    const [newVideosList, setNewVideosList] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [gpsMode, setGpsMode] = useState(false);
    const [viewDownsampleCoordinates, setViewDownsampleCoordinates] = useState([]);
    const { uploadEditGpsLoader, uploadEditGpsIsDoneAndSent, deleteVideosMessage, deleteVideosLoader, deleteVideosIsDoneAndSent } = project;
    const [zoomLevel, setZoomLevel] = useState(18);
    const [tabValue, setTabValue] = useState(0);
    const GpsPerPage = 4;
    const [segmentIndex, setSegmentIndex] = useState(0);
    const videosList = drProjectData.videos ? Object.values(drProjectData?.videos) : [];
    videosList.sort((a, b) => a.correctedOrder - b.correctedOrder);
    const [selectVideo, setSelectVideo] = useState(videosList[0]);
    const [videoFilteredCoordinates, setVideoFilteredCoordinates] = useState([]);
    const classes = useStyles();
    const [newCurrentPage, setNewCurrentPage] = useState(0);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const totalPages = Math.ceil(videosList?.length / GpsPerPage);
    const [frameLink, setFrameLink] = useState(null);
    const handleMapZoom = (event) => {
        setZoomLevel(event.target._zoom);
    };
    const originalFramesList = visualizerProjectPredictions.length > 0 ?
        visualizerProjectPredictions
            .flatMap(obj => Object.values(obj.markerData))
            .sort((a, b) => a.serialNumber - b.serialNumber)
            .map((each) => each.frameLink) : [];

    const handleFrame = (coordinate) => {
        const originalFrameIndex = allCoordinates.findIndex((each) => each.millis === coordinate.millis && each.videoIndex === coordinate.videoIndex)
        if (originalFramesList.length > 0) {
            setFrameLink(originalFramesList[originalFrameIndex])
        }
    }
    useEffect(() => {
        if (markers.length > 0) {
            const updatedDownSampleCoordinates = downsampleGpsOnDistance(markers, 5);
            setViewDownsampleCoordinates(updatedDownSampleCoordinates);
        }
    }, [markers])
    useEffect(() => {
        const fetchData = async () => {
            setTimeout(() => {
                setLoading(false);
            }, 5000);
        };
        // Check if data is initially null or undefined
        if (allCoordinates === null || allCoordinates === undefined || allCoordinates.length === 0) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [allCoordinates]);
    useEffect(() => {
        if (allCoordinates && allCoordinates.length > 0) {
            const filteredData = allCoordinates.filter(
                (eachCoordinate) => eachCoordinate.videoIndex === segmentIndex
            );
            setVideoFilteredCoordinates(filteredData);
        }
    }, [allCoordinates, segmentIndex]);
    useEffect(() => {
        if (videoFilteredCoordinates) {
            const startIndex = (currentPage - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            const coordinatesSubset = videoFilteredCoordinates.slice(startIndex, endIndex);
            setMarkers(coordinatesSubset);
        }
    }, [currentPage, videoFilteredCoordinates]);
    const handleTabChangeV2 = (event, newValue) => {
        if (videoFilteredCoordinates.length > newValue) {

            setTabValue(newValue);
            setSegmentIndex(newValue);
            setSelectVideo(videosList[newValue]);
        }
        else {

            setActiveStep(4);
            gpsEditNext(proid);
        }
    }

    const handlePreviousPage = () => {
        if (newCurrentPage > 0) {
            setNewCurrentPage(newCurrentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (newCurrentPage < totalPages - 1) {
            setNewCurrentPage(newCurrentPage + 1);
        }
    };


    const currentVideos = videosList.slice(
        newCurrentPage * GpsPerPage,
        (newCurrentPage + 1) * GpsPerPage
    );
    const tabs = Object.values(currentVideos).map((path, index) => (
        <Tab
            key={index}
            value={index + newCurrentPage * GpsPerPage}
            label={path.name}
            // label={`Video ${(index + newCurrentPage * GpsPerPage) + 1}`}
            // onClick={() => handleTabClick(index + currentPage * videosPerPage)}
            className={`${classes.tab} ${segmentIndex === (index + newCurrentPage * GpsPerPage) ? classes.activeTab : ''
                }`}
            sx={{
                width: "25%",
                textTransform: 'none',
                fontWeight: 'bold',
            }}
        />
    ));

    if (allCoordinates === null || allCoordinates === undefined || allCoordinates.length === 0) {
        if (loading) {
            return (
                <div style={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomProgress text={"Loading gps"} />
                </div>
            )
        }
        else {
            return (
                <div style={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ textAlign: 'center' }}>
                        <h6>No gps data found!</h6>
                        <Link
                            style={{ color: 'black', cursor: 'pointer', marginTop: '0px', textDecoration: 'underline' }}
                            to={`/p${ROUTES.CONTACT}`}
                            state={{ proid: proid, isProject: true }}
                        >  Click here</Link>&nbsp;to contact customer support team.
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                            <Button onClick={() => {
                                gpsEditNext(proid);
                                setActiveStep(4);
                            }} variant='contained' sx={{
                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                '&:hover': {
                                    backgroundColor: '#025196',
                                    color: '#FFFFFF'
                                }
                            }}
                            >Next</Button>
                        </div>
                    </div>
                </div>
            )
        }
    }
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };
    if (markers.length <= 0) {
        return <></>
    }
    const centerCoordinate = markers[0];
    const polylinePositions = videoFilteredCoordinates.map(coordinate => [coordinate.lat, coordinate.lng]);
    const individualPolylinePositions = markers.map(coordinate => [coordinate.lat, coordinate.lng]);
    const iconSize = [26 / 1, 38 / 1];
    const paginationLength = Math.ceil(videoFilteredCoordinates.length / itemsPerPage);

    const handleDelete = () => {
        props.deleteVideos(proid, newVideosList);
    }
    const onDelete = (newList) => {
        setNewVideosList(drProjectData.videos.length === newList.length ? null : newList);
    }
    const handleBack = () => {
        props.clearReuploadVideoState(proid);
        setActiveStep(0);
    }
    return (
        <>
            <SimpleDialogBox
                open={openDeleteDialog}
                onClose={() => { setOpenDeleteDialog(false) }}
                title={<Error errorMessage={"Deleting videos will reset the GPS edit and video selection of the videos"}/>}
                bodyWidget={<DeleteVideos videoList={drProjectData.videos} onDelete={onDelete} />}
                showError={deleteVideosIsDoneAndSent === true ? false : true}
                errorMessage={deleteVideosIsDoneAndSent === true ? "" : deleteVideosMessage}
                width={700}
                showPositive
                positiveButtonWidget={<>
                    {deleteVideosLoader ?
                        <>
                            <Button variant="contained" disabled sx={{
                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                "&.Mui-disabled": {
                                    backgroundColor: '#025196',
                                    color: '#FFFFFF'
                                }
                            }}>
                                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Deleting...</span>
                            </Button>
                        </>
                        :

                        <Button size="medium" variant="contained"
                            onClick={handleDelete}
                            disabled={newVideosList === null}
                            sx={{
                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                '&:hover': {
                                    backgroundColor: '#025196',
                                    color: '#FFFFFF'
                                }
                            }}>Delete</Button>
                    }
                </>}
            />
            <>{
                !canViewGps ?
                    <AccessControlAccountError access={role} />
                    :
                    gpsMode ?
                        !canUpdateGps ?
                            <AccessControlAccountError access={role} />
                            :
                            <EditGpsScreen
                                drProjectData={drProjectData}
                                handleMapZoom={handleMapZoom}
                                zoomLevel={zoomLevel}
                                removeUploadEditGpsMsgAndError={removeUploadEditGpsMsgAndError}
                                uploadEditGpsLoader={uploadEditGpsLoader}
                                uploadEditGpsIsDoneAndSent={uploadEditGpsIsDoneAndSent}
                                setDisableHeader={setDisableHeader}
                                uploadEditGpsData={uploadEditGpsData}
                                uploadEditGpsDataAfterInference={uploadEditGpsDataAfterInference}
                                visualizerProjectPredictions={visualizerProjectPredictions}
                                allCoordinates={videoFilteredCoordinates}
                                originalAllCoordinates={allCoordinates}
                                proid={proid}
                                setGpsMode={setGpsMode}
                                filteredCoordinates={markers}
                                showButton={showButton}
                            />
                        :

                        <div style={{ padding: '10px' }}>

                            <div style={{ height: 'calc(15vh - 20px)', width: '100%', background: '' }}>
                                <div

                                    style={{
                                        display: 'flex', alignItems: 'center',
                                        backgroundColor: '#EFEFEF',
                                        boxShadow: '0px 0px 10px rgba(64, 64, 64, 0.1)',
                                        width: '100%',
                                        borderBottomLeftRadius: '0',
                                        borderBottomRightRadius: '0',
                                    }}>
                                    <IconButton onClick={handlePreviousPage} disabled={newCurrentPage === 0}>
                                        <ArrowBackIosIcon />
                                    </IconButton >

                                    <Tabs
                                        value={tabValue}
                                        onChange={handleTabChangeV2}
                                        sx={{ width: '100%' }}
                                        indicatorColor=''
                                        textColor=''
                                    >
                                        {tabs}
                                    </Tabs>

                                    <div style={{ marginLeft: 'auto' }}>
                                        <IconButton onClick={handleNextPage} disabled={newCurrentPage === totalPages - 1} >
                                            <ArrowForwardIosIcon />
                                        </IconButton >
                                    </div>
                                </div>

                            </div>
                            <MapContainer key={`${centerCoordinate.lat}-${centerCoordinate.lng}`} attributionControl={false} center={[centerCoordinate.lat, centerCoordinate.lng]} zoom={zoomLevel} style={{ background: '', height: 'calc(50vh - 0px)', width: '100%' }}
                                whenReady={(map) => {
                                    map.target.on('zoom', handleMapZoom);
                                }}>

                                <ReactLeafletGoogleLayer apiKey='AIzaSyA1Zq5IDPlPl_i4MnG9wCBg8s6XQrfB9Ig' type={'roadmap'} maxZoom={25} maxNativeZoom={22} />
                                <Polyline positions={polylinePositions} color="#025196" />
                                <Polyline positions={individualPolylinePositions} color="green" />
                                <MarkerClusterGroup chunkedLoading>
                                    {viewDownsampleCoordinates.map((coordinate, index) => (
                                        <Marker
                                            eventHandlers={{
                                                click: () => {
                                                    handleFrame(coordinate)
                                                }
                                            }}
                                            icon={L.icon({
                                                iconAnchor: [iconSize[0] / 2, iconSize[1]],
                                                popupAnchor: [-0, -0],
                                                iconUrl: markerImage,
                                                iconSize: iconSize,
                                            })}
                                            key={index} position={[coordinate.lat, coordinate.lng]}
                                        >
                                            {frameLink && (
                                                <div className="inset-image-container">
                                                    <MuiTooltip
                                                        title={"Click to close"}
                                                        arrow
                                                    >
                                                        <img
                                                            src={frameLink}
                                                            alt="Frame"
                                                            className="inset-image"
                                                            onClick={() => { setFrameLink('') }}
                                                        />
                                                    </MuiTooltip>

                                                </div>
                                            )}
                                        </Marker>
                                    ))}
                                </MarkerClusterGroup>
                            </MapContainer>
                            {paginationLength > 1 ?
                                <div style={{ height: 'calc(7vh - 0px)', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'end', background: '' }}>
                                    <Pagination
                                        size='large'
                                        variant='outlined'
                                        count={Math.ceil(videoFilteredCoordinates.length / itemsPerPage)}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                    />
                                </div>
                                : <></>}
                            <div style={{ height: paginationLength > 1 ? 'calc(8vh - 0px)' : `calc(${showButton ? '15vh' : '8vh'} - 0px)`, width: '100%', background: '' }}>
                                <Grid container spacing={0} sx={{ height: '100%', }}>
                                    <Grid item xs={12} sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {drProjectData.projectType !== 2 ? <Button
                                            onClick={handleBack}
                                            // disabled={!canUpdateGps}
                                            variant="contained"
                                            sx={{
                                                display: showButton ? 'flex' : 'none',
                                                textTransform: "initial",
                                                backgroundColor: "#025196",
                                                color: "#FFFFFF",
                                                "&:hover": {
                                                    backgroundColor: "#025196",
                                                    color: "#FFFFFF",
                                                },
                                            }}
                                        >
                                            Back
                                        </Button> : <></>}
                                        <Button
                                            onClick={() => { setGpsMode(true); setDisableHeader(true) }}
                                            disabled={!canUpdateGps}
                                            variant="contained"
                                            sx={{
                                                marginLeft: '10px',
                                                textTransform: "initial",
                                                backgroundColor: "#025196",
                                                color: "#FFFFFF",
                                                "&:hover": {
                                                    backgroundColor: "#025196",
                                                    color: "#FFFFFF",
                                                },
                                            }}
                                        >
                                            Edit GPS
                                        </Button>
                                        {drProjectData.projectType !== 2 ? <Button
                                            onClick={() => { setOpenDeleteDialog(true) }}
                                            disabled={!canUpdateGps}
                                            variant="contained"
                                            sx={{
                                                display: showButton ? 'flex' : 'none',
                                                marginLeft: '10px',
                                                textTransform: "initial",
                                                backgroundColor: "#025196",
                                                color: "#FFFFFF",
                                                "&:hover": {
                                                    backgroundColor: "#025196",
                                                    color: "#FFFFFF",
                                                },
                                            }}
                                        >
                                            Delete videos
                                        </Button> : <></>}
                                        <Button
                                            onClick={() => {
                                                gpsEditNext(proid);
                                                setActiveStep(4);
                                            }}
                                            variant="contained"
                                            sx={{
                                                display: showButton ? 'flex' : 'none',
                                                marginLeft: '10px',
                                                textTransform: "initial",
                                                backgroundColor: "#025196",
                                                color: "#FFFFFF",
                                                "&:hover": {
                                                    backgroundColor: "#025196",
                                                    color: "#FFFFFF",
                                                },
                                            }}
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>

                        </div >
            }
            </>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        project: state.project,
        visualizerProjectPredictions: state.firestore.ordered.VisualizerProjectPredictions,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        uploadEditGpsData: (gpsList, proid, projectType) => dispatch(uploadEditGpsData(gpsList, proid, projectType)),
        uploadEditGpsDataAfterInference: (gpsList, proid, projectType) => dispatch(uploadEditGpsDataAfterInference(gpsList, proid, projectType)),
        removeUploadEditGpsMsgAndError: () => dispatch(removeUploadEditGpsMsgAndError()),
        gpsEditNext: (proid) => dispatch(gpsEditNext(proid)),
        deleteVideos: (proid, newList) => dispatch(deleteVideos(proid, newList)),
        clearReuploadVideoState: (proid) => dispatch(clearReuploadVideoState(proid)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGpsMap);