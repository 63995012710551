import React from 'react'
import {useParams } from 'react-router-dom';
import NetworkDashboardController from './dashboard/NetworkDashboardController';


const NetworkDashboardWrapper = () => {
  const { netid } = useParams();
  return (
    <NetworkDashboardController netid={netid} />
  )
}
export default NetworkDashboardWrapper