import React, { useEffect, useState } from 'react'
import { Paper, FormControl, FormControlLabel, Radio, IconButton, Divider, Tooltip } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import Aligator from "../../../../../../assets/icons/Alligator.png";
import Bleeding from "../../../../../../assets/icons/Bleeding.png";
import EdgeDeterioration from "../../../../../../assets/icons/Edge deterioration.png";
import CrackSeals from "../../../../../../assets/icons/Crack seals.png";
import Longitudinal from "../../../../../../assets/icons/longitudinal_crack_type_1.png";
import Block from "../../../../../../assets/icons/block_crack.png";
import Transverse from "../../../../../../assets/icons/Lateral.png";
import Pothole from "../../../../../../assets/icons/pothole.png";
import Patch from "../../../../../../assets/icons/patch.png";
import Crack from "../../../../../../assets/icons/crack.png";
import Vegetation from "../../../../../../assets/icons/Vegetation.png";
import SmartUtils from '../../../../../../helpers/SmartUtils';
import { DeleteOutline } from '@mui/icons-material';
const EditOptions = ({ svgDimensions, originalVideoWidth, fps, allPolyPoints, currentTime, projectPredictions, updateDistressV2, proid,
    rectangles, addDistressV2, isAddingDistress, setFieldValues, setShowPointer, handleChange, fieldValues, setSelectedId, setIsEditMode,
    basicImagingData, handleDelete }) => {

    const { dataInfo } = basicImagingData;
    const [sectionId, setSectionId] = useState(null);
    const gpsObj = allPolyPoints.length !== 0 ? allPolyPoints.reduce((prev, curr) => {
        return (Math.abs(curr.time * 1000 - currentTime * 1000) < Math.abs(prev.time * 1000 - currentTime * 1000) ? curr : prev);
    }) : [];
    function mapIt(sectionDocument) {
        let sortedDetections = Object.values(sectionDocument.GPS);
        // sortedDetections.sort((x, y) => x.frameTime - y.frameTime);
        if (sortedDetections.length == 0) {
            return {
                id: sectionDocument.id,
                sectionId: sectionDocument.sectionId,
                initTime: -1,
                endTime: -1,
            };
        }
        return {
            id: sectionDocument.id,
            sectionId: sectionDocument.sectionId,
            initTime: sortedDetections[0].time,
            endTime: sortedDetections.at(-1).time,
        };
    }
    useEffect(() => {
        if (projectPredictions) {
            const selectedSection = projectPredictions.map((eachObj) => mapIt(eachObj));
            const pickedSections = selectedSection.filter((eachSegment) =>
                SmartUtils.checkInRange(Number((eachSegment.initTime)).toFixed(5), Number((eachSegment.endTime)).toFixed(5), Number(currentTime).toFixed(5)));
            if (pickedSections.length > 0) {
                const sectionId = pickedSections[0]['id'];
                setSectionId(sectionId)
            }
        }
    }, [projectPredictions])
    const handleSubmit = () => {
        const ratio = (svgDimensions.width / originalVideoWidth);
        if (fieldValues.basicImagingClassState !== '') {
            if (fieldValues.basicImagingClassState === 1 && fieldValues.classification === '') {
                alert('Please select classification');
            }
            else {
                if (isAddingDistress) {
                    const { x, y, width, height } = rectangles.find((each) => each.id === fieldValues.defectId);
                    const bbox = [x / ratio, y / ratio, width / ratio, height / ratio]
                    addDistressV2(proid, sectionId, bbox, fieldValues.basicImagingClassState, Math.floor(fps * currentTime),
                        { gpsLat: gpsObj.lat, gpsLng: gpsObj.lng }, fieldValues.classification);
                    setIsEditMode(false);
                    setSelectedId(null);
                    setShowPointer(true);
                    setFieldValues({
                        basicImagingClassState: '',
                        sideState: '',
                        priorityState: '',
                        lateralPositionState: '',
                        detectionQualityState: 0,
                        recognitionQualityState: 0,
                        roadSideState: '',
                        frameNumberState: '',
                        classification: '',
                    });
                }
                else {
                    const { x, y, width, height } = rectangles.find((each) => each.id === fieldValues.defectId);
                    const bbox = [x / ratio, y / ratio, width / ratio, height / ratio]
                    updateDistressV2(proid, fieldValues.sectionId, bbox, fieldValues.basicImagingClassState, fieldValues.frameNumberState,
                        { gpsLat: fieldValues.gpsLat, gpsLng: fieldValues.gpsLng }, fieldValues.classification, fieldValues.defectId);
                    setIsEditMode(false);
                    setSelectedId(null);
                    setFieldValues({
                        basicImagingClassState: '',
                        sideState: '',
                        priorityState: '',
                        lateralPositionState: '',
                        detectionQualityState: 0,
                        recognitionQualityState: 0,
                        roadSideState: '',
                        frameNumberState: '',
                        classification: '',
                    });
                }
            }

        }
        else {
            alert('Please select details')
        }

    }
    const getIconByDefectName = (defectName) => {
        switch (defectName) {
            case 'Pothole': return Pothole;
            case 'Crack': return Crack;
            case 'Edge Deterioration': return EdgeDeterioration;
            case 'Bleeding': return Bleeding;
            case 'Crack Seals': return CrackSeals;
            case 'Patching': return Patch;
            case 'Vegetation': return Vegetation;
        }
    };
    const getIconByDefectClassification = (defectName) => {
        switch (defectName) {
            case 'Alligator': return Aligator;
            case 'Longitudinal': return Longitudinal;
            case 'Transverse': return Transverse;
            case 'Block': return Block;
        }
    };
    return (
        <div>
            <Paper
                elevation={0}
                sx={(theme) => ({
                    display: 'flex',
                    border: `2px solid ${theme.palette.divider}`,
                    flexWrap: 'wrap',
                    backgroundColor: '#f5f5f5',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '4px 8px',
                })}
            >
                <FormControl component="fieldset">
                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                        <FormControlLabel sx={{ marginLeft: '5px', marginTop: "5px" }} value="text" disabled label="Distress type:" control={<Radio sx={{ display: 'none' }} />} />
                        {dataInfo.defectName.map(defect => (
                            <Tooltip key={defect.key} title={defect.name}>
                                <IconButton
                                    onClick={() => handleChange("basicImagingClassState")({ target: { value: defect.key } })}
                                    sx={{
                                        border: fieldValues.basicImagingClassState === defect.key ? '2px solid #1976d2' : '2px solid transparent',
                                        boxShadow: fieldValues.basicImagingClassState === defect.key ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none',
                                        borderRadius: '8px',
                                        padding: '4px',
                                    }}
                                >
                                    <img src={getIconByDefectName(defect.name)} alt={defect.name} style={{ width: '24px', height: '24px' }} />
                                </IconButton>
                            </Tooltip>
                        ))}
                    </div>
                </FormControl>
                {/* Display Classification Icons if Defect Type is Crack */}
                {fieldValues.basicImagingClassState === 1 && (
                    <div style={{ display: 'flex', gap: '8px' }}>
                        <Divider flexItem orientation="vertical" sx={{ height: 'auto', mx: 0.5, my: 1, mr: 2 }} />
                        <FormControlLabel sx={{ marginLeft: '5px', marginTop: "5px" }} value="text" disabled label="Classification:" control={<Radio sx={{ display: 'none' }} />} />
                        {dataInfo.typeIndex.map(defect => (
                            <Tooltip key={defect.key} title={defect.value}>
                                <IconButton
                                    onClick={() => handleChange("classification")({ target: { value: defect.key } })}
                                    sx={{
                                        border: fieldValues.classification === defect.key ? '2px solid #1976d2' : '2px solid transparent',
                                        boxShadow: fieldValues.classification === defect.key ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none',
                                        borderRadius: '8px',
                                        padding: '4px',
                                    }}
                                >
                                    <img src={getIconByDefectClassification(defect.value)} alt={defect.value} style={{ transform: defect.value === 'Longitudinal' ? 'rotate(90deg)' : 'rotate(0deg)', width: '24px', height: '24px' }} />
                                </IconButton>
                            </Tooltip>
                        ))}
                    </div>
                )}
                <Divider flexItem orientation="vertical" sx={{ height: 'auto', mx: 0.5, my: 1, }} />
                <Tooltip title="Delete selected distress" arrow placement='bottom'>
                    <IconButton onClick={handleDelete}>
                        <DeleteOutline sx={{ color: 'black' }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Done" arrow placement='bottom'>
                    <IconButton onClick={handleSubmit}>
                        <DoneIcon sx={{ color: 'black' }} />
                    </IconButton>
                </Tooltip>
            </Paper></div>
    )
}

export default EditOptions