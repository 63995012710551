import { storage, cloudFunction } from '../../config/firebaseConfig';
import { db } from '../LocalDatabase';
//////////////////////////////////////////////////////////////////////////////////////////////
/// README: SECTION 1 => HANDLE THE AUTH
///

// Function to remove the access code
export const removeAccessCodeFromDexieDB = async () => {
  try {
    await db.accessCodes.clear();
    // //////console.log('Access code removed successfully.');
  } catch (error) {
    console.error('Error removing access code:', error);
  }
};
export const getAccessCodeFromDexieDB = async () => {
  try {
    const result = await db.accessCodes.toArray();
    return result.length > 0 ? result[0].accessCode : null;
  } catch (error) {
    console.error('Error getting access code:', error);
    return null;
  }
};
export const signIn = (email, password) => {
  return (dispatch, getState, { getFirebase }) => {
    // async call to login to firebase
    dispatch({
      type: "INIT_USER_ACTION",
    });

    const firebase = getFirebase();
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        dispatch({
          type: "SIGN_IN",
        });
      })
      .catch((error) => {
        //////console.log("SIGNING IN FAILED!");
        dispatch({
          type: "SIGN_IN_ERROR",
          error: error.message,
        });
      });
  };
};

export const signOut = (uidRequired = false, uid = "-1", accessCode = "-1") => {
  // //////console.log(accessCode, "accessCode")
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    // async call to logout to firebase
    const firebase = getFirebase();
    const firestore = getFirestore();
    let accessCodeFromDb = null;
    if (accessCode === null || accessCode.length <= 0) {
      accessCodeFromDb = await getAccessCodeFromDexieDB();
    }
    else {
      accessCodeFromDb = accessCode;
    }
    firebase
      .auth()
      .signOut()
      .then(async () => {
        dispatch({
          type: "SIGN_OUT",
        });
        if (uidRequired) {
          if (accessCodeFromDb.length > 0) {
            // //////console.log("signout-access-code", accessCodeFromDb)
            await firestore
              .collection(`Users`)
              .doc(uid)
              .set(
                {
                  accessCodes: {
                    [`${accessCodeFromDb}`]: {
                      active: false
                    },
                  },
                },
                { merge: true }
              );
          }
        }
        removeAccessCodeFromDexieDB();
      })
      .catch((error) => {
        //////console.log("SIGNING OUT FAILED!");
      });

  };
};

export const sendPasswordResetEmail = (email) => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: "INIT_FORGOT_PASSWORD_ACTION",
    });
    // async call to password reset email
    const firebase = getFirebase();
    firebase.auth().sendPasswordResetEmail(email).then(() => {
      dispatch({
        type: "PASSWORD_RESET",
        message: "Please check your inbox, we have sent you a reset link!"
      });
    }).catch((ex) => {
      dispatch({
        type: "PASSWORD_FORGOT_EXCEPTION",
        message: ex.message
      })
    })
  }
};

export const removeAuthErrorAndMessage = () => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: "CLEAR_ERROR_MSG"
    })
  }
}

export const sendVerificationEmail = () => {
  // return (dispatch, getState, { getFirebase }) => {
  //   // async call to verify email
  //   const firebase = getFirebase();
  //   firebase
  //     .auth()
  //     .currentUser.sendEmailVerification()
  //     .then(() => {
  //       dispatch({
  //         type: "VERIFY",
  //       });
  //     })
  //     .catch((error) => {
  //       //////console.log(error.message)
  //     });
  // };
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "SEND_VERIFICATION_EMAIL_START",
      emailVerificationLoader: true
    });
    cloudFunction.httpsCallable('userAccessControlCallableV2-sendVerificationEmail')(
      {

      }
    )
      .then((res) => {
        //////console.log(res, "sendVerificationEmail")
        /// dispatch to indicate successful request
        dispatch({
          type: "SEND_VERIFICATION_EMAIL_COMPLETE",
          emailVerificationLoader: false,
        });
      }).catch((error) => {
        dispatch({
          type: "SEND_VERIFICATION_EMAIL_ERROR",
          emailVerificationLoader: false
        });
      });
  }
};

export const updateButton = (organizationName, name, countryName, uid) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    dispatch({
      type: "UPDATE_DETAILS",
    });
    // updating the firestore data

    await firestore.collection("Users").doc(uid).update({
      // ...credentials,
      uid: uid,
      name: name,
      organizationName: organizationName,
      country: countryName
    });

    return dispatch({
      type: "UPDATED_DETAILS",
    });
  }
}

export const signUp = (fullName, email, orgName, password, country, image) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    dispatch({
      type: "INIT_USER_ACTION",
    });

    firebase.auth().createUserWithEmailAndPassword(
      email,
      password
    ).then(async (res) => {
      const uid = res.user.uid
      const employeeid = "emp_" + firestore.collection("Companies").doc().id;
      const newCoid = "webcom_" + uid;
      const ueid = uid + employeeid;
      const visibility = false;

      // await res.user.sendEmailVerification();
      let imagePath, storageReference;

      if (image) {
        const fileExtension = image.name.split('.').pop();
        imagePath = `Companies/${newCoid}/logo.${fileExtension}`;
        storageReference = storage.ref(imagePath);

        storageReference.put(image)
          .then((snapshot) => {
            // //////console.log('Uploaded a file!', snapshot);
          })
          .catch((error) => {
            // console.error('Error uploading file:', error);
          });
      }
      // const storageRef = storage().ref();
      // const imagePath = `Projects/${proid}`;
      // const imageRef = storageRef.child('');

      await firestore
        .collection("Companies")
        .doc(newCoid)
        .set({
          countryName: country,
          showFreshIntroMob: true,
          showFreshIntroWeb: true,
          isFreeTrialAvailed: false,
          companyName: `${orgName}` ?? "",
          companyNameLC: `${orgName}`.toLowerCase() ?? "",
          creatorUid: `${uid}` ?? "",
          creatorEmployeeid: `${employeeid}` ?? "",
          coid: `${newCoid}`,
          isStripeSetupComplete: false,
          imagePath: imagePath || "",
          stripeCusId: "",
          accessList: firestore.FieldValue.arrayUnion(`${ueid}`),
          accessMap: {
            [ueid]: {
              access: 0,
              name: `${fullName}` ?? "",
              uid: `${uid}` ?? "",
              employeeid: `${employeeid}` ?? "",
            },
          },
          allMembers: {
            [`${uid}`]: employeeid,
          },
          visibility: visibility,
          profilePic: "",
          email: `${email}` ?? "",
          address: "",
          phone: "",
          credits: 0,
          dateCreated: firestore.FieldValue.serverTimestamp(),
          totalActiveCompanyMembers: 1,
        });

      // // add project to company briefings
      await firestore
        .collection(`Companies/${newCoid}/Briefings`)
        .doc("projects")
        .set({
          all: {},
        });


      // add company to global collection
      // if (visibility) {
      //   await firestore
      //     .collection("Global")
      //     .doc("companies")
      //     .update({
      //       [`all.${newCoid}`]: {
      //         coid: `${newCoid}`,
      //         companyName: `${companyData.companyName}` ?? "",
      //         companyNameLC: `${companyData.companyName}`.toLowerCase() ?? "",
      //         creatorUid: `${userData.uid}` ?? "",
      //       },
      //     });
      // }
      await firestore.collection("Users").doc(uid).set({
        // ...credentials,
        uid: uid,
        countryName: country,
        email: email,
        name: fullName,
        nameLC: fullName.toLowerCase(),
        nameInitial: fullName[0],
        coid: `${newCoid}`,
        employeeid: `${employeeid}`,
        organizationName: orgName,
        dev: false,
      });

      dispatch({
        type: "NEW_COMPANY_ADDED",
        coid: newCoid,
      });
    }).then(() => {
      dispatch({
        type: "SIGN_UP",
      });
    }).catch((error) => {
      dispatch({
        type: "SIGN_UP_ERROR",
        error: error.message,
      });
    });

  };
};
export const signUpV2 = (fullName, email, orgName, password, country, image) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    dispatch({
      type: "INIT_USER_ACTION",
    });
    cloudFunction.httpsCallable('userAccessControlCallableV2-registerNewGeneralAccount')(
      {
        "fullName": fullName,
        "email": email,
        "orgName": orgName,
        "password": password,
        "country": country
      })
      .then(async (res) => {
        if (res.data.isDoneAndSent === false) {
          dispatch({
            type: "SIGN_UP_ERROR",
            error: res.data.responseMsg,
          });
          return;
        }
        await firebase.auth().signInWithEmailAndPassword(email, password);
        const newCoid = res.data.coid
        let imagePath;
        let storageReference;

        if (image) {
          const fileExtension = image.name.split('.').pop();
          imagePath = `Companies/${newCoid}/logo.${fileExtension}`;
          storageReference = storage.ref(imagePath);
          storageReference.put(image).then(async (snapshot) => {
            await firestore
              .collection("Companies")
              .doc(newCoid)
              .update({
                imagePath: imagePath,
              });
          }).catch(async (error) => {
            await firestore
              .collection("Companies")
              .doc(newCoid)
              .update({
                imagePath: "",
              });
          });
        }
        dispatch({
          type: "NEW_COMPANY_ADDED",
          coid: newCoid,
        });
        dispatch({
          type: "SIGN_UP",
        });
      }).catch((error) => {
        dispatch({
          type: "SIGN_UP_ERROR",
          error: error.message,
        });
      });
  }
};

//////////////////////////////////////////////////////////////////////////////////////////////
/// README: SECTION 2 => HANDLE UPDATES
///

export const openUserUpdateModal = () => {
  return {
    type: "OPEN_USER_UPDATE_MODAL",
  };
};

export const updateUser = (uid, selectedDp, phone, address) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    /// dispatch to indicate user update started
    dispatch({ type: "UPDATE_USER" });

    // async call to update the user to firebase
    const firestore = getFirestore();

    // upload dp
    var dpUrl = "";
    if (selectedDp !== null) {
      var result = await addUserDp(uid, selectedDp);
      if (result != null) {
        //////console.log(`Available at: ${result.substring(0, 5)}`);
        dpUrl = result;

        await firestore
          .collection("Users")
          .doc(uid)
          .update({
            profilePic: `${dpUrl}`,
          });
      }
    }

    /// dispatch to indicate successful file uploaded
    dispatch({ type: "USER_DP_UPDATED" });

    await firestore
      .collection("Users")
      .doc(uid)
      .update({
        phone: `${phone}` ?? "",
        address: `${address}` ?? "",
      });

    /// finally dispatch success action
    return dispatch({
      type: "USER_UPDATED",
    });
  };
};

/////////////////////////////////////////////////////////////////////////////////////////////
/// README: SECTION 3 => ADD FILES UTILS
///
async function addUserDp(uid, selectedFile) {
  //////console.log(`Uploading: dp`);
  var metadata = {
    contentType: "image/jpg",
  };

  var storageReference = storage.ref(`Users/${uid}/dp/dp.jpg`);
  var uploadTask = await storageReference.put(selectedFile, metadata);
  return storageReference
    .getDownloadURL()
    .then((downloadURL) => {
      return downloadURL;
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
}

export const customerSupport = (reasonToContact, name, email, proid, message) => {
  //////console.log(fromFrame, toFrame, proid)
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    /// dispatch to indicate initiation of request
    dispatch({
      type: "GENERATING_CUSTOMER_SUPPORT_REQUEST",
      customerSupportMessage: "",
      customerSupportLoader: true,
      customerSupportIsDoneAndSent: null,
    });

    cloudFunction.httpsCallable('customerSupport-generalSupportOnCall')(
      {
        "message": message,
        "queryType": reasonToContact,
        "email": email,
        "proid": proid,
        "name": name
      })
      .then((res) => {
        /// dispatch to indicate successful request
        dispatch({
          type: "GENERATING_CUSTOMER_SUPPORT_COMPLETE",
          customerSupportMessage: res.data.responseMsg,
          customerSupportIsDoneAndSent: res.data.isDoneAndSent,
          customerSupportLoader: false,
        });
      }).catch((error) => {
        // console.error(error,"error");
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "GENERATING_CUSTOMER_SUPPORT_ERROR",
          customerSupportMessage: "API failed to be called",
          customerSupportIsDoneAndSent: false,
          customerSupportLoader: false,
        });
      });
  }
}

export const removeCustomerSupportErrorAndMessage = () => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: "CLEAR_CUSTOMER_SUPPORT_ERROR_MSG"
    })
  }
}

// Linked account 
export const registerLinkedAccount = (uid, email, password) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "GENERATING_REGISTER_LINKED_ACCOUNT_REQUEST",
      registerLinkedAccountMessage: "",
      registerLinkedAccountLoader: true,
      registerLinkedAccountIsDoneAndSent: null,
      registerLinkedAccountData: null,
    });

    cloudFunction.httpsCallable('userAccessControlCallableV2-registerLinkedAccount')(
      {
        "uid": uid,
        "email": email,
        "password": password
      })
      .then((res) => {
        /// dispatch to indicate successful request

        dispatch({
          type: "GENERATING_REGISTER_LINKED_ACCOUNT_COMPLETE",
          registerLinkedAccountMessage: res.data.responseMsg,
          registerLinkedAccountIsDoneAndSent: res.data.isDoneAndSent,
          registerLinkedAccountLoader: false,
          registerLinkedAccountData: res.data.responseMsg.userRecord,
        });
      }).catch((error) => {
        // console.error(error,"error");
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "GENERATING_REGISTER_LINKED_ACCOUNT_ERROR",
          registerLinkedAccountMessage: "API failed to be called",
          registerLinkedAccountIsDoneAndSent: false,
          registerLinkedAccountLoader: false,
          registerLinkedAccountData: null,
        });
      });
  }
}
export const clearLinkedAccountMsgAndError = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "CLEAR_REGISTER_LINKED_ACCOUNT_MSG_ERROR",
    });
  }
}
export const checkLinkedAccountVerification = (uid) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "GENERATING_CHECKED_LINKED_ACCOUNT_VERIFICATION_REQUEST",
      checkLinkedAccountVerificationAccountMessage: "",
      checkLinkedAccountVerificationAccountLoader: true,
      checkLinkedAccountVerificationAccountIsDoneAndSent: null,
    });

    cloudFunction.httpsCallable('userAccessControlCallableV2-checkLinkedAccountVerification')(
      {
        "uid": uid
      })
      .then((res) => {
        //////console.log(res, "checkLinkedAccountVerification")
        /// dispatch to indicate successful request
        dispatch({
          type: "GENERATING_CHECKED_LINKED_ACCOUNT_VERIFICATION_COMPLETE",
          checkLinkedAccountVerificationAccountMessage: res.data.responseMsg,
          checkLinkedAccountVerificationAccountIsDoneAndSent: res.data.isDoneAndSent,
          checkLinkedAccountVerificationAccountLoader: false,
        });
      }).catch((error) => {
        // console.error(error,"error");
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "GENERATING_CHECKED_LINKED_ACCOUNT_VERIFICATION_ERROR",
          checkLinkedAccountVerificationAccountMessage: "API failed to be called",
          checkLinkedAccountVerificationAccountIsDoneAndSent: false,
          checkLinkedAccountVerificationAccountLoader: false
        });
      });
  }
}

export const removeLinkedAccountVerificationMessage = () => {
  //////console.log("removeLinkedAccountVerificationMessage")
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "REMOVE_LINKED_ACCOUNT_VERIFICATION_MESSAGE",
    });

  }
}
export const deleteLinkedAccount = (uid) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "GENERATING_DELETE_LINKED_ACCOUNT_REQUEST",
      deleteLinkedAccountMessage: "",
      deleteLinkedAccountIsDoneAndSent: null,
      deleteLinkedAccountLoader: true
    });

    cloudFunction.httpsCallable('userAccessControlCallableV2-deleteLinkedAccount')(
      {
        "uid": uid
      })
      .then((res) => {
        /// dispatch to indicate successful request
        dispatch({
          type: "GENERATING_DELETE_LINKED_ACCOUNT_COMPLETE",
          deleteLinkedAccountMessage: res.data.responseMsg,
          deleteLinkedAccountIsDoneAndSent: res.data.isDoneAndSent,
          deleteLinkedAccountLoader: false,
        });
      }).catch((error) => {
        // console.error(error,"error");
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "GENERATING_DELETE_LINKED_ACCOUNT_ERROR",
          deleteLinkedAccountMessage: "API failed to be called",
          deleteLinkedAccountIsDoneAndSent: false,
          deleteLinkedAccountLoader: false
        });
      });
  }
}
export const addAccessCode = (uid, name, role) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "GENERATING_ADD_ACCESS_CODE_REQUEST",
      addAccessCodeMessage: "",
      addAccessCodeIsDoneAndSent: null,
      addAccessCodeLoader: true
    });

    cloudFunction.httpsCallable('userAccessControlCallableV2-addAccessCode')(
      {
        "uid": uid,
        "name": name,
        "role": role
      })
      .then((res) => {
        //////console.log(res, "res")
        /// dispatch to indicate successful request
        dispatch({
          type: "GENERATING_ADD_ACCESS_CODE_COMPLETE",
          addAccessCodeMessage: res.data.responseMsg,
          addAccessCodeIsDoneAndSent: res.data.isDoneAndSent,
          addAccessCodeLoader: false,
        });
      }).catch((error) => {
        // console.error(error,"error");
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "GENERATING_ADD_ACCESS_CODE_ERROR",
          addAccessCodeMessage: "API failed to be called",
          addAccessCodeIsDoneAndSent: false,
          addAccessCodeLoader: false
        });
      });
  }
}
export const updateAccessCode = (uid, name, accessCode, role) => {
  //////console.log(uid, name, accessCode, role, "uid, name, accessCode, role")
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "GENERATING_UPDATE_ACCESS_CODE_REQUEST",
      updateAccessCodeMessage: "",
      updateAccessCodeIsDoneAndSent: null,
      updateAccessCodeLoader: true
    });

    cloudFunction.httpsCallable('userAccessControlCallableV2-updateAccessCodeInfo')(
      {
        "uid": uid,
        "accessCode": accessCode,
        "name": name,
        "role": role
        // "type": "1"
      })
      .then((res) => {
        //////console.log(res, "res")
        /// dispatch to indicate successful request
        dispatch({
          type: "GENERATING_UPDATE_ACCESS_CODE_COMPLETE",
          updateAccessCodeMessage: res.data.responseMsg,
          updateAccessCodeIsDoneAndSent: res.data.isDoneAndSent,
          updateAccessCodeLoader: false,
        });
      }).catch((error) => {
        // console.error(error,"error");
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "GENERATING_UPDATE_ACCESS_CODE_ERROR",
          updateAccessCodeMessage: "API failed to be called",
          updateAccessCodeIsDoneAndSent: false,
          updateAccessCodeLoader: false
        });
      });
  }
}
export const removeAccessCode = (uid, accessCode) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "GENERATING_REMOVE_ACCESS_CODE_REQUEST",
      removeAccessCodeMessage: "",
      removeAccessCodeIsDoneAndSent: null,
      removeAccessCodeLoader: true
    });

    cloudFunction.httpsCallable('userAccessControlCallableV2-removeAccessCode')(
      {
        "uid": uid,
        "accessCode": accessCode
      })
      .then((res) => {
        /// dispatch to indicate successful request
        dispatch({
          type: "GENERATING_REMOVE_ACCESS_CODE_COMPLETE",
          removeAccessCodeMessage: res.data.responseMsg,
          removeAccessCodeIsDoneAndSent: res.data.isDoneAndSent,
          removeAccessCodeLoader: false,
        });
      }).catch((error) => {
        // console.error(error,"error");
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "GENERATING_REMOVE_ACCESS_CODE_ERROR",
          removeAccessCodeMessage: "API failed to be called",
          removeAccessCodeIsDoneAndSent: false,
          removeAccessCodeLoader: false
        });
      });
  }
}
export const verifyAccessCode = (linkedAccountUid, accessCode) => {
  // //////console.log(linkedAccountUid, accessCode, "verifyAccessCode")
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "GENERATING_VERIFY_ACCESS_CODE_REQUEST",
      verifyAccessCodeMessage: "",
      verifyAccessCodeIsDoneAndSent: null,
      verifyAccessCodeLoader: true
    });

    cloudFunction.httpsCallable('userAccessControlCallableV2-verifyAccessCode')(
      {
        "linkedAccount": linkedAccountUid,
        "accessCode": accessCode
      })
      .then((res) => {
        /// dispatch to indicate successful request
        dispatch({
          type: "GENERATING_VERIFY_ACCESS_CODE_COMPLETE",
          verifyAccessCodeMessage: res.data.responseMsg,
          verifyAccessCodeIsDoneAndSent: res.data.isDoneAndSent,
          verifyAccessCodeLoader: false,
        });
      }).catch((error) => {
        // console.error(error,"error");
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "GENERATING_VERIFY_ACCESS_CODE_ERROR",
          verifyAccessCodeMessage: "API failed to be called",
          verifyAccessCodeIsDoneAndSent: false,
          verifyAccessCodeLoader: false
        });
      });
  }
}
export const removeVerifyAccessCodeMessage = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "REMOVE_VERIFY_ACCESS_CODE_MESSAGE",
    });

  }
}
export const removeDeleteLinkedAccountMessage = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "REMOVE_DELETE_LINKED_ACCOUNT_MESSAGE",
    });

  }
}
export const updateUserActiveStatus = (uid, accessCode) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore();
    const userDoc = firestore.collection('Users').doc(uid);

    // Check if the user document exists
    const userSnapshot = await userDoc.get();

    if (userSnapshot.exists) {
      // Retrieve existing access codes or initialize an empty object
      const existingAccessCodes = userSnapshot.data().accessCodes || {};

      // Check if the access code is already present
      if (existingAccessCodes.hasOwnProperty(accessCode)) {
        // Update the active status of the access code
        const updatedAccessCodes = {
          ...existingAccessCodes,
          [accessCode]: {
            active: true
          },
        };

        // Set the updated accessCodes object in the user document
        await userDoc.set(
          {
            accessCodes: updatedAccessCodes,
          },
          { merge: true }
        );
      }
    }
  };
};
// export const updateUserActiveStatus = (uid, accessCode) => {
//   return async (dispatch, getState, { getFirebase, getFirestore }) => {

//     const firestore = getFirestore();
//     await firestore
//       .collection(`Users`)
//       .doc(uid)
//       .set(
//         {
//           accessCodes: {
//             [`${accessCode}`]: {
//               active: true
//             },
//           },
//         },
//         { merge: true }
//       );

//   };
// };
export const updateUserInActiveStatus = (uid, accessCode) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore();
    await firestore
      .collection(`Users`)
      .doc(uid)
      .set(
        {
          accessCodes: {
            [`${accessCode}`]: {
              active: false
            },
          },
        },
        { merge: true }
      );

  };
};

export const changePassword = (currentPassword, newPassword) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: 'START_CHANGE_PASSWORD'
    });

    const firebase = getFirebase();
    const user = firebase.auth().currentUser;

    if (!user) {
      //console.log("User not authenticated");
      return dispatch({ type: 'PASSWORD_CHANGE_ERROR', payload: "User not authenticated" });
    }

    try {
      const credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        currentPassword,
      );
      await user.reauthenticateWithCredential(credential);

      await user.updatePassword(newPassword);
      //console.log('Password updated successfully');
      return dispatch({ type: 'PASSWORD_CHANGED' });
    } catch (error) {
      //console.log('Error reauthenticating:', error.message);
      return dispatch({ type: 'PASSWORD_CHANGE_ERROR', payload: error.message });
    }
  };
};

export const removeChangePasswordMessage = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "REMOVE_CHANGE_PASSWORD_MESSAGE",
    });

  }
}
// export const sendVerificationEmailToSecondaryAccount = () => {
//   return (dispatch, getState, { getFirebase }) => {
//     // async call to verify email
//     const firebase = getFirebase();
//     firebase.auth().sendSignInLinkToEmail("iamzainum.ca@gmail.com", { handleCodeInApp: true, url: "https://deeproad.stradaimaging.com" })
//       .then((result) => {
//         //////console.log(result, "result-sendVerificationEmailToSecondaryAccount")
//         dispatch({
//           type: "VERIFY",
//         });
//       })
//       .catch((error) => {
//         //////console.log(error.message)
//       });
//   };
// };